define("sirvo-web/routes/oca/ordenes-envio/procesar", ["exports", "sirvo-web/mixins/permission-validator", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators"], function (_exports, _permissionValidator, _emberChangeset, _emberChangesetValidations, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_permissionValidator.default, {
    kardex: Ember.inject.service(),
    ocaHelper: Ember.inject.service(),
    routePermission: 'OcaProcesarOrdenEnvio',
    company: Ember.computed.readOnly('currentCompany.company'),
    indexRoute: 'oca.ordenes-envio',
    validations: Ember.computed(function () {
      return {
        codigo: (0, _validators.validatePresence)(true),
        empresa: (0, _validators.validatePresence)(true),
        fecha: (0, _validators.validatePresence)(true)
      };
    }),
    ordenCompraValidations: Ember.computed(function () {
      return {
        codigo: (0, _validators.validatePresence)(true),
        ordenCompraId: (0, _validators.validatePresence)(true),
        empresa: (0, _validators.validatePresence)(true),
        fecha: (0, _validators.validatePresence)(true),
        serie: (0, _validators.validatePresence)(true),
        numero: (0, _validators.validatePresence)(true)
      };
    }),
    lineaValidations: Ember.computed(function () {
      return {
        ordenCompra: (0, _validators.validatePresence)(true),
        articulo: (0, _validators.validatePresence)(true),
        bodega: (0, _validators.validatePresence)(true),
        unidadMedida: (0, _validators.validatePresence)(true),
        cantidad: [(0, _validators.validatePresence)(true), // WARNING: This validation doesn't work
        (0, _validators.validateLength)({
          min: 1
        })],
        subtotal: (0, _validators.validatePresence)(true),
        iva: (0, _validators.validatePresence)(true),
        total: (0, _validators.validatePresence)(true)
      };
    }),

    model()
    /*params, transition*/
    {
      // Not allowed to pass just an ID, an object should be passed.
      return this.transitionTo(this.indexRoute);
    },

    setupController(controller, model) {
      this._super(...arguments);

      if (!model.get('ordenes.length')) {
        this.toast.error('Orden de envío sin líneas.');
        return this.transitionTo(this.indexRoute);
      }

      let record = this.store.createRecord('post-oca-orden-envio', {
        codigo: model.get('envio_codigo'),
        ordenEnvioId: model.get('id'),
        empresa: this.company,
        fecha: new Date()
      });
      let changeset = new _emberChangeset.default(record, (0, _emberChangesetValidations.default)(this.validations), this.validations);
      controller.setProperties({
        record,
        changeset
      }); // WARNING: Se dejaron los queries aquí intencionalmente, ya que los hooks del
      // model nunca se llaman porque a la ruta se le pasa un objeto completo (no ID).

      let codArticuloQuery = model.get('ordenes').reduce((codigos, ordenCompraData) => {
        codigos.push(...ordenCompraData.get('lineas').mapBy('producto_codigo'));
        return codigos;
      }, []).uniq(); // WARNING: El backend truena cuando se le manda un array de un solo item.

      if (codArticuloQuery.length === 1) {
        codArticuloQuery = {
          exact: codArticuloQuery[0]
        };
      }

      this.loader.setLoading(true);
      Ember.RSVP.hash({
        bodegas: this.kardex.queryBodegas(true),
        articulos: this.store.query('kar-articulo', {
          filter: {
            empresa: this.company.id,
            codarticulo: codArticuloQuery
          }
        }),
        tiposUbicaciones: this.store.query('kar-articulo-tipo-ubicacion', {
          filter: {
            empresa: this.company.id
          }
        })
      }).then(({
        bodegas,
        articulos,
        tiposUbicaciones
      }) => {
        controller.set('bodegas', bodegas);
        controller.set('tiposUbicaciones', tiposUbicaciones);
        let ordenLinea = null;
        let ordenCompraData = null;
        let ordenesData = Ember.A([]); // WARNING: Se usa un for nativo para hacer break cuando el artículo no existe.

        for (let h = 0; h < model.get('ordenes').length; h++) {
          ordenCompraData = model.get('ordenes')[h];
          let ordenCompra = this.store.createRecord('post-oca-orden-compra', {
            empresa: this.company,
            ordenEnvio: record,
            codigo: ordenCompraData.get('orden_compra_codigo'),
            ordenCompraId: ordenCompraData.get('orden_compra_id'),
            fecha: new Date(),
            // Ignoramos la fecha de la orden
            serie: ordenCompraData.get('serie'),
            numero: ordenCompraData.get('numero'),
            proveedor: ordenCompraData.get('proveedor_codigo'),
            proveedorNombre: ordenCompraData.get('proveedor_nombre'),
            justificacion: ordenCompraData.get('justificacion'),
            cuentaContable: ordenCompraData.get('proyecto_renglon_cuenta_contable'),
            centroCosto: ordenCompraData.get('centro_prespuesto_codigo'),
            centroIntegracion: ordenCompraData.get('centro_integracion_codigo'),
            centroActividad: ordenCompraData.get('centro_actividad_codigo')
          });
          let ordenChangeset = new _emberChangeset.default(ordenCompra, (0, _emberChangesetValidations.default)(this.ordenCompraValidations), this.ordenCompraValidations);
          let ordenData = {
            ordenCompra,
            ordenChangeset,
            ordenLineasData: Ember.A([])
          };

          for (let i = 0; i < ordenCompraData.get('lineas').length; i++) {
            ordenLinea = ordenCompraData.get('lineas')[i];
            let articulo = articulos.findBy('codarticulo', ordenLinea.get('producto_codigo'));

            if (!articulo) {
              this.toast.error(`No se encontró el artículo "${ordenLinea.get('producto_codigo')}".`);
              controller.discardChanges();
              return this.transitionTo(this.indexRoute);
            }

            let subtotal = ordenLinea.get('subtotal') || 0;
            let iva = ordenLinea.get('iva') || 0;
            let linea = this.store.createRecord('post-oca-orden-compra-linea', {
              ordenCompra,
              articulo: articulo,
              cantidad: ordenLinea.get('cantidad'),
              subtotal,
              iva,
              total: ordenLinea.get('total') || subtotal + iva,
              // Mockear el movimiento, solo para armar el query de unidades de medida.
              movimiento: {
                deha: 'D'
              }
            });
            let lineaChangeset = new _emberChangeset.default(linea, (0, _emberChangesetValidations.default)(this.lineaValidations), this.lineaValidations);
            let lineaData = {
              linea,
              lineaChangeset,
              unidadesMedida: Ember.A([]),
              ubicacionesChangesets: this.ocaHelper.createUbicacionesChangesets(tiposUbicaciones),
              ubicacionesMeta: {}
            };
            tiposUbicaciones.forEach(tipoUbicacion => {
              lineaData.ubicacionesMeta[tipoUbicacion.id] = {
                tipoUbicacion,
                ubicaciones: Ember.A([])
              };
            });
            ordenData.ordenLineasData.pushObject(lineaData);
            this.kardex.onChangesetArticuloUpdated(lineaChangeset, {
              codigoUnidadMedida: ordenLinea.get('unidad_medida_codigo')
            }).then(articuloData => {
              Ember.set(lineaData, 'unidadesMedida', articuloData.uMedidaBaseEquivalencias); // Procesar las ubicaciones por defecto del artículo.

              let localizacionesByBodega = this.kardex.groupArticuloUbicacionesByBodega(articuloData.localizaciones);
              let firstLocalizacion = localizacionesByBodega.get('firstObject');

              if (!firstLocalizacion) {
                return;
              } // Setear la bodega por defecto.


              lineaChangeset.set('bodega', firstLocalizacion.ubicacionBodega.get('bodega'));
              this.controller.actions.bodegaUpdated.apply(this.controller, [lineaData]); // Setear las ubicaciones por defecto.

              lineaData.ubicacionesChangesets.forEach(ubicacionChangeset => {
                let localizacion = firstLocalizacion.ubicaciones.findBy('tipoUbicacion.id', ubicacionChangeset.get('tipoUbicacion.id'));

                if (localizacion) {
                  ubicacionChangeset.set('articuloUbicacion', localizacion.get('articuloUbicacion'));
                  this.controller.actions.ubicacionUpdated.apply(this.controller, [lineaData, ubicacionChangeset, localizacion.get('articuloUbicacion')]);
                }
              });
            });
          }

          ordenesData.pushObject(ordenData);
        }

        controller.set('ordenesData', ordenesData);
      }).finally(() => this.loader.setLoading(false));
    }

  });

  _exports.default = _default;
});