define("sirvo-web/components/emision-contrasenas/impresion-documento", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    request: Ember.inject.service(),
    store: Ember.inject.service(),
    transaccion: null,
    serie: null,
    numero: null,
    valorml: null,
    valorme: null,
    numeroContrasena: null,

    async init() {
      this._super(...arguments);

      try {
        let transaccion = this.get('contrasenaNew.keytra');
        let serie = this.get('contrasenaNew.serie');
        let contrasenaEdit = await this.store.findRecord('que/contrasena', this.get('contrasenaNew.id'), {
          reload: true
        });
        let saldos = await this.request.get('contrasenas/saldos-sum', {
          keytra: transaccion
        });
        this.set('valorme', saldos.data.valorme);
        this.set('valorml', saldos.data.valor);
        this.set('transaccion', transaccion);
        this.set('serie', serie);
        this.set('numeroContrasena', contrasenaEdit.numero);
      } catch (error) {
        this.toast.error('Error al cargar los datos. ' + error);
      }
    },

    actions: {
      closeModal() {
        this.set('isVisible', false);
      },

      generaraImpresion() {
        try {
          this.loader.setLoading(true);
          let keytra = this.get('contrasenaNew.keytra');
          this.toast.info('Generando reporte');
          return this.request.get('contrasena/reporte-pdf', {
            keytra
          }).then(() => {
            this.toast.success('Reporte generado exitosamente');
            this.refreshData();
            this.send('closeModal');
          }).finally(() => this.loader.setLoading(false));
        } catch (error) {
          this.toast.success('Error al generar el reporte. '.error);
          this.loader.setLoading(false);
        }
      }

    }
  });

  _exports.default = _default;
});