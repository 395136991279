define("sirvo-web/controllers/ods/servicios/ordenes-servicio/record/documentos/inspeccion-mangueras", ["exports", "sirvo-web/mixins/com/arrange-items", "ember-changeset", "ember-changeset-validations", "sirvo-web/validations/ods/buceo-inmersion", "sirvo-web/validations/ods/buceo-inmersion-linea", "sirvo-web/utils/common"], function (_exports, _arrangeItems, _emberChangeset, _emberChangesetValidations, _buceoInmersion, _buceoInmersionLinea, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_arrangeItems.default, {
    request: Ember.inject.service(),
    errorHandler: Ember.inject.service(),
    indexRoute: null,
    ordenDocumento: null,
    selectedTemplate: null,

    resetProperties() {
      this.setProperties({
        ordenDocumento: null,
        inmersiones: Ember.A([]),
        selectedTemplate: null
      });
    },

    createBuceoInmersionChangeset(inmersion) {
      if (!inmersion) {
        let numero = this.inmersiones.length ? Math.max(...this.inmersiones.mapBy('numero')) || 0 : 0;
        inmersion = this.store.createRecord('ods/buceo-inmersion', {
          ordenDocumento: this.ordenDocumento,
          fechaInicio: new Date(),
          fechaFin: new Date(),
          numero: numero + 1
        });
      }

      let changeset = new _emberChangeset.default(inmersion, (0, _emberChangesetValidations.default)(_buceoInmersion.default), _buceoInmersion.default, {
        skipValidate: false
      });
      changeset.set('lineasList', inmersion.get('lineas').map(linea => this.createBuceoInmersionLineaChangeset(linea)));
      return changeset;
    },

    createBuceoInmersionLineaChangeset(inmersionLinea, values = {}) {
      if (!inmersionLinea) {
        inmersionLinea = this.store.createRecord('ods/buceo-inmersion-linea', { ...values
        });
      }

      return new _emberChangeset.default(inmersionLinea, (0, _emberChangesetValidations.default)(_buceoInmersionLinea.default), _buceoInmersionLinea.default, {
        skipValidate: false
      });
    },

    createItem(list, values = {}) {
      let manguera = list.length ? Math.max(...list.mapBy('manguera')) || 0 : 0;
      return this.createBuceoInmersionLineaChangeset(null, {
        manguera: manguera + 1,
        ...values
      });
    },

    getAllLineas() {
      return this.inmersiones.reduce((lineasArray, inmersion) => {
        lineasArray.push(...inmersion.get('lineasList'));
        return lineasArray;
      }, Ember.A([]));
    },

    saveLinea(linea) {
      return linea.validate().then(() => {
        if (linea.get('isInvalid')) {
          linea.set('hasErrors', true);
          return Ember.RSVP.resolve(linea);
        }

        return linea.save().then(record => {
          linea.set('hasErrors', false);
          return record;
        }).catch(error => {
          linea.set('hasErrors', true);
          this.errorHandler.handleSaveErrors(error, linea);
        });
      });
    },

    /**
     * Parsea los valores de tipo hora y los copia a los objetos Date.
     */
    setHoursToDates(inmersion) {
      if ((0, _common.isHour)(inmersion.get('horaInicio'))) {
        let parts = inmersion.get('horaInicio').split(':');
        inmersion.get('fechaInicio').setHours(parts[0], parts[1], 0);
      }

      if ((0, _common.isHour)(inmersion.get('horaFin'))) {
        let parts = inmersion.get('horaFin').split(':');
        inmersion.get('fechaFin').setHours(parts[0], parts[1], 0);
      }
    },

    /**
     * Usar este método luego de haber validado el changeset y haber seteado las horas.
     */
    validateFechas(inmersion) {
      if (!inmersion.get('isValid')) {
        return false;
      }

      if (!(0, _common.isHour)(inmersion.get('horaInicio')) || !(0, _common.isHour)(inmersion.get('horaFin'))) {
        this.toast.error(`Horas inválidas en Inmersión ${inmersion.get('numero')}.`);
        return false;
      }

      if (inmersion.get('fechaInicio') >= inmersion.get('fechaFin')) {
        this.toast.error(`La fecha fin debe ser mayor a la fecha inicio en Inmersión ${inmersion.get('numero')}.`);
        return false;
      }

      return true;
    },

    validate() {
      return Ember.RSVP.all([...this.inmersiones.map(inmersion => inmersion.validate()), ...this.getAllLineas().map(linea => linea.validate())]).then(() => {
        // Copiar las horas a los Dates.
        this.inmersiones.forEach(inmersion => this.setHoursToDates(inmersion));
        let inmersionesValid = this.inmersiones.reduce((prevIsValid, inmersion) => {
          return prevIsValid && inmersion.get('isValid') && this.validateFechas(inmersion);
        }, true);

        if (!inmersionesValid) {
          this.toast.error('Hay formularios con errores.');
          return Ember.RSVP.resolve(false);
        }

        let inmersionesLineasValid = this.getAllLineas().reduce((prevIsValid, linea) => prevIsValid && linea.get('isValid'), true);

        if (!inmersionesLineasValid) {
          this.toast.error('Hace falta completar la información de las líneas.');
          return Ember.RSVP.resolve(false);
        }

        return Ember.RSVP.resolve(true);
      });
    },

    saveChanges() {
      return this.validate().then(isValid => {
        if (!isValid) {
          return;
        }

        let savePromises = this.inmersiones.filterBy('isDirty').map(inmersion => {
          return inmersion.save().catch(error => this.errorHandler.handleSaveErrors(error, inmersion));
        });
        this.loader.setLoading(true);
        return Ember.RSVP.all(savePromises).then(() => {
          let lineasPromises = this.getAllLineas().map(linea => this.saveLinea(linea));
          return Ember.RSVP.all(lineasPromises);
        }).then(() => {
          this.toast.success('Registros guardados correctamente.');
        }).catch(error => {
          this.toast.error('Ocurrió un error al guardar sus cambios.');
          throw error;
        }).finally(() => this.loader.setLoading(false));
      });
    },

    actions: {
      onClose() {
        // Destroy records
        this.getAllLineas().forEach(linea => linea.get('_content').rollbackAttributes());
        this.inmersiones.forEach(inmersion => inmersion.get('_content').rollbackAttributes()); // Destroy changesets

        this.getAllLineas().forEach(linea => linea.rollback());
        this.inmersiones.forEach(inmersion => inmersion.rollback());
        this.transitionToRoute(this.indexRoute);
      },

      onAddInmersion() {
        let inmersion = this.createBuceoInmersionChangeset();
        this.inmersiones.pushObject(inmersion);
      },

      addLinea(list, inmersion) {
        let linea = this.actions.addItem.call(this, list);
        linea.set('inmersion', inmersion.get('_content'));
      },

      insertLinea(list, afterLinea, inmersion) {
        let linea = this.actions.insertItem.call(this, list, afterLinea);
        linea.set('inmersion', inmersion.get('_content'));
      },

      updateLibras(inmersion, propertyName, value) {
        inmersion.set(propertyName, value || null);
        inmersion.set('librasUsadas', inmersion.get('librasInicio') - inmersion.get('librasFin'));
      },

      trySaveLinea(linea) {
        if (!linea.get('isDirty')) {
          return;
        }

        linea.validate().then(() => {
          // Se puede guardar la línea si ya se guardó la cabecera.
          if (linea.get('isValid') && linea.get('inmersion.id')) {
            this.saveLinea(linea);
          }
        });
      },

      onSaveLinea(linea) {
        this.saveLinea(linea);
      },

      onSubmit() {
        return this.saveChanges();
      },

      deleteInmersion(list, inmersion) {
        this.send('openModal', 'popups/simple-confirm', {
          message: '¿Está seguro de eliminar el registro?',
          onSubmit: () => {
            this.actions.deleteItem.call(this, list, inmersion);
          }
        });
      },

      reporteInspeccionMangueras() {
        if (!this.ordenDocumento.get('id')) {
          this.toast.error('Debe guardar primero la orden de servicio.');
          return;
        }

        this.loader.setLoading(true);
        return this.request.get(`ods/orden-servicio-documentos/${this.ordenDocumento.get('id')}/inspeccion-mangueras`).then(() => {
          this.toast.success('Reporte generado exitosamente');
        }).finally(() => this.loader.setLoading(false));
      },

      generateFromTemplate(template) {
        if (!template || !template.id) {
          this.toast.error('Seleccione una plantilla.');
          return;
        } // NOTE: En el template está restringido que se pueda llamar esta acción
        // solo cuando aún no hay inmersiones registrados, pero por si acaso...


        if (this.inmersiones.length) {
          this.toast.error('Debe eliminar todas las inmersiones para generar a partir de una plantilla.');
          return;
        }

        this.send('openModal', 'popups/simple-confirm', {
          title: '¿Está seguro de generar a partir de una plantilla?',
          message: `Plantilla seleccionada: <strong>${template.get('descripcion')}</strong>`,
          onSubmit: () => {
            this.loader.setLoading(true);
            this.store.query('ods/buceo-inmersion-plantilla', {
              filter: {
                plantilla: template.id
              },
              include: 'lineas'
            }).then(inmersionesPlantillas => {
              inmersionesPlantillas = inmersionesPlantillas.sortBy('orden');
              inmersionesPlantillas.forEach(inmersionPlantilla => {
                let inmersion = this.store.createRecord('ods/buceo-inmersion', {
                  ordenDocumento: this.ordenDocumento,
                  fechaInicio: new Date(),
                  fechaFin: new Date(),
                  numero: inmersionPlantilla.get('numero'),
                  narrativa: inmersionPlantilla.get('narrativa')
                });
                inmersionPlantilla.get('lineas').forEach(linea => {
                  this.store.createRecord('ods/buceo-inmersion-linea', {
                    inmersion,
                    manguera: linea.get('manguera'),
                    posicion: linea.get('posicion'),
                    areaInspecionada: linea.get('areaInspecionada'),
                    alineada: linea.get('alineada'),
                    anomalias: linea.get('anomalias'),
                    orden: linea.get('orden') || 0
                  });
                });
                this.inmersiones.pushObject(this.createBuceoInmersionChangeset(inmersion));
              });
            }).finally(() => this.loader.setLoading(false));
          }
        });
      }

    }
  });

  _exports.default = _default;
});