define("sirvo-web/routes/ods/servicios/tipos-orden/record/estado-hechos-plantilla", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    indexRoute: 'ods.servicios.tipos-orden.record',
    modelName: 'ods/estado-hechos-plantilla',

    model(params) {
      let plantilla;
      let actividades = Ember.A([]);

      if (params.plantilla_id === 'new') {
        plantilla = this.store.createRecord(this.modelName, {
          tipoOrden: this.modelFor(this.indexRoute).record
        });
      } else {
        plantilla = this.store.find(this.modelName, params.plantilla_id);
        actividades = this.store.query('ods/estado-hechos-plantilla-actividad', {
          filter: {
            plantilla: params.plantilla_id
          },
          sort: 'orden'
        });
      }

      let promises = {
        plantilla,
        actividades
      };
      return Ember.RSVP.hash(promises);
    },

    setupController(controller, model) {
      this._super(...arguments);

      controller.setProperties({
        indexRoute: this.indexRoute,
        plantilla: model.plantilla,
        actividades: model.actividades.toArray()
      });
    }

  });

  _exports.default = _default;
});