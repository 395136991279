define("sirvo-web/controllers/carga-saldos-iniciales-cxp", ["exports", "sirvo-web/config/environment", "ember-uploader/uploaders/uploader"], function (_exports, _environment, _uploader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    request: Ember.inject.service(),
    session: Ember.inject.service(),
    toast: Ember.inject.service(),
    ajaxService: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),
    data: null,
    saveResult: null,
    saveResultPopupOpen: null,
    uploadUrl: null,
    persistValidData: 0,

    getSerializedData() {
      let data = {
        persistValidData: this.get('persistValidData') ? '1' : '0'
      };
      return data;
    },

    _getNewUploader() {
      let {
        token
      } = this.get('session.data.authenticated');

      let uploader = _uploader.default.create({
        url: this.uploadUrl,
        paramName: 'archivo',
        ajaxSettings: {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      });

      return uploader;
    },

    actions: {
      descargarPlantilla() {
        this.get('loader').setIsLoading();
        return this.request.get('carga-saldos-iniciales-cxp', {
          getTemplate: true
        }).then(() => {
          this.toast.success('Plantilla generada correctamente');
        }).finally(() => this.loader.setLoading(false));
      },

      cargarArchivo(guardarArchivo) {
        let {
          namespace
        } = _environment.default.API;
        this.set('persistValidData', guardarArchivo);
        this.set('uploadUrl', `/${namespace}/carga-saldos-iniciales-cxp`);
        this.loader.setIsLoading();
        return this.documentoChangeset.validate().then(() => {
          if (!this.documentoChangeset.get('isValid')) {
            this.loader.setNotLoading();
            this.toast.error('Hubo un problema al cargar el documento.');
            return;
          }

          const data = this.getSerializedData();

          let uploader = this._getNewUploader();

          return uploader.upload(this.documentoChangeset.get('archivo'), data).then(data => {
            if (!guardarArchivo) {
              this.toast.success('Documento validado correctamente');
              this.set('data', data);
            }

            if (guardarArchivo) {
              this.toast.success('Datos guardados correctamente');
              this.set('saveResult', data);
              this.set('saveResultPopupOpen', true);
            }
          }).catch(error => {
            this.toast.error('No se pudo cargar correctamente el documento.');
            throw error;
          }).finally(() => {
            this.loader.setLoading(false);
          });
        });
      }

    }
  });

  _exports.default = _default;
});