define("sirvo-web/controllers/cxp/contrasena/provision/registrar", ["exports", "moment", "sirvo-web/utils/date", "sirvo-web/models/que/contrasena-linea"], function (_exports, _moment, _date, _contrasenaLinea) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    company: Ember.computed.readOnly('currentCompany.company'),
    serie: null,
    numero: null,
    selectDirectorio: null,
    dropdownDirectorios: null,
    diasCR: null,
    fechaDocumento: null,
    fechaVencimiento: null,
    cuentaProvisionSeleccionada: null,
    dropdownCuentaProvision: null,
    tipoDocumentoSeleccionado: null,
    tipoDocumentosOpciones: null,
    nitSeleccionado: null,
    tipoIVASeleccionado: null,
    valorInputCombustible: null,
    amountBienes: 0,
    amountServicios: 0,
    baseGravadaBienes: null,
    baseGravadaServicios: null,
    impuestosBienes: null,
    impuestosServicios: null,
    totalSinImpuestos: null,
    contrasenaLineaId: null,
    totalImpuestos: null,
    totalFinal: null,
    botonAgregarFactura: false,
    modelClass: _contrasenaLinea.default,
    reload: true,
    years: (0, _date.lastYears)(),
    months: _date.monthIndexesFilters,
    isProvision: false,
    tipoImpuestoOpciones: null,
    openModalPolizaPrincipal: false,
    transaccionCreated: null,
    keytraContrasenaObtenido: null,
    fechaActual: Ember.computed(function () {
      let fechaActual = (0, _moment.default)();
      let fechaToDate = fechaActual.toDate();
      return (0, _moment.default)(fechaToDate).format('YYYY-MM-DD');
    }),
    mesAnio: Ember.computed(function () {
      let fechaActual = (0, _moment.default)();
      let formattedDate = fechaActual.format('YYYY-MM');
      return formattedDate;
    }),
    currentYear: Ember.computed(function () {
      return new Date().getFullYear();
    }),
    currentMonth: Ember.computed(function () {
      return String(new Date().getMonth() + 1).padStart(2, '0');
    }),
    currentYearMonth: Ember.computed('currentYear', 'currentMonth', function () {
      return `${this.currentYear}${this.currentMonth}`;
    }),
    modelSortingProperties: Ember.computed(function () {
      return [{
        propertyName: 'valor'
      }];
    }),
    modelFilters: Ember.computed('currentYearMonth', 'currentYear', 'currentMonth', function () {
      let filters = [];
      let startDate = (0, _moment.default)({
        year: this.currentYear,
        month: this.currentMonth - 1,
        day: 1
      });
      let endDate = (0, _moment.default)({
        year: this.currentYear,
        month: this.currentMonth - 1
      }).endOf('month');
      filters.push({
        selectedFilter: {
          filterName: 'fechaFac'
        },
        value: {
          after: startDate.format('YYYY-MM-DD'),
          before: endDate.format('YYYY-MM-DD')
        }
      });
      filters.push({
        selectedFilter: {
          filterName: 'keytraProvision'
        },
        value: {
          exact: ''
        }
      });
      filters.push({
        selectedFilter: {
          filterName: 'contrasena.codtipo'
        },
        value: {
          exact: 'QUE'
        }
      });
      filters.push({
        selectedFilter: {
          filterName: 'devuelto'
        },
        value: {
          exact: 0
        }
      });
      filters.push({
        selectedFilter: {
          filterName: 'esNotaCobro'
        },
        value: 0
      });
      filters.push({
        selectedFilter: {
          filterName: 'contrasena.codempQuedan'
        },
        value: {
          exact: this.company.get('codempresa')
        }
      });
      filters.push({
        selectedFilter: {
          filterName: 'devuelto'
        },
        value: 0
      });
      filters.push({
        selectedFilter: {
          filterName: 'fechaRecEncargado'
        },
        value: "!NULL"
      });
      return filters;
    }),
    includedRelationships: Ember.computed(function () {
      return ['contrasena', 'gru-directorio'];
    }),
    columns: Ember.computed(function () {
      return [{
        label: 'Fecha',
        valuePath: 'fechaFac',
        filtrable: true,
        filterName: 'fechaFac',
        width: '30%'
      }, {
        label: 'Directorio',
        valuePath: 'contrasena.directorio.razonsocial',
        filtrable: true,
        width: '30%'
      }, {
        label: 'Moneda',
        valuePath: 'codmoneda',
        sortingPropertyName: 'codmoneda',
        width: '15%'
      }, {
        label: 'Valor',
        valuePath: 'valor',
        width: '20%'
      }, {
        label: 'Valor Doc ML',
        valuePath: 'valorme',
        width: '20%'
      }, {
        label: 'Descripcion',
        valuePath: 'descripcion',
        width: '35%'
      }, {
        label: 'Contrasena',
        valuePath: 'contrasena.keytra',
        width: '25%'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-alt',
            action: this.actions.openModal.bind(this)
          }
        }
      }];
    }),

    async getDirectorio(codigoDirectorio) {
      return await this.store.query('gru-directorio', {
        filter: {
          coddirectorio: codigoDirectorio,
          fechabaja: 'NULL'
        }
      }).then(directorioList => {
        this.set('dropdownDirectorios', directorioList);
        let primerDirectorio = directorioList.get('firstObject');

        if (primerDirectorio) {
          this.send('selectDirectorio', primerDirectorio);
          this.send('loadClasificaciones');
        }

        return directorioList;
      }).catch(error => {
        this.toast.error('No existen registros con el siguiente código de directorio  ' + codigoDirectorio);
        this.toast.error('Error ' + error);
        this.set('selectDirectorio', null);
        this.set('dropdownDirectorios', []);
      });
    },

    async searchNit() {
      this.loader.setIsLoading();
      return await this.store.query('iva-nit', {
        filter: {
          nit: this.selectDirectorio.nit
        }
      }).then(ivaNit => {
        let ivaNitFirst = ivaNit.get('firstObject');
        let valorTotal = parseFloat(this.amountBienes.toString().replace(/,/g, '')) + parseFloat(this.amountServicios.toString().replace(/,/g, ''));

        if (ivaNitFirst) {
          this.set('usaServicios', ivaNitFirst.usaServicios);
          this.notifyPropertyChange('usaServicios');
          this.set('ivaNitObject', ivaNitFirst);
          this.setUsaServiciosOrBienes(valorTotal);
          return ivaNitFirst;
        }

        this.set('notFound', false);
        this.set('ivaNitObject', ivaNitFirst);
        return ivaNitFirst;
      }).catch(error => {
        this.toast.error('Error al buscar el NIT: ' + error);
        this.set('notFound', true);
      }).finally(() => this.loader.setNotLoading());
    },

    setUsaServiciosOrBienes(value) {
      this.loader.setIsLoading();

      try {
        if (this.usaServicios === true) {
          this.set('amountServicios', this.formatNumberWithCommas(value));
          this.set('amountBienes', 0.0);
          this.notifyPropertyChange('amountServicios');
          this.notifyPropertyChange('amountBienes');
          return;
        }

        this.set('amountBienes', this.formatNumberWithCommas(value));
        this.set('amountServicios', 0.0);
        this.notifyPropertyChange('amountServicios');
        this.notifyPropertyChange('amountBienes');
      } catch (error) {
        this.toast.error('Error al calcular el valor de los servicios o bienes: ' + error);
      } finally {
        this.loader.setNotLoading();
      }
    },

    formatNumberWithCommas(value) {
      let cleanedValue = value.toString().replace(/,/g, '');
      let numerico = parseFloat(cleanedValue);

      if (isNaN(numerico)) {
        return '0.00';
      }

      let parts = numerico.toFixed(2).split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return parts.join('.');
    },

    async loadTipoDocumentos() {
      return await this.request.get('documentos-empresa/sistema/tipo-documento', {
        codSistema: 'CXP'
      }).then(response => {
        let tipoDocumentos = response.data;
        let tipoDocSeleccionado = tipoDocumentos.findBy('codtipo', 'FA');
        this.set('tipoDocumentoSeleccionado', tipoDocSeleccionado);
        return response.data;
      }).catch(error => {
        this.toast.error('Error al cargar tipos de documentos. ' + error);
        throw error;
      });
    },

    async loadTiposIva() {
      return await this.store.query('iva-tipo', {
        filter: {
          codpais: 'GT',
          clasificacion: 'C'
        }
      }).then(tiposDeIVA => {
        let tipoDeIvaSeleccionado = tiposDeIVA.find(tipoIVA => tipoIVA.tipo === 'LOCAL');
        this.set('tipoIVASeleccionado', tipoDeIvaSeleccionado);
        this.set('tipoImpuestoOpciones', tiposDeIVA);
        return tiposDeIVA;
      }).catch(error => {
        this.toast.error('Error al cargar los tipos de impuesto. ' + error);
      });
    },

    actions: {
      async openModal(contrasenaLinea) {
        await this.loadTiposIva();
        await this.loadTipoDocumentos();
        let fechaFactura = (0, _moment.default)(contrasenaLinea.fechaFac).format('YYYY-MM-DD');
        this.set('fechaDocumento', fechaFactura);
        this.set('serie', contrasenaLinea.serie);
        this.set('contrasenaLineaId', contrasenaLinea.id);
        this.set('numero', contrasenaLinea.numero);
        this.set('isProvision', true);
        this.getDirectorio(contrasenaLinea.coddirectorio);
        this.toggleProperty('openModal');
        this.send('resetImpuestos');
        this.setUsaServiciosOrBienes(contrasenaLinea.valor);
        this.send('calculateTaxAndTotal');
      },

      onclose() {
        if (this.openModal) {
          this.set('openModal', false);
        }
      },

      resetData() {
        this.listaFacturas = [];
        this.set('serie', '');
        this.set('numero', '');
        this.set('selectDirectorio', null);
        this.set('clasificacionSeleccionada', null);
        this.set('dropdownClasificaciones', null);
        this.set('cuentaProvisionSeleccionada', null);
        this.set('dropdownCuentaProvision', null);
        this.set('descripcionContable', null);
        this.set('fechaVencimiento', null);
        this.set('valorInputCombustible', null);
      },

      async selectDirectorio(directorio) {
        this.set('selectDirectorio', directorio);

        if (this.selectDirectorio) {
          let nitDirectorio = directorio.nit;
          let diasCrDirectorio = directorio.diasCrCxp;
          let direccion = directorio.direccion;
          this.set('nitSeleccionado', nitDirectorio);
          this.set('diasCR', diasCrDirectorio);
          this.set('direccion', direccion);
          this.notifyPropertyChange('diasCR');
          this.notifyPropertyChange('fechaOrdenCompraSeleccionada');
          this.notifyPropertyChange('nitDirectorio');
          this.notifyPropertyChange('selectDirectorio');
          await this.send('updateFechaPago');
          await this.searchNit();
          await this.send('loadClasificaciones');
          return;
        }

        this.toast.info('No se ha seleccionado un directorio.');
      },

      loadClasificaciones() {
        this.request.get('gru-directorio-clasifica-by-cod-grupo', {
          codDirectorio: this.selectDirectorio.coddirectorio
        }).then(clasificaciones => {
          this.set('dropdownClasificaciones', clasificaciones.data);
          let clasificacion = clasificaciones.data.find(clasificacion => clasificacion.tipo === 'PROVEEDORES');
          this.set('clasificacionSeleccionada', clasificacion);
          this.send('getCuentaProvision');
        }).catch(error => {
          this.toast.error('Error al cargar la clasificacion. ' + error);
        });
      },

      clasificacionSeleccionada(clasificacion) {
        if (!clasificacion) {
          return;
        }

        this.set('clasificacionSeleccionada', clasificacion);
        this.send('getCuentaProvision');
      },

      getCuentaProvision() {
        this.loader.setIsLoading();
        this.store.query('clasificacion-cuenta', {
          filter: {
            grupo: this.company.get('grupo.id'),
            empresa: this.company.get('id'),
            codclasifica: {
              exact: this.clasificacionSeleccionada.codclasifica
            },
            codperiodo: this.periodo
          }
        }).then(cuentasProvisionResponse => {
          let selectedCuentaProvision = cuentasProvisionResponse.get('firstObject');
          this.set('dropdownCuentaProvision', cuentasProvisionResponse);
          this.set('cuentaProvisionSeleccionada', selectedCuentaProvision);
        }).catch(error => {
          this.toast.error('Error al cargar la cuenta provision. ' + error);
        }).finally(() => this.loader.setNotLoading());
      },

      obtenerDirectorioPorNombre() {
        alert('obtenerDirectorioPorNombre');
      },

      openModalDocumentos() {
        alert('openModalDocumentos');
      },

      async updateFechaPago() {
        let diasCrCxp = this.selectDirectorio.dias_cr_cxp || this.diasCR;
        this.set('diasCR', diasCrCxp);
        let fechaRegistro = this.fechaActual;
        this.loader.setLoading(true);
        await this.request.get('contrasenas/fecha-pago', {
          diasCrCxp,
          fechaRegistro
        }).then(response => {
          let date = response.data[0].proximoPago;
          let dateMoment = (0, _moment.default)(date, 'DD-MM-YYYY').toDate();
          let fechaVencimiento = (0, _moment.default)(dateMoment).format('YYYY-MM-DD');
          this.set('fechaVencimiento', fechaVencimiento);
          this.notifyPropertyChange('fechaVencimiento');
          this.loader.setNotLoading();
        }).catch(error => {
          this.toast.error('Error al cargar la clasificacion. ' + error);
        });
        this.loader.setNotLoading();
      },

      cuentaProvisionSeleccionada() {
        alert('cuentaProvisionSeleccionada');
      },

      seleccionarTipoDocumento(tipoDoc) {
        if (tipoDoc.tipoDocIva === 'FPC') {
          let tipoIVASeleccionado = this.tipoImpuestoOpciones.find(tipoIVA => tipoIVA.tipo === 'P.CONTRIB');

          if (tipoIVASeleccionado) {
            this.send('seleccionarTipoIVa', tipoIVASeleccionado);
          }
        }

        this.set('tipoDocumentoSeleccionado', tipoDoc);
      },

      seleccionarTipoIVa(tipoIVA) {
        this.set('tipoIVASeleccionado', tipoIVA);
        this.send('calculateTaxAndTotal');
      },

      formatearCombustible() {
        const valorFiltrado = parseFloat(this.valorInputCombustible).toFixed(2);
        this.set('valorInputCombustible', valorFiltrado);
        this.send('calculateTaxAndTotal');
      },

      calculateTaxAndTotal() {
        let porcentajeDeIVAProcesado = parseFloat(this.tipoIVASeleccionado.porcentaje) / 100 || 0.0;
        let combustibleTotal = this.valorInputCombustible ? parseFloat(this.valorInputCombustible.toString().replace(/,/g, '')) || 0.0 : null;
        let cantidadBienesProcesados = this.amountBienes ? parseFloat(this.amountBienes.toString().replace(/,/g, '')) || 0.0 : 0.0;
        let cantidadServiciosProcesados = this.amountServicios ? parseFloat(this.amountServicios.toString().replace(/,/g, '')) || 0.0 : 0.0;
        let montoSinIVABienes = Math.max(cantidadBienesProcesados - combustibleTotal, 0);
        let combustibleRestante = combustibleTotal > cantidadBienesProcesados ? combustibleTotal - cantidadBienesProcesados : 0;
        let montoSinIVAServicios = Math.max(cantidadServiciosProcesados - combustibleRestante, 0);
        let bienesBaseGravada = parseFloat((montoSinIVABienes / (1 + porcentajeDeIVAProcesado)).toFixed(2));
        let serviciosBaseGravada = parseFloat((montoSinIVAServicios / (1 + porcentajeDeIVAProcesado)).toFixed(2));
        let impuestoSobreBienes = parseFloat((montoSinIVABienes - bienesBaseGravada).toFixed(2));
        let impuestoSobreServicios = parseFloat((montoSinIVAServicios - serviciosBaseGravada).toFixed(2));
        let totalImpuestosProcesado = impuestoSobreBienes + impuestoSobreServicios;
        let totalFinal = bienesBaseGravada + serviciosBaseGravada;
        this.setProperties({
          amountBienes: this.formatNumberWithCommas(cantidadBienesProcesados),
          amountServicios: this.formatNumberWithCommas(cantidadServiciosProcesados),
          baseGravadaBienes: this.formatNumberWithCommas(bienesBaseGravada),
          baseGravadaServicios: this.formatNumberWithCommas(serviciosBaseGravada),
          impuestosBienes: this.formatNumberWithCommas(impuestoSobreBienes),
          impuestosServicios: this.formatNumberWithCommas(impuestoSobreServicios),
          totalImpuestos: this.formatNumberWithCommas(totalImpuestosProcesado),
          totalSinImpuestos: this.formatNumberWithCommas(totalFinal),
          totalFinal: this.formatNumberWithCommas(cantidadBienesProcesados + cantidadServiciosProcesados)
        });
      },

      procesar() {
        this.loader.setIsLoading();
        let dataSend = [{
          codempresa: this.get('company.codempresa'),
          codsistema: 'CXP',
          nit: this.selectDirectorio.nit,
          codtipo: this.tipoDocumentoSeleccionado.codtipo,
          codtipodoctos: this.tipoDocumentoSeleccionado.codtipodoctos,
          codtipoIva: this.tipoDocumentoSeleccionado.codtipo,
          serie: this.serie,
          numero: this.numero,
          deha: this.tipoDocumentoSeleccionado.deha,
          tipoIva: this.tipoIVASeleccionado.tipo,
          valorCombustible: this.valorInputCombustible ? parseFloat(this.valorInputCombustible.replace(/,/g, '')) : null,
          descripcionContable: this.get('descripcionContable'),
          fechaFactura: (0, _moment.default)(this.fechaActual, 'YYYY-MM-DD').toDate(),
          codClasifica: this.clasificacionSeleccionada ? this.clasificacionSeleccionada.codclasifica : null,
          numeroDocumento: this.serie + '-' + this.numero,
          ivaRetencion: 0,
          valordocml: parseFloat(this.totalFinal.replace(/,/g, '')),
          totalBienes: parseFloat(this.totalFinal.replace(/,/g, '')),
          valorme: 0,
          totalServicios: parseFloat(this.baseGravadaServicios.replace(/,/g, '')),
          baseExentaBienes: 0,
          baseExentaServicios: 0,
          tipoDocumentoImpuesto: '',
          numDocumentoImpuesto: '',
          valorOtrosNoGravados: 0,
          ordenCedula: '',
          registroCedula: '',
          iva: parseFloat(this.totalImpuestos.replace(/,/g, '')),
          totalDoc: parseFloat(this.totalFinal.replace(/,/g, '')),
          valor: parseFloat(this.totalFinal.replace(/,/g, '')),
          baseNoGravada: 0,
          baseGrabadaBienes: parseFloat(this.baseGravadaBienes.replace(/,/g, '')),
          baseGravadaServicios: parseFloat(this.baseGravadaServicios.replace(/,/g, '')),
          fechaVencimiento: this.fechaVencimiento,
          direccion: this.direccion,
          diasCredito: this.diasCR,
          yearMes: this.mesAnio,
          codigoDirectorioProveedor: this.selectDirectorio.coddirectorio,
          idIvaNit: this.ivaNitObject ? this.ivaNitObject.id : null,
          usaServicios: this.usaServicios,
          esContrasenaLinea: true,
          idContrasenaLinea: this.contrasenaLineaId
        }];
        let camposAVerificar = ['serie', 'numero', 'totalDoc', 'valor', 'iva', 'codempresa', 'codsistema', 'nit', 'codtipo', 'descripcionContable'];

        let encontrarCamposInvalidos = elemento => {
          let camposInvalidos = camposAVerificar.filter(campo => !elemento[campo] && elemento[campo] !== 0);
          return camposInvalidos;
        };

        let camposInvalidos = dataSend.flatMap(encontrarCamposInvalidos);

        if (camposInvalidos.length > 0) {
          this.toast.error(`Algunos campos requeridos están vacíos o son nulos: ${camposInvalidos.join(', ')}.`);
          this.loader.setNotLoading();
          return;
        }

        let json = {
          data: dataSend
        };
        this.request.post('cxp/registrar-factura', json).then(response => {
          this.toast.success(response.message);
          this.set('transaccionCreated', response.data.keytra);
          this.set('dataSend', null); // this.set('listaFacturas', null);
          // this.send('setLastRoute');

          this.send('onclose');
          this.set('openModalPolizaPrincipal', true);
          this.send('reloadPage');
        }).catch(error => {
          this.toast.error(error.status + ' ' + error.statusText);
        }).finally(() => this.loader.setNotLoading());
      },

      reloadTable() {
        this.set('reload', true);
      },

      reloadPage() {
        this.set('selectDirectorio', null);
        this.set('dropdownDirectorios', []);
      },

      resetImpuestos() {
        this.set('amountBienes', 0.0);
        this.set('amountServicios', 0.0);
        this.set('totalFinal', 0.0);
        this.set('baseGravadaBienes', 0.0);
        this.set('impuestosBienes', 0.0);
        this.set('totalImpuestos', 0.0);
        this.set('baseGravadaServicios', 0.0);
        this.set('impuestosServicios', 0.0);
        this.set('totalSinImpuestos', 0.0);
      },

      oncloseModalPolizaPrincipal() {
        if (this.openModalPolizaPrincipal == true) {
          this.set('openModalPolizaPrincipal', false);
        }
      },

      closeModalProcesarFacturas() {
        this.set('isProvision', false);
      },

      handleActualizar() {
        return;
      }

    }
  });

  _exports.default = _default;
});