define("sirvo-web/initializers/request", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.inject('route', 'request', 'service:request');
    application.inject('controller', 'request', 'service:request');
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});