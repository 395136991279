define("sirvo-web/routes/ods/servicios/ordenes-servicio/record/documentos/estado-hechos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    indexRoute: 'ods.servicios.ordenes-servicio.record.documentos',

    model(params) {
      let parentModel = this.modelFor(this.indexRoute);
      let promises = {
        ordenDocumento: this.store.find('ods/orden-servicio-documento', params.orden_servicio_documento_id),
        plantillas: this.store.query('ods/estado-hechos-plantilla', {
          filter: {
            tipoOrden: parentModel.record.get('tipoOrden.id')
          }
        }),
        actividades: this.store.query('ods/estado-hechos-actividad', {
          filter: {
            ordenDocumento: params.orden_servicio_documento_id
          },
          sort: 'orden'
        })
      };
      return Ember.RSVP.hash(promises);
    },

    setupController(controller, model) {
      this._super(...arguments);

      controller.resetProperties();
      controller.setProperties({
        indexRoute: this.indexRoute,
        ordenDocumento: model.ordenDocumento,
        actividades: model.actividades.toArray(),
        plantillas: model.plantillas,
        selectedTemplate: model.plantillas.get('firstObject')
      });
    }

  });

  _exports.default = _default;
});