define("sirvo-web/utils/common", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isHour = _exports.isNumeric = _exports.isObject = _exports.getPromiseContent = void 0;

  const getPromiseContent = (objectPromise, propertyName = null) => {
    let result = propertyName ? objectPromise.get(propertyName) : objectPromise;

    if (result && result.isFulfilled !== undefined) {
      result = result.content;
    }

    return result;
  };

  _exports.getPromiseContent = getPromiseContent;

  const isObject = value => {
    return typeof value === 'object' && value !== null;
  };

  _exports.isObject = isObject;

  const isNumeric = value => {
    return !isNaN(value) && !isNaN(parseFloat(value));
  };

  _exports.isNumeric = isNumeric;

  const isHour = value => {
    // Formato: 00:00
    let regex = /^\d+:\d+$/;
    return regex.test(value);
  };

  _exports.isHour = isHour;
});