define("sirvo-web/models/transaccion", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    codempresa: (0, _model.attr)('string'),
    keytra: (0, _model.attr)('string'),
    codsistema: (0, _model.attr)('string'),
    codgrupo: (0, _model.attr)('string')
  });

  _exports.default = _default;
});