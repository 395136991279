define("sirvo-web/routes/admon/procesos/notificaciones/index", ["exports", "servir-ember-utilities/routes/abstract-module-index", "sirvo-web/mixins/access-validator"], function (_exports, _abstractModuleIndex, _accessValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend(_accessValidator.default, {
    routeAccess: 'ADMON-NOTIFICACIONES'
  });

  _exports.default = _default;
});