define("sirvo-web/models/ods/estado-hechos-actividad", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    fecha: attr('custom-date'),
    descripcion: attr('string'),
    ordenDocumento: belongsTo('ods/orden-servicio-documento'),
    orden: attr('number'),
    hasValidFecha: Ember.computed('fecha', function () {
      let momentDate = (0, _moment.default)(this.fecha || '');
      return momentDate.isValid();
    }),
    horas: Ember.computed('fecha', {
      set(key, value) {
        if (this.hasValidFecha) {
          this.fecha.setHours(value || 0); // Custom property because 'hasDirtyAttributes' doesn't help.

          this.set('hasChanges', true);
        }

        return value;
      },

      get() {
        if (!this.hasValidFecha) {
          return null;
        }

        return (0, _moment.default)(this.fecha).format('HH');
      }

    }),
    minutos: Ember.computed('fecha', {
      set(key, value) {
        if (this.hasValidFecha) {
          this.fecha.setMinutes(value || 0); // Custom property because 'hasDirtyAttributes' doesn't help.

          this.set('hasChanges', true);
        }

        return value;
      },

      get() {
        if (!this.hasValidFecha) {
          return null;
        }

        return (0, _moment.default)(this.fecha).format('mm');
      }

    }),

    save()
    /*options*/
    {
      return this._super(...arguments).then(result => {
        this.set('hasChanges', false);
        return result;
      });
    }

  });

  _exports.default = _default;
});