define("sirvo-web/models/clasificacion-cuenta", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    // codempresa: attr('string'),
    empresa: (0, _model.belongsTo)('empresa'),
    grupo: (0, _model.belongsTo)('grupo'),
    periodo: (0, _model.belongsTo)('emp-periodo'),
    // empresa_id: attr('int'),

    /**
     * Atributo deprecado en favor de grupo
     */
    // codgrupo: attr('string'),

    /**
     * Atributo deprecado en favor de grupo
     */
    // grupo_id: attr('int'),
    codclasifica: (0, _model.attr)('string'),
    // codperiodo: attr('string'),
    // periodo_id: attr('int'),
    codcuenta: (0, _model.attr)('string'),
    codcc: (0, _model.attr)('string'),
    codci: (0, _model.attr)('string'),
    codca: (0, _model.attr)('string')
  });

  _exports.default = _default;
});