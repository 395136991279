define("sirvo-web/controllers/ods/tablas/documentos/record/inspeccion-mangueras-plantilla", ["exports", "sirvo-web/mixins/com/arrange-items", "ember-changeset", "ember-changeset-validations", "sirvo-web/validations/ods/buceo-inmersion-plantilla", "sirvo-web/validations/ods/buceo-inmersion-linea-plantilla"], function (_exports, _arrangeItems, _emberChangeset, _emberChangesetValidations, _buceoInmersionPlantilla, _buceoInmersionLineaPlantilla) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_arrangeItems.default, {
    request: Ember.inject.service(),
    errorHandler: Ember.inject.service(),
    parentCtrl: Ember.inject.controller('ods.tablas.documentos.record'),
    indexRoute: null,
    plantilla: null,

    resetProperties() {
      this.setProperties({
        plantilla: null,
        inmersiones: Ember.A([])
      });
    },

    createBuceoInmersionChangeset(inmersion) {
      if (!inmersion) {
        let numero = this.inmersiones.length ? Math.max(...this.inmersiones.mapBy('numero')) || 0 : 0;
        inmersion = this.store.createRecord('ods/buceo-inmersion-plantilla', {
          plantilla: this.plantilla,
          numero: numero + 1
        });
      }

      let changeset = new _emberChangeset.default(inmersion, (0, _emberChangesetValidations.default)(_buceoInmersionPlantilla.default), _buceoInmersionPlantilla.default, {
        skipValidate: false
      });
      changeset.set('lineasList', inmersion.get('lineas').map(linea => this.createBuceoInmersionLineaChangeset(linea)));
      return changeset;
    },

    createBuceoInmersionLineaChangeset(inmersionLinea, values = {}) {
      if (!inmersionLinea) {
        inmersionLinea = this.store.createRecord('ods/buceo-inmersion-linea-plantilla', { ...values
        });
      }

      return new _emberChangeset.default(inmersionLinea, (0, _emberChangesetValidations.default)(_buceoInmersionLineaPlantilla.default), _buceoInmersionLineaPlantilla.default, {
        skipValidate: false
      });
    },

    createItem(list, values = {}) {
      let manguera = list.length ? Math.max(...list.mapBy('manguera')) || 0 : 0;
      return this.createBuceoInmersionLineaChangeset(null, {
        manguera: manguera + 1,
        ...values
      });
    },

    getAllLineas() {
      return this.inmersiones.reduce((lineasArray, inmersion) => {
        lineasArray.push(...inmersion.get('lineasList'));
        return lineasArray;
      }, Ember.A([]));
    },

    saveLinea(linea) {
      return linea.validate().then(() => {
        if (linea.get('isInvalid')) {
          linea.set('hasErrors', true);
          return Ember.RSVP.resolve(linea);
        }

        return linea.save().then(record => {
          linea.set('hasErrors', false);
          return record;
        }).catch(error => {
          linea.set('hasErrors', true);
          this.errorHandler.handleSaveErrors(error, linea);
        });
      });
    },

    validate() {
      return Ember.RSVP.all([...this.inmersiones.map(inmersion => inmersion.validate()), ...this.getAllLineas().map(linea => linea.validate())]).then(() => {
        if (!this.validatePlantilla(this.plantilla)) {
          return Ember.RSVP.resolve(false);
        }

        let inmersionesValid = this.inmersiones.reduce((prevIsValid, inmersion) => prevIsValid && inmersion.get('isValid'), true);

        if (!inmersionesValid) {
          this.toast.error('Hay formularios con errores.');
          return Ember.RSVP.resolve(false);
        }

        let inmersionesLineasValid = this.getAllLineas().reduce((prevIsValid, linea) => prevIsValid && linea.get('isValid'), true);

        if (!inmersionesLineasValid) {
          this.toast.error('Hace falta completar la información de las líneas.');
          return Ember.RSVP.resolve(false);
        }

        return Ember.RSVP.resolve(true);
      });
    },

    validatePlantilla(plantilla) {
      if (!plantilla.get('descripcion') || !plantilla.get('descripcion').trim()) {
        plantilla.set('hasErrors', true);
        this.toast.error('Debe definir un nombre para la plantilla.');
        return false;
      }

      return true;
    },

    savePlantilla(plantilla) {
      return plantilla.save().then(record => {
        plantilla.set('hasErrors', false);
        this.parentCtrl.set('reload', true);
        return record;
      }).catch(error => {
        plantilla.set('hasErrors', true);
        this.errorHandler.handleSaveErrors(error, plantilla);
      });
    },

    saveChanges() {
      return this.validate().then(isValid => {
        if (!isValid) {
          return;
        }

        return this.savePlantilla(this.plantilla).then(() => {
          let inmersionesPromises = this.inmersiones.filterBy('isDirty').map(inmersion => {
            return inmersion.save().catch(error => this.errorHandler.handleSaveErrors(error, inmersion));
          });
          this.loader.setLoading(true);
          return Ember.RSVP.all(inmersionesPromises).then(() => {
            let lineasPromises = this.getAllLineas().map(linea => this.saveLinea(linea));
            return Ember.RSVP.all(lineasPromises);
          }).then(() => {
            this.toast.success('Registros guardados correctamente.');
          }).catch(error => {
            this.toast.error('Ocurrió un error al guardar sus cambios.');
            throw error;
          }).finally(() => this.loader.setLoading(false));
        });
      });
    },

    trySavePlantilla(plantilla) {
      if (this.validatePlantilla(plantilla)) {
        this.savePlantilla(plantilla);
      }
    },

    actions: {
      onClose() {
        // Destroy records
        this.getAllLineas().forEach(linea => linea.get('_content').rollbackAttributes());
        this.inmersiones.forEach(inmersion => inmersion.get('_content').rollbackAttributes()); // Destroy changesets

        this.getAllLineas().forEach(linea => linea.rollback());
        this.inmersiones.forEach(inmersion => inmersion.rollback());
        this.transitionToRoute(this.indexRoute);
      },

      trySavePlantilla() {
        this.trySavePlantilla(this.plantilla);
      },

      onAddInmersion() {
        let inmersion = this.createBuceoInmersionChangeset();
        this.inmersiones.pushObject(inmersion);
      },

      addLinea(list, inmersion) {
        let linea = this.actions.addItem.call(this, list);
        linea.set('plantilla', inmersion.get('_content'));
      },

      insertLinea(list, afterLinea, inmersion) {
        let linea = this.actions.insertItem.call(this, list, afterLinea);
        linea.set('plantilla', inmersion.get('_content'));
      },

      trySaveLinea(linea) {
        if (!linea.get('isDirty')) {
          return;
        }

        linea.validate().then(() => {
          // Se puede guardar la línea si ya se guardó la cabecera.
          if (linea.get('isValid') && linea.get('plantilla.id')) {
            this.saveLinea(linea);
          }
        });
      },

      onSaveLinea(linea) {
        this.saveLinea(linea);
      },

      onSubmit() {
        return this.saveChanges();
      },

      deleteInmersion(list, inmersion) {
        this.send('openModal', 'popups/simple-confirm', {
          message: '¿Está seguro de eliminar el informe?',
          onSubmit: () => {
            this.actions.deleteItem.call(this, list, inmersion);
          }
        });
      }

    }
  });

  _exports.default = _default;
});