define("sirvo-web/components/tables/cell/toggle-attribute", ["exports", "sirvo-web/templates/components/tables/cell/toggle-attribute"], function (_exports, _toggleAttribute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Este componente permite hacer toggle de un atributo boleano de un registro
   * desde una tabla de EmberLightTable.
   */
  var _default = Ember.Component.extend({
    layout: _toggleAttribute.default,
    table: null,
    row: null,
    column: null,
    propertyName: Ember.computed.readOnly('column.propertyName'),

    init() {
      this._super(...arguments);

      let record = this.get('row.content');
      this.set('value', Boolean(record.get(this.propertyName)));
    },

    actions: {
      toggleObjectProperty() {
        let record = this.get('row.content');
        let currentValue = Boolean(this.value);
        this.set('value', !currentValue);
        record.set(this.propertyName, this.value);
        record.save().catch(() => {
          this.toast.error('Ocurrió un error al actualizar el registro.');
          this.set('value', currentValue);
          record.rollbackAttributes();
        });
      }

    }
  });

  _exports.default = _default;
});