define("sirvo-web/validations/com/excel-upload", ["exports", "ember-changeset-validations/validators", "sirvo-web/models/constants"], function (_exports, _validators, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.blankExcelUpload = _exports.default = void 0;
  const excelUpload = {
    file: (0, _validators.validatePresence)(true),
    type: (0, _validators.validateInclusion)({
      message: 'Archivo inválido',
      list: _constants.default.UPLOADS_EXCEL_TYPES
    }) // Se podría validar el tamaño máximo del archivo con el key "size".

  };
  const blankExcelUpload = {
    type: (0, _validators.validateInclusion)({
      message: 'Archivo inválido',
      list: _constants.default.UPLOADS_EXCEL_TYPES
    })
  };
  _exports.blankExcelUpload = blankExcelUpload;
  var _default = excelUpload;
  _exports.default = _default;
});