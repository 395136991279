define("sirvo-web/templates/components/com/search-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JYh1kDjD",
    "block": "{\"symbols\":[\"@searchText\",\"@containerClasses\",\"@fullWidth\",\"@searchPlaceholder\"],\"statements\":[[6,\"div\"],[11,\"class\",[27,[\"form-inline \",[21,2,[]]]]],[8],[0,\"\\n\"],[4,\"if\",[[21,1,[]]],null,{\"statements\":[[0,\"    \"],[6,\"span\"],[10,\"class\",\"mr-1\"],[8],[1,[21,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[6,\"div\"],[11,\"class\",[27,[\"search-input-container \",[26,\"if\",[[21,3,[]],\"flex-grow-1\"],null]]]],[8],[0,\"\\n    \"],[1,[26,\"input\",null,[[\"value\",\"classNames\",\"placeholder\",\"enter\"],[[21,0,[\"searchValue\"]],\"form-control search-input\",[26,\"or\",[[21,4,[]],\"Filtrar documentos\"],null],[26,\"action\",[[21,0,[]],\"onSearch\"],null]]]],false],[0,\"\\n\\n    \"],[4,\"bs-button\",null,[[\"type\",\"size\",\"classNames\",\"onClick\"],[\"light\",\"md\",\"search-icon\",[26,\"action\",[[21,0,[]],\"onSearch\"],null]]],{\"statements\":[[0,\"\\n      \"],[6,\"span\"],[10,\"class\",\"fa fa-search\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n    \"]],\"parameters\":[]},null],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/components/com/search-input.hbs"
    }
  });

  _exports.default = _default;
});