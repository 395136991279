define("sirvo-web/routes/admon/procesos/cargas/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import AccessValidator from 'sirvo-web/mixins/access-validator';
  // WARNING: Acceso comentado a fin de configurar instalaciones desde cero.
  var _default = Ember.Route.extend(
  /*AccessValidator, */
  {
    routeAccess: 'ADMON-CARGAS-GENERALES',

    setupController() {
      this._super(...arguments);

      this.controller.newExcelChangeset();
      this.controller.set('data', []);
      this.controller.set('loadType', this.constants.ADMON_TIPOS_CARGA.get('firstObject'));
    }

  });

  _exports.default = _default;
});