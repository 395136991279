define("sirvo-web/routes/ods/servicios/ordenes-servicio/record/generales/anular", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    indexRoute: 'ods.servicios.ordenes-servicio.record.generales',

    setupController(controller, model) {
      this._super(controller, model);

      let parentCtrl = this.controllerFor(this.indexRoute);
      controller.setProperties({
        record: parentCtrl.get('record'),
        changeset: parentCtrl.get('changeset'),
        indexRoute: this.indexRoute
      });
    }

  });

  _exports.default = _default;
});