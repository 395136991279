define("sirvo-web/controllers/inventario/movimientos/salidas/registro-detalle", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    kardex: Ember.inject.service(),
    karParameters: Ember.computed.readOnly('currentCompany.karParameters'),
    fechaCierre: Ember.computed.readOnly('currentCompany.lastCierre.fecha'),
    periodo: null,
    movimiento: null,
    bodegas: null,
    unidadesMedida: null,
    existenciaBase: 0,
    existencia: 0,
    existenciaLote: 0,
    tipoCuenta: null,
    postSaveTransitionRoute: Ember.computed.readOnly('indexRoute'),
    registroCerrado: Ember.computed('movimiento.fecha', 'fechaCierre', function () {
      if (!this.fechaCierre || !this.movimiento.get('fecha')) {
        return false;
      }

      return this.fechaCierre >= this.movimiento.get('fecha');
    }),
    articuloActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editArticulo.bind(this)
      }];
    }),
    bodegaActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editArticulo.bind(this)
      }];
    }),
    loteActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editLote.bind(this)
      }];
    }),
    medidaActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editArticulo.bind(this)
      }];
    }),
    cuentaActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editCuentas.bind(this)
      }];
    }),
    centroCostoActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editCuentas.bind(this)
      }];
    }),
    centroIntegracionActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editCuentas.bind(this)
      }];
    }),
    propertyNameActionMap: Ember.computed(function () {
      return [{
        propertyNames: ['articulo', 'bodega', 'unidadMedida'],
        action: this.actions.editArticulo.bind(this)
      }, {
        propertyName: 'lote',
        action: this.actions.editLote.bind(this)
      }, {
        propertyNames: ['cuenta', 'centroCosto', 'centroIntegracion'],
        action: this.actions.editCuentas.bind(this)
      }];
    }),

    resetProperties() {
      this.setProperties({
        existenciaBase: 0,
        existencia: 0,
        existenciaLote: 0,
        tipoCuenta: null
      });
    },

    postSave() {
      if (!this.movimiento) {
        return;
      }

      return this.transitionToRoute(`${this.indexRoute}.detalles`, this.movimiento.id);
    },

    processSave(changeset) {
      // Validar la existencia solo cuando el registro es nuevo. En teoría, la cantidad
      // no se puede modificar después.
      if (this.record.get('isNew') && this.existenciaBase - Math.abs(changeset.get('cantidadBase')) < 0) {
        this.loader.setLoading(false);
        this.toast.error('No hay suficiente existencia del artículo seleccionado.');
        return Ember.RSVP.resolve(false);
      }

      if (this.changeset.get('articulo.usaLote')) {
        if (this.existenciaLote - Math.abs(changeset.get('cantidadBase')) < 0) {
          this.loader.setLoading(false);
          this.toast.error('No hay suficiente existencia del artículo en el lote especificado.');
          return Ember.RSVP.resolve(false);
        }
      }

      return this._super(...arguments);
    },

    submit() {
      let superRef = this._super.bind(this);

      return Ember.RSVP.all([this.changeset.validate(), ...this.ubicacionesChangesets.map(changeset => changeset.validate())]).then(() => {
        if (this.changeset.get('isInvalid')) {
          this.toast.error('Hay un problema de validación con el formulario.');
          return;
        }

        let ubicacionesChangesetsValid = this.ubicacionesChangesets.reduce((prevIsValid, changeset) => prevIsValid && changeset.get('isValid'), true);

        if (!ubicacionesChangesetsValid) {
          this.toast.error('Hay un problema con las ubicaciones del artículo.');
          return;
        }

        this.ubicacionesChangesets.forEach(changeset => changeset.execute());
        return superRef();
      });
    },

    discardChanges() {
      this.changeset.rollback();
      this.ubicacionesChangesets.forEach(changeset => changeset.rollback()); // Eliminar los registros de ubicación.

      this.kardex.destroyUnsavedUbicaciones(this.record.get('ubicaciones')); // Es necesario que esto ocurra después de eliminar las ubicaciones.

      this.record.rollbackAttributes();
    },

    actions: {
      onClose() {
        this.discardChanges();
        this.transitionToRoute(this.indexRoute);
      },

      updateCantidad(changeset, property, value) {
        changeset.set(property, value || 0);
        this.kardex.onChangesetCantidadUpdated(changeset);
      },

      serializeArticulo(articulo) {
        return articulo ? articulo.get('codarticulo') : null;
      },

      serializeBodega(bodega) {
        return bodega ? bodega.get('codbodega') : null;
      },

      serializeUnidadMedida(unidadMedida) {
        return unidadMedida ? unidadMedida.get('codigo') : null;
      },

      serializeCuenta(cuenta) {
        return cuenta ? cuenta.get('codcuenta') : null;
      },

      serializeCentroCosto(centroCosto) {
        return centroCosto ? centroCosto.get('codcc') : null;
      },

      serializeCentroIntegracion(centroIntegracion) {
        return centroIntegracion ? centroIntegracion.get('codci') : null;
      },

      editArticulo() {
        this.transitionToRoute(`${this.routeName}.articulo`);
      },

      editLote() {
        this.transitionToRoute(`${this.routeName}.lote`);
      },

      editCuentas() {
        this.transitionToRoute(`${this.routeName}.cuentas`);
      },

      goToDetails() {
        this.discardChanges();
        this.transitionToRoute(`${this.indexRoute}.detalles`, this.movimiento.id);
      }

    }
  });

  _exports.default = _default;
});