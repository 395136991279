define("sirvo-web/templates/iva/reportes/libro-ventas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TQhGKtmf",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[6,\"div\"],[10,\"class\",\"w-100 bg-white border-bottom px-4\"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"py-4\"],[8],[0,\"\\n    \"],[6,\"h3\"],[8],[0,\"\\n      Reporte de libro de ventas\\n\\n      \"],[6,\"div\"],[10,\"class\",\"float-right\"],[8],[0,\"\\n        \"],[6,\"a\"],[10,\"href\",\"#\"],[10,\"class\",\"btn btn-success btn-xs\"],[3,\"action\",[[21,0,[]],\"generarReporte\"]],[8],[0,\"\\n          \"],[6,\"i\"],[10,\"class\",\"fa fa-download\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n          Descargar\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[6,\"div\"],[10,\"class\",\"row pb-4\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-sm-12 col-lg-6\"],[8],[0,\"\\n      \"],[6,\"h4\"],[10,\"class\",\"header mt-0\"],[8],[0,\"\\n        Filtrar reporte\\n      \"],[9],[0,\"\\n\\n\"],[4,\"extended-form-for\",[[21,0,[\"changeset\"]]],null,{\"statements\":[[0,\"        \"],[1,[26,\"component\",[[21,1,[\"ace-datetimepicker\"]],\"fechaFin\",\"Año-mes período:\"],[[\"date\",\"format\",\"pickTime\",\"forceDateOutput\",\"showIcon\",\"placeholder\"],[[21,0,[\"changeset\",\"fechaFin\"]],\"MM/YYYY\",false,true,true,\"MM/YYYY\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\\n    \"],[6,\"div\"],[10,\"class\",\"col-sm-12 col-lg-6\"],[8],[0,\"\\n      \"],[14,\"partials/reports-fechas-filters\",[]],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "sirvo-web/templates/iva/reportes/libro-ventas.hbs"
    }
  });

  _exports.default = _default;
});