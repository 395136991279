define("sirvo-web/routes/facturacion/envio-factura/emitir", ["exports", "servir-ember-utilities/routes/abstract-module-index", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators", "moment"], function (_exports, _abstractModuleIndex, _emberChangeset, _emberChangesetValidations, _validators, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    request: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),
    fechaInicio: Ember.computed(function () {
      return (0, _moment.default)().startOf('month').toDate();
    }),
    fechaFin: Ember.computed(function () {
      return (0, _moment.default)().endOf('month').toDate();
    }),
    validations: Ember.computed(function () {
      return {
        fechaInicio: (0, _validators.validatePresence)({
          presence: true
        }),
        fechaFin: (0, _validators.validatePresence)({
          presence: true
        }),
        directorio: (0, _validators.validatePresence)({
          presence: true,
          message: "El directorio es obligatorio."
        }),
        destinatario: (0, _validators.validatePresence)({
          presence: true,
          message: "Debe agregar al menos un destinatario."
        }),
        titulo: (0, _validators.validatePresence)({
          presence: true,
          message: "El título es obligatorio."
        }),
        mensaje: (0, _validators.validatePresence)({
          presence: true,
          message: "El mensaje es obligatorio."
        })
      };
    }),

    model() {
      let model = new Ember.Object({
        codigoEmpresa: this.get('company.codempresa'),
        fechaFin: this.fechaFin,
        fechaInicio: this.fechaInicio,
        titulo: '',
        mensaje: this.mensaje,
        facturaId: null,
        directorio: this.directorio,
        destinatario: this.destinatario
      });
      let changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(this.get('validations')), this.get('validations'));
      return Ember.RSVP.hash({
        changeset: changeset,
        dataFel: this.listFacturasFel()
      });
    },

    setupController(controller, model) {
      this.loader.setIsLoading();
      this.loader.setLoading(true);

      this._super(controller, model);

      controller.setProperties({
        changeset: model.changeset,
        data: model.dataFel,
        habilitarTabla: false,
        isEnviarFacturas: false,
        openModal: false
      });
      this.loader.setLoading(false);
    },

    listFacturasFel() {
      this.loader.setIsLoading();
      return this.request.get('fac/facturas-emitidas/fecha', {
        codigoEmpresa: this.get('company.codempresa'),
        fechaInicio: (0, _moment.default)(this.fechaInicio).format(),
        fechaFin: (0, _moment.default)(this.fechaFin).format()
      }).then(response => {
        let facturas = response.data;
        return facturas;
      }).catch(error => {
        this.toast.error('Error al obtener los documentos FEL.');
        this.toast.error(error);
      }).finally(() => this.loader.setNotLoading());
    }

  });

  _exports.default = _default;
});