define("sirvo-web/controllers/sat-documentos-fel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    request: Ember.inject.service(),
    documentos: null,
    columns: Ember.computed(function () {
      return Ember.A([{
        label: 'FEL UUID',
        valuePath: 'id',
        width: '300px'
      }, {
        label: 'Fecha hora emisión',
        valuePath: 'fechaHoraEmision',
        width: '250px'
      }, {
        label: 'Serie',
        valuePath: 'serie',
        width: '100px'
      }, {
        label: 'Número',
        valuePath: 'numero',
        width: '100px'
      }, {
        label: 'NIT emisor',
        valuePath: 'nitEmisor',
        width: '100px'
      }, {
        label: 'Nombre emisor',
        valuePath: 'nombreEmisor',
        width: '300px'
      }, {
        label: 'Código establecimiento',
        valuePath: 'codigoEstablecimiento',
        width: '200px'
      }, {
        label: 'Nombre establecimiento',
        valuePath: 'nombreEstablecimiento',
        width: '400px'
      }, {
        label: 'Receptor',
        valuePath: 'idReceptor',
        width: '150px'
      }, {
        label: 'Nombre receptor',
        valuePath: 'nombreReceptor',
        width: '300px'
      }, {
        label: 'Código moneda',
        valuePath: 'codigoMoneda',
        width: '150px'
      }, {
        label: 'Valor total',
        valuePath: 'valorTotal',
        width: '200px'
      }, {
        label: 'Estado FEL',
        valuePath: 'felEstado',
        width: '100px'
      }, {
        label: 'Anulado',
        valuePath: 'anulado',
        width: '100px'
      }, {
        label: 'Fecha hora anulación',
        valuePath: 'fechaHoraAnulacion',
        width: '250px'
      }, {
        label: 'Valor IVA',
        valuePath: 'valorImpuestoIva',
        width: '250px'
      }, {
        label: 'Valor impuesto petroleo',
        valuePath: 'valorImpuestoPetroleo',
        width: '250px'
      }, {
        label: 'Valor impuesto turismo',
        valuePath: 'valorImpuestoTurismo',
        width: '250px'
      }, {
        label: 'Valor impuesto Pasajes',
        valuePath: 'valorImpuestoPasajes',
        width: '250px'
      }, {
        label: 'Valor impuesto Timbre Prensa',
        valuePath: 'valorImpuestoTimbrePrensa',
        width: '250px'
      }, {
        label: 'Valor Impuesto Bomberos',
        valuePath: 'valorImpuestoBomberos',
        width: '250px'
      }, {
        label: 'Valor Impuesto Tasa Municipal',
        valuePath: 'valorImpuestoTasaMunicipal',
        width: '250px'
      }, {
        label: 'Valor Impuesto Bebidas Alcoholicas',
        valuePath: 'valorImpuestoBebidasAlcoholicas',
        width: '250px'
      }, {
        label: 'Valor Impuesto Tabaco',
        valuePath: 'valorImpuestoTabaco',
        width: '250px'
      }, {
        label: 'Valor Impuesto Cemento',
        valuePath: 'valorImpuestoCemento',
        width: '250px'
      }, {
        label: 'Valor Impuesto Bebidas No Alcoholicas',
        valuePath: 'valorImpuestoBebidasNoAlcoholicas',
        width: '300px'
      }, {
        label: 'Valor Impuesto Tarifa Portuaria',
        valuePath: 'valorImpuestoTarifaPortuaria',
        width: '250px'
      }]);
    }),
    actions: {
      searchDocuments() {
        const {
          id,
          serie,
          numero
        } = this.model;
        this.loader.setIsLoading();
        return this.store.query('sat-documento-fel', {
          filter: {
            id,
            serie,
            numero
          }
        }).then(documentos => {
          this.set('documentos', documentos);
        }).finally(() => this.loader.setNotLoading());
      },

      resetFields() {
        this.set('documentos', null);
        this.set('model', {
          uuid: '',
          serie: '',
          numero: ''
        });
      }

    }
  });

  _exports.default = _default;
});