define("sirvo-web/validations/ods/muelle", ["exports", "ember-changeset-validations/validators", "servir-ember-utilities/validators/is-codigo", "servir-ember-utilities/validators/custom-presence"], function (_exports, _validators, _isCodigo, _customPresence) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    codigo: [(0, _customPresence.default)(true), (0, _isCodigo.default)()],
    nombre: (0, _validators.validatePresence)(true),
    tipo: (0, _validators.validatePresence)(true),
    terminal: (0, _validators.validatePresence)(true)
  };
  _exports.default = _default;
});