define("sirvo-web/routes/admon/maestros/empresas", ["exports", "sirvo-web/mixins/access-validator"], function (_exports, _accessValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_accessValidator.default, {
    routeAccess: 'ADMON-EMPRESAS'
  });

  _exports.default = _default;
});