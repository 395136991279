define("sirvo-web/validations/ods/orden-servicio", ["exports", "ember-changeset-validations/validators", "servir-ember-utilities/validators/is-codigo", "servir-ember-utilities/validators/custom-presence"], function (_exports, _validators, _isCodigo, _customPresence) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    codigo: [(0, _customPresence.default)(true), (0, _isCodigo.default)()],
    tipoOrden: (0, _validators.validatePresence)(true),
    tiposServicios: (0, _validators.validateLength)({
      min: 1
    }),
    servicios: (0, _validators.validateLength)({
      min: 1
    }),
    puerto: (0, _validators.validatePresence)(true),
    terminal: (0, _validators.validatePresence)(true),
    buque: (0, _validators.validatePresence)(true),
    fecha: (0, _validators.validatePresence)(true),
    cliente: (0, _validators.validatePresence)(true)
  };
  _exports.default = _default;
});