define("sirvo-web/models/gru-directorio-contacto", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    // coddirectorio: attr('string'),
    // codgrupo: attr('string'),
    codcontacto: (0, _model.attr)('string'),
    nombre: (0, _model.attr)('string'),
    vocativo: (0, _model.attr)('string'),
    cargo: (0, _model.attr)('string'),
    departamento: (0, _model.attr)('string'),
    saludo: (0, _model.attr)('string'),
    direccion: (0, _model.attr)('string'),
    telefonooficina: (0, _model.attr)('string'),
    telefonomovil: (0, _model.attr)('string'),
    telefonofax: (0, _model.attr)('string'),
    telefonoparticular: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    webpage: (0, _model.attr)('string'),
    chat: (0, _model.attr)('string'),
    foto: (0, _model.attr)('string'),
    observa: (0, _model.attr)('string'),
    creusr: (0, _model.attr)('date'),
    fecusr: (0, _model.attr)('string'),
    codusr: (0, _model.attr)('string'),
    contactoFacturaElectronica: (0, _model.attr)('boolean'),
    // Relationships
    directorio: (0, _model.belongsTo)('gru-directorio'),
    grupo: (0, _model.belongsTo)('grupo')
  });

  _exports.default = _default;
});