define("sirvo-web/controllers/ods/tablas/muelles/index", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "sirvo-web/models/ods/muelle"], function (_exports, _abstractModuleIndex, _muelle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    editRoute: 'ods.tablas.muelles.record',
    modelName: 'ods/muelle',
    modelClass: _muelle.default,
    columns: Ember.computed(function () {
      return Ember.A([{
        label: 'Código',
        valuePath: 'codigo',
        filtrable: true,
        filterName: 'codigo',
        sortable: false,
        width: '150px'
      }, {
        label: 'Nombre',
        valuePath: 'nombre',
        filtrable: true,
        filterName: 'nombre',
        sortable: false,
        width: '250px'
      }, {
        label: 'Terminal',
        valuePath: 'terminal.nombre',
        filtrable: true,
        filterName: 'terminal.nombre',
        sortable: false,
        width: '200px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }]);
    }),
    actions: {
      addRecord() {
        this.transitionToRoute(this.editRoute, 'new');
      }

    }
  });

  _exports.default = _default;
});