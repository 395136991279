define("sirvo-web/controllers/ods/servicios/ordenes-servicio/record/documentos", ["exports", "ember-changeset", "ember-changeset-validations", "sirvo-web/validations/com/pdf-upload", "sirvo-web/utils/common"], function (_exports, _emberChangeset, _emberChangesetValidations, _pdfUpload, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    request: Ember.inject.service(),
    uploader: Ember.inject.service('ods/uploader'),
    errorHandler: Ember.inject.service(),
    parentCtrl: Ember.inject.controller('ods.servicios.ordenes-servicio.record'),
    currentView: 'list',
    selectModeEnabled: false,
    selectAll: false,
    docConsolidadoChangeset: null,
    consolidadoUploadPopupOpen: false,
    consolidadoDownloadPopupOpen: false,
    searchTerm: null,
    allDocumentosChangesets: Ember.computed('ordenDocumentosList', function () {
      if (!this.ordenDocumentosList) {
        return Ember.A([]);
      }

      let allDocumentosChangesets = Ember.A([]);
      this.ordenDocumentosList.forEach(servicioDocumentosList => {
        allDocumentosChangesets.pushObjects(servicioDocumentosList.documentosChangesets);
      });
      return allDocumentosChangesets;
    }),
    pdfFileValidations: Ember.computed(function () {
      return { ..._pdfUpload.default
      };
    }),

    resetProperties() {
      this.setProperties({
        selectAll: false,
        selectModeEnabled: false,
        consolidadoUploadPopupOpen: false,
        consolidadoDownloadPopupOpen: false
      });
    },

    resetDocumentoConsolidadoChangeset() {
      if (this.record.get('documentoConsolidado.id')) {
        this.set('docConsolidadoChangeset', null);
        return;
      }

      this.set('docConsolidadoChangeset', new _emberChangeset.default(new Ember.Object({
        file: null
      }), (0, _emberChangesetValidations.default)(this.pdfFileValidations), this.pdfFileValidations, {
        skipValidate: false
      }));
    },

    clearSelection() {
      this.set('selectAll', false);
      this.get('allDocumentosChangesets').filterBy('isSelected').forEach(documentoChangeset => documentoChangeset.set('isSelected', false));
    },

    clearPopupChangesAndClose() {
      this.set('consolidadoUploadPopupOpen', false);
      this.set('consolidadoDownloadPopupOpen', false);
      this.set('searchTerm', null);
      this.applySearchFilter();
      this.get('allDocumentosChangesets').forEach(documentoChangeset => {
        documentoChangeset.setProperties({
          paginaInicio: null,
          paginaFin: null,
          ordenDescarga: null
        });
      });
    },

    applySearchFilter() {
      this.ordenDocumentosList.forEach(servicioDocumentosList => {
        servicioDocumentosList.set('documentosChangesetsFiltered', servicioDocumentosList.documentosChangesets.filter(documentoChangeset => {
          if (!this.searchTerm || !this.searchTerm.trim()) {
            return true;
          }

          let search = this.searchTerm.trim().toLowerCase();
          let documentName = documentoChangeset.get('servicioDocumento').get('documento.nombre');
          let perfilName = documentoChangeset.get('servicioDocumento').get('cargaPerfil.nombre');
          let matchName = documentName.toLowerCase().indexOf(search) !== -1;
          let matchPerfil = perfilName && perfilName.toLowerCase().indexOf(search) !== -1;
          return matchName || matchPerfil;
        }));
      });
    },

    uploadDocumentoConsolidado() {
      if (!this.record.get('id')) {
        this.toast.error('Guarde primero la orden de servicio');
        return Ember.RSVP.resolve(null);
      }

      if (this.record.get('documentoConsolidado.id')) {
        return Ember.RSVP.resolve(this.record.get('documentoConsolidado'));
      }

      if (!this.docConsolidadoChangeset) {
        throw Error('Object docConsolidadoChangeset not defined yet');
      }

      if (!this.docConsolidadoChangeset.get('file')) {
        this.toast.error('Adjunte un documento para cargar.');
        return Ember.RSVP.resolve(null);
      }

      return this.docConsolidadoChangeset.validate().then(() => {
        if (this.docConsolidadoChangeset.get('isInvalid')) {
          this.toast.error('Hay errores con el archivo.');
          return Ember.RSVP.resolve(null);
        }

        this.loader.setLoading(true);
        return this.uploader.uploadFile(this.docConsolidadoChangeset.get('file'), {
          uploadRelatedModel: this.docConsolidadoChangeset
        }).finally(() => this.loader.setLoading(false));
      });
    },

    actions: {
      toggleView() {
        this.set('currentView', this.currentView === 'list' ? 'grid' : 'list');
      },

      filterDocumentos(searchValue) {
        this.set('searchTerm', searchValue);
        this.applySearchFilter();
      },

      uploadDocumentoFile(documentoChangeset) {
        if (!documentoChangeset.get('file')) {
          this.toast.error('Adjunte un documento para cargar.');
          return;
        }

        return documentoChangeset.validate().then(() => {
          if (documentoChangeset.get('isInvalid')) {
            this.toast.error('Hay errores con el archivo.');
            return;
          }

          return this.uploader.uploadFile(documentoChangeset.get('file'), {
            uploadRelatedModel: documentoChangeset
          }).then(upload => {
            documentoChangeset.set('upload', upload);
            this.loader.setLoading(true);
            return documentoChangeset.save().catch(error => {
              documentoChangeset.set('upload', null); // TODO: Fix the following error related to destroying the record.
              // Assertion Failed: Cannot create a new chain watcher for `<(unknown mixin):ember2282>` after it has been destroyed.

              upload.destroyRecord();
              this.errorHandler.handleSaveErrors(error, documentoChangeset);
            }).finally(() => this.loader.setLoading(false));
          });
        });
      },

      downloadDocumentoFile(documentoChangeset) {
        if (!documentoChangeset.get('upload.id')) {
          this.toast.error('No hay un documento adjunto.');
          return;
        }

        return this.uploader.downloadFile(documentoChangeset.get('upload.id'));
      },

      deleteDocumentoFile(documentoChangeset) {
        if (!documentoChangeset.get('upload.id')) {
          this.toast.error('No hay un documento adjunto.');
          return;
        } // NOTE: No hay necesidad de validar el documentoChangeset porque no hay
        // campos en pantalla que permitan ensuciar el changeset.


        this.send('openModal', 'popups/simple-confirm', {
          message: '¿Está seguro de eliminar el archivo?',
          onSubmit: () => {
            let upload = (0, _common.getPromiseContent)(documentoChangeset.get('upload'));
            this.loader.setLoading(true); // Limpiar este campo para evitar errores con el file input.
            //    Assertion Failed: Cannot create a new chain watcher for X after it has been destroyed.

            documentoChangeset.set('file', null); // Gracias a la configuración "orphanRemoval" del backend, basta con setear
            // a null la relación para eliminar el upload (y el archivo en S3).

            documentoChangeset.set('upload', null);
            documentoChangeset.save().then(() => {
              // Destroy upload record
              this.store.unloadRecord(upload);
            }).catch(error => {
              this.toast.error('Error al desasociar el archivo.');
              throw error;
            }).finally(() => this.loader.setLoading(false));
          }
        });
      },

      deleteOrdenDocumento(servicioDocumentos, documentoChangeset) {
        this.send('openModal', 'popups/simple-confirm', {
          message: '¿Está seguro de eliminar el registro?',
          onSubmit: () => {
            this.loader.setLoading(true);
            let ordenDocumento = documentoChangeset.get('_content');
            ordenDocumento.destroyRecord().then(() => {
              servicioDocumentos.documentosChangesets.removeObject(documentoChangeset);
            }).catch(error => {
              this.errorHandler.handleBackendErrors(error);
            }).finally(() => this.loader.setLoading(false));
          }
        });
      },

      addDocumentoChangeset(servicioDocumentos, documentoChangeset) {
        let ordenDocumento = this.store.createRecord('ods/orden-servicio-documento', {
          ordenServicio: documentoChangeset.get('ordenServicio'),
          servicioDocumento: documentoChangeset.get('servicioDocumento')
        });
        let ordenDocumentoChangeset = this.parentCtrl.createDocumentoChangeset(ordenDocumento);
        let index = servicioDocumentos.documentosChangesets.indexOf(documentoChangeset);

        if (index !== -1) {
          servicioDocumentos.documentosChangesets.insertAt(index + 1, ordenDocumentoChangeset);
        }
      },

      toggleDocumentoSelection(documentoChangeset) {
        if (!this.selectModeEnabled) {
          return;
        }

        documentoChangeset.set('isSelected', !documentoChangeset.get('isSelected'));
      },

      toggleSelection() {
        this.set('selectModeEnabled', !this.selectModeEnabled);
        this.clearSelection();
      },

      toggleSelectAll() {
        this.set('selectAll', !this.selectAll);
        this.ordenDocumentosList.forEach(servicioDocumentosList => {
          servicioDocumentosList.documentosChangesetsFiltered.forEach(documentoChangeset => documentoChangeset.set('isSelected', this.selectAll));
        });
      },

      prepareConsolidadoDownloadPopup() {
        let unsavedDocumentoChangeset = this.get('allDocumentosChangesets').findBy('isNew');

        if (this.changeset.get('isDirty') || unsavedDocumentoChangeset) {
          this.toast.error('Haga click en Guardar antes de descargar el documento consolidado.');
          return;
        }

        let selectedFileDocuments = this.get('allDocumentosChangesets').filterBy('isSelected');

        if (!selectedFileDocuments.length) {
          this.toast.error('Seleccione los documentos a descargar.');
          return;
        }

        this.set('consolidadoDownloadPopupOpen', true);
      },

      onCloseConsolidadoPopup() {
        this.clearPopupChangesAndClose();
      },

      downloadCustomDocumentoConsolidado() {
        if (!this.record.get('id')) {
          this.toast.error('Guarde primero la orden de servicio');
          return;
        }

        let selectedFileDocuments = this.get('allDocumentosChangesets').filterBy('isSelected').filterBy('upload.id').filterBy('upload.isPdf');

        if (!selectedFileDocuments.length) {
          this.toast.error('Seleccione al menos un documento PDF para descargar.');
          return;
        }

        let ordenDocumentosParams = {};
        selectedFileDocuments.forEach(documentoChangeset => {
          ordenDocumentosParams[documentoChangeset.get('id')] = {
            ordenDescarga: documentoChangeset.get('ordenDescarga') || 0
          };
        });
        this.loader.setLoading(true);
        return this.request.get(`ods/orden-servicios/${this.record.get('id')}/documento-consolidado`, {
          ordenDocumentosParams
        }).then(() => {
          this.toast.success('Reporte generado exitosamente');
          this.set('consolidadoDownloadPopupOpen', false);
        }).finally(() => this.loader.setLoading(false));
      },

      prepareConsolidadoUploadPopup() {
        let unsavedDocumentoChangeset = this.get('allDocumentosChangesets').findBy('isNew');

        if (this.changeset.get('isDirty') || unsavedDocumentoChangeset) {
          this.toast.error('Haga click en Guardar antes de cargar el documento consolidado.');
          return;
        }

        let selectedFileDocuments = this.get('allDocumentosChangesets').filterBy('isSelected');

        if (!selectedFileDocuments.length) {
          this.toast.error('Seleccione los documentos a cargar.');
          return;
        }

        this.set('consolidadoUploadPopupOpen', true);
      },

      downloadDocumentoConsolidado() {
        if (!this.record.get('documentoConsolidado.id')) {
          return;
        }

        this.uploader.downloadFile(this.record.get('documentoConsolidado.id'));
      },

      deleteDocumentoConsolidado() {
        return this.changeset.validate().then(() => {
          if (this.changeset.get('isInvalid')) {
            this.toast.error('Corrija los errores del formulario primero.');
            return;
          }

          this.send('openModal', 'popups/simple-confirm', {
            message: '¿Está seguro de eliminar el archivo?',
            onSubmit: () => {
              this.changeset.set('documentoConsolidado', null);
              this.loader.setLoading(true);
              this.changeset.save().then(() => {
                this.toast.success('Documento eliminado correctamente.');
                this.resetDocumentoConsolidadoChangeset();
              }).catch(error => this.errorHandler.handleSaveErrors(error, this.changeset)).finally(() => this.loader.setLoading(false));
            }
          });
        });
      },

      uploadAndSplitDocumentoConsolidado() {
        if (!this.record.get('id')) {
          this.toast.error('Guarde primero la orden de servicio');
          return;
        }

        let selectedFileDocuments = this.get('allDocumentosChangesets').filterBy('isSelected').filterBy('paginaInicio').filterBy('paginaFin');

        if (!selectedFileDocuments.length) {
          this.toast.error('Especifique las páginas inicio-fin de al menos un documento.');
          return;
        }

        for (var idx = 0; idx < selectedFileDocuments.length; idx++) {
          if (selectedFileDocuments[idx].get('paginaInicio') < 1 || selectedFileDocuments[idx].get('paginaFin') < 1) {
            this.toast.error('Numeración de páginas incorrecta.');
            return;
          }
        }

        let ordenDocumentosParams = {};
        selectedFileDocuments.forEach(documentoChangeset => {
          ordenDocumentosParams[documentoChangeset.get('id')] = {
            paginaInicio: documentoChangeset.get('paginaInicio'),
            paginaFin: documentoChangeset.get('paginaFin')
          };
        });
        return this.uploadDocumentoConsolidado().then(upload => {
          if (!upload) {
            return Ember.RSVP.resolve();
          }

          this.loader.setLoading(true);
          return this.request.post(`ods/orden-servicios/${this.record.get('id')}/split-documento-consolidado`, {
            uploadId: upload.id,
            ordenDocumentosParams
          }).then(() => {
            this.toast.success('Documento procesado correctamente.'); // Recargar toda la pantalla para obtener los documentos divididos y
            // asignados a cada "orden-servicio-documento".

            window.location.reload(true);
          }).finally(() => this.loader.setLoading(false));
        });
      },

      llenarFormulario(documentoChangeset) {
        if (!documentoChangeset.get('id')) {
          this.toast.error('Haga click en Guardar primero.');
          return;
        }

        this.changeset.validate().then(() => {
          if (this.changeset.get('isInvalid')) {
            this.toast.error('Corrija los errores del formulario primero.');
            return;
          } // TODO: Parametrizar esto.


          if (documentoChangeset.get('servicioDocumento.documento.codigo') === 'DOC-007') {
            this.transitionToRoute(`${this.routeName}.estado-hechos`, documentoChangeset.get('id'));
            return;
          }

          if (documentoChangeset.get('servicioDocumento.documento.codigo') === 'DOC-017') {
            this.transitionToRoute(`${this.routeName}.informe-buceo`, documentoChangeset.get('id'));
            return;
          }

          if (documentoChangeset.get('servicioDocumento.documento.codigo') === 'DOC-024') {
            this.transitionToRoute(`${this.routeName}.inspeccion-mangueras`, documentoChangeset.get('id'));
            return;
          }

          this.toast.warning('No hay un formulario para llenar este documento.');
        });
      }

    }
  });

  _exports.default = _default;
});