define("sirvo-web/controllers/inventario/movimientos/cortesias/despacho-cortesias", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    request: Ember.inject.service(),
    reload: true,
    indexRoute: 'inventario.movimientos.cortesias',
    editRoute: 'inventario.movimientos.cortesias.despacho-cortesias.detalle',
    columns: Ember.computed(function () {
      return [{
        label: 'Artículo',
        valuePath: 'articulo.codarticulo',
        width: '110px'
      }, {
        label: 'Nombre',
        valuePath: 'articulo.descripcion',
        width: '200px'
      }, {
        label: 'U. Medida',
        valuePath: 'unidadMedida.codigo',
        width: '100px',
        sortable: false
      }, {
        label: 'Cantidad',
        valuePath: 'cantidad',
        width: '90px',
        sortable: false
      }, {
        label: 'Costo unitario',
        valuePath: 'precioUnitario',
        width: '130px',
        sortable: false
      }, {
        label: 'Total',
        valuePath: 'total',
        width: '100px',
        sortable: false
      }];
    }),

    reloadTable() {
      this.set('reload', true);
    },

    actions: {
      addDetail() {
        this.transitionToRoute(this.editRoute);
      },

      serializeBodega(bodega) {
        return bodega ? bodega.get('codbodega') : null;
      },

      serializeCuenta(cuenta) {
        return cuenta ? cuenta.get('codcuenta') : null;
      },

      serializeCentroCosto(centroCosto) {
        return centroCosto ? centroCosto.get('codcc') : null;
      },

      procesarCortesia() {
        this.loader.setIsLoading();
        return this.changeset.validate().then(() => {
          if (!this.changeset.get('isValid')) {
            this.loader.setNotLoading();
            this.toast.error('Debe agregar al menos un detalle para procesar la cortesía.');
            return;
          }

          let detalles = this.changeset.get('detalles').map(detalle => {
            return {
              articuloId: detalle.get('articulo.id'),
              cantidad: detalle.get('cantidad'),
              unidadMedidaId: detalle.get('unidadMedida.id'),
              cantidadBase: detalle.get('cantidadBase'),
              precioUnitario: detalle.get('precioUnitario'),
              total: detalle.get('total')
            };
          });
          return this.request.post('kar/despacho-cortesias', {
            detalles,
            fecha: this.changeset.get('fecha'),
            cuentaContableId: this.changeset.get('cuenta.id'),
            centroCostoId: this.changeset.get('centroCosto.id'),
            tipoMovimientoId: this.changeset.get('tipoMovimiento.id'),
            tipoDocumentoId: this.changeset.get('tipoDocumento.id'),
            bodegaId: this.changeset.get('bodega.id'),
            periodoId: this.changeset.get('periodo.id')
          }).then(() => {
            this.toast.success('Cortesía procesada exitosamente.');
            this.transitionToRoute(this.indexRoute);
          }).catch(() => {
            this.toast.error('Error al procesar la cortesía.');
          }).finally(() => this.loader.setNotLoading());
        });
      }

    }
  });

  _exports.default = _default;
});