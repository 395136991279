define("sirvo-web/controllers/inventario/movimientos/ingresos/ajuste/poliza", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    columns: Ember.computed(function () {
      return [{
        label: 'Cuenta',
        valuePath: 'cuenta',
        width: '110px',
        sortable: false
      }, {
        label: 'Nombre',
        valuePath: 'cuentaNombre',
        width: '200px',
        sortable: false
      }, {
        label: 'Concepto contable',
        valuePath: 'descripcionContable',
        width: '300px',
        sortable: false
      }, {
        label: 'Cargo',
        valuePath: 'cargo',
        width: '100px',
        sortable: false,
        totalValuePath: 'cargo',
        cellComponent: 'tables/cell/accounting-amount'
      }, {
        label: 'Abono',
        valuePath: 'abono',
        width: '100px',
        sortable: false,
        totalValuePath: 'cargo',
        cellComponent: 'tables/cell/accounting-amount'
      }, {
        label: 'Centro costo',
        valuePath: 'centroCosto',
        width: '110px',
        sortable: false
      }, {
        label: 'Centro integración',
        valuePath: 'centroIntegracion',
        width: '150px',
        sortable: false
      }];
    }),
    actions: {
      getReport() {
        if (!this.record.id) {
          this.toast.error('Guarde primero el registro para visualizar el reporte.');
          return;
        }

        this.loader.setLoading(true);
        this.toast.info('Generando reporte');
        return this.request.get(`kar-movs/${this.record.id}/reporte_poliza`).then(() => this.toast.success('Reporte generado exitosamente')).finally(() => this.loader.setLoading(false));
      }

    }
  });

  _exports.default = _default;
});