define("sirvo-web/routes/ods/tablas/documentos/record/inspeccion-mangueras-plantilla", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    indexRoute: 'ods.tablas.documentos.record',
    modelName: 'ods/inspeccion-mangueras-plantilla',

    model(params) {
      let plantilla;
      let inmersiones = Ember.A([]);

      if (params.plantilla_id === 'new') {
        plantilla = this.store.createRecord(this.modelName, {
          documento: this.modelFor(this.indexRoute).record
        });
      } else {
        plantilla = this.store.find(this.modelName, params.plantilla_id);
        inmersiones = this.store.query('ods/buceo-inmersion-plantilla', {
          filter: {
            plantilla: params.plantilla_id
          },
          include: 'lineas',
          sort: 'orden'
        });
      }

      let promises = {
        plantilla,
        inmersiones
      };
      return Ember.RSVP.hash(promises);
    },

    setupController(controller, model) {
      this._super(...arguments);

      controller.resetProperties();
      let inmersionesChangesets = model.inmersiones.map(inmersion => {
        return controller.createBuceoInmersionChangeset(inmersion);
      });
      controller.setProperties({
        indexRoute: this.indexRoute,
        plantilla: model.plantilla,
        inmersiones: inmersionesChangesets
      });
    }

  });

  _exports.default = _default;
});