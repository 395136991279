define("sirvo-web/services/ods/uploader", ["exports", "sirvo-web/services/uploader"], function (_exports, _uploader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _uploader.default.extend({
    getNewUploader() {
      return this._super('ods/upload');
    },

    uploadFile(file, options = {}) {
      let uploadOptions = Ember.merge(options, {
        uploadModel: 'ods/upload'
      });
      return this._super(file, uploadOptions);
    },

    downloadFile(id) {
      return this._super(id, 'ods');
    },

    getNewUploadChangeset(uploadValidations) {
      return this._super('ods', uploadValidations);
    }

  });

  _exports.default = _default;
});