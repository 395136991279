define("sirvo-web/routes/admon/accesos/permisos/record/grupo", ["exports", "servir-ember-utilities/routes/abstract-module-record", "sirvo-web/mixins/permission-validator", "ember-changeset-validations/validators", "servir-ember-utilities/validators/is-codigo"], function (_exports, _abstractModuleRecord, _permissionValidator, _validators, _isCodigo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_permissionValidator.default, {
    routePermission: 'AdmonCrearPermisosGrupos',
    modelName: 'permiso-grupo',
    indexRoute: 'admon.accesos.permisos.record',
    relatedModelsToQuery: Ember.computed(function () {
      let relatedModels = [{
        name: 'grupos',
        modelName: 'permiso-grupo'
      }];
      return relatedModels;
    }),
    validations: Ember.computed(function () {
      return {
        codigo: [(0, _validators.validatePresence)({
          presence: true
        }), (0, _isCodigo.default)()],
        nombre: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),

    model(params
    /*, transition*/
    ) {
      if (params.grupo_id !== 'new') {
        this.toast.error('Esta ruta es únicamente para registrar un nuevo grupo.');
        return this.transitionTo(this.get('indexRoute'));
      }

      return this._super(...arguments);
    },

    postSave(record) {
      let parentController = this.controllerFor(this.get('indexRoute'));
      parentController.changeset.set('grupo', record);
      this.controllerFor(this.get('indexRoute')).reloadGroups();
    },

    actions: {
      willTransition() {
        return true;
      }

    }
  });

  _exports.default = _default;
});