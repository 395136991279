define("sirvo-web/routes/ods/servicios/tipos-servicios/record/servicios/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "sirvo-web/mixins/permission-validator", "sirvo-web/validations/ods/servicio"], function (_exports, _abstractModuleRecord, _permissionValidator, _servicio) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_permissionValidator.default, {
    routePermission: 'OdsCrearServicio',
    modelName: 'ods/servicio',
    indexRoute: 'ods.servicios.tipos-servicios.record.servicios',
    routeRecordIdentifierDynamicSegment: 'servicio_id',
    relatedModelsToQuery: Ember.computed('isNew', function () {
      let relatedModels = [];

      if (!this.isNew) {
        relatedModels.pushObject({
          name: 'documentos',
          modelName: 'ods/servicio-documento',
          queryParams: {
            filter: {
              servicio: this.recordId
            }
          }
        });
      }

      return relatedModels;
    }),
    validations: Ember.computed(function () {
      return { ..._servicio.default
      };
    }),

    createRecordInstance() {
      let parentModel = this.modelFor(this.indexRoute);
      return this.store.createRecord(this.modelName, {
        tipoServicio: parentModel.record
      });
    },

    setupController(controller, model) {
      this._super(...arguments);

      let {
        perfiles,
        documentos
      } = this.modelFor(this.indexRoute);
      let documentosChangesets = model.record.get('documentos').map(documentoConfig => controller.createDocumentoChangeset(documentoConfig));
      controller.setProperties({
        perfiles,
        documentos,
        documentosChangesets
      });
    },

    actions: {
      willTransition()
      /*transition*/
      {
        return false;
      }

    }
  });

  _exports.default = _default;
});