define("sirvo-web/models/emp-docto", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    // codempresa: attr('string'),
    empresa: (0, _model.belongsTo)('empresa'),
    // codsistema: attr('string'),
    sistema: (0, _model.belongsTo)('own-sistema'),
    tipoDocumento: (0, _model.belongsTo)('com-tipo-docto'),
    codtipo: (0, _model.attr)('string'),
    tipoctachq: (0, _model.attr)('string'),
    nomtipodoc: (0, _model.attr)('string'),
    // concepto: attr('string'),
    // codmoneda: attr('string'),
    moneda: (0, _model.belongsTo)('emp-moneda'),
    esdefault: (0, _model.attr)('number', {
      defaultValue: 1
    }),
    // clasificacionIva: attr('string'),
    // tipoDocIva: attr('string'),
    usaserie: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    // lenSerie: attr('number', { defaultValue: 0 }),
    // lenNumero: attr('number', { defaultValue: 0 }),
    // usaimprime: attr('number', { defaultValue: 0 }),
    // escrystal: attr('number', { defaultValue: 0 }),
    // nomformato: attr('string'),
    // anchoInches: attr('number', { defaultValue: 0 }),
    // largoInches: attr('number', { defaultValue: 0 }),
    // usacnt: attr('number', { defaultValue: 0 }),
    // usaFdpRc: attr('number', { defaultValue: 0 }),
    // usaconciAutomatica: attr('number', { defaultValue: 0 }),
    // usaentregaDocs: attr('number', { defaultValue: 0 }),
    // espropio: attr('number', { defaultValue: 0 }),
    // usancf: attr('number', { defaultValue: 0 }),
    // ncfSerie: attr('number', { defaultValue: 0 }),
    // ncfNumero: attr('number', { defaultValue: 0 }),
    // ncfLenSerie: attr('number', { defaultValue: 0 }),
    // ncfLenNumero: attr('number', { defaultValue: 0 }),
    // usaflujodecaja: attr('number', { defaultValue: 0 }),
    // fechaBaja: attr('date'),
    // creusr: attr('string'),
    // fecusr: attr('date'),
    // codusr: attr('string'),
    codigoNombre: Ember.computed('codtipo', 'nomtipodoc', function () {
      return `${this.codtipo || ''} - ${this.nomtipodoc || ''}`;
    })
  });

  _exports.default = _default;
});