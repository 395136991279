define("sirvo-web/controllers/ods/servicios/tipos-servicios/record/servicios/record", ["exports", "servir-ember-utilities/controllers/abstract-module-record", "ember-changeset", "ember-changeset-validations", "sirvo-web/validations/ods/servicio-documento"], function (_exports, _abstractModuleRecord, _emberChangeset, _emberChangesetValidations, _servicioDocumento) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    errorHandler: Ember.inject.service(),
    indexCtrl: Ember.inject.controller('ods.servicios.tipos-servicios.record.servicios'),
    postSaveTransitionRoute: Ember.computed.readOnly('routeName'),
    documentosChangesets: Ember.computed(function () {
      return Ember.A([]);
    }),

    createDocumentoChangeset(servicioDocumento) {
      if (!servicioDocumento) {
        servicioDocumento = this.store.createRecord('ods/servicio-documento', {
          servicio: this.record,
          isEditing: true
        });
      }

      return new _emberChangeset.default(servicioDocumento, (0, _emberChangesetValidations.default)(_servicioDocumento.default), _servicioDocumento.default, {
        skipValidate: false
      });
    },

    validate() {
      return Ember.RSVP.all([this.changeset.validate(), ...this.documentosChangesets.map(changeset => changeset.validate())]).then(() => {
        let changesetsAreValid = this.documentosChangesets.reduce((prevIsValid, changeset) => prevIsValid && changeset.get('isValid'), true);

        if (!this.changeset.get('isValid') || !changesetsAreValid) {
          this.toast.error('Hay errores en el formulario.');
          return Ember.RSVP.resolve(false);
        }

        return Ember.RSVP.resolve(true);
      });
    },

    processSave() {
      let superRef = this._super.bind(this, ...arguments);

      return this.validate().then(isValid => {
        if (!isValid) {
          this.loader.setLoading(false);
          return Ember.RSVP.resolve();
        }

        return superRef();
      });
    },

    saveDocumentosChangesets() {
      let savePromises = this.documentosChangesets.filterBy('isDirty').map(changeset => {
        changeset.set('isEditing', false);
        return changeset.save().catch(error => this.errorHandler.handleSaveErrors(error, changeset));
      });
      return Ember.RSVP.all(savePromises);
    },

    postSave() {
      this.indexCtrl.reloadTable();
      return this.saveDocumentosChangesets();
    },

    actions: {
      discardChanges() {
        this.changeset.rollback();
        this.record.rollbackAttributes();
        this.documentosChangesets.forEach(changeset => changeset.get('_content').rollbackAttributes());
        this.documentosChangesets.forEach(changeset => changeset.rollback());
        this.transitionToRoute(this.get('indexRoute'));
      },

      onDocumentoCargaPerfilUpdated(documentoChangeset) {
        documentoChangeset.set('cargaPerfilId', documentoChangeset.get('cargaPerfil.id') || null);
      },

      createDocumentoChangeset() {
        if (this.record.get('isNew')) {
          this.toast.warning('Haga click en Guardar antes de registrar documentos.');
          return;
        }

        this.documentosChangesets.pushObject(this.createDocumentoChangeset());
      },

      removeDocumento(documentoChangeset) {
        this.send('openModal', 'popups/simple-confirm', {
          message: '¿Está seguro de eliminar esta configuración?',
          onSubmit: () => {
            documentoChangeset.rollback();
            documentoChangeset.get('_content').destroyRecord().then(() => {
              this.documentosChangesets.removeObject(documentoChangeset);
              documentoChangeset.destroy();
            }).catch(error => this.errorHandler.handleBackendErrors(error));
          }
        });
      },

      saveDocumentoChangeset(documentoChangeset) {
        return documentoChangeset.validate().then(() => {
          if (!documentoChangeset.get('isValid')) {
            this.toast.error('Hay un error con el formulario.');
            return;
          }

          this.loader.setLoading(true);
          documentoChangeset.set('isEditing', false);
          documentoChangeset.save().then(() => {
            this.toast.success('Configuración de documento guardada correctamente.');
          }).catch(error => {
            this.toast.error('Ocurrió un error al guardar el registro.');
            throw error;
          }).finally(() => this.loader.setLoading(false));
        });
      }

    }
  });

  _exports.default = _default;
});