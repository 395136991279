define("sirvo-web/models/kar-reporte-movimientos", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    tipoMovimiento: belongsTo('kar-tipomov'),
    deha: attr(),
    bodega: belongsTo('kar-bodega'),
    tiposArticulos: hasMany('kar-tipo-articulo'),
    periodo: belongsTo('emp-periodo'),
    centrosCosto: hasMany('emp-cc'),
    cuentasContables: hasMany('emp-nomenclatura'),
    proveedores: hasMany('gru-directorio'),
    articulo: belongsTo('kar-articulo'),
    fechaInicio: attr('custom-date'),
    fechaFin: attr('custom-date')
  });

  _exports.default = _default;
});