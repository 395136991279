define("sirvo-web/controllers/inventario/movimientos/ingresos/record", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    request: Ember.inject.service(),
    kardex: Ember.inject.service(),
    errorHandler: Ember.inject.service(),
    indexCtrl: Ember.inject.controller('inventario.movimientos.ingresos'),
    fechaCierre: Ember.computed.readOnly('currentCompany.lastCierre.fecha'),
    indexRoute: null,
    registroCerrado: Ember.computed('changeset.fecha', 'fechaCierre', function () {
      if (!this.fechaCierre || !this.changeset.get('fecha')) {
        return false;
      }

      return this.fechaCierre >= this.changeset.get('fecha');
    }),

    reloadItems() {
      return this.kardex.getKarMovLineas(this.record).then(detalles => this.set('detalles', detalles));
    },

    saveItemsSequentially() {
      return this.detalles.filterBy('isDirty').reduce((prevPromise, currentItem) => prevPromise.then(() => currentItem.save()), Ember.RSVP.resolve(true)).catch(error => {
        this.toast.error('Ocurrió un error al guardar las líneas de detalle.');

        if (Array.isArray(error.errors)) {
          error.errors.forEach(err => err.message && this.toast.error(err.message));
        }

        throw error;
      });
    },

    actions: {
      onClose() {
        this.record.rollbackAttributes(); // Eliminar las ubicaciones de las líneas que aún no se han guardado.

        this.detalles.filterBy('isNew').forEach(linea => {
          this.kardex.destroyUnsavedUbicaciones(linea.get('ubicaciones'));
        }); // Eliminar líneas no guardadas.

        this.detalles.filterBy('isNew').forEach(linea => linea.rollbackAttributes());
        this.transitionToRoute(this.indexRoute);
      },

      submit() {
        return this.changeset.validate().then(() => {
          if (this.changeset.get('isInvalid')) {
            this.toast.error('Hay un problema de validación del movimiento.');
            return;
          }

          let valordocml = this.detalles.mapBy('total').reduce((sum, value) => sum + value, 0);
          this.changeset.set('valordocml', valordocml);
          this.changeset.set('valorNeto', valordocml);
          this.loader.setLoading(true);
          return this.changeset.save().then(() => this.saveItemsSequentially()).then(() => {
            this.toast.success('Registro almacenado correctamente.');
            this.indexCtrl.set('reload', true);
            this.transitionToRoute(`${this.routeName}.detalles`, this.record.id);
          }).catch(error => this.errorHandler.handleSaveErrors(error, this.changeset, 'Ocurrió un error al guardar el registro.')).finally(() => this.loader.setLoading(false));
        });
      },

      eliminarLineas() {
        if (this.record.get('isNew')) {
          return;
        }

        if (this.registroCerrado) {
          this.toast.error('Registro solo de lectura por cierre de inventario.');
          return;
        }

        this.send('openModal', 'popups/simple-confirm', {
          title: '¿Está seguro de eliminar todas las líneas del movimiento?',
          message: 'Se eliminarán todas las líneas de detalle junto con las pólizas asociadas.',
          onSubmit: () => {
            this.loader.setLoading(true);
            return this.request.post(`kar-movs/${this.record.id}/eliminar-detalles`).then(() => {
              this.toast.success('Líneas de detalle eliminadas correctamente.');
              this.reloadItems();
            }).finally(() => this.loader.setLoading(false));
          }
        });
      }

    }
  });

  _exports.default = _default;
});