define("sirvo-web/models/kar-inventario-fisico", ["exports", "@ember-data/model", "moment"], function (_exports, _model, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    empresa: (0, _model.belongsTo)('empresa'),
    bodega: (0, _model.belongsTo)('kar-bodega'),
    ubicaciones: (0, _model.hasMany)('kar-inventario-fisico-ubicacion'),
    fecha: (0, _model.attr)('custom-date'),
    correlativo: (0, _model.attr)('number'),
    observaciones: (0, _model.attr)('string'),
    excluirArticulosSinMovimientos: (0, _model.attr)('boolean'),
    generado: (0, _model.attr)('boolean'),
    cerrado: (0, _model.attr)('boolean'),
    fechaInicioMovimientos: (0, _model.attr)('custom-date'),
    fechaFormatted: Ember.computed('fecha', function () {
      const momentDate = (0, _moment.default)(this.fecha);

      if (!this.fecha || !momentDate.isValid()) {
        return '';
      }

      return momentDate.format('DD/MM/YYYY');
    }),

    save(options) {
      if (this.get('isDeleted')) {
        return this._super(options);
      }

      let unsavedUbicaciones = this.ubicaciones.filter(ubicacion => ubicacion.get('isNew') || ubicacion.get('isDirty'));
      return this._internalModel.save(options).then(() => Ember.RSVP.all(unsavedUbicaciones.map(ubicacion => ubicacion.save()))).then(() => {
        return this;
      });
    }

  });

  _exports.default = _default;
});