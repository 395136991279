define("sirvo-web/templates/ods/tablas/puertos/record", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ru/bA7Vl",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[6,\"div\"],[10,\"class\",\"w-100 bg-white border-bottom p-4\"],[8],[0,\"\\n  \"],[6,\"h3\"],[8],[0,\"\\n\"],[4,\"if\",[[21,0,[\"record\",\"isNew\"]]],null,{\"statements\":[[0,\"      Crear puerto\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      Editar puerto\\n\"]],\"parameters\":[]}],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"pull-right\"],[8],[0,\"\\n      \"],[4,\"bs-button\",null,[[\"size\",\"onClick\"],[\"sm\",[26,\"action\",[[21,0,[]],\"discardChanges\"],null]]],{\"statements\":[[0,\"\\n        Cancelar\\n      \"]],\"parameters\":[]},null],[0,\"\\n\\n      \"],[4,\"bs-button\",null,[[\"type\",\"size\",\"onClick\"],[\"success\",\"sm\",[26,\"action\",[[21,0,[]],\"submit\",true],null]]],{\"statements\":[[0,\"\\n        Guardar y Crear\\n      \"]],\"parameters\":[]},null],[0,\"\\n\\n      \"],[1,[26,\"forms/controls/ace-async-save-button\",null,[[\"onClick\"],[[26,\"action\",[[21,0,[]],\"submit\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"p-4\"],[8],[0,\"\\n\"],[4,\"extended-form-for\",[[22,[\"changeset\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n      \"],[6,\"div\"],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n        \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"codigo\",\"Código\"],[[\"placeholder\"],[\"Código\"]]],false],[0,\"\\n\\n        \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"nombre\",\"Nombre\"],[[\"placeholder\"],[\"Nombre\"]]],false],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[6,\"div\"],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/ods/tablas/puertos/record.hbs"
    }
  });

  _exports.default = _default;
});