define("sirvo-web/controllers/ods/reportes/ordenes-servicio", ["exports", "sirvo-web/mixins/reports-fechas-filters", "moment"], function (_exports, _reportsFechasFilters, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_reportsFechasFilters.default, {
    request: Ember.inject.service(),
    directorio: Ember.inject.service(),
    clientesQuery: null,
    buquesQuery: null,

    resolvePromise(promise) {
      this.loader.setLoading(true);
      return promise.then(data => data).finally(() => this.loader.setLoading(false));
    },

    getSerializedFilters() {
      let fechaInicio = this.changeset.get('fechaInicio');
      let fechaFin = this.changeset.get('fechaFin');
      let filters = {
        tiposOrden: this.changeset.get('tiposOrden').map(p => p.id),
        tiposServicios: this.changeset.get('tiposServicios').map(p => p.id),
        puerto: this.changeset.get('puerto.id') || null,
        terminal: this.changeset.get('terminal.id') || null,
        cliente: this.changeset.get('cliente.id') || null,
        buques: this.changeset.get('buques').map(p => p.id),
        fechaInicio: fechaInicio ? (0, _moment.default)(fechaInicio).format('YYYY-MM-DD') : null,
        fechaFin: fechaFin ? (0, _moment.default)(fechaFin).format('YYYY-MM-DD') : null
      };
      return filters;
    },

    actions: {
      onPuertoUpdated() {
        this.set('terminales', []);
        this.changeset.set('terminal', null);

        if (!this.changeset.get('puerto.id')) {
          return;
        }

        let promise = this.store.query('ods/terminal', {
          filter: {
            'puerto': this.changeset.get('puerto.id')
          }
        });
        this.set('terminales', this.resolvePromise(promise));
      },

      onSearchCliente(text) {
        if (!text || text.length < 3) {
          return;
        }

        if (this.clientesQuery) {
          Ember.run.cancel(this.clientesQuery);
        }

        this.clientesQuery = Ember.run.later(() => {
          // TODO: Definir con Granelsa la clasificación de los clientes de ODS.
          this.set('clientes', this.directorio.searchKarMovProveedores(text));
        }, 500);
      },

      onSearchBuque(text) {
        if (!text || text.length < 3) {
          return;
        }

        if (this.buquesQuery) {
          Ember.run.cancel(this.buquesQuery);
        }

        this.buquesQuery = Ember.run.later(() => {
          this.set('buques', this.store.query('ods/buque', {
            filter: {
              codigo: {
                OR: text
              },
              nombre: {
                OR: text
              }
            }
          }));
        }, 500);
      },

      generarReporte() {
        return this.changeset.validate().then(() => {
          if (!this.changeset.get('isValid')) {
            this.toast.error('Corrija los errores en el formulario.');
            return;
          }

          let filters = this.getSerializedFilters();
          this.loader.setLoading(true);
          this.toast.info('Generando reporte');
          return this.request.get('ods/reportes/ordenes-servicio', filters).then(() => {
            this.toast.success('Reporte generado exitosamente');
          }).finally(() => this.loader.setLoading(false));
        });
      }

    }
  });

  _exports.default = _default;
});