define("sirvo-web/routes/carga-saldos-iniciales-cxp", ["exports", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators"], function (_exports, _emberChangeset, _emberChangesetValidations, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    company: Ember.computed.readOnly('currentCompany.company'),
    validations: Ember.computed(function () {
      return {
        archivo: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),

    model() {
      let documentoModel = new Ember.Object({
        archivo: null,
        save: 0
      });
      return documentoModel;
    },

    setupController(controller, model) {
      this._super(controller, model);

      let changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(this.get('validations')), this.get('validations'));
      controller.set('documentoChangeset', changeset);
    }

  });

  _exports.default = _default;
});