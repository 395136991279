define("sirvo-web/routes/ods/reportes/ordenes-servicio", ["exports", "servir-ember-utilities/routes/abstract-module-index", "sirvo-web/mixins/access-validator", "ember-changeset", "ember-changeset-validations"], function (_exports, _abstractModuleIndex, _accessValidator, _emberChangeset, _emberChangesetValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend(_accessValidator.default, {
    routeAccess: 'ODS-REPORTE-ORDENES-SERVICIO',
    validations: Ember.computed(function () {
      return {};
    }),

    model()
    /*params, transition*/
    {
      this.loader.setIsLoading();
      let promises = {
        puertos: this.store.query('ods/puerto', {}),
        tiposOrden: this.store.query('ods/tipo-orden', {}),
        tiposServicios: this.store.query('ods/tipo-servicio', {})
      };
      return Ember.RSVP.hash(promises).finally(() => this.loader.setNotLoading());
    },

    setupController(controller, model) {
      this._super(controller, model);

      let filterObj = this.store.createRecord('ods/reporte-ordenes-servicio');
      let changeset = new _emberChangeset.default(filterObj, (0, _emberChangesetValidations.default)(this.get('validations')), this.get('validations'));
      controller.set('changeset', changeset);
      controller.setProperties(model);
    }

  });

  _exports.default = _default;
});