define("sirvo-web/components/cxp/poliza-principal", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    request: Ember.inject.service(),
    router: Ember.inject.service(),
    lastRouteService: Ember.inject.service('last-route'),
    indexRoute: null,
    isPolizaModalOpen: false,
    transaccionKeytra: null,
    showFilterMenu: false,
    filterCodigo: '',
    dataEncabezados: null,
    dataElementosTabla: null,
    fechaSis: null,
    tipoPoliza: null,
    conceptoPoliza: null,
    periodoPoliza: null,
    keytraPoliza: null,
    keytraContrasena: null,
    totalCargos: null,
    totalAbonos: null,
    isActualizarDisabled: false,
    isPrint: false,
    contrasenaEncontrada: null,
    isOpenAgregarLinea: false,
    isConfirmModalOpen: false,
    confirmTitle: '',
    confirmMessage: '',
    recordToDelete: null,
    onActualizarEmpty: () => {},

    init() {
      this._super(...arguments);

      this.set('lastRoute', this.get('lastRouteService.lastRoute'));
      this.loadData();
    },

    loadData() {
      let keytraPoliza = this.get('transaccionCreated');
      let keytraContrasena = this.get('keytraContrasenaObtenido');
      Ember.RSVP.hash({
        encabezados: this.loadEncabezados(keytraPoliza),
        elementosPoliza: this.loadElementosPoliza(keytraPoliza),
        // contrasena: this.findContrasena(keytraContrasena),
        contrasena: keytraContrasena ? this.findContrasena(keytraContrasena) : null
      }).then(model => {
        this.setProperties({
          periodoPoliza: model.elementosPoliza.data[0].codperiodo,
          keytraPoliza: keytraPoliza,
          keytraContrasena: keytraContrasena,
          dataEncabezados: model.encabezados.data,
          dataElementosTabla: model.elementosPoliza.data,
          fechaSis: model.encabezados.fechaSis,
          tipoPoliza: model.encabezados.tipoPoliza,
          conceptoPoliza: model.encabezados.conceptoPoliza,
          totalCargos: model.elementosPoliza.totalCargos,
          diferencia: model.elementosPoliza.diferencia,
          totalAbonos: model.elementosPoliza.totalAbonos,
          contrasenaEncontrada: model.contrasena,
          isActualizarDisabled: model.encabezados.data.actualizaDocCnt
        });
      });
    },

    loadEncabezados(keytraPoliza) {
      return this.request.get('transacciones/empresas/sistema/obtener', {
        keytra: keytraPoliza
      }).then(response => {
        let encabezado = response.data[0];
        return {
          data: encabezado,
          fechaSis: (0, _moment.default)(encabezado.fecha.date).format('YYYY-MM-DD'),
          tipoPoliza: encabezado.nomsistema,
          conceptoPoliza: encabezado.descripcionContable
        };
      });
    },

    loadElementosPoliza(keytraPoliza) {
      let periodo = new Date().getFullYear() + '-01';
      return this.request.get('detalle-transaccion/empresas/periodo', {
        keytra: keytraPoliza,
        periodo: periodo
      }).then(response => {
        let totalCargos = 0,
            totalAbonos = 0;
        response.data.forEach(row => {
          totalCargos += parseFloat(row.cargo);
          totalAbonos += parseFloat(row.abono);
        });
        let diferencia = parseFloat(totalCargos) - parseFloat(totalAbonos);
        return {
          data: response.data,
          totalCargos: this.formatNumberWithCommas(totalCargos),
          diferencia: this.formatNumberWithCommas(diferencia),
          totalAbonos: this.formatNumberWithCommas(totalAbonos)
        };
      });
    },

    findContrasena(keytraContrasena) {
      // Si no se proporciona keytraContrasena, no se realiza la búsqueda
      if (!keytraContrasena) {
        return null;
      }

      return this.store.query('que/contrasena', {
        filter: {
          keytra: keytraContrasena
        }
      }).then(result => result.get('firstObject'));
    },

    formatNumberWithCommas(value) {
      let numerico = Number(value);
      let parts = numerico.toFixed(2).split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return parts.join('.');
    },

    columns: Ember.computed(function () {
      return [{
        label: 'Propio',
        cellComponent: 'readonly-checkbox',
        valuePath: 'espropio',
        width: '50px'
      }, {
        label: 'U. Niv.',
        cellComponent: 'readonly-checkbox',
        valuePath: 'univ',
        width: '50px'
      }, {
        label: 'Cuenta',
        valuePath: 'codcuenta',
        width: '80px'
      }, {
        label: 'Nombre',
        valuePath: 'nomcta',
        width: '200px'
      }, {
        label: 'Concepto contable',
        valuePath: 'orden_compra_codigo',
        width: '200px'
      }, {
        label: 'Cargo',
        valuePath: 'cargo',
        width: '80px',
        align: 'right',
        cellComponent: 'total-float-tabla'
      }, {
        label: 'Abono',
        valuePath: 'abono',
        width: '80px',
        align: 'right',
        cellComponent: 'total-float-tabla'
      }, {
        label: 'Cod. C.I.',
        valuePath: 'codci',
        width: '80px'
      }, {
        label: 'Cod. C.C.',
        valuePath: 'codcc',
        width: '150px'
      }, {
        label: '',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '40px',
        sortable: false,
        objectActions: {
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.showDeleteConfirmModal.bind(this)
          }
        }
      }];
    }),

    showDeleteConfirmModal(item) {
      let id = item.id;
      this.store.findRecord('det-transaccion', id).then(record => {
        if (typeof record.destroyRecord !== 'function') {
          this.toast.error('El objeto obtenido no es un registro de Ember Data.');
          return;
        }

        if (parseInt(record.espropio) === 1) {
          this.toast.error('Registro generado por el sistema, no se puede eliminar.');
          return;
        }

        this.setProperties({
          isConfirmModalOpen: true,
          confirmTitle: '¿Estás seguro que deseas eliminar el registro?',
          confirmMessage: 'No podrás revertir esta acción!',
          recordToDelete: record
        });
      }).catch(error => {
        this.toast.error('Error al encontrar el registro. ' + error);
      });
    },

    /**
     * Función que se llama cuando se confirma la eliminación en el modal
     */
    onSubmit() {
      const record = this.recordToDelete;

      if (record && typeof record.destroyRecord === 'function') {
        record.destroyRecord().then(() => {
          this.send('refreshData');
          this.toast.success('Registro eliminado.');
        }).catch(error => {
          this.toast.error('Error eliminando el registro.' + error);
        });
      } else {
        this.toast.error('No se pudo eliminar el registro.');
      }

      this.set('isConfirmModalOpen', false);
    },

    /**
     * Función que se llama cuando se cancela la eliminación en el modal
     */
    onCancel() {
      this.set('isConfirmModalOpen', false);
    },

    /**
     * Función que se llama para cerrar el modal
     */
    onClose() {
      this.set('isConfirmModalOpen', false);
    },

    actions: {
      // Acciones del componente
      showDeleteConfirmModal(item) {
        this.showDeleteConfirmModal(item);
      },

      onSubmit() {
        this.onSubmit();
      },

      onCancel() {
        this.onCancel();
      },

      onClose() {
        this.router.transitionTo(this.get('lastRouteService.lastRoute'));
      },

      toggleCloseModal() {
        this.router.transitionTo(this.get('lastRouteService.lastRoute'));
      },

      actualizarPoliza() {
        this.loader.setLoading(true);
        this.request.get('actualizar-poliza/transaccion', {
          keytra: this.get('keytraPoliza')
        }).then(response => {
          this.toast.success(response.message);
          this.set('isActualizarDisabled', true);
          (this.get('onActualizar') || this.onActualizarEmpty)();
        }).catch(error => {
          let errorMessage = error.errors && error.errors[0] && error.errors[0].message ? error.errors[0].message : 'Ocurrió un error al actualizar la póliza.';
          this.toast.error(errorMessage);
        }).finally(() => this.loader.setNotLoading());
      },

      refreshData() {
        this.send('loadElementosPoliza', this.get('keytraPoliza'));
      },

      verContrasena() {
        if (this.get('contrasenaEncontrada')) {
          try {
            this.transitionToRoute('emision-contrasenas.new', this.get('contrasenaEncontrada').get('id'));
          } catch (error) {
            this.toast.error('Error en la transicion a ruta.');
          }
        } else {
          this.toast.error('Contraseña no encontrada.');
        }
      },

      impresionDocumentoModal() {
        this.set('isPrint', true);
      },

      mantenimientoPoliza() {
        this.set('isOpenAgregarLinea', true);
      },

      ocultarModal() {
        this.set('isModalVisible', false);
      },

      loadElementosPoliza(keytraPoliza) {
        let periodo = new Date().getFullYear() + '-01';
        return this.request.get('detalle-transaccion/empresas/periodo', {
          keytra: keytraPoliza,
          periodo: periodo
        }).then(response => {
          let totalCargos = 0,
              totalAbonos = 0;
          response.data.forEach(row => {
            totalCargos += parseFloat(row.cargo);
            totalAbonos += parseFloat(row.abono);
          });
          let diferencia = parseFloat(totalCargos) - parseFloat(totalAbonos);
          let totalCargosFormatted = this.formatNumberWithCommas(totalCargos);
          let diferenciaFormatted = this.formatNumberWithCommas(diferencia);
          let totalAbonosFormatted = this.formatNumberWithCommas(totalAbonos);
          this.set('dataElementosTabla', response.data);
          this.set('totalCargos', totalCargosFormatted);
          this.set('diferencia', diferenciaFormatted);
          this.set('totalAbonos', totalAbonosFormatted);
          this.notifyPropertyChange('dataElementosTabla');
          this.notifyPropertyChange('totalCargos');
          this.notifyPropertyChange('diferencia');
          this.notifyPropertyChange('totalAbonos');
        });
      },

      onCloseModal() {
        this.get('onClose')();
      }

    }
  });

  _exports.default = _default;
});