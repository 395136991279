define("sirvo-web/routes/admon/accesos/perfiles/record/permisos", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute"], function (_exports, _isTabSubroute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_isTabSubroute.default, {
    setupController() {
      this._super(...arguments);

      this.controller.notifyPropertyChange('changeset');
    }

  });

  _exports.default = _default;
});