define("sirvo-web/controllers/emision-contrasenas/index", ["exports", "sirvo-web/models/que/contrasena", "sirvo-web/utils/date", "moment"], function (_exports, _contrasena, _date, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    company: Ember.computed.readOnly('currentCompany.company'),
    request: Ember.inject.service(),
    toast: Ember.inject.service(),
    modelClass: _contrasena.default,
    user: Ember.computed.readOnly('currentUser.user'),
    contrasenaEdit: null,
    motivo: null,
    itemId: null,
    iva: null,
    ivaNit: null,
    notFound: false,
    isModalAnulacion: false,
    reload: true,
    currentYear: Ember.computed(function () {
      return new Date().getFullYear();
    }),
    currentMonth: Ember.computed(function () {
      return String(new Date().getMonth() + 1).padStart(2, '0');
    }),
    currentYearMonth: Ember.computed('currentYear', 'currentMonth', function () {
      return `${this.currentYear}${this.currentMonth}`;
    }),
    years: (0, _date.lastYears)(),
    months: _date.monthIndexesFilters,
    modelSortingProperties: Ember.computed(function () {
      return [{
        propertyName: 'fecha'
      }, {
        propertyName: 'serie'
      }, {
        propertyName: 'numero'
      }, {
        propertyName: 'codsistema'
      }, {
        propertyName: 'empresa.nombreemp'
      }, {
        propertyName: 'directorio.razonsocial'
      }, {
        propertyName: 'fechaPago'
      }, {
        propertyName: 'diascredito'
      }, {
        propertyName: 'codtipodoctos'
      }, {
        propertyName: 'keytra'
      }];
    }),
    modelFilters: Ember.computed('currentYearMonth', 'currentYear', 'currentMonth', 'company', function () {
      let filters = [{
        selectedFilter: {
          filterName: 'codempresa'
        },
        value: this.company.get('codempresa')
      }];
      let startDate = (0, _moment.default)({
        year: this.currentYear,
        month: this.currentMonth - 1,
        day: 1
      });
      let endDate = (0, _moment.default)({
        year: this.currentYear,
        month: this.currentMonth - 1
      }).endOf('month');
      filters.push({
        selectedFilter: {
          filterName: 'fecha'
        },
        value: {
          after: startDate.format('YYYY-MM-DD'),
          before: endDate.format('YYYY-MM-DD')
        }
      });
      return filters;
    }),
    columns: Ember.computed(function () {
      return [{
        label: 'Fecha',
        valuePath: 'fechaFormateada',
        sortingPropertyName: 'fecha',
        width: '100px'
      }, {
        label: 'Serie',
        valuePath: 'serie',
        sortingPropertyName: 'serie',
        width: '75px',
        filtrable: true,
        filterName: 'serie'
      }, {
        label: 'Número',
        valuePath: 'numero',
        sortingPropertyName: 'numero',
        width: '100px',
        filtrable: true,
        filterName: 'numero'
      }, {
        label: 'Código de Sistema',
        valuePath: 'codsistema',
        width: '100px',
        sortingPropertyName: 'fecha'
      }, {
        label: 'Empresa',
        valuePath: 'empresa.nombreemp',
        filtrable: true,
        filterName: 'empresa.nombreemp',
        width: '175px',
        sortingPropertyName: 'empresa.nombreemp'
      }, {
        label: 'Razón Social',
        valuePath: 'directorio.razonsocial',
        width: '250px',
        filtrable: true,
        filterName: 'directorio.razonsocial',
        sortingPropertyName: 'directorio.razonsocial'
      }, {
        label: 'Fecha pago',
        valuePath: 'fechaPagoFormateada',
        width: '100px',
        sortingPropertyName: 'fechaPago'
      }, {
        label: 'Crédito',
        valuePath: 'diascredito',
        width: '100px',
        sortingPropertyName: 'diascredito'
      }, {
        label: 'Tipo de documento',
        valuePath: 'codtipodoctos',
        width: '100px',
        sortingPropertyName: 'codtipodoctos',
        filtrable: true,
        filterName: 'codtipodoctos'
      }, {
        label: 'Clave',
        valuePath: 'keytra',
        width: '200px',
        filtrable: true,
        filterName: 'keytra',
        sortingPropertyName: 'keytra'
      }, {
        label: 'Anulado',
        valuePath: 'anulado',
        width: '85px',
        cellClassNames: 'align-center',
        cellComponent: 'tables/cell/anulado-cell'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }];
    }),
    actions: {
      closeModalAnulacion() {
        this.set('isModalAnulacion', false);
      },

      update(itemId) {
        this.loader.setLoading(true);

        if (!this.motivo || this.motivo.trim() === '') {
          this.toast.error('Por favor, proporciona un motivo válido.');
          return;
        }

        return this.store.findRecord('que/contrasena', itemId).then(contrasena => {
          let fechaActual = new Date();
          let codUser = this.user.coduser;
          contrasena.set('anulado', 1);
          contrasena.set('fechaAnulado', fechaActual);
          contrasena.set('motivoAnulado', this.motivo);
          contrasena.set('userAnulado', codUser);
          contrasena.save().then(() => {
            this.toast.success('Registro anulado.');
            this.send('closeModalAnulacion');
            this.set('reload', true);
            this.loader.setLoading(false);
          }).catch(error => {
            this.toast.error('Error al actualizar el registro: ' + error);
            this.loader.setLoading(false);
          });
        });
      },

      reloadTable() {
        this.set('reload', true);
      },

      createContrasena() {
        this.transitionToRoute('emision-contrasenas.new', 'new');
      },

      edit(contrasena) {
        try {
          let id = contrasena.id;
          this.transitionToRoute('emision-contrasenas.new', id);
        } catch (error) {
          this.toast.error('Error .');
        }
      },

      delete(item) {
        let id = item.id;
        return this.store.findRecord('que/contrasena', id).then(record => {
          return this.store.query('que/contrasena-linea', {
            filter: {
              keytra: item.keytra
            }
          }).then(contrasenaLineas => {
            let numberOfRecords = contrasenaLineas.get('length');

            if (numberOfRecords > 0) {
              this.toast.error('Existen facturas en el detalle de la contraseña.');
            } else if (record.anulado === 1) {
              this.toast.error('¡Error! Este registro ya ha sido anulado previamente. No se puede anular nuevamente');
            } else if (record.numero === 0 && record.anulado !== 1) {
              return this.send('openModal', 'popups/simple-confirm', {
                title: '¿Estás seguro que deseas eliminar la contraseña?',
                message: 'No podrás revertir esta acción!',
                onSubmit: () => {
                  if (record) {
                    record.destroyRecord().then(() => {
                      this.set('reload', true);
                      this.toast.success('Registro eliminado.');
                    });
                  } else {
                    this.toast.error('El registro no se encontró.');
                  }
                }
              });
            } else if (record.numero > 0 && record.anulado !== 1) {
              this.set('itemId', id);
              this.set('isModalAnulacion', true);
            }
          });
        }).catch(error => {
          this.toast.error(error);
        });
      }

    }
  });

  _exports.default = _default;
});