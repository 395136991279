define("sirvo-web/controllers/ods/servicios/ordenes-servicio/record/generales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    directorio: Ember.inject.service(),
    buquesQuery: null,
    clientesQuery: null,
    facturaClientesQuery: null,
    tiposOrdenFiltered: Ember.computed('tiposOrden', 'changeset.esAdicional', function () {
      return this.tiposOrden.filter(tipoOrden => {
        return Boolean(tipoOrden.get('esAdicional')) === Boolean(this.changeset.get('esAdicional'));
      });
    }),
    buqueActions: Ember.computed('changeset.buque.isNew', function () {
      let fieldActions = Ember.A([]);

      if (this.changeset.get('buque.id')) {
        fieldActions.pushObject({
          title: 'Ver buque',
          iconName: 'fa-external-link',
          action: this.actions.editBuque.bind(this)
        });
      }

      fieldActions.pushObject({
        title: 'Registrar nuevo buque',
        iconName: 'fa-plus',
        action: this.actions.addBuque.bind(this)
      });
      return fieldActions;
    }),

    resolvePromise(promise) {
      this.loader.setLoading(true);
      return promise.then(data => data).finally(() => this.loader.setLoading(false));
    },

    actions: {
      onPuertoUpdated() {
        this.setProperties({
          terminales: [],
          muelles: []
        });
        this.changeset.setProperties({
          terminal: null,
          muelle: null
        });

        if (!this.changeset.get('puerto.id')) {
          return;
        }

        let promise = this.store.query('ods/terminal', {
          filter: {
            'puerto': this.changeset.get('puerto.id')
          }
        });
        this.set('terminales', this.resolvePromise(promise));
      },

      onTerminalUpdated() {
        this.set('muelles', []);
        this.changeset.set('muelle', null);

        if (!this.changeset.get('terminal.id')) {
          return;
        }

        let promise = this.store.query('ods/muelle', {
          filter: {
            'terminal': this.changeset.get('terminal.id')
          }
        });
        this.set('muelles', this.resolvePromise(promise));
      },

      onTiposServiciosUpdated() {
        let serviciosAplicables = Ember.A([]);
        this.changeset.get('tiposServicios').forEach(tipoServicio => {
          serviciosAplicables.pushObjects(tipoServicio.get('servicios').toArray());
        }); // Quitar los servicios que ya no aplican porque se eliminó su tipo de servicio.

        this.changeset.get('servicios').forEach(servicio => {
          if (!serviciosAplicables.includes(servicio)) {
            this.changeset.get('servicios').removeObject(servicio);
          }
        }); // Si se eliminó un tipo de servicio, quitar/esconder su lista de servicios.

        this.serviciosPorTipoServicio.forEach(tipoServicioData => {
          if (!this.changeset.get('tiposServicios').includes(tipoServicioData.tipoServicio)) {
            this.serviciosPorTipoServicio.removeObject(tipoServicioData);
          }
        }); // Si se agregó un tipo de servicio, agregar/mostrar su lista de servicios.

        this.changeset.get('tiposServicios').forEach(tipoServicio => {
          let haveList = this.serviciosPorTipoServicio.find(tipoServicioData => tipoServicioData.tipoServicio.id === tipoServicio.id);

          if (!haveList) {
            this.serviciosPorTipoServicio.pushObject({
              tipoServicio,
              selected: Ember.A([])
            });
          }
        });
      },

      onServiciosUpdated(selected, item, action) {
        if (action === 'added') {
          if (!this.changeset.get('servicios').includes(item)) {
            this.changeset.get('servicios').pushObject(item);
          }
        } else {
          if (this.changeset.get('servicios').includes(item)) {
            this.changeset.get('servicios').removeObject(item);
          }
        }
      },

      onSearchBuque(text) {
        if (!text || !text.trim()) {
          return;
        }

        if (this.buquesQuery) {
          Ember.run.cancel(this.buquesQuery);
        }

        this.buquesQuery = Ember.run.later(() => {
          this.set('buques', this.store.query('ods/buque', {
            filter: {
              codigo: {
                OR: text.trim()
              },
              nombre: {
                OR: text.trim()
              }
            }
          }));
        }, 500);
      },

      addBuque() {
        this.transitionToRoute(`${this.routeName}.buque`, 'new');
      },

      editBuque() {
        // Por si acaso
        if (!this.changeset.get('buque.id')) {
          this.toast.error('Buque pendiente de guardar.');
          return;
        }

        this.transitionToRoute(`${this.routeName}.buque`, this.changeset.get('buque.id'));
      },

      onSearchCliente(text) {
        if (!text || text.length < 3) {
          return;
        }

        if (this.clientesQuery) {
          Ember.run.cancel(this.clientesQuery);
        }

        this.clientesQuery = Ember.run.later(() => {
          // TODO: Definir con Granelsa la clasificación de los clientes de ODS.
          this.set('clientes', this.directorio.searchDirectorios(text));
        }, 500);
      },

      onSearchClienteFactura(text) {
        if (!text || text.length < 3) {
          return;
        }

        if (this.facturaClientesQuery) {
          Ember.run.cancel(this.facturaClientesQuery);
        }

        this.facturaClientesQuery = Ember.run.later(() => {
          // TODO: Definir con Granelsa la clasificación de los clientes para facturación.
          this.set('facturaClientes', this.directorio.searchDirectorios(text));
        }, 500);
      }

    }
  });

  _exports.default = _default;
});