define("sirvo-web/models/ods/servicio-documento", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    servicio: belongsTo('ods/servicio'),
    documento: belongsTo('ods/documento'),
    // Se mapea y setea en frontend a fin de disparar los hooks de Doctrine en el
    // backend. De lo contrario, si solo se modifica "cargaPerfil", los hooks preUpdate()
    // y postUpdate() no se disparan porque "cargaPerfil" no está mapeado por Doctrine.
    cargaPerfilId: attr('number'),
    cargaPerfil: belongsTo('vf-perfil'),
    esUnico: attr('boolean'),
    esMultiple: attr('boolean'),
    esRequerido: attr('boolean')
  });

  _exports.default = _default;
});