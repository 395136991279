define("sirvo-web/validations/ods/buceo-inmersion-linea", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    inmersion: (0, _validators.validatePresence)(true),
    manguera: (0, _validators.validatePresence)(true),
    posicion: (0, _validators.validatePresence)(true),
    areaInspecionada: (0, _validators.validatePresence)(true)
  };
  _exports.default = _default;
});