define("sirvo-web/controllers/inventario/movimientos/ingresos/record/ingreso", ["exports", "ember-changeset", "ember-changeset-validations", "sirvo-web/validations/kar-movdet", "sirvo-web/utils/format"], function (_exports, _emberChangeset, _emberChangesetValidations, _karMovdet, _format) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    kardex: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),
    decimalesConfig: Ember.computed.readOnly('currentCompany.karDecimalesConfig'),
    detalles: null,
    lineaModel: null,
    lineaChangeset: null,
    ingresoValidations: Ember.computed(function () {
      return { ..._karMovdet.defaultValidations
      };
    }),
    fechaCierre: Ember.computed.readOnly('currentCompany.lastCierre.fecha'),
    registroCerrado: Ember.computed('changeset.fecha', 'fechaCierre', function () {
      if (!this.fechaCierre || !this.changeset.get('fecha')) {
        return false;
      }

      return this.fechaCierre >= this.changeset.get('fecha');
    }),
    usaCorrelativoAutomatico: Ember.computed('changeset.tipoDocumento', function () {
      if (!this.changeset.get('tipoDocumento')) {
        return false;
      }

      return this.changeset.get('tipoDocumento').get('correlativoAutomatico');
    }),
    proveedorActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editProveedor.bind(this)
      }];
    }),
    articuloActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editArticulo.bind(this)
      }];
    }),
    propertyNameActionMap: Ember.computed(function () {
      return [{
        propertyName: 'proveedor',
        action: this.actions.editProveedor.bind(this)
      }, {
        propertyNames: ['articulo', 'bodega'],
        action: this.actions.editArticulo.bind(this)
      }];
    }),

    createNewLinewChangeset() {
      this.set('lineaModel', this.store.createRecord('kar-movdet', {
        empresa: this.company,
        movimiento: this.record,
        linea: this.detalles.length + 1,
        porcentajeIva: this.record.tipoIva.get('porcentaje')
      }));
      this.set('lineaChangeset', new _emberChangeset.default(this.lineaModel, (0, _emberChangesetValidations.default)(_karMovdet.default), _karMovdet.default));
      (0, _karMovdet.applyValidationsOnChangesetIngreso)(this.lineaChangeset, this.ingresoValidations);
      this.set('ubicacionesChangesets', this.kardex.createMovDetUbicacionesChangesets(this.tiposUbicaciones, this.lineaModel));
    },

    cancelAddingItem() {
      this.ubicacionesChangesets.forEach(changeset => changeset.rollback());
      this.lineaChangeset.rollback(); // Eliminar los registros de ubicación del ítem actual.

      this.kardex.destroyUnsavedUbicaciones(this.lineaModel.get('ubicaciones')); // Es necesario que esto ocurra después de eliminar las ubicaciones.

      this.lineaModel.rollbackAttributes();
    },

    actions: {
      cancelAddingItem() {
        this.cancelAddingItem();
      },

      editProveedor() {
        this.transitionToRoute(`${this.routeName}.proveedor`);
      },

      editArticulo() {
        this.transitionToRoute(`${this.routeName}.articulo`);
      },

      addItem() {
        if (this.registroCerrado) {
          this.toast.error('Registro solo de lectura por cierre de inventario.');
          return;
        }

        return Ember.RSVP.all([this.lineaChangeset.validate(), ...this.ubicacionesChangesets.map(changeset => changeset.validate())]).then(() => {
          if (this.lineaChangeset.get('isInvalid')) {
            this.toast.error('Hay un problema de validación del artículo.');
            return;
          }

          let ubicacionesChangesetsValid = this.ubicacionesChangesets.reduce((prevIsValid, changeset) => prevIsValid && changeset.get('isValid'), true);

          if (!ubicacionesChangesetsValid) {
            this.toast.error('Hay un problema con las ubicaciones del artículo.');
            return;
          }

          this.lineaChangeset.execute();
          this.detalles.pushObject(this.lineaModel);
          this.ubicacionesChangesets.forEach(changeset => changeset.execute());
          this.createNewLinewChangeset();
          this.toast.info('Artículo agregado correctamente. No olvide guardar sus cambios.');
        });
      },

      updateValor(changeset, name, value = 0) {
        changeset.set(name, value);
        let total = changeset.get('total');
        let cantidad = parseFloat(changeset.get('cantidad')) || 0;
        let tasaCambio = parseFloat(changeset.get('tasac')) || 0;
        let uMedidaFactor = changeset.get('unidadMedida.factor') || 1;
        let valorNeto = total;
        let iva = 0;

        if (this.lineaChangeset.get('porcentajeIva')) {
          let porcentajeIva = this.lineaChangeset.get('porcentajeIva') / 100;
          valorNeto = (0, _format.round)(total / (1 + porcentajeIva));
          iva = total - valorNeto;
        }

        Ember.setProperties(changeset, {
          iva,
          valorNeto,
          subtotal: valorNeto,
          valorNetoMe: tasaCambio ? (0, _format.round)(valorNeto / tasaCambio) : 0,
          precioUnitario: cantidad ? (0, _format.round)(valorNeto / cantidad, this.decimalesConfig.costos) : 0,
          // WARNING: Este valor se sobre escribe en el backend para asegurar la consistencia.
          cantidadBase: cantidad * uMedidaFactor
        });
      },

      serializeProveedor(proveedor) {
        return proveedor ? proveedor.get('codigoRazonSocial') : null;
      },

      serializeArticulo(articulo) {
        return articulo ? articulo.get('codigoDescripcion') : null;
      },

      serializeBodega(bodega) {
        return bodega ? bodega.get('codigoDescripcion') : null;
      },

      serializeUnidadMedida(unidadMedida) {
        return unidadMedida ? unidadMedida.get('codigo') : null;
      }

    }
  });

  _exports.default = _default;
});