define("sirvo-web/templates/admon/procesos/notificaciones/record/plantilla", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TQX1wrsy",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[4,\"extended-form-for\",[[22,[\"changeset\"]]],null,{\"statements\":[[0,\"  \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"subjectTemplate\",\"Asunto\"],[[\"placeholder\",\"labelSize\",\"widgetSize\"],[\"Defina aquí el asunto del correo\",\"sm-2\",\"sm-10\"]]],false],[0,\"\\n\\n  \"],[1,[26,\"component\",[[21,1,[\"ace-textarea-field\"]],\"bodyTemplate\",\"Cuerpo\"],[[\"placeholder\",\"labelSize\",\"widgetSize\",\"rows\"],[\"Defina aquí el contenido del correo\",\"sm-2\",\"sm-10\",10]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/admon/procesos/notificaciones/record/plantilla.hbs"
    }
  });

  _exports.default = _default;
});