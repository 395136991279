define("sirvo-web/adapters/application", ["exports", "@ember-data/adapter/json-api", "ember-simple-auth/mixins/data-adapter-mixin", "sirvo-web/config/environment"], function (_exports, _jsonApi, _dataAdapterMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend(_dataAdapterMixin.default, {
    loader: Ember.inject.service(),
    namespace: _environment.default.API.namespace,
    headers: Ember.computed('session.data.authenticated.token', function () {
      // TODO: Fix this at the 'ember-data' level.
      let headers = {
        'Content-Type': 'application/vnd.api+json'
      };

      if (this.session.isAuthenticated) {
        // OAuth 2
        headers['Authorization'] = `Bearer ${this.session.data.authenticated.token}`;
      }

      return headers;
    }),

    /**
     * Automatically add page_enabled=null query parameter to all queries if
     * pagination is not present.
     *
     * @param  {[type]} store [description]
     * @param  {[type]} type  [description]
     * @param  {[type]} query [description]
     * @return {[type]}       [description]
     */
    query(store, type, query) {
      if (Ember.isNone(query.filter) || Ember.isNone(query.filter.page_number)) {
        if (Ember.isNone(query.filter)) {
          query.filter = {};
        }

        query.filter.page_enabled = null;
      }

      this.loader.setLoading(true);
      return this._super(...arguments).finally(() => this.loader.setLoading(false));
    }

  });

  _exports.default = _default;
});