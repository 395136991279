define("sirvo-web/services/ajax-service", ["exports", "ember-ajax/services/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    session: Ember.inject.service(),
    toast: Ember.inject.service(),
    headers: Ember.computed('session.data.authenticated', {
      get() {
        let {
          token
        } = this.get('session.data.authenticated');
        return {
          Authorization: `Bearer ${token}`
        };
      }

    }),

    _handleRequestError(error) {
      if (error.payload && Array.isArray(error.payload.errors)) {
        error.payload.errors.forEach(e => this.toast.error(e.message));
      } else if (error.status === 403) {
        this.toast.error('Parece que no tiene permiso para realizar esta operación.');
      } else {
        this.toast.error('Ocurrió un error con su solicitud.');
      }

      throw error;
    },

    /**
     * @param  {[type]} uri     [description]
     * @param  {Object} options [description]
     * @return {[type]}         [description]
     */
    request(uri, options = {}) {
      return this._super(uri, options).catch(this._handleRequestError.bind(this));
    }

  });

  _exports.default = _default;
});