define("sirvo-web/controllers/ods/servicios/ordenes-servicio/record/documentos/informe-buceo", ["exports", "sirvo-web/mixins/com/arrange-items", "ember-changeset", "ember-changeset-validations", "sirvo-web/validations/ods/informe-buceo", "sirvo-web/validations/ods/informe-buceo-linea", "sirvo-web/utils/common"], function (_exports, _arrangeItems, _emberChangeset, _emberChangesetValidations, _informeBuceo, _informeBuceoLinea, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_arrangeItems.default, {
    request: Ember.inject.service(),
    errorHandler: Ember.inject.service(),
    indexRoute: null,
    ordenDocumento: null,
    selectedTemplate: null,

    resetProperties() {
      this.setProperties({
        ordenDocumento: null,
        informesBuceo: Ember.A([]),
        selectedTemplate: null
      });
    },

    createInformeBuceoChangeset(informeBuceo) {
      if (!informeBuceo) {
        let numero = this.informesBuceo.length ? Math.max(...this.informesBuceo.mapBy('numero')) || 0 : 0;
        informeBuceo = this.store.createRecord('ods/informe-buceo', {
          ordenDocumento: this.ordenDocumento,
          fechaInicio: new Date(),
          fechaFin: new Date(),
          numero: numero + 1
        });
      }

      let changeset = new _emberChangeset.default(informeBuceo, (0, _emberChangesetValidations.default)(_informeBuceo.default), _informeBuceo.default, {
        skipValidate: false
      });
      changeset.set('lineasList', informeBuceo.get('lineas').map(linea => this.createInformeBuceoLineaChangeset(linea)));
      return changeset;
    },

    createInformeBuceoLineaChangeset(informeBuceoLinea, values = {}) {
      if (!informeBuceoLinea) {
        informeBuceoLinea = this.store.createRecord('ods/informe-buceo-linea', { ...values
        });
      }

      return new _emberChangeset.default(informeBuceoLinea, (0, _emberChangesetValidations.default)(_informeBuceoLinea.default), _informeBuceoLinea.default, {
        skipValidate: false
      });
    },

    createItem(list, values = {}) {
      let manguera = list.length ? Math.max(...list.mapBy('manguera')) || 0 : 0;
      return this.createInformeBuceoLineaChangeset(null, {
        manguera: manguera + 1,
        ...values
      });
    },

    getAllLineas() {
      return this.informesBuceo.reduce((lineasArray, informeBuceo) => {
        lineasArray.push(...informeBuceo.get('lineasList'));
        return lineasArray;
      }, Ember.A([]));
    },

    saveLinea(linea) {
      return linea.validate().then(() => {
        if (linea.get('isInvalid')) {
          linea.set('hasErrors', true);
          return Ember.RSVP.resolve(linea);
        }

        return linea.save().then(record => {
          linea.set('hasErrors', false);
          return record;
        }).catch(error => {
          linea.set('hasErrors', true);
          this.errorHandler.handleSaveErrors(error, linea);
        });
      });
    },

    /**
     * Parsea los valores de tipo hora y los copia a los objetos Date.
     */
    setHoursToDates(informeBuceo) {
      if ((0, _common.isHour)(informeBuceo.get('horaInicio'))) {
        let parts = informeBuceo.get('horaInicio').split(':');
        informeBuceo.get('fechaInicio').setHours(parts[0], parts[1], 0);
      }

      if ((0, _common.isHour)(informeBuceo.get('horaFin'))) {
        let parts = informeBuceo.get('horaFin').split(':');
        informeBuceo.get('fechaFin').setHours(parts[0], parts[1], 0);
      }
    },

    /**
     * Usar este método luego de haber validado el changeset y haber seteado las horas.
     */
    validateFechas(informeBuceo) {
      if (!informeBuceo.get('isValid')) {
        return false;
      }

      if (!(0, _common.isHour)(informeBuceo.get('horaInicio')) || !(0, _common.isHour)(informeBuceo.get('horaFin'))) {
        this.toast.error(`Horas inválidas en Informe ${informeBuceo.get('numero')}.`);
        return false;
      }

      if (informeBuceo.get('fechaInicio') >= informeBuceo.get('fechaFin')) {
        this.toast.error(`La fecha fin debe ser mayor a la fecha inicio en Informe ${informeBuceo.get('numero')}.`);
        return false;
      }

      return true;
    },

    validate() {
      return Ember.RSVP.all([...this.informesBuceo.map(informeBuceo => informeBuceo.validate()), ...this.getAllLineas().map(linea => linea.validate())]).then(() => {
        // Copiar las horas a los Dates.
        this.informesBuceo.forEach(informeBuceo => this.setHoursToDates(informeBuceo));
        let informesBuceoValid = this.informesBuceo.reduce((prevIsValid, informeBuceo) => {
          return prevIsValid && informeBuceo.get('isValid') && this.validateFechas(informeBuceo);
        }, true);

        if (!informesBuceoValid) {
          this.toast.error('Hay formularios con errores.');
          return Ember.RSVP.resolve(false);
        }

        let informesBuceoLineasValid = this.getAllLineas().reduce((prevIsValid, linea) => prevIsValid && linea.get('isValid'), true);

        if (!informesBuceoLineasValid) {
          this.toast.error('Hace falta completar la información de las líneas.');
          return Ember.RSVP.resolve(false);
        }

        return Ember.RSVP.resolve(true);
      });
    },

    saveChanges() {
      return this.validate().then(isValid => {
        if (!isValid) {
          return;
        }

        let savePromises = this.informesBuceo.filterBy('isDirty').map(informeBuceo => {
          return informeBuceo.save().catch(error => this.errorHandler.handleSaveErrors(error, informeBuceo));
        });
        this.loader.setLoading(true);
        return Ember.RSVP.all(savePromises).then(() => {
          let lineasPromises = this.getAllLineas().map(linea => this.saveLinea(linea));
          return Ember.RSVP.all(lineasPromises);
        }).then(() => {
          this.toast.success('Registros guardados correctamente.');
        }).catch(error => {
          this.toast.error('Ocurrió un error al guardar sus cambios.');
          throw error;
        }).finally(() => this.loader.setLoading(false));
      });
    },

    actions: {
      onClose() {
        // Destroy records
        this.getAllLineas().forEach(linea => linea.get('_content').rollbackAttributes());
        this.informesBuceo.forEach(informeBuceo => informeBuceo.get('_content').rollbackAttributes()); // Destroy changesets

        this.getAllLineas().forEach(linea => linea.rollback());
        this.informesBuceo.forEach(informeBuceo => informeBuceo.rollback());
        this.transitionToRoute(this.indexRoute);
      },

      onAddInformeBuceo() {
        let informeBuceo = this.createInformeBuceoChangeset();
        this.informesBuceo.pushObject(informeBuceo);
      },

      addLinea(list, informeBuceo) {
        let linea = this.actions.addItem.call(this, list);
        linea.set('informeBuceo', informeBuceo.get('_content'));
      },

      insertLinea(list, afterLinea, informeBuceo) {
        let linea = this.actions.insertItem.call(this, list, afterLinea);
        linea.set('informeBuceo', informeBuceo.get('_content'));
      },

      updateLibras(informeBuceo, propertyName, value) {
        informeBuceo.set(propertyName, value || null);
        informeBuceo.set('librasUsadas', informeBuceo.get('librasInicio') - informeBuceo.get('librasFin'));
      },

      trySaveLinea(linea) {
        if (!linea.get('isDirty')) {
          return;
        }

        linea.validate().then(() => {
          // Se puede guardar la línea si ya se guardó la cabecera.
          if (linea.get('isValid') && linea.get('informeBuceo.id')) {
            this.saveLinea(linea);
          }
        });
      },

      onSaveLinea(linea) {
        this.saveLinea(linea);
      },

      onSubmit() {
        return this.saveChanges();
      },

      deleteInformeBuceo(list, informeBuceo) {
        this.send('openModal', 'popups/simple-confirm', {
          message: '¿Está seguro de eliminar el informe?',
          onSubmit: () => {
            this.actions.deleteItem.call(this, list, informeBuceo);
          }
        });
      },

      generateFromTemplate(template) {
        if (!template || !template.id) {
          this.toast.error('Seleccione una plantilla.');
          return;
        } // NOTE: En el template está restringido que se pueda llamar esta acción
        // solo cuando aún no hay inmersiones registrados, pero por si acaso...


        if (this.informesBuceo.length) {
          this.toast.error('Debe eliminar todas las inmersiones para generar a partir de una plantilla.');
          return;
        }

        this.send('openModal', 'popups/simple-confirm', {
          title: '¿Está seguro de generar a partir de una plantilla?',
          message: `Plantilla seleccionada: <strong>${template.get('descripcion')}</strong>`,
          onSubmit: () => {
            this.loader.setLoading(true);
            this.store.query('ods/buceo-inmersion-plantilla', {
              filter: {
                plantilla: template.id
              },
              include: 'lineas'
            }).then(inmersionesPlantillas => {
              inmersionesPlantillas = inmersionesPlantillas.sortBy('orden');
              inmersionesPlantillas.forEach(inmersionPlantilla => {
                let informeBuceo = this.store.createRecord('ods/informe-buceo', {
                  ordenDocumento: this.ordenDocumento,
                  fechaInicio: new Date(),
                  fechaFin: new Date(),
                  numero: inmersionPlantilla.get('numero'),
                  narrativa: inmersionPlantilla.get('narrativa')
                });
                inmersionPlantilla.get('lineas').forEach(linea => {
                  this.store.createRecord('ods/informe-buceo-linea', {
                    informeBuceo,
                    manguera: linea.get('manguera'),
                    posicion: linea.get('posicion'),
                    areaInspecionada: linea.get('areaInspecionada'),
                    alineada: linea.get('alineada'),
                    anomalias: linea.get('anomalias'),
                    orden: linea.get('orden') || 0
                  });
                });
                this.informesBuceo.pushObject(this.createInformeBuceoChangeset(informeBuceo));
              });
            }).finally(() => this.loader.setLoading(false));
          }
        });
      }

    }
  });

  _exports.default = _default;
});