define("sirvo-web/controllers/inventario/procesos/inventario-fisico/articulos", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "sirvo-web/models/kar-inventario-fisico-articulo"], function (_exports, _abstractModuleIndex, _karInventarioFisicoArticulo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    company: Ember.computed.readOnly('currentCompany.company'),
    modelClass: _karInventarioFisicoArticulo.default,
    reload: true,
    editRoute: 'inventario.procesos.inventario-fisico.articulos.record',
    modelFilters: Ember.computed('model.inventario', function () {
      return [{
        selectedFilter: {
          filterName: 'inventarioFisico'
        },
        value: this.model.inventario.id
      }, {
        selectedFilter: {
          filterName: 'esConteoActual'
        },
        value: true
      }];
    }),
    includedRelationships: Ember.computed(function () {
      return ['articulo', 'articuloUbicacion'];
    }),
    columns: Ember.computed('model.tiposUbicaciones', function () {
      let tipoUbicacionRoot = this.model.tiposUbicaciones.findBy('esPrincipal');
      let columns = Ember.A([{
        label: 'Código',
        valuePath: 'articulo.codarticulo',
        width: '100px',
        filtrable: true,
        filterName: 'articulo.codarticulo'
      }, {
        label: 'Artículo',
        valuePath: 'articulo.descripcion',
        width: '250px',
        filtrable: true,
        filterName: 'articulo.descripcion'
      }]);

      if (tipoUbicacionRoot) {
        columns.pushObject({
          label: tipoUbicacionRoot.get('nombre'),
          valuePath: 'articuloUbicacion',
          cellComponent: 'tables/cell/articulo-ubicacion',
          tipoUbicacion: tipoUbicacionRoot,
          width: '150px',
          sortable: false
        });
        let currentTipoUbicacion = tipoUbicacionRoot;

        while (currentTipoUbicacion.get('tipoUbicacionInferior.id')) {
          currentTipoUbicacion = currentTipoUbicacion.get('tipoUbicacionInferior');
          columns.pushObject({
            label: currentTipoUbicacion.get('nombre'),
            valuePath: 'articuloUbicacion',
            cellComponent: 'tables/cell/articulo-ubicacion',
            tipoUbicacion: currentTipoUbicacion,
            width: '150px',
            sortable: false
          });
        }
      }

      columns.pushObjects([{
        label: 'Conteo',
        valuePath: 'conteo',
        width: '90px',
        sortable: false
      }, {
        label: 'Existencia teórica',
        valuePath: 'existenciaTeorica',
        width: '110px',
        sortable: false
      }, {
        label: 'Existencia física',
        valuePath: 'existenciaFisica',
        width: '110px',
        sortable: false
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.editRecord.bind(this)
          }
        }
      }]);
      return columns;
    }),

    reloadTable() {
      this.set('reload', true);
    },

    actions: {
      editRecord(record) {
        if (!this.model.inventario || !record || !record.id) {
          return;
        }

        this.transitionToRoute(this.editRoute, this.model.inventario.id, record.id);
      },

      eliminarLineas() {
        this.send('openModal', 'popups/simple-confirm', {
          title: '¿Está seguro de eliminar todas las líneas?',
          message: 'Posteriormente podrá generar de nuevo el inventario físico.',
          onSubmit: () => {
            this.loader.setLoading(true);
            return this.request.post(`kar-inventario-fisicos/${this.model.inventario.id}/eliminar-lineas`).then(() => {
              this.toast.success('Líneas de inventario físico eliminadas correctamente.');
              this.reloadTable();
            }).finally(() => this.loader.setLoading(false));
          }
        });
      }

    }
  });

  _exports.default = _default;
});