define("sirvo-web/models/det-transaccion", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    codca: attr('string'),
    codcc: attr('string'),
    codci: attr('string'),
    descripcionCont: attr('string'),
    codempresa: attr('string'),
    codperiodo: attr('string'),
    cargo: attr('number'),
    abono: attr('number'),
    fechaTransaccion: attr('date'),
    deha: attr('string'),
    keytra: attr('string'),
    codcuenta: attr('string'),
    codsistemaAux: attr('string'),
    codsistema: attr('string'),
    codgrupo: attr('string'),
    transaccion: belongsTo('transaccion'),
    periodo: belongsTo('emp-periodo'),
    cuenta: belongsTo('emp-nomenclatura'),
    espropio: attr('number')
  });

  _exports.default = _default;
});