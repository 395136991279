define("sirvo-web/components/com/search-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    searchValue: null,
    containerClasses: null,
    inputClasses: null,
    fullWidth: false,
    onSearch: () => true,
    actions: {
      onSearch() {
        this.onSearch(this.searchValue);
      }

    }
  });

  _exports.default = _default;
});