define("sirvo-web/helpers/get-first-character", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getFirstCharacter = getFirstCharacter;
  _exports.default = void 0;

  function getFirstCharacter([string]) {
    if (!string) {
      // Retorna un string vacío si la entrada es falsy
      return '';
    }

    return string.substring(0, 1);
  }

  var _default = Ember.Helper.helper(getFirstCharacter);

  _exports.default = _default;
});