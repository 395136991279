define("sirvo-web/controllers/inventario/tablas/articulos/record/datos-generales/ubicaciones", ["exports", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators"], function (_exports, _emberChangeset, _emberChangesetValidations, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    kardex: Ember.inject.service(),
    request: Ember.inject.service(),
    errorHandler: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),
    indexRoute: 'inventario.tablas.articulos.record.datos-generales',
    tiposUbicaciones: null,
    bodegaChangeset: null,
    ubicacionesChangesets: null,
    ubicacionesByTipo: null,
    addingUbicacion: false,
    localizacionesByBodega: null,
    articuloBodegaValidations: Ember.computed(function () {
      return {
        articulo: (0, _validators.validatePresence)(true),
        bodega: (0, _validators.validatePresence)(true)
      };
    }),
    articuloUbicacionValidations: Ember.computed(function () {
      return {
        articulo: (0, _validators.validatePresence)(true),
        tipoUbicacion: (0, _validators.validatePresence)(true),
        articuloUbicacion: (0, _validators.validatePresence)(true)
      };
    }),

    groupLocalizaciones() {
      this.set('localizacionesByBodega', this.kardex.groupArticuloUbicacionesByBodega(this.localizaciones)); // Consultar la existencia por cada bodega/ubicación.

      this.localizacionesByBodega.forEach(localizacion => {
        let lastUbicacion = localizacion.ubicaciones.get('lastObject');

        if (lastUbicacion) {
          Ember.set(localizacion, 'saldo', this.request.get(`kar-articulos/${this.record.get('id')}/saldo`, {
            ubicacionId: lastUbicacion.get('articuloUbicacion.id')
          }));
        } else {
          Ember.set(localizacion, 'saldo', this.request.get(`kar-articulos/${this.record.get('id')}/saldo`, {
            bodegaId: localizacion.ubicacionBodega.get('bodega.id')
          }));
        }
      });
    },

    validateUbicacion() {
      return Ember.RSVP.all([this.bodegaChangeset.validate(), ...this.ubicacionesChangesets.map(changeset => changeset.validate())]).then(() => {
        if (this.bodegaChangeset.get('isInvalid')) {
          this.toast.error('Hay un problema de validación con el formulario.');
          return Ember.RSVP.resolve(false);
        }

        let ubicacionesChangesetsValid = this.ubicacionesChangesets.reduce((prevIsValid, changeset) => prevIsValid && changeset.get('isValid'), true);

        if (!ubicacionesChangesetsValid) {
          this.toast.error('Hay un problema con las ubicaciones del artículo.');
          return Ember.RSVP.resolve(false);
        }

        return Ember.RSVP.resolve(true);
      });
    },

    discardChanges() {
      if (this.bodegaChangeset) {
        this.bodegaChangeset.rollback();
        this.bodegaChangeset.get('_content').rollbackAttributes();
      } // Eliminar los registros de ubicación.


      this.ubicacionesChangesets.forEach(changeset => changeset.rollback());
      this.ubicacionesChangesets.forEach(changeset => changeset.get('_content').rollbackAttributes());
    },

    actions: {
      onClose() {
        this.discardChanges();
        this.transitionToRoute(this.get('indexRoute'));
      },

      addUbicacion() {
        let articuloBodega = this.store.createRecord('kar-articulo-localizacion', {
          articulo: this.record,
          bodega: null
        });
        this.set('bodegaChangeset', new _emberChangeset.default(articuloBodega, (0, _emberChangesetValidations.default)(this.articuloBodegaValidations), this.articuloBodegaValidations));
        let tipoUbicacionRoot = this.tiposUbicaciones.findBy('esPrincipal');
        let lastTipoUbicacion = tipoUbicacionRoot;
        this.set('ubicacionesChangesets', Ember.A([]));

        while (lastTipoUbicacion && lastTipoUbicacion.get('id')) {
          let articuloUbicacion = this.store.createRecord('kar-articulo-localizacion', {
            articulo: this.record,
            tipoUbicacion: lastTipoUbicacion,
            articuloUbicacion: null
          });
          this.ubicacionesChangesets.pushObject(new _emberChangeset.default(articuloUbicacion, (0, _emberChangesetValidations.default)(this.articuloUbicacionValidations), this.articuloUbicacionValidations));
          lastTipoUbicacion = lastTipoUbicacion.get('tipoUbicacionInferior');
        }

        this.set('addingUbicacion', true);
      },

      bodegaUpdated(ubicacionChangeset) {
        // Resetear el valor seleccionado de cada ubicación.
        this.ubicacionesChangesets.forEach(ubicacionChangeset => {
          if (ubicacionChangeset.get('articuloUbicacion.id')) {
            ubicacionChangeset.set('articuloUbicacion', null);
          }
        });
        this.kardex.updateBodegaUbicaciones(ubicacionChangeset.get('bodega'), this.ubicacionesByTipo);
      },

      ubicacionUpdated(ubicacionChangeset, articuloUbicacion) {
        let tipoUbicacion = ubicacionChangeset.get('tipoUbicacion'); // Resetear el valor seleccionado de las ubicaciones dependientes.

        this.ubicacionesChangesets.forEach(ubicacionChangeset => {
          if (ubicacionChangeset.get('tipoUbicacion').get('tipoUbicacionSuperior.id') === tipoUbicacion.get('id')) {
            if (ubicacionChangeset.get('articuloUbicacion.id')) {
              ubicacionChangeset.set('articuloUbicacion', null);
            }
          }
        });
        this.kardex.updateUbicacionSubDivisiones(tipoUbicacion, articuloUbicacion, this.ubicacionesByTipo);
      },

      saveUbicacion() {
        return this.validateUbicacion().then(isValid => {
          if (!isValid) {
            return;
          }

          this.loader.setLoading(true);
          return Ember.RSVP.all([this.bodegaChangeset.save(), ...this.ubicacionesChangesets.map(changeset => changeset.save())]).then(() => {
            this.toast.success('Ubicación guardada correctamente.');
            this.set('addingUbicacion', false); // Actualizar las localizaciones.

            return this.kardex.queryArticuloLocalizaciones(this.record).then(localizaciones => {
              this.set('localizaciones', localizaciones);
              this.groupLocalizaciones();
            });
          }).catch(error => this.errorHandler.handleBackendErrors(error, 'No se pudo registrar la ubicación para el artículo.')).finally(() => this.loader.setLoading(false));
        });
      },

      removeUbicacion(localizacion) {
        this.loader.setLoading(true);
        return Ember.RSVP.all([localizacion.ubicacionBodega.destroyRecord(), ...localizacion.ubicaciones.map(ubicacion => ubicacion.destroyRecord())]).then(() => {
          this.set('localizacionesByBodega', this.localizacionesByBodega.without(localizacion));
        }).catch(error => this.errorHandler.handleBackendErrors(error, 'No se pudo desvincular la ubicación del artículo.')).finally(() => this.loader.setLoading(false));
      }

    }
  });

  _exports.default = _default;
});