define("sirvo-web/models/perfil", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    codigo: (0, _model.attr)('string'),
    nombre: (0, _model.attr)('string'),
    descripcion: (0, _model.attr)('string'),
    activo: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    rutaInicio: (0, _model.attr)('string'),
    permisos: (0, _model.hasMany)('permiso')
  });

  _exports.default = _default;
});