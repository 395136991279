define("sirvo-web/services/uploader", ["exports", "ember-uploader/uploaders/uploader", "ember-changeset", "ember-changeset-validations", "sirvo-web/config/environment", "sirvo-web/utils/common", "sirvo-web/validations/com/excel-upload"], function (_exports, _uploader, _emberChangeset, _emberChangesetValidations, _environment, _common, _excelUpload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    request: Ember.inject.service(),
    toast: Ember.inject.service(),
    loader: Ember.inject.service(),

    getNewUploader(uploadUrl = 'com/upload') {
      let {
        namespace
      } = _environment.default.API;
      let {
        token
      } = this.get('session.data.authenticated');

      let uploader = _uploader.default.create({
        url: `/${namespace}/${uploadUrl}`,
        paramName: 'file',
        ajaxSettings: {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      });

      return uploader;
    },

    validateFile() {},

    uploadFile(file, options = {}) {
      let uploadModel = options.uploadModel || 'com/upload';
      let {
        uploadUrl,
        uploadRelatedModel,
        successMsg
      } = options; // WARNING: Si no se especifica el uploadUrl, el nombre del endpoint debe
      // coincidir con el nombre del modelo.

      let uploader = this.getNewUploader(uploadUrl || uploadModel); // Representa el modelo al que está asociado el upload. Se usa para setearle
      // el uploader y mostrar el progreso de la carga en UI.

      if (uploadRelatedModel) {
        uploadRelatedModel.uploader = uploader;
      }

      this.loader.setLoading(true);
      return uploader.upload(file).then(result => {
        if (successMsg) {
          this.toast.success(successMsg);
        } // Es necesario crear el modelo del upload y registrarlo en el store para
        // que al attacharlo a otro modelo, se serialize correctamente al guardar.
        // WARNING: Es necesario construir el payload según el requerimiento de JSON API.
        //  TypeError: Cannot read properties of undefined (reading 'replace')


        let uploadNormalized = this.store.normalize(uploadModel, {
          id: result.id,
          type: uploadModel,
          attributes: result
        });
        let upload = this.store.push(uploadNormalized);
        return upload;
      }).catch(error => {
        this.toast.error('Ocurrió un error durante la carga.');

        if (error.responseJSON && Array.isArray(error.responseJSON.errors)) {
          error.responseJSON.errors.forEach(e => this.toast.error(e.message));
        }

        throw error;
      }).finally(() => {
        // En cada upload se crea un nuevo uploader.
        uploader.destroy();
        this.loader.setLoading(false);
      });
    },

    downloadFile(id, module = 'com') {
      if (typeof id !== 'string' || !id || !id.trim()) {
        let error = new Error(`ID de archivo '${id}' inválido.`);
        this.toast.error(error.message);
        return Ember.RSVP.reject(error);
      }

      this.loader.setLoading(true);
      return this.request.get(`${module}/uploads/${id.trim()}/download`).catch(error => {
        this.toast.error('Error al descargar el archivo.');
        throw error;
      }).finally(() => this.loader.setLoading(false));
    },

    deleteFile(uploadObject) {
      uploadObject = (0, _common.getPromiseContent)(uploadObject);
      this.loader.setIsLoading();
      return uploadObject.destroyRecord().then(() => {
        this.toast.success('Archivo eliminado exitosamente!');
      }).catch(error => {
        this.toast.error('No se pudo eliminar el archivo.');

        if (error.errors && Array.isArray(error.errors)) {
          error.errors.forEach(e => this.toast.error(e.message));
        }

        throw error;
      }).finally(() => this.loader.setLoading(false));
    },

    getNewUploadChangeset(module = 'com', uploadValidations) {
      let upload = this.store.createRecord(`${module}/upload`);
      let validations = uploadValidations ? { ...uploadValidations
      } : { ..._excelUpload.default
      };
      return new _emberChangeset.default(upload, (0, _emberChangesetValidations.default)(validations), validations, {
        skipValidate: false
      });
    }

  });

  _exports.default = _default;
});