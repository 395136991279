define("sirvo-web/controllers/ods/tablas/documentos/record", ["exports", "servir-ember-utilities/controllers/abstract-module-record", "sirvo-web/validations/com/word-upload", "sirvo-web/models/ods/inspeccion-mangueras-plantilla"], function (_exports, _abstractModuleRecord, _wordUpload, _inspeccionManguerasPlantilla) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    uploader: Ember.inject.service('ods/uploader'),
    errorHandler: Ember.inject.service(),
    postSaveTransitionRoute: Ember.computed.readOnly('routeName'),
    plantillaChangeset: null,
    reload: true,
    plantillaRoutes: Ember.computed(function () {
      return {
        'DOC-024': 'inspeccion-mangueras-plantilla'
      };
    }),
    plantillaClassObject: _inspeccionManguerasPlantilla.default,
    plantillasFilters: Ember.computed('record', function () {
      return [{
        selectedFilter: {
          filterName: 'documento'
        },
        value: this.record.get('id') || -1
      }];
    }),
    plantillasColumns: Ember.computed(function () {
      return Ember.A([{
        label: 'Descripción',
        valuePath: 'descripcion',
        sortable: false,
        width: '250px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.editRecord.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.deleteRecord.bind(this)
          }
        }
      }]);
    }),

    resetProperties() {
      this.setProperties({
        reload: true,
        plantillaChangeset: null
      });
    },

    createPlantillaChangeset() {
      this.set('plantillaChangeset', this.uploader.getNewUploadChangeset(_wordUpload.blankWordUpload));
    },

    saveFileChangeset(fileChangeset) {
      let file = fileChangeset ? fileChangeset.get('file') : null;

      if (!file) {
        return Ember.RSVP.resolve(null);
      }

      return fileChangeset.validate().then(() => {
        if (fileChangeset.get('isInvalid')) {
          this.toast.error('Hay errores con el archivo.');
          return Ember.RSVP.reject(null);
        }

        return this.uploader.uploadFile(file, {
          uploadRelatedModel: fileChangeset
        });
      });
    },

    processSave(changeset) {
      let superRef = this._super.bind(this, ...arguments);

      let saveFileChangesets = {
        plantilla: this.saveFileChangeset(this.plantillaChangeset)
      };
      return Ember.RSVP.hash(saveFileChangesets).then(({
        plantilla
      }) => {
        // Registrar la plantilla en el documento.
        plantilla && changeset.set('plantilla', plantilla); // Limpiar changesets de plantilla.

        plantilla && this.set('plantillaChangeset', null);
        return superRef();
      });
    },

    actions: {
      discardChanges() {
        this.changeset.rollback();
        this.record.rollbackAttributes();
        this.transitionToRoute(this.get('indexRoute'));
      },

      downloadPlantilla(propertyName = 'plantilla') {
        if (!this.changeset.get(`${propertyName}.id`)) {
          return;
        }

        this.uploader.downloadFile(this.changeset.get(`${propertyName}.id`));
      },

      deletePlantilla(propertyName = 'plantilla') {
        return this.changeset.validate().then(() => {
          if (this.changeset.get('isInvalid')) {
            this.toast.error('Corrija los errores del formulario primero.');
            return;
          }

          this.send('openModal', 'popups/simple-confirm', {
            message: '¿Está seguro de eliminar el archivo?',
            onSubmit: () => {
              this.changeset.set(propertyName, null);
              this.submit().then(() => {
                if (propertyName === 'plantilla') {
                  this.createPlantillaChangeset();
                }
              });
            }
          });
        });
      },

      addRecord() {
        if (this.record.get('isNew')) {
          return;
        }

        let editRoute = this.plantillaRoutes[this.record.get('codigo')];

        if (!editRoute) {
          this.toast.error('Funcionalidad no disponible para este documento.');
          return;
        }

        this.transitionToRoute(`${this.routeName}.${editRoute}`, 'new');
      },

      editRecord(item) {
        let editRoute = this.plantillaRoutes[this.record.get('codigo')];

        if (!editRoute) {
          this.toast.error('Funcionalidad no disponible para este documento.');
          return;
        }

        this.transitionToRoute(`${this.routeName}.${editRoute}`, item.get('id'));
      },

      deleteRecord(item
      /*, row, lightTable*/
      ) {
        this.send('openModal', 'popups/simple-confirm', {
          title: '¿Está seguro de eliminar el registro?',
          message: '',
          onSubmit: () => {
            item.destroyRecord().then(() => this.set('reload', true)).catch(error => this.errorHandler.handleBackendErrors(error));
          }
        });
      }

    }
  });

  _exports.default = _default;
});