define("sirvo-web/models/ods/upload", ["exports", "ember-data", "sirvo-web/config/environment", "sirvo-web/utils/format"], function (_exports, _emberData, _environment, _format) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    request: Ember.inject.service(),
    fileName: attr('string'),
    mimeType: attr('string'),
    fileSize: attr('number'),
    fileSizeString: Ember.computed('fileSize', function () {
      return (0, _format.formatBytes)(this.fileSize);
    }),
    isPdf: Ember.computed.equal('mimeType', 'application/pdf'),
    downloadLink: Ember.computed('id', function () {
      if (!this.id) {
        return null;
      } // Get signed download link.


      return this.request.get(`ods/uploads/${this.id}/signed-link`).then(response => response.signed_link).catch(error => {
        this.toast.error('Error al descargar el archivo.');
        throw error;
      });
    }),
    thumbnailUrl: Ember.computed('mimeType', function () {
      let icon = 'file.png';

      if (this.mimeType) {
        if (this.mimeType.includes('image/jpeg')) {
          icon = 'jpg.png';
        }

        if (this.mimeType.includes('image/png')) {
          icon = 'png.png';
        }

        if (this.mimeType.includes('application/pdf')) {
          icon = 'pdf.png';
        }

        if (this.mimeType.includes('application/vnd.ms-excel') || this.mimeType.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
          icon = 'xlsx.png';
        }

        if (this.mimeType.includes('application/msword') || this.mimeType.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
          icon = 'docx.png';
        }

        if (this.mimeType.includes('text')) {
          icon = 'text.png';
        }
      }

      return `/${_environment.default.API.namespace}/icons/${icon}`;
    })
  });

  _exports.default = _default;
});