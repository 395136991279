define("sirvo-web/models/ods/tipo-buque", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    codigo: attr('string'),
    nombre: attr('string'),
    llegaPuerto: attr('boolean'),
    llegaBoya: attr('boolean'),
    llegaPuertoFormatted: Ember.computed(function () {
      return this.llegaPuerto ? 'SI' : 'NO';
    }),
    llegaBoyaFormatted: Ember.computed(function () {
      return this.llegaBoya ? 'SI' : 'NO';
    })
  });

  _exports.default = _default;
});