define("sirvo-web/validations/com/pdf-upload", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    file: (0, _validators.validatePresence)(true),
    type: (0, _validators.validateInclusion)({
      message: 'Archivo inválido',
      list: ['application/pdf']
    }) // Se podría validar el tamaño máximo del archivo con el key "size".

  };
  _exports.default = _default;
});