define("sirvo-web/components/checkbox-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    isChecked: Ember.computed('row.enviarCorreo', function () {
      return this.get('row.enviarCorreo') === '1';
    }),
    actions: {
      toggleCheckbox() {
        let newValue = !this.get('isChecked');
        this.set('row.enviarCorreo', newValue ? '1' : '0');

        if (this.get('objectActions.updateCheckboxState')) {
          this.get('objectActions.updateCheckboxState')(this.get('row'), newValue);
        }
      }

    }
  });

  _exports.default = _default;
});