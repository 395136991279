define("sirvo-web/routes/inventario/tablas/articulos-ubicaciones/registros/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "sirvo-web/mixins/permission-validator", "ember-changeset-validations/validators"], function (_exports, _abstractModuleRecord, _permissionValidator, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_permissionValidator.default, {
    routePermission: 'KarAgregarArticuloUbicacion',
    company: Ember.computed.readOnly('currentCompany.company'),
    modelName: 'kar-articulo-ubicacion',
    indexRoute: 'inventario.tablas.articulos-ubicaciones.registros',
    routeRecordIdentifierDynamicSegment: 'registro_id',
    validations: Ember.computed(function () {
      return {
        tipoUbicacion: (0, _validators.validatePresence)(true),
        codigo: (0, _validators.validatePresence)(true),
        nombre: (0, _validators.validatePresence)(true)
      };
    }),

    model() {
      let parentData = this.modelFor(this.indexRoute);
      return this._super(...arguments).then(modelData => {
        return Ember.RSVP.all([modelData.record.get('bodega'), modelData.record.get('ubicacionSuperior')]).then(() => Ember.merge(modelData, parentData));
      });
    },

    createRecordInstance() {
      let parentData = this.modelFor(this.indexRoute);
      return this.store.createRecord(this.modelName, {
        tipoUbicacion: parentData.tipoUbicacion
      });
    },

    actions: {
      willTransition() {
        return false;
      }

    }
  });

  _exports.default = _default;
});