define("sirvo-web/controllers/ods/servicios/ordenes-servicio/record", ["exports", "servir-ember-utilities/controllers/abstract-module-record", "moment", "ember-changeset", "ember-changeset-validations", "sirvo-web/validations/ods/orden-servicio-operacion", "sirvo-web/validations/ods/orden-servicio-documento", "sirvo-web/utils/format"], function (_exports, _abstractModuleRecord, _moment, _emberChangeset, _emberChangesetValidations, _ordenServicioOperacion, _ordenServicioDocumento, _format) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    router: Ember.inject.service(),
    request: Ember.inject.service(),
    errorHandler: Ember.inject.service(),
    user: Ember.computed.readOnly('currentUser.user'),
    postSaveTransitionRoute: Ember.computed.readOnly('routeName'),
    // WARNING: Da problemas en la URL si se usa "ordenOrigen".
    queryParams: ['orden_origen'],
    ordenOrigenActions: Ember.computed('changeset.ordenOrigen', function () {
      let fieldActions = Ember.A([]);

      if (this.changeset.get('ordenOrigen.id')) {
        fieldActions.pushObject({
          title: 'Abrir orden',
          iconName: 'fa-external-link',
          action: this.actions.abrirOrdenOrigen.bind(this)
        });
      }

      return fieldActions;
    }),
    operacionesChangesets: Ember.computed(function () {
      return Ember.A([]);
    }),

    /**
     * WARNING: Se instancia una nueva variable para evitar side-effects.
     */
    ordenOpercionValidations: Ember.computed(function () {
      return { ..._ordenServicioOperacion.default
      };
    }),
    ordenDocumentoValidations: Ember.computed(function () {
      return { ..._ordenServicioDocumento.default
      };
    }),
    ordenesOrigenQuery: null,
    totalHorasActions: Ember.computed(function () {
      return [{
        title: 'Calcular',
        iconName: 'fa-refresh',
        action: this.updateTotalHoras.bind(this)
      }];
    }),

    createOperacionChangeset(ordenOperacion) {
      if (!ordenOperacion) {
        ordenOperacion = this.store.createRecord('ods/orden-servicio-operacion', {
          ordenServicio: this.record,
          isEditing: true
        });
      }

      return new _emberChangeset.default(ordenOperacion, (0, _emberChangesetValidations.default)(this.ordenOpercionValidations), this.ordenOpercionValidations, {
        skipValidate: false
      });
    },

    createDocumentoChangeset(ordenDocumento) {
      return new _emberChangeset.default(ordenDocumento, (0, _emberChangesetValidations.default)(this.ordenDocumentoValidations), this.ordenDocumentoValidations, {
        skipValidate: false
      });
    },

    validate() {
      return Ember.RSVP.all([this.changeset.validate(), ...this.operacionesChangesets.map(changeset => changeset.validate())]).then(() => {
        let changesetsAreValid = this.operacionesChangesets.reduce((prevIsValid, changeset) => prevIsValid && changeset.get('isValid'), true);

        if (!this.changeset.get('isValid') || !changesetsAreValid) {
          this.toast.error('Hay errores en el formulario.');
          return Ember.RSVP.resolve(false);
        }

        if (this.changeset.get('esAdicional') && !this.changeset.get('ordenOrigen.id')) {
          this.toast.error('Debe especificar la orden referencial.');
          return Ember.RSVP.resolve(false);
        }

        if (this.changeset.get('esAdicional') && !this.changeset.get('tipoOrden.esAdicional')) {
          this.toast.error('Debe seleccionar un tipo de orden de tipo "Adicional".');
          return Ember.RSVP.resolve(false);
        }

        if (!this.changeset.get('esAdicional') && this.changeset.get('tipoOrden.esAdicional')) {
          this.toast.error('Debe seleccionar un tipo de orden que no sea de tipo "Adicional".');
          return Ember.RSVP.resolve(false);
        }

        return Ember.RSVP.resolve(true);
      });
    },

    processSave() {
      let superRef = this._super.bind(this, ...arguments);

      return this.validate().then(isValid => {
        if (!isValid) {
          this.loader.setLoading(false);
          return Ember.RSVP.resolve();
        }

        return superRef();
      });
    },

    saveOperacionesChangesets() {
      let savePromises = this.operacionesChangesets.filter(changeset => changeset.get('isDirty') || changeset.get('isNew')).map(changeset => {
        changeset.set('isEditing', false);
        return changeset.save().catch(error => this.errorHandler.handleSaveErrors(error, changeset));
      });
      return Ember.RSVP.all(savePromises);
    },

    saveDocumentosChangesets() {
      let allDocumentosChangesets = Ember.A([]);
      this.ordenDocumentosList.forEach(servicioDocumentosList => {
        allDocumentosChangesets.pushObjects(servicioDocumentosList.documentosChangesets);
      });
      let savePromises = allDocumentosChangesets.filterBy('isNew').map(changeset => {
        return changeset.save().catch(error => this.errorHandler.handleSaveErrors(error, changeset));
      });
      return Ember.RSVP.all(savePromises);
    },

    postSave() {
      return Ember.RSVP.all([this.saveOperacionesChangesets(), this.saveDocumentosChangesets()]);
    },

    updateTotalHoras() {
      let fechaInicio = this.changeset.get('fechaInicio');
      let fechaFin = this.changeset.get('fechaFin');

      if (!fechaInicio || !fechaFin || fechaFin < fechaInicio) {
        this.changeset.set('totalHoras', 0);
        return;
      }

      let mFechaInicio = (0, _moment.default)(fechaInicio);
      let mFechaFin = (0, _moment.default)(fechaFin);

      let duration = _moment.default.duration(mFechaFin.diff(mFechaInicio));

      this.changeset.set('totalHoras', (0, _format.round)(duration.asHours()));
    },

    actions: {
      discardChanges() {
        this.changeset.rollback();
        this.record.rollbackAttributes();
        this.operacionesChangesets.forEach(changeset => changeset.get('_content').rollbackAttributes());
        this.operacionesChangesets.forEach(changeset => changeset.rollback());
        this.transitionToRoute(this.get('indexRoute'));
        this.transitionToRoute(this.get('indexRoute'));
      },

      fechaInicioUpdated()
      /*datetimeComponent*/
      {
        // WARNING: Cuando se llama este callback, el campo del changeset todavía no
        // se ha actualizado. Si forzamos un set() manual, al cambiar el valor del
        // campo de forma rápida (navegando el calendar con las flechas), Ember da
        // el siguiente error:
        //    Assertion Failed: You modified "changeset.fechaInicio" twice on changeset:[object Object] in a single render.
        //    Reference: https://github.com/emberjs/ember.js/issues/13948
        //
        // Optamos mejor por hacer un delay antes de calcular el total de horas para
        // esperar a que el componente actualice el changeset (two-day binding).
        //
        // this.changeset.set('fechaInicio', datetimeComponent.get('date'));
        // this.updateTotalHoras();
        if (this.updateTotalHorasTask) {
          Ember.run.cancel(this.updateTotalHorasTask);
        } // WARNING: No funciona pasar una instancia de la función con .bind(this).


        this.updateTotalHorasTask = Ember.run.later(() => {
          this.updateTotalHoras();
        }, 500);
      },

      fechaFinUpdated()
      /*datetimeComponent*/
      {
        if (this.updateTotalHorasTask) {
          Ember.run.cancel(this.updateTotalHorasTask);
        }

        this.updateTotalHorasTask = Ember.run.later(() => {
          this.updateTotalHoras();
        }, 500);
      },

      onSearchOrdenOrigen(text) {
        if (!text || !text.trim() || text.trim().length < 3) {
          return;
        }

        if (this.ordenesOrigenQuery) {
          Ember.run.cancel(this.ordenesOrigenQuery);
        }

        this.ordenesOrigenQuery = Ember.run.later(() => {
          this.set('ordenesOrigen', this.store.query('ods/orden-servicio', {
            filter: {
              codigo: text.trim()
            }
          }));
        }, 500);
      },

      descargarFormato(formato = 'EXCEL') {
        if (!this.record.get('id')) {
          this.toast.error('Debe guardar la orden de servicio para descargar el formato.');
          return;
        }

        this.loader.setLoading(true);
        return this.request.get(`ods/orden-servicios/${this.record.get('id')}/formato`, {
          formato
        }).then(() => {
          this.toast.success('Reporte generado exitosamente');
        }).finally(() => this.loader.setLoading(false));
      },

      anularOrden() {
        return this.validate().then(isValid => {
          if (!isValid) {
            this.toast.error('Hay errores en el formulario.');
            return;
          }

          this.transitionToRoute(`${this.routeName}.generales.anular`);
        });
      },

      abrirOrdenOrigen() {
        if (!this.record.get('ordenOrigen.id')) {
          return;
        }

        let url = this.router.urlFor(this.routeName, this.record.get('ordenOrigen.id'));
        window.open(url);
      },

      crearOrdenAdicional() {
        if (this.record.get('isNew')) {
          this.toast.error('Debe guardar la orden de servicio.');
          return;
        }

        if (this.changeset.get('isDirty')) {
          this.toast.error('Debe guardar sus cambios.');
          return;
        }

        this.send('openModal', 'popups/simple-confirm', {
          title: '¿Está seguro de generar una orden adicional?',
          message: '',
          onSubmit: () => {
            this.transitionToRoute(this.routeName, 'new', {
              queryParams: {
                orden_origen: this.record.get('id')
              }
            });
          }
        });
      },

      updateEsAdicional(changeset, propertyName, value = null) {
        changeset.set(propertyName, value || null); // Borrar posible valor asignado previamente.

        if (!changeset.get('esAdicional')) {
          changeset.set('ordenOrigen', null);
        }
      }

    }
  });

  _exports.default = _default;
});