define("sirvo-web/models/vf-user-kar-bodega", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    usuario: (0, _model.belongsTo)('vf-user'),
    bodega: (0, _model.belongsTo)('kar-bodega')
  });

  _exports.default = _default;
});