define("sirvo-web/templates/inventario/movimientos/ingresos/ajuste/detalles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MGVUlZ8I",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"row pb-2\"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"col-sm-12\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"pull-right\"],[8],[0,\"\\n      \"],[4,\"bs-button\",null,[[\"type\",\"size\",\"onClick\"],[\"info\",\"sm\",[26,\"action\",[[21,0,[]],\"getReport\"],null]]],{\"statements\":[[0,\"\\n        Descargar\\n      \"]],\"parameters\":[]},null],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[26,\"tables/paginated-table\",null,[[\"model\",\"columns\",\"modelName\",\"modelClassObject\",\"modelFilters\",\"includedRelationships\",\"pageSize\",\"load\",\"tableClassNames\"],[[21,0,[\"detalles\"]],[21,0,[\"columns\"]],\"kar-movdet\",[21,0,[\"modelClass\"]],[21,0,[\"modelFilters\"]],[21,0,[\"includedRelationships\"]],10,[21,0,[\"reload\"]],\"ember-table thead-gray\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/inventario/movimientos/ingresos/ajuste/detalles.hbs"
    }
  });

  _exports.default = _default;
});