define("sirvo-web/controllers/inventario/movimientos/salidas/ajuste/registro/articulo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    indexCtrl: Ember.inject.controller('inventario.movimientos.salidas.ajuste.registro'),
    kardex: Ember.inject.service(),
    user: Ember.computed.readOnly('currentUser.user'),
    company: Ember.computed.readOnly('currentCompany.company'),
    articulosQuery: null,
    actions: {
      onClose() {
        this.transitionToRoute(this.parentRoute);
      },

      searchArticulo(text) {
        if (!text || text.length < 3) {
          return;
        }

        if (this.articulosQuery) {
          Ember.run.cancel(this.articulosQuery);
        }

        this.articulosQuery = Ember.run.later(() => {
          this.set('articulos', this.store.query('kar-articulo', {
            filter: {
              empresa: this.company.id,
              debaja: 0,
              descripcion: {
                OR: text
              },
              codarticulo: {
                OR: text
              }
            },
            sort: 'descripcion'
          }));
        }, 500);
      },

      articuloUpdated() {
        this.kardex.onChangesetArticuloUpdated(this.changeset).then(articuloData => {
          this.setProperties(articuloData); // Procesar las ubicaciones por defecto del artículo.

          let localizacionesByBodega = this.kardex.groupArticuloUbicacionesByBodega(articuloData.localizaciones);
          let firstLocalizacion = localizacionesByBodega.get('firstObject');

          if (!firstLocalizacion) {
            // Setear la bodega a null solo si ya tiene valor.
            if (this.changeset.get('bodega.id')) {
              this.changeset.set('bodega', null);
              this.actions.bodegaUpdated.apply(this);
            }

            return;
          } // Setear la bodega por defecto.


          this.changeset.set('bodega', firstLocalizacion.ubicacionBodega.get('bodega'));
          this.actions.bodegaUpdated.apply(this); // Setear las ubicaciones por defecto.

          this.ubicacionesChangesets.forEach(ubicacionChangeset => {
            let localizacion = firstLocalizacion.ubicaciones.findBy('tipoUbicacion.id', ubicacionChangeset.get('tipoUbicacion.id'));

            if (localizacion) {
              ubicacionChangeset.set('articuloUbicacion', localizacion.get('articuloUbicacion')); // WARNING: Dado que estamos seteando todas las ubicaciones sin esperar
              // que el ubicacionUpdated() de cada set termine, en cada update se
              // obtienen el saldo de la ubicación del último nivel. TODO: Encontrar
              // una forma de obtener el saldo solo una vez.

              this.actions.ubicacionUpdated.apply(this, [ubicacionChangeset, localizacion.get('articuloUbicacion')]);
            }
          });
        });
      },

      bodegaUpdated() {
        // Si no hay ubicaciones dinámicas, la existencia se calcula a nivel de bodega.
        if (!this.ubicacionesChangesets.length) {
          this.kardex.onChangesetBodegaUpdated(this.changeset, this.indexCtrl);
          return;
        } // Resetear el valor seleccionado de cada ubicación.


        this.ubicacionesChangesets.forEach(ubicacionChangeset => {
          if (ubicacionChangeset.get('articuloUbicacion.id')) {
            ubicacionChangeset.set('articuloUbicacion', null);
          }
        });
        this.kardex.updateBodegaUbicaciones(this.changeset.get('bodega'), this.ubicacionesMeta);
      },

      unidadMedidaUpdated() {
        this.kardex.onChangesetUnidadMedidaUpdated(this.changeset, this.indexCtrl);
      },

      ubicacionUpdated(ubicacionChangeset, articuloUbicacion) {
        this.kardex.resetMovDetCalculo(this.changeset);
        let tipoUbicacion = ubicacionChangeset.get('tipoUbicacion'); // Resetear el valor seleccionado de las ubicaciones dependientes.

        this.ubicacionesChangesets.forEach(ubicacionChangeset => {
          if (ubicacionChangeset.get('tipoUbicacion').get('tipoUbicacionSuperior.id') === tipoUbicacion.get('id')) {
            if (ubicacionChangeset.get('articuloUbicacion.id')) {
              ubicacionChangeset.set('articuloUbicacion', null);
            }
          }
        });
        this.kardex.updateUbicacionSubDivisiones(tipoUbicacion, articuloUbicacion, this.ubicacionesMeta).then(() => {
          let ubicacionUltimoNivel = this.kardex.findUbicacionUltimoNivel(this.ubicacionesChangesets);

          if (ubicacionUltimoNivel) {
            this.kardex.onArticuloUbicacionUpdated(this.changeset, ubicacionUltimoNivel, this.indexCtrl);
          }
        });
      }

    }
  });

  _exports.default = _default;
});