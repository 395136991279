define("sirvo-web/templates/ods/servicios/ordenes-servicio/record/generales/anular", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RutyssE7",
    "block": "{\"symbols\":[\"modal\",\"f\"],\"statements\":[[4,\"bs-modal\",null,[[\"onHide\"],[[26,\"action\",[[21,0,[]],\"discardChanges\"],null]]],{\"statements\":[[0,\"\\n  \"],[4,\"component\",[[21,1,[\"header\"]]],null,{\"statements\":[[0,\"\\n    \"],[6,\"h4\"],[10,\"class\",\"modal-title\"],[8],[0,\"\\n      Anular orden \"],[1,[21,0,[\"record\",\"codigo\"]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]},null],[0,\"\\n  \"],[4,\"component\",[[21,1,[\"body\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"extended-form-for\",[[22,[\"changeset\"]]],null,{\"statements\":[[0,\"      \"],[1,[26,\"component\",[[21,2,[\"ace-textarea-field\"]],\"razonAnulacion\",\"Razón de anulación\"],[[\"placeholder\"],[\"Razón de anulación\"]]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"]],\"parameters\":[]},null],[0,\"\\n  \"],[4,\"component\",[[21,1,[\"footer\"]]],null,{\"statements\":[[0,\"\\n    \"],[4,\"bs-button\",null,[[\"onClick\",\"size\",\"classNames\"],[[26,\"action\",[[21,0,[]],[21,1,[\"close\"]]],null],\"xs\",\"mr-auto\"]],{\"statements\":[[0,\"\\n      Cancelar\\n    \"]],\"parameters\":[]},null],[0,\"\\n\\n    \"],[4,\"bs-button\",null,[[\"type\",\"size\",\"onClick\"],[\"danger\",\"xs\",[26,\"action\",[[21,0,[]],\"anular\"],null]]],{\"statements\":[[0,\"\\n      Anular\\n    \"]],\"parameters\":[]},null],[0,\"\\n  \"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/ods/servicios/ordenes-servicio/record/generales/anular.hbs"
    }
  });

  _exports.default = _default;
});