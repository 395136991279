define("sirvo-web/models/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    DEBE: 'D',
    HABER: 'H',
    DEHA: [{
      key: 'D',
      name: 'Debe'
    }, {
      key: 'H',
      name: 'Haber'
    }],
    INGRESOS_TIPOS_MOVIMIENTOS: [{
      key: 'INGRESOS',
      name: 'Ingresos'
    }, {
      key: 'AJUSTE',
      name: 'Ajustes'
    }, {
      key: 'EXISTENCIA_INICIAL',
      name: 'Existencia inicial'
    }],
    SALIDAS_TIPOS_MOVIMIENTOS: [{
      key: 'SALIDAS',
      name: 'Salidas'
    }, {
      key: 'TRASLADOS',
      name: 'Traslados'
    }, {
      key: 'AJUSTE',
      name: 'Ajustes'
    }],
    KAR_TIPOS_CARGA: [{
      key: 'BODEGAS',
      name: 'Bodegas'
    }, {
      key: 'TIPOS_ARTICULOS',
      name: 'Tipos artículos'
    }, {
      key: 'UNIDADES_MEDIDA',
      name: 'Unidades medida'
    }, {
      key: 'ARTICULOS',
      name: 'Artículos'
    }, {
      key: 'EXISTENCIA_INICIAL',
      name: 'Existencia inicial'
    }],
    ADMON_TIPOS_CARGA: [{
      key: 'PERMISOS_GRUPOS',
      name: 'Grupos de permisos',
      serviceId: 'excel_loader_permiso_grupos'
    }, {
      key: 'PERMISOS',
      name: 'Permisos',
      serviceId: 'excel_loader_permisos'
    }, {
      key: 'MENUS',
      name: 'Menús',
      serviceId: 'excel_loader_menus'
    }],
    NOTIFICATION_EVENTS: [{
      key: 'ENTITY_CREATED',
      name: 'Entidad creada'
    }, {
      key: 'ENTITY_UPDATED',
      name: 'Entidad actualizada'
    }, {
      key: 'ENTITY_PROPERTY_UPDATED',
      name: 'Propiedad actualizada'
    }, {
      key: 'ENTITY_REMOVED',
      name: 'Entidad eliminada'
    }, {
      key: 'TEMPLATE',
      name: 'Plantilla'
    }],
    ODS_TIPOS_TERMINALES: [{
      key: 'TP',
      name: 'Portuaria'
    }, {
      key: 'TM',
      name: 'Marítima'
    }],
    ODS_TIPOS_MUELLES: [{
      key: 'MU',
      name: 'Muelle'
    }, {
      key: 'MB',
      name: 'Multi Boyas'
    }],
    UPLOADS_CONTENT_TYPES: ['application/pdf', 'application/json', 'image/jpeg', 'image/png', 'text/plain', 'text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
    UPLOADS_EXCEL_TYPES: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
    UPLOADS_WORD_TYPES: ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
  };
  _exports.default = _default;
});