define("sirvo-web/models/que/contrasena-linea", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    codempresa: (0, _model.attr)('string'),
    codmoneda: (0, _model.attr)('string'),
    valordocml: (0, _model.attr)('number'),
    valorme: (0, _model.attr)('number'),
    valor: (0, _model.attr)('number'),
    codsistema: (0, _model.attr)('string'),
    codtipodoctos: (0, _model.attr)('string'),
    codtipo: (0, _model.attr)('string'),
    keytra: (0, _model.attr)('string'),
    devuelto: (0, _model.attr)('number'),
    numerodoc: (0, _model.attr)('string'),
    coddirectorio: (0, _model.attr)('string'),
    codgrupo: (0, _model.attr)('string'),
    descripcion: (0, _model.attr)('string'),
    esNotaCobro: (0, _model.attr)('number'),
    fechaFac: (0, _model.attr)('custom-date'),
    serie: (0, _model.attr)('string'),
    numero: (0, _model.attr)('number'),
    usatasac: (0, _model.attr)('number'),
    fechaRecEncargado: (0, _model.attr)('date'),
    categoria: (0, _model.attr)('string'),
    tasac: (0, _model.attr)('number'),
    keytraProvision: (0, _model.attr)('string'),
    contrasena: (0, _model.belongsTo)('que/contrasena'),
    transaccion: (0, _model.belongsTo)('transaccion')
  });

  _exports.default = _default;
});