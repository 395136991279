define("sirvo-web/controllers/ods/servicios/ordenes-servicio/record/generales/buque", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    indexCtrl: Ember.inject.controller('ods.servicios.ordenes-servicio.record.generales'),
    postSaveTransitionRoute: Ember.computed.readOnly('indexRoute'),

    postSave(record) {
      this.indexCtrl.get('changeset').set('buque', record);

      if (this.isNew) {
        this.toast.info('No olvide guardar la orden de servicio para asociar el buque creado.');
      }
    },

    actions: {
      discardChanges() {
        this.changeset.rollback();
        this.record.rollbackAttributes();
        this.transitionToRoute(this.get('indexRoute'));
      }

    }
  });

  _exports.default = _default;
});