define("sirvo-web/models/reporte-existencias", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    fechaFin: attr('custom-date'),
    bodega: belongsTo('kar-bodega'),
    tipoArticulo: belongsTo('kar-tipo-articulo'),
    articulo: belongsTo('kar-articulo'),
    incluirInactivos: attr('boolean', {
      defaultValue: false
    })
  });

  _exports.default = _default;
});