define("sirvo-web/controllers/inventario/procesos/cierre-costos", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    request: Ember.inject.service(),
    errorHandler: Ember.inject.service(),
    empParameters: Ember.computed.readOnly('currentCompany.empParameters'),
    fechaCierre: Ember.computed.readOnly('currentCompany.lastCierre.fecha'),
    actions: {
      updateEsCierre(changeset, properyName, value) {
        changeset.set(properyName, value);
        changeset.set('esApertura', !changeset.get('esCierre'));
        let fecha = (0, _moment.default)().endOf('month');

        if (this.empParameters.get('usaCierrePorFecha')) {
          fecha = (0, _moment.default)();

          if (this.fechaCierre && this.fechaCierre >= fecha.toDate()) {
            fecha = (0, _moment.default)(this.fechaCierre).add(1, 'days');
          }
        }

        changeset.set('fecha', fecha);
      },

      updateEsApertura(changeset, properyName, value) {
        changeset.set(properyName, value);
        changeset.set('esCierre', !changeset.get('esApertura'));
        changeset.set('fecha', null);
      },

      submit() {
        return this.changeset.validate().then(() => {
          if (!this.changeset.get('isValid')) {
            this.toast.error('Hay errores en el formulario.');
            return;
          }

          this.loader.setLoading(true);
          const momentDate = this.changeset.get('fecha') ? (0, _moment.default)(this.changeset.get('fecha')) : null;
          return this.request.post('kar-cierre-costo', {
            empresaId: this.changeset.get('empresa.id'),
            fecha: momentDate ? momentDate.format('YYYY-MM-DD HH:mm:ss') : null,
            esApertura: this.changeset.get('esApertura') || false,
            motivo: this.changeset.get('motivo') || null,
            actualizarCostos: this.changeset.get('actualizarCostos') || false
          }, {}, false).then(() => {
            this.toast.success('Proceso realizado correctamente.');
            return this.currentCompany.loadLastCierre().then(() => {
              this.changeset.set('lastCierreFecha', this.fechaCierre || null);
            });
          }).catch(error => {
            this.errorHandler.handleBackendErrors(error, null, {
              timeOut: 0,
              extendedTimeOut: 0
            });
          }).finally(() => this.loader.setLoading(false));
        });
      }

    }
  });

  _exports.default = _default;
});