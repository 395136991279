define("sirvo-web/routes/ods/tablas/tipos-productos/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "sirvo-web/mixins/permission-validator", "sirvo-web/validations/ods/tipo-producto"], function (_exports, _abstractModuleRecord, _permissionValidator, _tipoProducto) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_permissionValidator.default, {
    routePermission: 'OdsCrearTipoProducto',
    modelName: 'ods/tipo-producto',
    indexRoute: 'ods.tablas.tipos-productos',
    relatedModelsToQuery: Ember.computed('isNew', function () {
      return [];
    }),
    validations: Ember.computed(function () {
      return { ..._tipoProducto.default
      };
    })
  });

  _exports.default = _default;
});