define("sirvo-web/routes/reporte-ingresos-cliente-base", ["exports", "servir-ember-utilities/routes/abstract-module-index", "ember-changeset", "ember-changeset-validations/validators", "ember-changeset-validations", "moment"], function (_exports, _abstractModuleIndex, _emberChangeset, _validators, _emberChangesetValidations, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    validations: Ember.computed(function () {
      return {
        fechaInicio: (0, _validators.validatePresence)({
          presence: true
        }),
        fechaFin: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),

    setupController(controller, model) {
      this._super(controller, model);

      let changeset = new _emberChangeset.default({
        fechaInicio: (0, _moment.default)().startOf('month').toDate(),
        fechaFin: (0, _moment.default)().endOf('month').toDate()
      }, (0, _emberChangesetValidations.default)(this.get('validations')), this.get('validations'));
      controller.set('changeset', changeset);
    }

  });

  _exports.default = _default;
});