define("sirvo-web/components/checkbox-cell-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selectionService: Ember.inject.service(),
    tagName: '',
    isChecked: Ember.computed('row.status', function () {
      return this.get('row.status') === '1';
    }),
    actions: {
      toggleCheckboxStatus(event) {
        if (event && event.type === 'keydown' && event.key !== 'Enter') {
          return;
        }

        let newValue = !this.get('isChecked');
        this.set('row.status', newValue ? '1' : '0');

        if (this.get('objectActions.updateCheckboxState')) {
          this.get('objectActions.updateCheckboxState')(this.get('row'), newValue);
        }

        this.get('selectionService').toggleRowSelection(this.get('row'));
      }

    }
  });

  _exports.default = _default;
});