define("sirvo-web/routes/inventario/movimientos/cortesias/despacho-cortesias/detalle", ["exports", "ember-changeset-validations", "ember-changeset-validations/validators", "ember-changeset"], function (_exports, _emberChangesetValidations, _validators, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    kardex: Ember.inject.service(),
    parentRoute: 'inventario.movimientos.cortesias.despacho-cortesias',
    bodega: null,
    validations: Ember.computed(function () {
      return {
        articulo: (0, _validators.validatePresence)({
          presence: true
        }),
        bodega: (0, _validators.validatePresence)({
          presence: true
        }),
        cantidad: (0, _validators.validateNumber)({
          gt: 0
        }),
        unidadMedida: (0, _validators.validatePresence)({
          presence: true
        }),
        precioUnitario: (0, _validators.validatePresence)({
          presence: true
        }),
        total: (0, _validators.validatePresence)({
          presence: true
        }),
        cantidadBase: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),

    model() {
      let changeset = this.controllerFor(this.parentRoute).changeset;

      if (!changeset) {
        this.transitionTo(this.parentRoute);
      }

      this.bodega = changeset.get('bodega');
      let modelData = new Ember.Object({
        bodega: this.bodega,
        articulo: null,
        unidadMedida: null,
        unidadMedidaBase: null,
        existencia: 0,
        existenciaBase: 0,
        cantidad: 0,
        precioUnitario: 0,
        cantidadBase: 0,
        total: 0
      });
      return modelData;
    },

    setupController(controller, model) {
      this._super(controller, model);

      let detalleChangeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(this.get('validations')), this.get('validations'));
      let changeset = this.controllerFor(this.parentRoute).changeset;
      controller.set('detalleChangeset', detalleChangeset);
      controller.set('changeset', changeset);
      controller.set('articulos', this.kardex.getArticulosPorBodega(this.bodega));
    }

  });

  _exports.default = _default;
});