define("sirvo-web/routes/ods/servicios/ordenes-servicio/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "servir-ember-utilities/mixins/route/has-tabs", "sirvo-web/mixins/permission-validator", "sirvo-web/validations/ods/orden-servicio", "sirvo-web/utils/common"], function (_exports, _abstractModuleRecord, _hasTabs, _permissionValidator, _ordenServicio, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_hasTabs.default, _permissionValidator.default, {
    routePermission: 'OdsCrearOrdenServicio',
    modelName: 'ods/orden-servicio',
    indexRoute: 'ods.servicios.ordenes-servicio',
    defaultTabSubRoute: 'generales',
    queryParams: {
      orden_origen: {
        refreshModel: true
      }
    },
    relatedModelsToQuery: Ember.computed('isNew', function () {
      let relatedModels = [{
        name: 'tiposOrden',
        modelName: 'ods/tipo-orden'
      }, {
        name: 'tiposServicios',
        modelName: 'ods/tipo-servicio',
        queryParams: {
          include: 'servicios'
        }
      }, {
        name: 'puertos',
        modelName: 'ods/puerto'
      }, {
        name: 'productos',
        modelName: 'ods/producto'
      }, {
        name: 'operaciones',
        modelName: 'ods/operacion'
      }, {
        name: 'documentos',
        modelName: 'ods/documento'
      }];

      if (!this.isNew) {
        relatedModels.pushObjects([{
          name: 'ordenOperaciones',
          modelName: 'ods/orden-servicio-operacion',
          queryParams: {
            filter: {
              ordenServicio: this.recordId
            }
          }
        }, {
          name: 'ordenDocumentos',
          modelName: 'ods/orden-servicio-documento',
          queryParams: {
            filter: {
              ordenServicio: this.recordId
            }
          }
        }]);
      }

      return relatedModels;
    }),
    validations: Ember.computed(function () {
      return { ..._ordenServicio.default
      };
    }),

    createRecordInstance() {
      return this.store.createRecord(this.modelName, {
        fecha: new Date()
      });
    },

    model(params
    /*, transition*/
    ) {
      return this._super(...arguments).then(modelData => {
        let relationships = [modelData.record.get('puerto'), modelData.record.get('terminal'), modelData.record.get('muelle'), modelData.record.get('buque'), modelData.record.get('cliente'), modelData.record.get('facturaCliente'), modelData.record.get('ordenOrigen'), modelData.record.get('documentoConsolidado')];
        return Ember.RSVP.all(relationships).then(() => modelData);
      }) // Cargar posible orden origen para una orden adicional.
      .then(modelData => {
        if (!modelData.record.get('isNew') || !(0, _common.isNumeric)(params.orden_origen)) {
          return modelData;
        }

        return this.store.findRecord('ods/orden-servicio', params.orden_origen).then(ordenOrigen => Ember.merge(modelData, {
          ordenOrigen
        }));
      }).then(modelData => {
        let record = modelData.record;
        let {
          ordenOrigen
        } = modelData; // Crear orden adicional con valores predeterminados de la orden original.

        if (ordenOrigen) {
          modelData.record.setProperties({
            esAdicional: true,
            ordenOrigen: ordenOrigen,
            puerto: ordenOrigen.get('puerto'),
            terminal: ordenOrigen.get('terminal'),
            muelle: ordenOrigen.get('muelle'),
            buque: ordenOrigen.get('buque'),
            cliente: ordenOrigen.get('cliente'),
            facturaCliente: ordenOrigen.get('facturaCliente'),
            numeroViaje: ordenOrigen.get('numeroViaje')
          });
        }

        let promises = {
          terminales: [],
          muelles: [],
          serviciosDocumentosRequeridos: [],
          ordenesAdicionales: []
        };

        if (record.get('id')) {
          promises.ordenesAdicionales = this.store.query('ods/orden-servicio', {
            filter: {
              ordenOrigen: record.get('id')
            }
          });
        }

        if (record.get('puerto.id')) {
          promises.terminales = this.store.query('ods/terminal', {
            filter: {
              puerto: record.get('puerto.id')
            }
          });
        }

        if (record.get('terminal.id')) {
          promises.muelles = this.store.query('ods/muelle', {
            filter: {
              terminal: record.get('terminal.id')
            }
          });
        }

        let serviciosDocumentosIds = [];
        record.get('servicios').forEach(servicio => {
          let servicioDocumentosIds = servicio.hasMany('documentos').ids();
          serviciosDocumentosIds.push(...servicioDocumentosIds);
        });

        if (serviciosDocumentosIds.length) {
          promises.serviciosDocumentosRequeridos = this.store.query('ods/servicio-documento', {
            filter: {
              id: serviciosDocumentosIds
            },
            include: 'cargaPerfil'
          });
        }

        this.loader.setLoading(true);
        return Ember.RSVP.hash(promises).then(relatedData => Ember.merge(modelData, relatedData)).finally(() => this.loader.setLoading(false));
      });
    },

    setupController(controller, model) {
      this._super(...arguments); // *************************************************************************
      // Configuración de changesets para las operaciones.
      // *************************************************************************


      let operacionesChangesets = model.record.get('operaciones').map(operacion => controller.createOperacionChangeset(operacion));
      controller.setProperties({
        operacionesChangesets
      });
      let ordenDocumentosList = Ember.A([]);
      let documentosGeneralesChangesets = Ember.A([]); // *************************************************************************
      // Configuración de changesets para las listas de documentos.
      // *************************************************************************

      model.record.servicios.forEach(servicio => {
        let servicioDocumentos = new Ember.Object({
          servicio,
          documentosChangesets: Ember.A([]),
          documentosChangesetsFiltered: Ember.A([])
        });
        let ordenServicioDocumentos = model.ordenDocumentos.filterBy('servicioDocumento.servicio.id', servicio.get('id')); // Crear los documentos que todavía no existen para la orden de servicio.

        servicio.get('documentos').forEach(servicioDocumento => {
          let ordenDocumento = ordenServicioDocumentos.findBy('servicioDocumento.id', servicioDocumento.get('id'));

          if (!ordenDocumento) {
            ordenDocumento = this.store.createRecord('ods/orden-servicio-documento', {
              ordenServicio: model.record,
              servicioDocumento
            });
            ordenServicioDocumentos.pushObject(ordenDocumento);
          }
        }); // Ordenar según la definición de la relación servicio-documento.
        // WARNING: La función de Ember.Array.sortBy() no funcionó.

        ordenServicioDocumentos.sort(function (a, b) {
          return parseInt(a.get('servicioDocumento.id')) - parseInt(b.get('servicioDocumento.id'));
        });
        ordenServicioDocumentos.forEach(ordenDocumento => {
          let ordenDocumentoChangeset = controller.createDocumentoChangeset(ordenDocumento);

          if (ordenDocumento.get('servicioDocumento').get('esUnico')) {
            let alreadyAdded = documentosGeneralesChangesets.findBy('servicioDocumento.documento.id', ordenDocumento.get('servicioDocumento').get('documento.id'));

            if (!alreadyAdded) {
              documentosGeneralesChangesets.pushObject(ordenDocumentoChangeset);
            }

            return;
          }

          servicioDocumentos.documentosChangesets.pushObject(ordenDocumentoChangeset);
        });
        servicioDocumentos.documentosChangesetsFiltered = servicioDocumentos.documentosChangesets;
        ordenDocumentosList.pushObject(servicioDocumentos);
      }); // Insertar al principio de la lista la data para la sección de documentos generales.

      ordenDocumentosList.unshiftObject(new Ember.Object({
        servicio: {
          nombre: 'Documentos generales'
        },
        documentosChangesets: documentosGeneralesChangesets,
        documentosChangesetsFiltered: documentosGeneralesChangesets
      }));
      controller.setProperties({
        ordenDocumentosList
      });
    },

    actions: {
      /**
       * Sobre escribir para validar cambios en las operaciones también.
       */
      willTransition(transition) {
        let changeset = this.controller.get('changeset');
        let record = this.controller.get('record');
        let operacionesChangesets = this.controller.get('operacionesChangesets');
        let operacionesChangesetsDirty = operacionesChangesets.reduce((prevIsDirty, changeset) => prevIsDirty || changeset.get('isDirty'), false);

        if (changeset.get('isDirty') || operacionesChangesetsDirty) {
          transition.abort();
          this.send('openModal', 'popups/simple-confirm', {
            title: 'La forma se ha modificado. ¿Está seguro de descartar sus cambios?',
            message: '',
            onSubmit: () => {
              changeset.rollback();
              operacionesChangesets.forEach(changeset => changeset.rollback());
              transition.retry();
            }
          });
          return false;
        }

        record.rollbackAttributes();
        operacionesChangesets.forEach(changeset => changeset.get('_content').rollbackAttributes());
      }

    }
  });

  _exports.default = _default;
});