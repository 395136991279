define("sirvo-web/controllers/admon/accesos/permisos/record/grupo", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    postSaveTransitionRoute: Ember.computed.readOnly('indexRoute'),
    routeRecordIdentifierDynamicSegment: 'grupo_id',
    actions: {
      discardChanges() {
        this.changeset.rollback();
        this.record.rollbackAttributes();
        this.transitionToRoute(this.get('indexRoute'));
      }

    }
  });

  _exports.default = _default;
});