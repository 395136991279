define("sirvo-web/routes/ods/servicios/tipos-orden/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "sirvo-web/mixins/permission-validator", "sirvo-web/validations/ods/tipo-orden"], function (_exports, _abstractModuleRecord, _permissionValidator, _tipoOrden) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_permissionValidator.default, {
    routePermission: 'OdsCrearTipoOrden',
    modelName: 'ods/tipo-orden',
    indexRoute: 'ods.servicios.tipos-orden',
    relatedModelsToQuery: Ember.computed(function () {
      return [];
    }),
    validations: Ember.computed(function () {
      return { ..._tipoOrden.default
      };
    }),

    model() {
      return this._super(...arguments).then(modelData => {
        return Ember.RSVP.all([modelData.record.get('plantilla'), modelData.record.get('plantillaPdf')]).then(() => modelData);
      });
    },

    setupController(controller, model) {
      this._super(...arguments);

      this.controller.resetProperties();

      if (!model.record.get('plantilla.id')) {
        this.controller.createPlantillaChangeset();
      }

      if (!model.record.get('plantillaPdf.id')) {
        this.controller.createPlantillaPdfChangeset();
      }
    }

  });

  _exports.default = _default;
});