define("sirvo-web/templates/components/tables/cell/anulado-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N+WWqj2w",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[11,\"class\",[26,\"if\",[[22,[\"isAnulado\"]],\"anulado-cell\",\"\"],null],null],[11,\"style\",[20,\"cellStyle\"],null],[8],[0,\"\\n\"],[4,\"if\",[[22,[\"cellContent\"]]],null,{\"statements\":[[0,\"    \"],[6,\"i\"],[10,\"class\",\"fa fa-times\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[13,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/components/tables/cell/anulado-cell.hbs"
    }
  });

  _exports.default = _default;
});