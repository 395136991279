define("sirvo-web/routes/contrasenas/movimientos/ordenes-de-compra/emitir", ["exports", "servir-ember-utilities/routes/abstract-module-index", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators", "moment"], function (_exports, _abstractModuleIndex, _emberChangeset, _emberChangesetValidations, _validators, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    request: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),
    periodo: Ember.computed(function () {
      return (0, _moment.default)().format('YYYY-01');
    }),
    validations: Ember.computed(function () {
      return {
        fechaFin: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),

    model() {
      let model = new Ember.Object({
        codigoEmpresa: this.get('company.codempresa'),
        fechaFin: null,
        sendOrdenes: 0
      });
      let changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(this.get('validations')), this.get('validations'));
      return Ember.RSVP.hash({
        changeset: changeset,
        documentosData: this.loadTipoDocumentos(),
        documentosEmpresa: this.getDocumentoEmpresa(),
        tiposImpuestos: this.loadTiposIva(),
        cuentasProvisiones: this.loadCuentaProvision()
      });
    },

    setupController(controller, model) {
      this.loader.setIsLoading();
      this.loader.setLoading(true);

      this._super(controller, model);

      controller.setProperties({
        changeset: model.changeset,
        tipoDocumentosOpciones: model.documentosData.tipoDocumentos,
        tipoDocumentoSeleccionado: model.documentosData.tipoDocumentoSeleccionado,
        documentosEmpresa: model.documentosEmpresa,
        tipoImpuestoOpciones: model.tiposImpuestos.tipoImpuestoOpcionesresponse,
        tipoIVASeleccionado: model.tiposImpuestos.tipoIVASeleccionado,
        tipoIVA: model.tiposImpuestos.tipoImpuestoOpcionesresponse,
        cuentaProvisionSeleccionada: model.cuentasProvisiones.cuentaProvisionSeleccionada,
        dropdownCuentaProvision: model.cuentasProvisiones.dropdownCuentaProvision,
        ordenDeCompraItems: []
      });
      this.loader.setLoading(false);
    },

    loadTipoDocumentos() {
      return this.request.get('documentos-empresa/sistema/tipo-documento', {
        codSistema: 'CXP'
      }).then(response => {
        let tipoDocumentos = response.data;
        let tipoDocSeleccionado = tipoDocumentos.findBy('codtipo', 'FA');
        return {
          tipoDocumentos: tipoDocumentos,
          tipoDocumentoSeleccionado: tipoDocSeleccionado
        };
      }).catch(error => {
        this.toast.error('Error al cargar tipos de documentos. ' + error);
        throw error;
      });
    },

    getDocumentoEmpresa() {
      return this.request.get('documentos-empresa/sistema/tipo-documento', {
        codempresa: this.company.codempresa,
        codSistema: 'CXP',
        codtipo: 'FA'
      }).then(documentoEmpresa => {
        let documento = documentoEmpresa.data.findBy('codtipo', 'FA');
        return documento;
      }).catch(error => {
        this.toast.error('Error al cargar los documentos de empresa. ' + error);
        throw error;
      });
    },

    loadTiposIva() {
      return new Promise((resolve, reject) => {
        this.store.query('iva-tipo', {
          filter: {
            codpais: 'GT',
            clasificacion: 'C'
          }
        }).then(tiposDeIVA => {
          let tipoImpuestoOpcionesMapped = tiposDeIVA.map(ivaTipo => {
            return {
              id: ivaTipo.get('id'),
              clasificacion: ivaTipo.get('clasificacion'),
              tipo: ivaTipo.get('tipo'),
              observa: ivaTipo.get('observa'),
              porcentaje: ivaTipo.get('porcentaje'),
              pais: ivaTipo.belongsTo('pais').id()
            };
          });

          if (tipoImpuestoOpcionesMapped.length > 0) {
            resolve({
              tipoImpuestoOpcionesresponse: tipoImpuestoOpcionesMapped,
              tipoIVASeleccionado: tipoImpuestoOpcionesMapped[0]
            });
          } else {
            resolve({
              tipoImpuestoOpciones: tipoImpuestoOpcionesMapped,
              tipoIVASeleccionado: null
            });
          }
        }).catch(error => {
          this.toast.error('Error al cargar los tipos de impuesto. ' + error);
          reject(error);
        });
      });
    },

    loadCuentaProvision() {
      return new Promise((resolve, reject) => {
        this.store.query('clasificacion-cuenta', {
          filter: {
            codgrupo: this.company.get('grupo.codgrupo'),
            codclasificacion: 'PROVEEDORES',
            codperiodo: this.periodo
          }
        }).then(cuentasProvisionResponse => {
          let cuentasProvision = cuentasProvisionResponse.findBy('codclasifica', 'PROVEEDORES');

          if (cuentasProvision) {
            resolve({
              cuentaProvisionSeleccionada: cuentasProvision,
              dropdownCuentaProvision: cuentasProvisionResponse
            });
          }

          resolve({
            cuentaProvisionSeleccionada: null,
            dropdownCuentaProvision: cuentasProvisionResponse
          });
        }).catch(error => {
          this.toast.error('Error al cargar la cuenta provision. ' + error);
          reject(error);
        });
      });
    }

  });

  _exports.default = _default;
});