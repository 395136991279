define("sirvo-web/models/reporte-kardex", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    articulos: hasMany('kar-articulo'),
    agruparPorBodega: attr('boolean', {
      defaultValue: true
    }),
    bodegas: hasMany('kar-bodega'),
    tiposArticulos: hasMany('kar-tipo-articulo'),
    fechaInicio: attr('custom-date'),
    fechaFin: attr('custom-date'),
    incluirInactivos: attr('boolean', {
      defaultValue: false
    })
  });

  _exports.default = _default;
});