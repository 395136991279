define("sirvo-web/models/ods/orden-servicio", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    codigo: attr('string'),
    tipoOrden: belongsTo('ods/tipo-orden'),
    tiposServicios: hasMany('ods/tipo-servicio'),
    servicios: hasMany('ods/servicio'),
    fecha: attr('custom-date'),
    fechaEta: attr('custom-date'),
    puerto: belongsTo('ods/puerto'),
    terminal: belongsTo('ods/terminal'),
    muelle: belongsTo('ods/muelle'),
    buque: belongsTo('ods/buque'),
    cliente: belongsTo('gru-directorio'),
    facturaCliente: belongsTo('gru-directorio'),
    fechaInicio: attr('custom-date'),
    fechaFin: attr('custom-date'),
    totalHoras: attr('number'),
    observaciones: attr('string'),
    operaciones: hasMany('ods/orden-servicio-operacion'),
    esAdicional: attr('boolean'),
    ordenOrigen: belongsTo('ods/orden-servicio', {
      inverse: null
    }),
    cargaPreliminar: attr('string'),
    documentoConsolidado: belongsTo('ods/upload'),
    anulada: attr('boolean'),
    numeroViaje: attr('string'),
    razonAnulacion: attr('string'),
    fechaAnulacion: attr('custom-date'),
    fechaFormatted: Ember.computed('fecha', function () {
      let momentDate = (0, _moment.default)(this.fecha || '');
      return momentDate.isValid() ? momentDate.format('DD/MM/YYYY') : null;
    }),
    fechaEtaFormatted: Ember.computed('fecha', function () {
      let momentDate = (0, _moment.default)(this.fechaEta || '');
      return momentDate.isValid() ? momentDate.format('DD/MM/YYYY') : null;
    }),
    fechaInicioFormatted: Ember.computed('fechaInicio', function () {
      let momentDate = (0, _moment.default)(this.fechaInicio || '');
      return momentDate.isValid() ? momentDate.format('DD/MM/YYYY HH:mm:ss') : null;
    }),
    fechaFinFormatted: Ember.computed('fechaFin', function () {
      let momentDate = (0, _moment.default)(this.fechaFin || '');
      return momentDate.isValid() ? momentDate.format('DD/MM/YYYY HH:mm:ss') : null;
    }),
    fechaAnulacionFormatted: Ember.computed('fechaAnulacion', function () {
      let momentDate = (0, _moment.default)(this.fechaAnulacion || '');
      return momentDate.isValid() ? momentDate.format('DD/MM/YYYY HH:mm:ss') : null;
    })
  });

  _exports.default = _default;
});