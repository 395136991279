define("sirvo-web/routes/inventario/movimientos/cortesias/despacho-cortesias", ["exports", "ember-changeset-validations", "ember-changeset-validations/validators", "ember-changeset", "moment"], function (_exports, _emberChangesetValidations, _validators, _emberChangeset, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    company: Ember.computed.readOnly('currentCompany.company'),
    indexRoute: 'inventario.movimientos.cortesias',
    codTipoMovimiento: 'SALIDAS',
    codTipoDocumento: 'NDC',
    codBodegaCortesia: '09',
    codCuentaCortesia: '51010202',
    codCentroCostoCortesia: '1502',
    validations: Ember.computed(function () {
      return {
        empresa: (0, _validators.validatePresence)({
          presence: true
        }),
        bodega: (0, _validators.validatePresence)({
          presence: true
        }),
        cuenta: (0, _validators.validatePresence)({
          presence: true
        }),
        centroCosto: (0, _validators.validatePresence)({
          presence: true
        }),
        fecha: (0, _validators.validatePresence)({
          presence: true
        }),
        detalles: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),

    model() {
      let modelData = new Ember.Object({
        empresa: this.company,
        fecha: new Date(),
        bodega: null,
        cuenta: null,
        centroCosto: null,
        tipoMovimiento: null,
        tipoDocumento: null,
        periodo: null,
        detalles: Ember.A([])
      });
      let fecha = (0, _moment.default)(modelData.get('fecha'));
      return Ember.RSVP.hash({
        periodo: this.store.query('emp-periodo', {
          filter: {
            empresa: this.company.id,
            fechaInicioPeriodo: {
              before: fecha.format('YYYY-MM-DD')
            },
            fechaFinPeriodo: {
              after: fecha.format('YYYY-MM-DD')
            }
          }
        })
      }).then(({
        periodo
      }) => {
        let fechaFormatted = fecha.format('DD/MM/YYYY');

        if (periodo.length === 0) {
          this.toast.error(`No se encontró el período fiscal para la fecha ${fechaFormatted}.`);
          return this.transitionTo(this.indexRoute);
        }

        if (periodo.length > 1) {
          this.toast.error(`Más de un período fiscal encontrado para la fecha ${fechaFormatted}.`);
          return this.transitionTo(this.indexRoute);
        }

        return Ember.merge(modelData, {
          periodo: periodo.get('firstObject')
        });
      }).then(modelData => {
        return Ember.RSVP.hash({
          cuenta: this.store.query('emp-nomenclatura', {
            filter: {
              empresa: this.company.id,
              periodo: modelData.periodo.id,
              ultimoNivel: 1,
              codcuenta: this.codCuentaCortesia,
              noInventario: 1
            }
          }),
          centroCosto: this.store.query('emp-cc', {
            filter: {
              empresa: this.company.id,
              periodo: modelData.periodo.id,
              ultimoNivel: 1,
              codcc: this.codCentroCostoCortesia
            }
          }),
          bodega: this.store.query('kar-bodega', {
            filter: {
              empresa: this.company.get('id'),
              codbodega: this.codBodegaCortesia
            }
          }),
          tipoDocumento: this.store.query('kar-doc', {
            filter: {
              empresa: this.company.get('id'),
              deha: 'H',
              codtipo: this.codTipoDocumento
            },
            include: 'tipoMovimiento'
          })
        }).then(({
          cuenta,
          centroCosto,
          bodega,
          tipoDocumento
        }) => {
          if (cuenta.length === 0) {
            this.toast.error('No se encontró la cuenta de cortesías.');
            return this.transitionTo(this.indexRoute);
          }

          if (cuenta.length > 1) {
            this.toast.error(`Más de una cuenta de cortesías encontrada con código ${this.codCuentaCortesia}.`);
            return this.transitionTo(this.indexRoute);
          }

          if (centroCosto.length === 0) {
            this.toast.error('No se encontró el centro de costo de cortesías.');
            return this.transitionTo(this.indexRoute);
          }

          if (centroCosto.length > 1) {
            this.toast.error(`Más de un centro de costo encontrado con código ${this.codCentroCostoCortesia}.`);
            return this.transitionTo(this.indexRoute);
          }

          if (bodega.length === 0) {
            this.toast.error('No se encontró la bodega de cortesías.');
            return this.transitionTo(this.indexRoute);
          }

          if (bodega.length > 1) {
            this.toast.error(`Más de una bodega de cortesías encontrada con código ${this.codBodegaCortesia}.`);
            return this.transitionTo(this.indexRoute);
          }

          if (tipoDocumento.length === 0) {
            this.toast.error(`No se encontró el tipo de documento ${this.tipoDocumento}.`);
            return this.transitionTo(this.indexRoute);
          }

          if (tipoDocumento.length > 1) {
            this.toast.error(`Más de un tipo de documento ${this.tipoDocumento} encontrado.`);
            return this.transitionTo(this.indexRoute);
          }

          let tipoDocumentoObject = tipoDocumento.get('firstObject');
          let tipoMovimiento = tipoDocumentoObject.get('tipoMovimiento');
          return Ember.merge(modelData, {
            cuenta: cuenta.get('firstObject'),
            centroCosto: centroCosto.get('firstObject'),
            bodega: bodega.get('firstObject'),
            tipoDocumento: tipoDocumentoObject,
            tipoMovimiento: tipoMovimiento
          });
        });
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      let changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(this.get('validations')), this.get('validations'));
      controller.set('changeset', changeset);
    },

    actions: {
      willTransition() {
        return false;
      }

    }
  });

  _exports.default = _default;
});