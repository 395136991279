define("sirvo-web/controllers/inventario/movimientos/ingresos/record/detalles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    kardex: Ember.inject.service(),
    errorHandler: Ember.inject.service(),
    parentCtrl: Ember.inject.controller('inventario.movimientos.ingresos.record'),
    fechaCierre: Ember.computed.readOnly('currentCompany.lastCierre.fecha'),
    registroCerrado: Ember.computed('changeset.fecha', 'fechaCierre', function () {
      if (!this.fechaCierre || !this.changeset.get('fecha')) {
        return false;
      }

      return this.fechaCierre >= this.changeset.get('fecha');
    }),
    columns: Ember.computed(function () {
      let actions = {
        gotToDetail: {
          title: 'Ver detalle',
          buttonClass: 'btn-info',
          icon: 'eye',
          action: this.actions.verDetalle.bind(this)
        }
      };

      if (!this.registroCerrado) {
        actions['delete'] = {
          title: 'Eliminar',
          buttonClass: 'btn-danger',
          icon: 'trash-o',
          action: this.actions.eliminarDetalle.bind(this)
        };
      }

      return [{
        label: 'Articulo',
        valuePath: 'articulo.codarticulo',
        sortable: false,
        width: '100px'
      }, {
        label: 'Descripción',
        valuePath: 'articulo.descripcion',
        sortable: false,
        width: '250px'
      }, {
        label: 'Cantidad',
        valuePath: 'cantidad',
        sortable: false,
        width: '100px'
      }, {
        label: 'U. Medida',
        valuePath: 'unidadMedida.codigo',
        sortable: false,
        width: '100px'
      }, {
        label: 'Precio U.',
        valuePath: 'precioUnitario',
        sortable: false,
        width: '100px'
      }, {
        label: 'Costo neto',
        valuePath: 'valorNeto',
        sortable: false,
        width: '100px',
        totalValuePath: 'valorNeto',
        cellComponent: 'tables/cell/accounting-amount'
      }, {
        label: 'Costo total',
        valuePath: 'total',
        sortable: false,
        width: '100px',
        totalValuePath: 'total',
        cellComponent: 'tables/cell/accounting-amount'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: actions
      }];
    }),
    actions: {
      verDetalle(item) {
        return this.kardex.queryMovDetUbicaciones(item).then(() => {
          this.transitionToRoute(`${this.routeName}.record`, item);
        });
      },

      eliminarDetalle(item) {
        if (this.registroCerrado) {
          this.toast.error('Registro solo de lectura por cierre de inventario.');
          return;
        }

        this.send('openModal', 'popups/simple-confirm', {
          title: '¿Está seguro de eliminar el registro?',
          message: '',
          onSubmit: () => {
            this.loader.setLoading(true);
            item.destroyRecord().then(() => {
              this.toast.success('Línea eliminada correctamente.');
              this.set('detalles', this.detalles.without(item)); // WARNING: Lamentablemente es necesario actualizar el array de la ruta papá.

              this.parentCtrl.set('detalles', this.parentCtrl.detalles.without(item));
            }).catch(error => this.errorHandler.handleBackendErrors(error, 'No se pudo eliminar la línea de detalle.')).finally(() => this.loader.setLoading(false));
          }
        });
      },

      getReport() {
        if (!this.record.id) {
          this.toast.error('Guarde primero el registro para visualizar el reporte.');
          return;
        }

        this.loader.setLoading(true);
        this.toast.info('Generando reporte');
        return this.request.get(`kar-movs/${this.record.id}/reporte_lineas`).then(() => this.toast.success('Reporte generado exitosamente')).finally(() => this.loader.setLoading(false));
      }

    }
  });

  _exports.default = _default;
});