define("sirvo-web/models/notification", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    code: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    subjectTemplate: (0, _model.attr)('string'),
    bodyTemplate: (0, _model.attr)('string'),
    event: (0, _model.attr)('string'),
    entityName: (0, _model.attr)('string'),
    entityVariableName: (0, _model.attr)('string'),
    propertyName: (0, _model.attr)('string'),
    propertyNewValue: (0, _model.attr)('string'),
    variables: (0, _model.hasMany)('notification-variable'),
    profileConfigs: (0, _model.hasMany)('notification-profile'),
    constraintExpression: (0, _model.attr)('string'),
    enabled: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    enabledString: Ember.computed('enabled', function () {
      return this.enabled ? 'Sí' : 'No';
    }),
    eventString: Ember.computed('event', function () {
      if (!this.event) {
        return null;
      }

      const eventString = this.constants.NOTIFICATION_EVENTS.findBy('key', this.event);
      return eventString ? eventString.name : this.event;
    }),

    save(options) {
      if (this.get('isDeleted')) {
        return this._super(...arguments);
      }

      if (this.event !== 'ENTITY_PROPERTY_UPDATED') {
        this.set('propertyName', null);
        this.set('propertyNewValue', null);
      }

      let removedProfileConfigs = this.profileConfigs.filterBy('isDeleted');
      let unsavedProfileConfigs = this.profileConfigs.rejectBy('isDeleted').filter(record => record.get('isNew') || record.get('isDirty'));
      return Ember.RSVP.all(removedProfileConfigs.map(record => record.save())).then(() => this._internalModel.save(options)).then(() => Ember.RSVP.all(unsavedProfileConfigs.map(record => record.save()))).then(() => this);
    }

  });

  _exports.default = _default;
});