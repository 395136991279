define("sirvo-web/validations/ods/orden-servicio-documento", ["exports", "ember-changeset-validations/validators", "sirvo-web/models/constants"], function (_exports, _validators, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    ordenServicio: (0, _validators.validatePresence)(true),
    servicioDocumento: (0, _validators.validatePresence)(true),
    // Este no es un atributo nativo, se usa para validar el tipo de archivo cargado.
    type: (0, _validators.validateInclusion)({
      message: 'Archivo inválido',
      list: _constants.default.UPLOADS_CONTENT_TYPES
    }) // Se podría validar el tamaño máximo del archivo con el key "size".

  };
  _exports.default = _default;
});