define("sirvo-web/services/servir-ember-utilities/config", ["exports", "servir-ember-utilities/services/servir-ember-utilities/config"], function (_exports, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _config.default;
    }
  });
});