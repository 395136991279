define("sirvo-web/controllers/inventario/reportes/kardex-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // export default [
  //   {
  //     "articulo_codigo": "BEB0194",
  //     "articulo_descripcion": "CERVEZA GALLO NO RETORNABLE",
  //     "medida_base_codigo": "BOTELLA",
  //     "existencia_inicial": 450,
  //     "saldo_inicial": 3146.18,
  //     "existencia": 547,
  //     "saldo": 3828.83,
  //     "movimientos": [],
  //     "movimientos_by_bodega": [
  //       {
  //         "bodega_codigo": "03",
  //         "bodega_descripcion": "BEBIDAS",
  //         "existencia_inicial": 168,
  //         "saldo_inicial": 1176.17,
  //         "movimientos": [
  //           {
  //             "fecha": "03/06/2024",
  //             "bodega": "BEBIDAS",
  //             "documento": "NS",
  //             "serie": null,
  //             "numero": 1909,
  //             "proveedor": null,
  //             "lote": null,
  //             "entrada": null,
  //             "salida": 48,
  //             "costo_unitario": 7.001,
  //             "existencia": -48,
  //             "entrada_valor": null,
  //             "salida_valor": 336.05,
  //             "saldo": -336.05,
  //             "valor_promedio": 7.001,
  //             "observaciones": null
  //           },
  //           {
  //             "fecha": "06/06/2024",
  //             "bodega": "BEBIDAS",
  //             "documento": "NS",
  //             "serie": null,
  //             "numero": 1937,
  //             "proveedor": null,
  //             "lote": null,
  //             "entrada": null,
  //             "salida": 48,
  //             "costo_unitario": 7.001,
  //             "existencia": -48,
  //             "entrada_valor": null,
  //             "salida_valor": 336.05,
  //             "saldo": -336.05,
  //             "valor_promedio": 7.001,
  //             "observaciones": null
  //           },
  //           {
  //             "fecha": "11/06/2024",
  //             "bodega": "BEBIDAS",
  //             "documento": "FA",
  //             "serie": "003-ODC-2024",
  //             "numero": 3086,
  //             "proveedor": "CLEANDISPER, S.A.",
  //             "lote": null,
  //             "entrada": 192,
  //             "salida": null,
  //             "costo_unitario": 7.0127,
  //             "existencia": 192,
  //             "entrada_valor": 1346.43,
  //             "salida_valor": null,
  //             "saldo": 1346.43,
  //             "valor_promedio": 7.0127,
  //             "observaciones": null
  //           }
  //         ],
  //         "existencia": 96,
  //         "saldo": 674.33
  //       },
  //       {
  //         "bodega_codigo": "S01",
  //         "bodega_descripcion": "STOCK COCINA Y RESTAURANTE",
  //         "existencia_inicial": 234,
  //         "saldo_inicial": 1635.19,
  //         "movimientos": [
  //           {
  //             "fecha": "01/06/2024",
  //             "bodega": "STOCK COCINA Y RESTAURANTE",
  //             "documento": "NS",
  //             "serie": "2024",
  //             "numero": 1040,
  //             "proveedor": null,
  //             "lote": null,
  //             "entrada": null,
  //             "salida": 1,
  //             "costo_unitario": 6.99,
  //             "existencia": -1,
  //             "entrada_valor": null,
  //             "salida_valor": 6.99,
  //             "saldo": -6.99,
  //             "valor_promedio": 6.99,
  //             "observaciones": null
  //           },
  //           {
  //             "fecha": "02/06/2024",
  //             "bodega": "STOCK COCINA Y RESTAURANTE",
  //             "documento": "NS",
  //             "serie": "2024",
  //             "numero": 1041,
  //             "proveedor": null,
  //             "lote": null,
  //             "entrada": null,
  //             "salida": 1,
  //             "costo_unitario": 6.99,
  //             "existencia": -1,
  //             "entrada_valor": null,
  //             "salida_valor": 6.99,
  //             "saldo": -6.99,
  //             "valor_promedio": 6.99,
  //             "observaciones": null
  //           },
  //           {
  //             "fecha": "03/06/2024",
  //             "bodega": "STOCK COCINA Y RESTAURANTE",
  //             "documento": "NI",
  //             "serie": "2024",
  //             "numero": 18468,
  //             "proveedor": null,
  //             "lote": null,
  //             "entrada": 48,
  //             "salida": null,
  //             "costo_unitario": 7.001,
  //             "existencia": 48,
  //             "entrada_valor": 336.05,
  //             "salida_valor": null,
  //             "saldo": 336.05,
  //             "valor_promedio": 7.001,
  //             "observaciones": null
  //           },
  //           {
  //             "fecha": "03/06/2024",
  //             "bodega": "STOCK COCINA Y RESTAURANTE",
  //             "documento": "NS",
  //             "serie": "2024",
  //             "numero": 1044,
  //             "proveedor": null,
  //             "lote": null,
  //             "entrada": null,
  //             "salida": 7,
  //             "costo_unitario": 6.99,
  //             "existencia": -7,
  //             "entrada_valor": null,
  //             "salida_valor": 48.93,
  //             "saldo": -48.93,
  //             "valor_promedio": 6.99,
  //             "observaciones": null
  //           },
  //           {
  //             "fecha": "04/06/2024",
  //             "bodega": "STOCK COCINA Y RESTAURANTE",
  //             "documento": "NS",
  //             "serie": "2024",
  //             "numero": 1046,
  //             "proveedor": null,
  //             "lote": null,
  //             "entrada": null,
  //             "salida": 2,
  //             "costo_unitario": 6.99,
  //             "existencia": -2,
  //             "entrada_valor": null,
  //             "salida_valor": 13.98,
  //             "saldo": -13.98,
  //             "valor_promedio": 6.99,
  //             "observaciones": null
  //           },
  //           {
  //             "fecha": "05/06/2024",
  //             "bodega": "STOCK COCINA Y RESTAURANTE",
  //             "documento": "NS",
  //             "serie": "2024",
  //             "numero": 1096,
  //             "proveedor": null,
  //             "lote": null,
  //             "entrada": null,
  //             "salida": 6,
  //             "costo_unitario": 6.99,
  //             "existencia": -6,
  //             "entrada_valor": null,
  //             "salida_valor": 41.94,
  //             "saldo": -41.94,
  //             "valor_promedio": 6.99,
  //             "observaciones": null
  //           },
  //           {
  //             "fecha": "06/06/2024",
  //             "bodega": "STOCK COCINA Y RESTAURANTE",
  //             "documento": "NI",
  //             "serie": "2024",
  //             "numero": 18746,
  //             "proveedor": null,
  //             "lote": null,
  //             "entrada": 48,
  //             "salida": null,
  //             "costo_unitario": 7.001,
  //             "existencia": 48,
  //             "entrada_valor": 336.05,
  //             "salida_valor": null,
  //             "saldo": 336.05,
  //             "valor_promedio": 7.001,
  //             "observaciones": null
  //           },
  //           {
  //             "fecha": "06/06/2024",
  //             "bodega": "STOCK COCINA Y RESTAURANTE",
  //             "documento": "NS",
  //             "serie": "2024",
  //             "numero": 1097,
  //             "proveedor": null,
  //             "lote": null,
  //             "entrada": null,
  //             "salida": 4,
  //             "costo_unitario": 6.9925,
  //             "existencia": -4,
  //             "entrada_valor": null,
  //             "salida_valor": 27.97,
  //             "saldo": -27.97,
  //             "valor_promedio": 6.9925,
  //             "observaciones": null
  //           },
  //           {
  //             "fecha": "07/06/2024",
  //             "bodega": "STOCK COCINA Y RESTAURANTE",
  //             "documento": "NS",
  //             "serie": "2024",
  //             "numero": 1100,
  //             "proveedor": null,
  //             "lote": null,
  //             "entrada": null,
  //             "salida": 17,
  //             "costo_unitario": 6.9918,
  //             "existencia": -17,
  //             "entrada_valor": null,
  //             "salida_valor": 118.86,
  //             "saldo": -118.86,
  //             "valor_promedio": 6.9918,
  //             "observaciones": null
  //           },
  //           {
  //             "fecha": "08/06/2024",
  //             "bodega": "STOCK COCINA Y RESTAURANTE",
  //             "documento": "NS",
  //             "serie": "2024",
  //             "numero": 1101,
  //             "proveedor": null,
  //             "lote": null,
  //             "entrada": null,
  //             "salida": 18,
  //             "costo_unitario": 6.9917,
  //             "existencia": -18,
  //             "entrada_valor": null,
  //             "salida_valor": 125.85,
  //             "saldo": -125.85,
  //             "valor_promedio": 6.9917,
  //             "observaciones": null
  //           },
  //           {
  //             "fecha": "09/06/2024",
  //             "bodega": "STOCK COCINA Y RESTAURANTE",
  //             "documento": "NS",
  //             "serie": "2024",
  //             "numero": 1103,
  //             "proveedor": null,
  //             "lote": null,
  //             "entrada": null,
  //             "salida": 7,
  //             "costo_unitario": 6.9914,
  //             "existencia": -7,
  //             "entrada_valor": null,
  //             "salida_valor": 48.94,
  //             "saldo": -48.94,
  //             "valor_promedio": 6.9914,
  //             "observaciones": null
  //           },
  //           {
  //             "fecha": "10/06/2024",
  //             "bodega": "STOCK COCINA Y RESTAURANTE",
  //             "documento": "NS",
  //             "serie": "2024",
  //             "numero": 1104,
  //             "proveedor": null,
  //             "lote": null,
  //             "entrada": null,
  //             "salida": 7,
  //             "costo_unitario": 6.9914,
  //             "existencia": -7,
  //             "entrada_valor": null,
  //             "salida_valor": 48.94,
  //             "saldo": -48.94,
  //             "valor_promedio": 6.9914,
  //             "observaciones": null
  //           }
  //         ],
  //         "existencia": 26,
  //         "saldo": 182.70999999999992
  //       },
  //       {
  //         "bodega_codigo": "S06",
  //         "bodega_descripcion": "STOCK MINIMARKET",
  //         "existencia_inicial": 48,
  //         "saldo_inicial": 334.82,
  //         "movimientos": [
  //           {
  //             "fecha": "01/06/2024",
  //             "bodega": "STOCK MINIMARKET",
  //             "documento": "NS",
  //             "serie": "2024",
  //             "numero": 1075,
  //             "proveedor": null,
  //             "lote": null,
  //             "entrada": null,
  //             "salida": 4,
  //             "costo_unitario": 6.975,
  //             "existencia": -4,
  //             "entrada_valor": null,
  //             "salida_valor": 27.9,
  //             "saldo": -27.9,
  //             "valor_promedio": 6.975,
  //             "observaciones": null
  //           },
  //           {
  //             "fecha": "12/06/2024",
  //             "bodega": "STOCK MINIMARKET",
  //             "documento": "NS",
  //             "serie": "2024",
  //             "numero": 1113,
  //             "proveedor": null,
  //             "lote": null,
  //             "entrada": null,
  //             "salida": 21,
  //             "costo_unitario": 6.9757,
  //             "existencia": -21,
  //             "entrada_valor": null,
  //             "salida_valor": 146.49,
  //             "saldo": -146.49,
  //             "valor_promedio": 6.9757,
  //             "observaciones": null
  //           }
  //         ],
  //         "existencia": -25,
  //         "saldo": -174.39000000000001
  //       }
  //     ]
  //   }
  // ];
  var _default = [{
    "articulo_codigo": "BEB0194",
    "articulo_descripcion": "CERVEZA GALLO NO RETORNABLE",
    "medida_base_codigo": "BOTELLA",
    "existencia_inicial": 450,
    "saldo_inicial": 3146.18,
    "existencia": 547,
    "saldo": 3828.83,
    "movimientos": [{
      "fecha": "01/06/2024",
      "bodega": "STOCK COCINA Y RESTAURANTE",
      "documento": "NS",
      "serie": "2024",
      "numero": 1040,
      "proveedor": null,
      "lote": null,
      "entrada": null,
      "salida": 1,
      "costo_unitario": 6.99,
      "existencia": -1,
      "entrada_valor": null,
      "salida_valor": 6.99,
      "saldo": -6.99,
      "valor_promedio": 6.99,
      "observaciones": null
    }, {
      "fecha": "01/06/2024",
      "bodega": "STOCK MINIMARKET",
      "documento": "NS",
      "serie": "2024",
      "numero": 1075,
      "proveedor": null,
      "lote": null,
      "entrada": null,
      "salida": 4,
      "costo_unitario": 6.975,
      "existencia": -4,
      "entrada_valor": null,
      "salida_valor": 27.9,
      "saldo": -27.9,
      "valor_promedio": 6.975,
      "observaciones": null
    }, {
      "fecha": "02/06/2024",
      "bodega": "STOCK COCINA Y RESTAURANTE",
      "documento": "NS",
      "serie": "2024",
      "numero": 1041,
      "proveedor": null,
      "lote": null,
      "entrada": null,
      "salida": 1,
      "costo_unitario": 6.99,
      "existencia": -1,
      "entrada_valor": null,
      "salida_valor": 6.99,
      "saldo": -6.99,
      "valor_promedio": 6.99,
      "observaciones": null
    }, {
      "fecha": "03/06/2024",
      "bodega": "STOCK COCINA Y RESTAURANTE",
      "documento": "NI",
      "serie": "2024",
      "numero": 18468,
      "proveedor": null,
      "lote": null,
      "entrada": 48,
      "salida": null,
      "costo_unitario": 7.001,
      "existencia": 48,
      "entrada_valor": 336.05,
      "salida_valor": null,
      "saldo": 336.05,
      "valor_promedio": 7.001,
      "observaciones": null
    }, {
      "fecha": "03/06/2024",
      "bodega": "BEBIDAS",
      "documento": "NS",
      "serie": null,
      "numero": 1909,
      "proveedor": null,
      "lote": null,
      "entrada": null,
      "salida": 48,
      "costo_unitario": 7.001,
      "existencia": -48,
      "entrada_valor": null,
      "salida_valor": 336.05,
      "saldo": -336.05,
      "valor_promedio": 7.001,
      "observaciones": null
    }, {
      "fecha": "03/06/2024",
      "bodega": "STOCK COCINA Y RESTAURANTE",
      "documento": "NS",
      "serie": "2024",
      "numero": 1044,
      "proveedor": null,
      "lote": null,
      "entrada": null,
      "salida": 7,
      "costo_unitario": 6.99,
      "existencia": -7,
      "entrada_valor": null,
      "salida_valor": 48.93,
      "saldo": -48.93,
      "valor_promedio": 6.99,
      "observaciones": null
    }, {
      "fecha": "04/06/2024",
      "bodega": "STOCK COCINA Y RESTAURANTE",
      "documento": "NS",
      "serie": "2024",
      "numero": 1046,
      "proveedor": null,
      "lote": null,
      "entrada": null,
      "salida": 2,
      "costo_unitario": 6.99,
      "existencia": -2,
      "entrada_valor": null,
      "salida_valor": 13.98,
      "saldo": -13.98,
      "valor_promedio": 6.99,
      "observaciones": null
    }, {
      "fecha": "05/06/2024",
      "bodega": "STOCK COCINA Y RESTAURANTE",
      "documento": "NS",
      "serie": "2024",
      "numero": 1096,
      "proveedor": null,
      "lote": null,
      "entrada": null,
      "salida": 6,
      "costo_unitario": 6.99,
      "existencia": -6,
      "entrada_valor": null,
      "salida_valor": 41.94,
      "saldo": -41.94,
      "valor_promedio": 6.99,
      "observaciones": null
    }, {
      "fecha": "06/06/2024",
      "bodega": "STOCK COCINA Y RESTAURANTE",
      "documento": "NI",
      "serie": "2024",
      "numero": 18746,
      "proveedor": null,
      "lote": null,
      "entrada": 48,
      "salida": null,
      "costo_unitario": 7.001,
      "existencia": 48,
      "entrada_valor": 336.05,
      "salida_valor": null,
      "saldo": 336.05,
      "valor_promedio": 7.001,
      "observaciones": null
    }, {
      "fecha": "06/06/2024",
      "bodega": "BEBIDAS",
      "documento": "NS",
      "serie": null,
      "numero": 1937,
      "proveedor": null,
      "lote": null,
      "entrada": null,
      "salida": 48,
      "costo_unitario": 7.001,
      "existencia": -48,
      "entrada_valor": null,
      "salida_valor": 336.05,
      "saldo": -336.05,
      "valor_promedio": 7.001,
      "observaciones": null
    }, {
      "fecha": "06/06/2024",
      "bodega": "STOCK COCINA Y RESTAURANTE",
      "documento": "NS",
      "serie": "2024",
      "numero": 1097,
      "proveedor": null,
      "lote": null,
      "entrada": null,
      "salida": 4,
      "costo_unitario": 6.9925,
      "existencia": -4,
      "entrada_valor": null,
      "salida_valor": 27.97,
      "saldo": -27.97,
      "valor_promedio": 6.9925,
      "observaciones": null
    }, {
      "fecha": "07/06/2024",
      "bodega": "STOCK COCINA Y RESTAURANTE",
      "documento": "NS",
      "serie": "2024",
      "numero": 1100,
      "proveedor": null,
      "lote": null,
      "entrada": null,
      "salida": 17,
      "costo_unitario": 6.9918,
      "existencia": -17,
      "entrada_valor": null,
      "salida_valor": 118.86,
      "saldo": -118.86,
      "valor_promedio": 6.9918,
      "observaciones": null
    }, {
      "fecha": "08/06/2024",
      "bodega": "STOCK COCINA Y RESTAURANTE",
      "documento": "NS",
      "serie": "2024",
      "numero": 1101,
      "proveedor": null,
      "lote": null,
      "entrada": null,
      "salida": 18,
      "costo_unitario": 6.9917,
      "existencia": -18,
      "entrada_valor": null,
      "salida_valor": 125.85,
      "saldo": -125.85,
      "valor_promedio": 6.9917,
      "observaciones": null
    }, {
      "fecha": "09/06/2024",
      "bodega": "STOCK COCINA Y RESTAURANTE",
      "documento": "NS",
      "serie": "2024",
      "numero": 1103,
      "proveedor": null,
      "lote": null,
      "entrada": null,
      "salida": 7,
      "costo_unitario": 6.9914,
      "existencia": -7,
      "entrada_valor": null,
      "salida_valor": 48.94,
      "saldo": -48.94,
      "valor_promedio": 6.9914,
      "observaciones": null
    }, {
      "fecha": "10/06/2024",
      "bodega": "STOCK COCINA Y RESTAURANTE",
      "documento": "NS",
      "serie": "2024",
      "numero": 1104,
      "proveedor": null,
      "lote": null,
      "entrada": null,
      "salida": 7,
      "costo_unitario": 6.9914,
      "existencia": -7,
      "entrada_valor": null,
      "salida_valor": 48.94,
      "saldo": -48.94,
      "valor_promedio": 6.9914,
      "observaciones": null
    }, {
      "fecha": "11/06/2024",
      "bodega": "BEBIDAS",
      "documento": "FA",
      "serie": "003-ODC-2024",
      "numero": 3086,
      "proveedor": "CLEANDISPER, S.A.",
      "lote": null,
      "entrada": 192,
      "salida": null,
      "costo_unitario": 7.0127,
      "existencia": 192,
      "entrada_valor": 1346.43,
      "salida_valor": null,
      "saldo": 1346.43,
      "valor_promedio": 7.0127,
      "observaciones": null
    }, {
      "fecha": "12/06/2024",
      "bodega": "STOCK MINIMARKET",
      "documento": "NS",
      "serie": "2024",
      "numero": 1113,
      "proveedor": null,
      "lote": null,
      "entrada": null,
      "salida": 21,
      "costo_unitario": 6.9757,
      "existencia": -21,
      "entrada_valor": null,
      "salida_valor": 146.49,
      "saldo": -146.49,
      "valor_promedio": 6.9757,
      "observaciones": null
    }],
    "movimientos_by_bodega": []
  }];
  _exports.default = _default;
});