define("sirvo-web/controllers/contrasenas/movimientos/ordenes-de-compra/emitir", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "moment"], function (_exports, _abstractModuleIndex, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    request: Ember.inject.service(),
    lastRouteService: Ember.inject.service('last-route'),
    editRoute: 'contrasenas.movimientos.ordenes-de-compra.emitir.poliza-modal',
    company: Ember.computed.readOnly('currentCompany.company'),
    data: null,
    dataOriginal: null,
    searchDirectorio: null,
    transaccionCreated: null,
    keytraContrasenaObtenido: null,
    sendOrdenes: null,
    isService: false,
    proveedor: null,
    filteredData: null,
    dropdownOptionSatDocumentoFel: null,
    selectedSatDocumentoFel: null,
    saveResult: null,
    botonAgregarFactura: true,
    openModal: false,
    isPolizaModalOpen: false,
    showFilterMenu: false,
    descripcionContable: '',
    selectDirectorio: null,
    isModalDocumentos: false,
    dropdownDirectorios: null,
    diasCR: null,
    filterCodigo: '',
    fechaDocumento: null,
    fechaVencimiento: null,
    tipoDocumentosOpciones: null,
    tipoDocumentoSeleccionado: null,
    documentosEmpresa: null,
    tipoIVA: null,
    tipoIVASeleccionado: null,
    ordenDeCompraItems: null,
    fechaOrdenCompraSeleccionada: null,
    fechaActual: Ember.computed(function () {
      let fechaActual = (0, _moment.default)();
      let fechaToDate = fechaActual.toDate();
      return (0, _moment.default)(fechaToDate).format('YYYY-MM-DD');
    }),
    mesanioOrdenCompraSeleccionada: null,
    nitSeleccionado: null,
    amountBienes: 0,
    amountServicios: 0,
    baseGravadaBienes: null,
    impuestosBienes: null,
    impuestosServicios: null,
    baseGravadaServicios: null,
    totalImpuestos: null,
    totalSinImpuestos: null,
    cuentaProvisionOrdenDeCompra: null,
    totalFinal: null,
    ordenesSeleccionadas: null,
    inputCombustible: null,
    valorInputCombustible: null,
    openModalPolizaPrincipal: false,
    columns: Ember.computed(function () {
      return [{
        label: '',
        valuePath: 'status',
        sortable: false,
        cellComponent: 'checkbox-cell-status',
        width: '50px'
      }, {
        label: 'Fecha de orden compra',
        valuePath: 'fecha_orden_compra',
        width: '100px'
      }, {
        label: 'Mon.',
        valuePath: 'moneda_codigo',
        width: '60px'
      }, {
        label: 'Total',
        valuePath: 'total',
        width: '100px',
        cellComponent: 'total-float-tabla',
        align: 'right'
      }, {
        label: 'Proveedor',
        valuePath: 'proveedor_nombre',
        width: '200px'
      }, {
        label: 'Orden Compra',
        valuePath: 'orden_compra_codigo',
        width: '150px'
      }, {
        label: 'Justificación',
        valuePath: 'justificacion',
        width: '200px'
      }, {
        label: 'Código Proveedor',
        valuePath: 'proveedor_codigo',
        width: '80px'
      }];
    }),
    areAllChecked: Ember.computed('data.@each.status', function () {
      let allData = this.get('data');
      return allData && allData.every(item => item.status === '1');
    }),

    reloadTable() {
      this.set('reload', true);
    },

    getSerializedFilters() {
      let fechaFin = this.changeset.get('fechaFin');
      let filters = {
        codigoEmpresa: this.changeset.get('codigoEmpresa'),
        fechaFin: fechaFin ? (0, _moment.default)(fechaFin).format() : null
      };
      return filters;
    },

    getFiltersPost() {
      let fechaFin = this.changeset.get('fechaFin');
      let filters = {
        codigoEmpresa: this.changeset.get('codigoEmpresa'),
        fechaFin: fechaFin ? (0, _moment.default)(fechaFin).format() : null,
        data: this.data
      };
      return filters;
    },

    getPeriodo() {
      this.loader.setIsLoading();
      return this.store.query('emp-periodo', {
        filter: {
          empresa: this.company.codempresa,
          fechaInicioPeriodo: {
            before: this.fechaActual
          },
          fechaFinPeriodo: {
            after: this.fechaActual
          }
        }
      }).finally(() => this.loader.setNotLoading());
    },

    async searchDirectorios(term) {
      try {
        let results = await this.request.get('directorios/razon-social-codigo', {
          term: term
        });
        this.set('dropdownDirectorios', results.data);
      } catch (error) {
        this.toast.error('Error al realizar la búsqueda: ', error);
        return [];
      }
    },

    formatNumberWithCommas(value) {
      let numerico = Number(value);
      let parts = numerico.toFixed(2).split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return parts.join('.');
    },

    actions: {
      handleActualizar() {},

      applyRightTextAlign(element) {
        element.style.textAlign = 'right';
      },

      setLastRoute() {
        this.set('lastRouteService.lastRoute', 'contrasenas.movimientos.ordenes-de-compra.emitir');
      },

      formatearCombustible() {
        const valorFiltrado = parseFloat(this.valorInputCombustible).toFixed(2);
        this.set('valorInputCombustible', valorFiltrado);
        this.send('calculateTaxAndTotal');
      },

      seleccionarTipoDocumento(tipoDoc) {
        if (tipoDoc.tipoDocIva === 'FPC') {
          let tipoIVASeleccionado = this.tipoImpuestoOpciones.find(tipoIVA => tipoIVA.tipo === 'P.CONTRIB');

          if (tipoIVASeleccionado) {
            this.send('seleccionarTipoIVa', tipoIVASeleccionado);
          }
        }

        this.set('tipoDocumentoSeleccionado', tipoDoc);
      },

      seleccionarTipoIVa(tipoIVA) {
        this.set('tipoIVASeleccionado', tipoIVA);
        this.send('calculateTaxAndTotal');
      },

      calculateTaxAndTotal() {
        let porcentajeDeIVAProcesado = parseFloat(this.tipoIVASeleccionado.porcentaje) / 100 || 0.0;
        let combustibleTotal = this.valorInputCombustible ? parseFloat(this.valorInputCombustible.toString().replace(/,/g, '')) || 0.0 : null;
        let cantidadBienesProcesados = this.amountBienes ? parseFloat(this.amountBienes.toString().replace(/,/g, '')) || 0.0 : 0.0;
        let cantidadServiciosProcesados = this.amountServicios ? parseFloat(this.amountServicios.toString().replace(/,/g, '')) || 0.0 : 0.0;
        let montoSinIVABienes = Math.max(cantidadBienesProcesados - combustibleTotal, 0);
        let combustibleRestante = combustibleTotal > cantidadBienesProcesados ? combustibleTotal - cantidadBienesProcesados : 0;
        let montoSinIVAServicios = Math.max(cantidadServiciosProcesados - combustibleRestante, 0);
        let bienesBaseGravada = parseFloat((montoSinIVABienes / (1 + porcentajeDeIVAProcesado)).toFixed(2));
        let serviciosBaseGravada = parseFloat((montoSinIVAServicios / (1 + porcentajeDeIVAProcesado)).toFixed(2));
        let impuestoSobreBienes = parseFloat((montoSinIVABienes - bienesBaseGravada).toFixed(2));
        let impuestoSobreServicios = parseFloat((montoSinIVAServicios - serviciosBaseGravada).toFixed(2));
        let totalImpuestosProcesado = impuestoSobreBienes + impuestoSobreServicios;
        let totalFinal = bienesBaseGravada + serviciosBaseGravada;
        this.setProperties({
          amountBienes: this.formatNumberWithCommas(cantidadBienesProcesados),
          amountServicios: this.formatNumberWithCommas(cantidadServiciosProcesados),
          baseGravadaBienes: this.formatNumberWithCommas(bienesBaseGravada),
          baseGravadaServicios: this.formatNumberWithCommas(serviciosBaseGravada),
          impuestosBienes: this.formatNumberWithCommas(impuestoSobreBienes),
          impuestosServicios: this.formatNumberWithCommas(impuestoSobreServicios),
          totalImpuestos: this.formatNumberWithCommas(totalImpuestosProcesado),
          totalSinImpuestos: this.formatNumberWithCommas(totalFinal),
          totalFinal: this.formatNumberWithCommas(cantidadBienesProcesados + cantidadServiciosProcesados)
        });
      },

      clasificacionSeleccionada(clasificacion) {
        this.set('clasificacionSeleccionada', clasificacion);
      },

      cuentaProvisionSeleccionada(cuenta) {
        this.set('cuentaProvisionSeleccionada', cuenta);
      },

      selectDirectorio(directorio) {
        this.set('selectDirectorio', directorio);
        let nitDirectorio = directorio.nit;
        let diasCrDirectorio = directorio.diasCrCxp;
        this.set('nitSeleccionado', nitDirectorio);
        this.set('diasCR', diasCrDirectorio);
        this.notifyPropertyChange('fechaOrdenCompraSeleccionada');
        this.notifyPropertyChange('nitDirectorio');
        this.notifyPropertyChange('selectDirectorio');
      },

      procesar() {
        this.loader.setIsLoading();
        let dataSend = [{
          codempresa: this.get('company.codempresa'),
          codsistema: 'CXP',
          nit: this.selectDirectorio.nit,
          codigoDirectorioProveedor: this.selectDirectorio.coddirectorio,
          codtipo: this.tipoDocumentoSeleccionado.codtipo,
          codtipodoctos: this.tipoDocumentoSeleccionado.codtipodoctos,
          codtipoIva: this.tipoDocumentoSeleccionado.codtipo,
          serie: this.serie,
          numero: this.numero,
          deha: this.documentosEmpresa.deha,
          tipoIva: this.tipoIVASeleccionado.tipo,
          valorCombustible: this.valorInputCombustible ? parseFloat(this.valorInputCombustible.replace(/,/g, '')) : null,
          descripcionContable: this.descripcionContable,
          fechaFactura: (0, _moment.default)(this.fechaActual, 'YYYY-MM-DD').toDate(),
          codClasifica: this.clasificacionSeleccionada.codclasifica,
          numeroDocumento: this.serie + '-' + this.numero,
          ivaRetencion: 0,
          valordocml: parseFloat(this.totalFinal.replace(/,/g, '')),
          totalBienes: parseFloat(this.totalFinal.replace(/,/g, '')),
          valorme: 0,
          totalServicios: parseFloat(this.baseGravadaServicios.replace(/,/g, '')),
          baseExentaBienes: 0,
          baseExentaServicios: 0,
          tipoDocumentoImpuesto: '',
          numDocumentoImpuesto: '',
          valorOtrosNoGravados: 0,
          ordenCedula: '',
          registroCedula: '',
          iva: parseFloat(this.totalImpuestos.replace(/,/g, '')),
          totalDoc: parseFloat(this.totalFinal.replace(/,/g, '')),
          valor: parseFloat(this.totalFinal.replace(/,/g, '')),
          baseNoGravada: 0,
          baseGrabadaBienes: parseFloat(this.baseGravadaBienes.replace(/,/g, '')),
          baseGravadaServicios: parseFloat(this.baseGravadaServicios.replace(/,/g, '')),
          fechaVencimiento: this.fechaVencimiento,
          direccion: this.direccion,
          diasCredito: this.diasCR,
          yearMes: this.mesanioOrdenCompraSeleccionada,
          cuentaProvisionOrdenDeCompra: this.cuentaProvisionOrdenDeCompra
        }];
        let camposAVerificar = ['serie', 'numero', 'totalDoc', 'valor', 'iva', 'codempresa', 'codsistema', 'nit', 'codtipo', 'descripcionContable'];

        let encontrarCamposInvalidos = elemento => {
          let camposInvalidos = camposAVerificar.filter(campo => !elemento[campo] && elemento[campo] !== 0);
          return camposInvalidos;
        };

        let camposInvalidos = dataSend.flatMap(encontrarCamposInvalidos);

        if (camposInvalidos.length > 0) {
          this.toast.error(`Algunos campos requeridos están vacíos o son nulos: ${camposInvalidos.join(', ')}.`);
          this.loader.setNotLoading();
          return;
        }

        let json = {
          data: dataSend,
          orden_compra: this.ordenDeCompraItems
        };
        this.request.post('cuentas-por-pagar/contrasena', json).then(response => {
          this.toast.success(response.message);
          this.set('transaccionCreated', response.data.keytra);
          this.set('keytraContrasenaObtenido', response.data.keytraContrasena);
          this.set('dataSend', null);
          this.set('ordenDeCompraItems', null);
          this.send('onclose');
          this.send('setLastRoute');
          this.set('openModalPolizaPrincipal', true);
          this.send('reloadPage');
        }).catch(error => {
          this.toast.error(error.status + ' ' + error.statusText);
        }).finally(() => this.loader.setNotLoading());
      },

      reloadPage() {
        this.set('selectDirectorio', null);
        this.set('dropdownDirectorios', []);
        this.set('data', null);
      },

      resetImpuestos() {
        this.set('amountBienes', 0.0);
        this.set('amountServicios', 0.0);
        this.set('totalFinal', 0.0);
        this.set('baseGravadaBienes', 0.0);
        this.set('impuestosBienes', 0.0);
        this.set('totalImpuestos', 0.0);
        this.set('baseGravadaServicios', 0.0);
        this.set('impuestosServicios', 0.0);
        this.set('totalSinImpuestos', 0.0);
      },

      selectAndCloseModal(directorio) {
        this.set('selectDirectorio', directorio);
        this.set('openModalDirectorio', false);
      },

      toggleFilterMenu() {
        this.toggleProperty('showFilterMenu');
      },

      applyFilters() {
        let filterValue = this.filterCodigo.toLowerCase();
        this.set('originalData', this.data);
        this.filteredData = this.data.filter(item => {
          return item.orden_compra_codigo.toLowerCase().includes(filterValue) || item.proveedor_nombre.toLowerCase().includes(filterValue);
        });
        this.set('data', this.filteredData);

        if (this.filteredData.length === 0) {
          this.toast.info('No hay resultados con ese valor.');
        }
      },

      clearFilters() {
        this.set('filterCodigo', '');

        if (this.originalData) {
          this.originalData.forEach(originalItem => {
            let filteredItem = this.filteredData.find(item => item.idtransac === originalItem.idtransac);

            if (filteredItem && filteredItem.status === '1') {
              originalItem.status = '1';
            }
          });
          this.set('data', this.originalData);
          this.set('originalData', null);
        }
      },

      toggleAllCheckboxes(event) {
        if (event && event.type === 'keydown' && event.key !== 'Enter') {
          return;
        }

        let areAllChecked = this.get('areAllChecked');
        let newData = this.get('data').map(item => {
          Ember.set(item, 'status', areAllChecked ? '0' : '1');
          return item;
        });
        this.set('data', newData);
        this.set('areAllChecked', !areAllChecked);
        this.notifyPropertyChange('areAllChecked');
        Ember.run.next(() => {
          let checkboxElement = document.getElementById('selectAllCheckboxes');

          if (checkboxElement) {
            checkboxElement.checked = !areAllChecked;
          }
        });
      },

      uncheckAllCheckboxes() {
        let newData = this.get('data').map(item => {
          Ember.set(item, 'status', '0');
          return item;
        });
        this.set('data', newData);
        this.set('areAllChecked', false);
        this.notifyPropertyChange('areAllChecked');
        Ember.run.next(() => {
          let checkboxElement = document.getElementById('selectAllCheckboxes');

          if (checkboxElement) {
            checkboxElement.checked = false;
          }
        });
      },

      fetchOrdenesCompraLineasOCA() {
        if (!this.selectDirectorio) {
          this.toast.error('Debe seleccionar un directorio a buscar.');
          return;
        }

        this.loader.setIsLoading();

        if (this.get('data')) {
          this.send('uncheckAllCheckboxes');
        }

        return this.request.get('contrasenas-orden-compra-lineas', {
          codDirectorio: this.selectDirectorio.coddirectorio,
          codEmpresa: this.get('company.codempresa')
        }).then(data => {
          this.toast.success('Líneas de órdenes de compra encontradas exitosamente');

          if (this.showFilterMenu) {
            this.send('toggleFilterMenu');
          }

          this.send('clearFilters');
          this.set('data', data.data);
        }).catch(error => {
          this.toast.error('No se pudo obtener correctamente las líneas de órdenes de compra');
          throw error;
        }).finally(() => this.loader.setNotLoading());
      },

      getDirectorios(term) {
        Ember.run.debounce(this, this.searchDirectorios, term, 500);
      },

      updateFechaPago() {
        let diasCrCxp = this.selectDirectorio.diasCrCxp;
        let fechaRegistro = this.fechaActual;
        this.loader.setLoading(true);
        this.request.get('contrasenas/fecha-pago', {
          diasCrCxp,
          fechaRegistro
        }).then(response => {
          let date = response.data[0].proximoPago;
          let dateMoment = (0, _moment.default)(date, 'DD-MM-YYYY').toDate();
          let fechaVencimiento = (0, _moment.default)(dateMoment).format('YYYY-MM-DD');
          this.set('fechaVencimiento', fechaVencimiento);
          this.notifyPropertyChange('fechaVencimiento');
          this.loader.setNotLoading();
        }).catch(error => {
          this.toast.error('Error al cargar la clasificacion. ' + error);
        });
        this.loader.setNotLoading();
      },

      closePolizaModal() {
        this.set('isPolizaModalOpen', false);
      },

      openPoliza() {
        let id = `${'poliza'}-${this.transaccionCreated}-${this.keytraContrasenaObtenido}`;
        this.transitionToRoute('contrasenas.movimientos.ordenes-de-compra.emitir.poliza-modal', id);
      },

      oncloseModalPolizaPrincipal() {
        if (this.openModalPolizaPrincipal == true) {
          this.set('openModalPolizaPrincipal', false);
        }
      },

      toggleModal() {
        this.ordenDeCompraItems = [];
        this.set('serie', '');
        this.set('numero', '');
        this.set('valorInputCombustible', null);
        this.set('cuentaProvisionOrdenDeCompra', null);

        if (this.data == null) {
          this.toast.error('Seleccione una razón social para obtener registros.');
          return;
        }

        let tieneSeleccionado = this.data.some(ordenDeCompra => ordenDeCompra.status === '1');
        let valorServicios = 0;
        let valorBienes = 0;
        let fecha = null;
        let descripcionContable = '';

        if (!tieneSeleccionado) {
          this.toast.error('No existen registros seleccionados.');
          return;
        }

        this.data.forEach(ordenDeCompra => {
          if (ordenDeCompra.status === '1') {
            if (ordenDeCompra.producto_tipo == 'SERVICIO') {
              this.set('isService', true);
              valorServicios = parseFloat(valorServicios) + parseFloat(ordenDeCompra.total);
            } else {
              valorBienes = parseFloat(valorBienes) + parseFloat(ordenDeCompra.total);
            }

            fecha = fecha > ordenDeCompra.fecha_orden_compra ? fecha : ordenDeCompra.fecha_orden_compra;
            this.set('cuentaProvisionOrdenDeCompra', ordenDeCompra.cuenta_provision);
            descripcionContable = descripcionContable + ordenDeCompra.justificacion + '\n';
            this.ordenDeCompraItems.push(ordenDeCompra);
          }
        });
        let formattedDate = typeof fecha === 'string' ? fecha.substring(0, 7) : null;
        this.set('fechaOrdenCompraSeleccionada', fecha);
        this.getPeriodo();
        this.send('resetImpuestos');
        this.send('loadClasificaciones');
        this.toggleProperty('openModal');
        this.set('direccion', this.selectDirectorio.direccion);
        this.set('amountServicios', valorServicios);
        this.set('amountBienes', valorBienes);
        this.set('mesanioOrdenCompraSeleccionada', formattedDate);
        this.set('fechaDocumento', fecha);
        this.set('descripcionContable', descripcionContable);
        this.send('calculateTaxAndTotal');
        this.send('updateFechaPago', fecha);
      },

      onclose() {
        if (this.openModal == true) {
          this.toggleProperty('openModal');
        }
      },

      openModalDocumentos() {
        let nitEmisor = this.selectDirectorio.nit;
        this.loader.setLoading(true);
        this.request.get('sat-documentos-fel/contrasenas', {
          nitEmisor
        }).then(response => {
          this.set('dropdownOptionSatDocumentoFel', response.data);
          this.loader.setNotLoading();
          this.set('isModalDocumentos', true);
        }).catch(error => {
          this.toast.error('Error al realizar la consulta: ' + error);
        }).finally(() => this.loader.setNotLoading());
      },

      closeModalDocumentos() {
        this.set('isModalDocumentos', false);
        this.set('selectedSatDocumentoFel', null);
      },

      addDocumentoSat() {
        if (this.isService) {
          this.set('amountServicios', this.selectedSatDocumentoFel.valorTotal);
        } else {
          this.set('amountBienes', this.selectedSatDocumentoFel.valorTotal);
        }

        this.set('serie', this.selectedSatDocumentoFel.serie);
        this.set('numero', this.selectedSatDocumentoFel.numero);
        let fechaDocumento = (0, _moment.default)(this.selectedSatDocumentoFel.fechaHoraEmision, 'DD/MM/YYYY').format('YYYY-MM-DD');
        this.set('fechaDocumento', fechaDocumento);
        this.send('calculateTaxAndTotal');
        this.set('isModalDocumentos', false);
        this.set('selectedSatDocumentoFel', null);
      },

      loadClasificaciones() {
        this.request.get('gru-directorio-clasifica-by-cod-grupo', {
          tipo: 'PROVEEDORES',
          codDirectorio: this.selectDirectorio.coddirectorio
        }).then(clasificaciones => {
          this.set('dropdownClasificaciones', clasificaciones.data);
          let clasificacionProveedores = clasificaciones.data.findBy('codclasifica', 'PROVEEDORES');

          if (clasificacionProveedores) {
            this.set('clasificacionSeleccionada', clasificacionProveedores);
          }
        }).catch(error => {
          this.toast.error('Error al cargar la clasificacion. ' + error);
        });
      }

    }
  });

  _exports.default = _default;
});