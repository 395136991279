define("sirvo-web/routes/admon/procesos/notificaciones/record/variables", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute"], function (_exports, _isTabSubroute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_isTabSubroute.default, {
    setupController(controller, model) {
      this._super(controller, model);

      controller.set('variablesChangesets', model.record.get('variables').map(variable => controller.createVariableChangeset(variable)));
    },

    actions: {
      willTransition(transition) {
        if (!transition.targetName.startsWith(this.routeName)) {
          let variablesChangesets = this.controller.get('variablesChangesets');
          variablesChangesets.forEach(changeset => changeset.get('_content').rollbackAttributes());
          variablesChangesets.forEach(changeset => changeset.rollback());
        }

        return true;
      }

    }
  });

  _exports.default = _default;
});