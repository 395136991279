define("sirvo-web/routes/admon/procesos/notificaciones/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "sirvo-web/mixins/permission-validator", "servir-ember-utilities/mixins/route/has-tabs", "sirvo-web/validations/notification"], function (_exports, _abstractModuleRecord, _permissionValidator, _hasTabs, _notification) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_hasTabs.default, _permissionValidator.default, {
    routePermission: 'AdmonCrearNotificacion',
    modelName: 'notification',
    indexRoute: 'admon.procesos.notificaciones',
    defaultTabSubRoute: 'configuracion',
    relatedModelsToQuery: Ember.computed('isNew', function () {
      let modelsToQuery = [{
        name: 'profiles',
        modelName: 'vf-perfil'
      }];

      if (!this.isNew) {
        modelsToQuery.pushObjects([{
          name: 'profileConfigs',
          modelName: 'notification-profile',
          queryParams: {
            filter: {
              notification: this.recordId
            }
          }
        }, {
          name: 'variables',
          modelName: 'notification-variable',
          queryParams: {
            filter: {
              notification: this.recordId
            }
          }
        }]);
      }

      return modelsToQuery;
    }),
    validations: _notification.default,

    setupController(controller, model) {
      this._super(...arguments);

      controller.set('profileConfigChangesets', model.record.get('profileConfigs').map(configRole => controller.createProfileConfigChangeset(configRole)));
    },

    actions: {
      willTransition(transition) {
        if (!transition.targetName.startsWith(this.routeName)) {
          let profileConfigChangesets = this.controller.profileConfigChangesets;
          profileConfigChangesets.forEach(changeset => changeset.get('_content').rollbackAttributes());
          profileConfigChangesets.forEach(changeset => changeset.rollback());
        }

        return true;
      }

    }
  });

  _exports.default = _default;
});