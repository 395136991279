define("sirvo-web/models/permiso", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    codigo: (0, _model.attr)('string'),
    nombre: (0, _model.attr)('string'),
    descripcion: (0, _model.attr)('string'),
    activo: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    orden: (0, _model.attr)('number'),
    soloSuperAdmin: (0, _model.attr)('boolean'),
    grupo: (0, _model.belongsTo)('permiso-grupo'),
    activoFormatted: Ember.computed('activo', function () {
      return this.activo ? 'Sí' : 'No';
    })
  });

  _exports.default = _default;
});