define("sirvo-web/routes/admon/accesos/perfiles/index", ["exports", "servir-ember-utilities/routes/abstract-module-index"], function (_exports, _abstractModuleIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import AccessValidator from 'sirvo-web/mixins/access-validator';
  // WARNING: Acceso comentado a fin de configurar instalaciones desde cero.
  var _default = _abstractModuleIndex.default.extend(
  /*AccessValidator, */
  {
    routeAccess: 'ADMON-PERFILES'
  });

  _exports.default = _default;
});