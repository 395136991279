define("sirvo-web/routes/ods/tablas/documentos/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "sirvo-web/mixins/permission-validator", "sirvo-web/validations/ods/documento"], function (_exports, _abstractModuleRecord, _permissionValidator, _documento) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_permissionValidator.default, {
    routePermission: 'OdsCrearDocumento',
    modelName: 'ods/documento',
    indexRoute: 'ods.tablas.documentos',
    relatedModelsToQuery: Ember.computed(function () {
      return [];
    }),
    validations: Ember.computed(function () {
      return { ..._documento.default
      };
    }),

    model() {
      return this._super(...arguments).then(modelData => {
        return Ember.RSVP.all([modelData.record.get('plantilla')]).then(() => modelData);
      });
    },

    setupController(controller, model) {
      this._super(...arguments);

      this.controller.resetProperties();

      if (!model.record.get('plantilla.id')) {
        this.controller.createPlantillaChangeset();
      }
    }

  });

  _exports.default = _default;
});