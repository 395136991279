define("sirvo-web/controllers/admon/procesos/notificaciones/record/configuracion", ["exports", "ember-changeset", "ember-changeset-validations", "sirvo-web/validations/notification-profile"], function (_exports, _emberChangeset, _emberChangesetValidations, _notificationProfile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    user: Ember.computed.readOnly('currentUser.user'),
    availableProfiles: Ember.computed('profiles', function () {
      if (this.user.get('perfil.esSuperAdmin')) {
        return this.profiles;
      }

      return this.profiles.rejectBy('esSuperAdmin');
    }),

    addNewProfileConfigChangeset() {
      let profileConfig = this.store.createRecord('notification-profile', {
        notification: this.record
      });
      this.profileConfigChangesets.pushObject(new _emberChangeset.default(profileConfig, (0, _emberChangesetValidations.default)(_notificationProfile.default), _notificationProfile.default, {
        skipValidate: false
      }));
    },

    actions: {
      profilesUpdated(profileConfigChangeset
      /*, selectedProfiles*/
      ) {
        profileConfigChangeset._deleteKey('_errors', 'profiles');

        profileConfigChangeset.get('profiles').forEach(profile => {
          let repeatedTimes = this.profileConfigChangesets.filter(profileConfigChangesetInner => {
            return profileConfigChangesetInner.get('profiles').includes(profile);
          });

          if (repeatedTimes.length > 1) {
            profileConfigChangeset.addError('profiles', ['Perfil asignado más de una vez.']);
          }
        });
      },

      addNewProfileConfigChangeset() {
        this.addNewProfileConfigChangeset();
      },

      removeProfileConfig(profileConfigChangeset) {
        profileConfigChangeset.rollback();
        profileConfigChangeset.get('_content').destroyRecord();
        this.profileConfigChangesets.removeObject(profileConfigChangeset);
        profileConfigChangeset.destroy();
      }

    }
  });

  _exports.default = _default;
});