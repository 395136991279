define("sirvo-web/models/ods/estado-hechos-plantilla-actividad", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    descripcion: attr('string'),
    plantilla: belongsTo('ods/estado-hechos-plantilla'),
    orden: attr('number'),

    save()
    /*options*/
    {
      return this._super(...arguments).then(result => {
        this.set('hasChanges', false);
        return result;
      });
    }

  });

  _exports.default = _default;
});