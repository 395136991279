define("sirvo-web/models/kar-bodega", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    codbodega: (0, _model.attr)('string'),
    descripcion: (0, _model.attr)('string'),
    noUsaControlInventario: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    metodoValuacion: (0, _model.attr)('number', {
      defaultValue: 1
    }),
    esServicio: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    esMateriaPrima: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    esMaterialEmpaque: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    esProductoTerminado: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    esTransitoria: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    observaciones: (0, _model.attr)('string'),
    esStock: (0, _model.attr)('boolean'),
    creusr: (0, _model.attr)('string'),
    fecusr: (0, _model.attr)('date'),
    codusr: (0, _model.attr)('string'),
    // Relationships
    empresa: (0, _model.belongsTo)('empresa'),
    ubicacion: (0, _model.belongsTo)('kar-bodega-ubicacion'),
    codigoDescripcion: Ember.computed('codbodega', 'descripcion', function () {
      return `${this.codbodega} - ${this.descripcion}`;
    })
  });

  _exports.default = _default;
});