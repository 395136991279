define("sirvo-web/templates/components/files/display-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ehon2HZl",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"tags\"],[8],[0,\"\\n\"],[4,\"if\",[[22,[\"createdAt\"]]],null,{\"statements\":[[0,\"    \"],[6,\"span\"],[10,\"class\",\"label-holder\"],[8],[0,\"\\n      \"],[6,\"span\"],[10,\"class\",\"label label-info arrowed\"],[8],[0,\"\\n        \"],[1,[26,\"moment-format\",[[22,[\"createdAt\"]],\"YYYY-MM-DD\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[22,[\"fileSize\"]]],null,{\"statements\":[[0,\"    \"],[6,\"span\"],[10,\"class\",\"label-holder\"],[8],[0,\"\\n      \"],[6,\"span\"],[10,\"class\",\"label label-success arrowed\"],[8],[0,\"\\n        \"],[1,[20,\"fileSize\"],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/components/files/display-tags.hbs"
    }
  });

  _exports.default = _default;
});