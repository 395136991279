define("sirvo-web/templates/popups/simple-confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1AeU2sQQ",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[4,\"bs-modal\",null,[[\"onHidden\",\"onSubmit\"],[[26,\"action\",[[21,0,[]],\"onCancel\"],null],[26,\"action\",[[21,0,[]],\"onSubmit\"],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[21,0,[\"title\"]]],null,{\"statements\":[[0,\"    \"],[4,\"component\",[[21,1,[\"header\"]]],null,{\"statements\":[[0,\"\\n      \"],[6,\"h5\"],[10,\"class\",\"modal-title\"],[8],[0,\"\\n        \"],[1,[21,0,[\"title\"]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[21,0,[\"message\"]]],null,{\"statements\":[[0,\"    \"],[4,\"component\",[[21,1,[\"body\"]]],null,{\"statements\":[[0,\"\\n      \"],[6,\"div\"],[10,\"class\",\"align-center\"],[8],[1,[21,0,[\"message\"]],true],[9],[0,\"\\n    \"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[4,\"component\",[[21,1,[\"footer\"]]],null,{\"statements\":[[0,\"\\n    \"],[4,\"bs-button\",null,[[\"onClick\",\"type\",\"size\"],[[26,\"queue\",[[26,\"action\",[[21,0,[]],[21,1,[\"close\"]]],null],[26,\"action\",[[21,0,[]],[21,1,[\"submit\"]]],null]],null],\"success\",\"xs\"]],{\"statements\":[[0,\"\\n      Si\\n    \"]],\"parameters\":[]},null],[0,\"\\n\\n    \"],[4,\"bs-button\",null,[[\"onClick\",\"type\",\"size\"],[[26,\"action\",[[21,0,[]],[21,1,[\"close\"]]],null],\"danger\",\"xs\"]],{\"statements\":[[0,\"\\n      No\\n    \"]],\"parameters\":[]},null],[0,\"\\n  \"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/popups/simple-confirm.hbs"
    }
  });

  _exports.default = _default;
});