define("sirvo-web/models/kar-movdet", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    empresa: (0, _model.belongsTo)('empresa'),
    estado: (0, _model.attr)('string'),
    // keymov: attr('string'),
    movimiento: (0, _model.belongsTo)('kar-mov'),
    keymovdet: (0, _model.attr)('string'),
    // keyref: attr('string'),
    // keyaux: attr('string'),
    ocaOrdenId: (0, _model.attr)('number'),
    ocaOrdenCodigo: (0, _model.attr)('string'),
    linea: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    bodega: (0, _model.belongsTo)('kar-bodega'),
    bodegaDestino: (0, _model.belongsTo)('kar-bodega'),
    ubicacionesDestino: (0, _model.attr)('string'),
    articulo: (0, _model.belongsTo)('kar-articulo'),
    unidadMedida: (0, _model.belongsTo)('kar-unidad-medida'),
    cantidad: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    cantidadBase: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    precioUnitario: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    subtotal: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    valorNeto: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    valorNetoMe: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    // Not persisted
    porcentajeIva: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    iva: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    total: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    tasac: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    fechaVencimiento: (0, _model.attr)('date'),
    lote: (0, _model.attr)('string'),
    observa: (0, _model.attr)('string'),
    cuenta: (0, _model.belongsTo)('emp-nomenclatura'),
    centroCosto: (0, _model.belongsTo)('emp-cc'),
    centroIntegracion: (0, _model.belongsTo)('emp-ci'),
    ubicaciones: (0, _model.hasMany)('kar-mov-det-ubicacion'),

    save(options) {
      if (this.get('isDeleted')) {
        return this._super(options);
      }

      let unsavedUbicaciones = this.ubicaciones.filter(ubicacion => ubicacion.get('isNew') || ubicacion.get('isDirty'));
      return this._internalModel.save(options).then(() => Ember.RSVP.all(unsavedUbicaciones.map(ubicacion => ubicacion.save()))).then(() => {
        return this;
      });
    }

  });

  _exports.default = _default;
});