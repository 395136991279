define("sirvo-web/controllers/inventario/reportes/kardex", ["exports", "sirvo-web/mixins/reports-fechas-filters", "moment"], function (_exports, _reportsFechasFilters, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_reportsFechasFilters.default, {
    request: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),
    popupOpen: false,
    kardexData: null,

    resetProperties() {
      this.setProperties({
        popupOpen: false,
        kardexData: []
      });
    },

    getSerializedFilters(asJson = false) {
      let fechaInicio = this.changeset.get('fechaInicio');
      let fechaFin = this.changeset.get('fechaFin');
      let filters = {
        articulos: this.changeset.get('articulos').map(p => p.id),
        agruparPorBodega: this.changeset.get('agruparPorBodega') ? 1 : 0,
        bodegas: this.changeset.get('bodegas').map(p => p.id),
        tiposArticulos: this.changeset.get('tiposArticulos').map(p => p.id),
        fechaInicio: fechaInicio ? (0, _moment.default)(fechaInicio).format() : null,
        fechaFin: fechaFin ? (0, _moment.default)(fechaFin).format() : null,
        incluirInactivos: this.changeset.get('incluirInactivos') ? 1 : 0,
        asJson: asJson ? 1 : 0
      };
      return filters;
    },

    actions: {
      searchArticulo(text) {
        if (!text || text.length < 3) {
          return;
        }

        if (this.articleQuery) {
          Ember.run.cancel(this.articleQuery);
        }

        this.articleQuery = Ember.run.later(() => {
          this.set('listaArticulos', this.store.query('kar-articulo', {
            filter: {
              empresa: this.company.id,
              descripcion: {
                OR: text
              },
              codarticulo: {
                OR: text
              }
            }
          }));
        }, 500);
      },

      updateAgruparPorBodega(changeset, propertyName, value) {
        changeset.set(propertyName, value);

        if (!changeset.get('agruparPorBodega')) {
          changeset.set('bodegas', []);
        }
      },

      generarReporte(asJson = false) {
        this.loader.setIsLoading();
        return this.changeset.validate().then(() => {
          if (!this.changeset.get('isValid')) {
            this.loader.setNotLoading();
            this.toast.error('Debe seleccionar fecha inicio y fecha fin.');
            return;
          }

          const filters = this.getSerializedFilters(asJson);
          this.loader.setLoading(true);
          this.toast.info('Generando reporte');
          return this.request.get('kar/reporte-kardex', filters).then(response => {
            if (asJson) {
              this.toast.success('Kardex generado correctamente.');
              this.set('kardexData', response);
              this.set('popupOpen', true);
            } else {
              this.toast.success('Reporte generado exitosamente');
            }
          }).finally(() => this.loader.setLoading(false));
        });
      },

      onClosePopup() {
        this.resetProperties();
      }

    }
  });

  _exports.default = _default;
});