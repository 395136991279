define("sirvo-web/instance-initializers/ember-utilities-initializer", ["exports", "sirvo-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;
  const DEFAULT_CONFIG = {
    modeloCofiguracionModel: 'modelo-configuracion'
  };

  function initialize(appInstance) {
    let addonConfig = Ember.merge(DEFAULT_CONFIG, _environment.default['servir-ember-utilities']);
    let configService = appInstance.lookup('service:servir-ember-utilities/config');
    Object.keys(addonConfig).forEach(key => {
      Ember.set(configService, key, addonConfig[key]);
    });
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});