define("sirvo-web/routes/ods/tablas/productos/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "sirvo-web/mixins/permission-validator", "sirvo-web/validations/ods/producto"], function (_exports, _abstractModuleRecord, _permissionValidator, _producto) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_permissionValidator.default, {
    routePermission: 'OdsCrearProducto',
    modelName: 'ods/producto',
    indexRoute: 'ods.tablas.productos',
    relatedModelsToQuery: Ember.computed('isNew', function () {
      let relatedModels = [{
        name: 'tiposProductos',
        modelName: 'ods/tipo-producto'
      }, {
        name: 'operaciones',
        modelName: 'ods/operacion'
      }];
      return relatedModels;
    }),
    validations: Ember.computed(function () {
      return { ..._producto.default
      };
    })
  });

  _exports.default = _default;
});