define("sirvo-web/controllers/inventario/tablas/bodegas/record", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    indexCtrl: Ember.inject.controller('inventario.tablas.bodegas'),
    indexRoute: null,

    postSave()
    /*record*/
    {
      this.indexCtrl.reloadTable();
      return this.saveUserBodegas();
    },

    saveUserBodegas() {
      let currentUserIds = this.userBodegas.mapBy('usuario.id');
      let updatedUserIds = this.changeset.get('usuarios').mapBy('id');
      let removedRecords = this.userBodegas.filter(userBodega => {
        return !updatedUserIds.includes(userBodega.get('usuario.id'));
      });
      let addedRecords = this.changeset.get('usuarios').filter(user => !currentUserIds.includes(user.get('id'))).map(user => {
        return this.store.createRecord('vf-user-kar-bodega', {
          bodega: this.record,
          usuario: user
        });
      });
      return Ember.RSVP.all([...removedRecords.map(record => record.destroyRecord()), ...addedRecords.map(record => record.save())]);
    },

    actions: {
      onClose() {
        this.record.rollbackAttributes();
        this.transitionToRoute(this.get('indexRoute'));
      }

    }
  });

  _exports.default = _default;
});