define("sirvo-web/routes/inventario/reportes/kardex", ["exports", "servir-ember-utilities/routes/abstract-module-index", "sirvo-web/mixins/access-validator", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators", "moment"], function (_exports, _abstractModuleIndex, _accessValidator, _emberChangeset, _emberChangesetValidations, _validators, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend(_accessValidator.default, {
    routeAccess: 'KAR-REPORTE-KARDEX',
    company: Ember.computed.readOnly('currentCompany.company'),
    validations: Ember.computed(function () {
      return {
        fechaInicio: (0, _validators.validatePresence)({
          presence: true
        }),
        fechaFin: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),

    model()
    /*params, transition*/
    {
      this.loader.setIsLoading();
      let promises = {
        bodegas: this.store.query('kar-bodega', {
          filter: {
            empresa: this.company.id
          }
        }),
        tiposArticulos: this.store.query('kar-tipo-articulo', {
          filter: {
            empresa: this.company.id
          }
        })
      };
      return Ember.RSVP.hash(promises).then(data => {
        let filterModel = this.store.createRecord('reporte-kardex', {
          fechaInicio: (0, _moment.default)().startOf('month').toDate(),
          fechaFin: (0, _moment.default)().endOf('month').toDate()
        });
        return Ember.merge(data, {
          filterModel
        });
      }).finally(() => this.loader.setNotLoading());
    },

    setupController(controller, model) {
      this._super(controller, model);

      let changeset = new _emberChangeset.default(model.filterModel, (0, _emberChangesetValidations.default)(this.get('validations')), this.get('validations'));
      controller.set('changeset', changeset);
      controller.resetProperties();
    }

  });

  _exports.default = _default;
});