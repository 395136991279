define("sirvo-web/models/com/modelo-configuracion", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    nombreModelo: attr('string'),
    usaCampoAutomatico: attr('boolean'),
    nombreCampoAutomatico: attr('string'),
    formatoCampoAutomatico: attr('string'),
    usaCorrelativoAutoincrementable: attr('boolean'),
    numeroCorrelativoActual: attr('number'),
    objetoProveedorCorrelativo: attr('string'),
    nombreCampoCorrelativo: attr('string'),
    usaCampoAutomaticoFormatted: Ember.computed(function () {
      return this.usaCampoAutomatico ? 'SI' : 'NO';
    })
  });

  _exports.default = _default;
});