define("sirvo-web/routes/ods/servicios/ordenes-servicio/record/documentos/inspeccion-mangueras", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    indexRoute: 'ods.servicios.ordenes-servicio.record.documentos',

    model(params) {
      if (params.orden_servicio_documento_id === 'new') {
        this.toast.error('Debe guardar primero el registro.');
        return this.transitionTo(this.indexRoute);
      } // NOTE: En principio, esta data ya se cargó en el indexRoute.


      let ordenDocumento = this.store.peekRecord('ods/orden-servicio-documento', params.orden_servicio_documento_id);

      if (!ordenDocumento || !ordenDocumento.get('servicioDocumento.documento.id')) {
        this.toast.error('Data no cargada previamente.');
        return this.transitionTo(this.indexRoute);
      }

      let promises = {
        ordenDocumento,
        inmersiones: this.store.query('ods/buceo-inmersion', {
          filter: {
            ordenDocumento: params.orden_servicio_documento_id
          },
          sort: 'numero',
          include: 'lineas'
        }),
        plantillas: this.store.query('ods/inspeccion-mangueras-plantilla', {
          filter: {
            documento: ordenDocumento.get('servicioDocumento.documento.id')
          }
        })
      };
      return Ember.RSVP.hash(promises);
    },

    setupController(controller, model) {
      this._super(...arguments);

      controller.resetProperties();
      let inmersionesChangesets = model.inmersiones.map(informeBuceo => {
        return controller.createBuceoInmersionChangeset(informeBuceo);
      });
      controller.setProperties({
        indexRoute: this.indexRoute,
        ordenDocumento: model.ordenDocumento,
        inmersiones: inmersionesChangesets,
        plantillas: model.plantillas,
        selectedTemplate: model.plantillas.get('firstObject')
      });
    }

  });

  _exports.default = _default;
});