define("sirvo-web/validations/notification-profile", ["exports", "ember-changeset-validations/validators", "servir-ember-utilities/validators/list-of-emails"], function (_exports, _validators, _listOfEmails) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    notification: (0, _validators.validatePresence)(true),
    recipients: (0, _listOfEmails.default)({
      allowBlank: true
    })
  };
  _exports.default = _default;
});