define("sirvo-web/templates/components/tables/cell/toggle-attribute", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MRmm7+sy",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"text-center\"],[8],[0,\"\\n  \"],[6,\"input\"],[11,\"checked\",[21,0,[\"value\"]],null],[11,\"onclick\",[26,\"action\",[[21,0,[]],\"toggleObjectProperty\"],null],null],[10,\"type\",\"checkbox\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/components/tables/cell/toggle-attribute.hbs"
    }
  });

  _exports.default = _default;
});