define("sirvo-web/routes/inventario/tablas/tipos-movimientos/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "servir-ember-utilities/mixins/route/has-tabs", "sirvo-web/mixins/permission-validator", "ember-changeset-validations/validators"], function (_exports, _abstractModuleRecord, _hasTabs, _permissionValidator, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_hasTabs.default, _permissionValidator.default, {
    routePermission: 'KarEditarTipoMovimiento',
    modelName: 'kar-tipomov',
    indexRoute: 'inventario.tablas.tipos-movimientos',
    defaultTabSubRoute: 'generales',
    validations: Ember.computed(function () {
      return {
        tipomov: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),
    actions: {
      willTransition() {
        return false;
      }

    }
  });

  _exports.default = _default;
});