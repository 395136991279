define("sirvo-web/controllers/emision-contrasenas/new", ["exports", "sirvo-web/utils/date", "moment"], function (_exports, _date, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    company: Ember.computed.readOnly('currentCompany.company'),
    request: Ember.inject.service(),
    toast: Ember.inject.service(),
    contrasena: null,
    isPrint: false,
    llave: null,
    pageTitle: Ember.computed('model.contrasenaEdit.keytra', function () {
      if (this.get('model.contrasenaEdit.keytra')) {
        return `Editar contraseña ${this.get('model.contrasenaEdit.keytra')}`;
      } else {
        return 'Crear nueva contraseña';
      }
    }),
    selectedYear: Ember.computed(function () {
      return new Date().getFullYear();
    }),
    years: (0, _date.lastYears)(),
    fecha: null,
    selectedMonth: Ember.computed(function () {
      return String(new Date().getMonth() + 1).padStart(2, '0');
    }),
    selectedOptionOwnSistemas: Ember.computed('dropdownOptionsOwnSistemas.[]', function () {
      let options = this.get('dropdownOptionsOwnSistemas');

      if (options && options.get('length') > 0) {
        return options.objectAt(0);
      }

      return null;
    }),
    selectedOptionTipoDocumentos: Ember.computed('dropdownOptionsTipoDocumentos.[]', function () {
      let options = this.get('dropdownOptionsTipoDocumentos');

      if (options && options.get('length') > 0) {
        return options.objectAt(0);
      }

      return null;
    }),
    selectedOptionDocumentoBase: Ember.computed('dropdownOptionsDocumentoBase.[]', function () {
      let options = this.get('dropdownOptionsDocumentoBase');

      if (options && options.get('length') > 0) {
        return options.objectAt(0);
      }

      return null;
    }),
    selectedOptionDocumentoSerie: Ember.computed('dropdownOptionsDocumentoSerie.[]', function () {
      let options = this.get('dropdownOptionsDocumentoSerie');
      let contrasena = this.get('contrasena');

      if (options && options.get('length') > 0) {
        if (contrasena && contrasena.serie !== null) {
          let serie = this.get('contrasena.serie');
          let selectedOption = options.findBy('serie', serie);
          return selectedOption;
        }

        return options.objectAt(0);
      }

      return null;
    }),
    selectedDirectorio: null,
    selectedOptionClasificacion: null,
    proximoPago: null,
    dropdownOptionsOwnSistemas: null,
    dropdownOptionsClasificaciones: null,
    dropdownOptionsTipoDocumentos: null,
    dropdownOptionsDocumentoBase: null,
    dropdownOptionsDocumentoSerie: null,
    dropdownOptionsDirectoriosContrasena: null,
    observaciones: '',
    data: null,
    actions: {
      back() {
        this.transitionToRoute('emision-contrasenas.index');
      },

      updateClasificacion(selectedItem) {
        let tipo = 'PROVEEDORES';
        let codDirectorio = selectedItem.codigo;
        this.request.get('gru-directorio-clasifica-by-cod-grupo', {
          tipo,
          codDirectorio
        }).then(response => {
          this.set('dropdownOptionsClasificaciones', response.data);
          let options = this.get('dropdownOptionsClasificaciones');
          this.set('selectedOptionClasificacion', options.objectAt(0));
        }).catch(error => {
          this.toast.error('Error al cargar la clasificacion. ' + error);
        });
      },

      updateFechaPago(selectedItem) {
        let diasCrCxp = selectedItem.diasCrCxp;
        this.set('selectedDirectorio', selectedItem);
        this.request.get('contrasenas/fecha-pago', {
          diasCrCxp
        }).then(response => {
          let date = response.data[0].proximoPago;
          let dateMoment = (0, _moment.default)(date, 'DD-MM-YYYY').toDate();
          let proximoPago = (0, _moment.default)(dateMoment).format('YYYY-MM-DD');
          this.set('proximoPago', proximoPago);
        }).catch(error => {
          this.toast.error('Error al cargar la clasificacion. ' + error);
        });
      },

      facturas() {
        let id = this.get('contrasena.id');
        this.transitionToRoute('emision-contrasenas.detalle', id);
      },

      refreshData() {
        return;
      },

      async save() {
        this.loader.setLoading(true);

        if (this.selectedDirectorio) {
          try {
            let codsistema = this.selectedOptionOwnSistemas.codsistema;
            let codempQuedan = this.company.get('codempresa');
            let codtipo = this.selectedOptionDocumentoSerie.codtipo;
            let fecha = (0, _moment.default)(this.fecha, 'YYYY-MM-DD').toDate();
            let yearmes = this.selectedYear + this.selectedMonth;
            let coddirectorio = this.selectedDirectorio.codigo;
            let fechaPago = (0, _moment.default)(this.proximoPago, 'YYYY-MM-DD').toDate();
            let diascredito = this.selectedDirectorio.diasCrCxp;
            let codempresa = this.company.get('codempresa');
            let codgrupo = '';
            let observaciones = this.get('observaciones');
            let codtipodoctos = this.selectedOptionDocumentoSerie.codtipodoctos;
            let codclasifica = this.selectedOptionClasificacion.codclasifica;
            let serie = this.selectedOptionDocumentoSerie.serie;
            let numero = 0;
            let numerodoc = 0;
            let anulado = 0;
            let userAnulado = ' ';
            let motivoAnulado = ' ';
            let keytra = '';
            let fechaAnulado = '';
            let facturaSinOrdenCompra = 0;

            if (this.contrasena) {
              let contrasenaEdit = await this.store.findRecord('que/contrasena', this.contrasena.id);
              contrasenaEdit.setProperties({
                fecha,
                coddirectorio,
                fechaPago,
                diascredito,
                observaciones,
                codclasifica
              });
              await contrasenaEdit.save().then(response => {
                let id = response.get('id');
                this.toast.success('Registro editado con éxito.');
                this.loader.setLoading(false);
                this.transitionToRoute('emision-contrasenas.detalle', id);
              }).catch(error => {
                this.toast.error('Error al guardar la contraseña. ' + error);
              }).finally(() => this.loader.setLoading(false));
              return;
            }

            let newContrasena = await this.store.createRecord('que/contrasena', {
              codsistema,
              codempQuedan,
              codtipo,
              fecha,
              yearmes,
              coddirectorio,
              codgrupo,
              motivoAnulado,
              keytra,
              fechaAnulado,
              fechaPago,
              userAnulado,
              diascredito,
              codempresa,
              observaciones,
              anulado,
              numerodoc,
              codtipodoctos,
              codclasifica,
              serie,
              numero,
              facturaSinOrdenCompra
            });
            await newContrasena.save().then(response => {
              let id = response.get('id');
              this.transitionToRoute('emision-contrasenas.detalle', id);
            }).catch(error => {
              this.toast.error('Error al guardar la contraseña. ' + error);
            }).finally(() => this.loader.setLoading(false));
          } catch (error) {
            this.toast.error('Error al guardar la contraseña. ' + error);
            this.loader.setLoading(false);
          }
        } else {
          this.toast.error('El campo directorio es requerido.');
          this.loader.setLoading(false);
          return;
        }
      },

      impresionDocumentoModal() {
        this.set('isPrint', true);
      },

      ocultarModal() {
        this.set('isModalVisible', false);
      }

    },

    searchDirectorios(term, dropdown) {
      return dropdown.options.filter(item => {
        return item.codigo.toLowerCase().includes(term.toLowerCase()) || item.razonsocial.toLowerCase().includes(term.toLowerCase());
      });
    }

  });

  _exports.default = _default;
});