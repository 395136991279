define("sirvo-web/models/sat-documento-fel", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    fechaHoraEmision: (0, _model.attr)('string'),
    felTipoDte: (0, _model.attr)('string'),
    serie: (0, _model.attr)('string'),
    numero: (0, _model.attr)('number'),
    nitEmisor: (0, _model.attr)('string'),
    nombreEmisor: (0, _model.attr)('string'),
    codigoEstablecimiento: (0, _model.attr)('string'),
    nombreEstablecimiento: (0, _model.attr)('string'),
    idReceptor: (0, _model.attr)('string'),
    nombreReceptor: (0, _model.attr)('string'),
    codigoMoneda: (0, _model.attr)('string'),
    valorTotal: (0, _model.attr)('number'),
    felEstado: (0, _model.attr)('string'),
    anulado: (0, _model.attr)('number'),
    fechaHoraAnulacion: (0, _model.attr)('string'),
    valorImpuestoIva: (0, _model.attr)('number'),
    valorImpuestoPetroleo: (0, _model.attr)('number'),
    valorImpuestoTurismo: (0, _model.attr)('number'),
    valorImpuestoPasajes: (0, _model.attr)('number'),
    valorImpuestoTimbrePrensa: (0, _model.attr)('number'),
    valorImpuestoBomberos: (0, _model.attr)('number'),
    valorImpuestoTasaMunicipal: (0, _model.attr)('number'),
    valorImpuestoBebidasAlcoholicas: (0, _model.attr)('number'),
    valorImpuestoTabaco: (0, _model.attr)('number'),
    valorImpuestoCemento: (0, _model.attr)('number'),
    valorImpuestoBebidasNoAlcoholicas: (0, _model.attr)('number'),
    valorImpuestoTarifaPortuaria: (0, _model.attr)('number')
  });

  _exports.default = _default;
});