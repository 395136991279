define("sirvo-web/controllers/inventario/movimientos/cortesias/despacho-cortesias/detalle", ["exports", "sirvo-web/utils/format"], function (_exports, _format) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    indexCtrl: Ember.inject.controller('inventario.movimientos.cortesias.despacho-cortesias'),
    company: Ember.computed.readOnly('currentCompany.company'),
    parentRoute: 'inventario.movimientos.cortesias.despacho-cortesias',
    kardex: Ember.inject.service(),
    request: Ember.inject.service(),
    indexRoute: 'inventario.movimientos.cortesias',
    articulos: null,
    unidadesMedida: null,

    updateExistencia(changeset) {
      let uMedidaFactor = changeset.get('unidadMedida.factor') || 1;
      let totalNeto = (0, _format.round)(changeset.get('cantidad') * changeset.get('precioUnitario') || 0);
      let cantidadBaseFactor = -1;
      let existenciaBase = changeset.get('existenciaBase');
      let existencia = existenciaBase * uMedidaFactor;
      Ember.setProperties(changeset, {
        existencia,
        existenciaBase,
        total: totalNeto,
        cantidadBase: changeset.get('cantidad') * uMedidaFactor * cantidadBaseFactor
      });
    },

    actions: {
      onClose() {
        this.transitionToRoute(this.parentRoute);
      },

      articuloUpdated() {
        this.loader.setIsLoading();
        this.store.query('kar-tabla-medida', {
          filter: {
            articulo: this.detalleChangeset.get('articulo.id'),
            venta: 1
          },
          include: 'unidadMedida'
        }).then(articuloUMedidas => {
          let unidadesMedida = articuloUMedidas.mapBy('unidadMedida');
          return this.request.get(`kar-articulos/${this.detalleChangeset.get('articulo.id')}/saldo`, {
            bodegaId: this.detalleChangeset.get('bodega.id')
          }).then(articuloSaldo => {
            let existencia = articuloSaldo.existencia;
            let existenciaBase = articuloSaldo.existencia;

            if (unidadesMedida.length === 1) {
              let unidadMedida = unidadesMedida.get('firstObject');
              let uMedidaFactor = unidadMedida.get('factor') || 1;
              existencia = existenciaBase * uMedidaFactor;
              Ember.setProperties(this.detalleChangeset, {
                unidadMedida: unidadMedida
              });
            }

            this.setProperties({
              unidadesMedida
            });
            Ember.setProperties(this.detalleChangeset, {
              existencia,
              existenciaBase,
              precioUnitario: articuloSaldo.valor_promedio,
              unidadMedidaBase: this.detalleChangeset.get('articulo.codmedidaBase')
            });
          }).finally(() => this.loader.setNotLoading());
        });
      },

      cantidadUpdated(changeset, property, value) {
        changeset.set(property, value || 0);
        this.updateExistencia(changeset);
      },

      unidadMedidaUpdated() {
        this.updateExistencia(this.detalleChangeset);
      },

      addDetail() {
        this.loader.setIsLoading();
        return this.detalleChangeset.validate().then(() => {
          if (!this.detalleChangeset.get('isValid')) {
            this.loader.setNotLoading();
            this.toast.error('Existe un error en el formulario.');
            return;
          }

          if (this.detalleChangeset.get('existenciaBase') - Math.abs(this.detalleChangeset.get('cantidadBase')) < 0) {
            this.loader.setNotLoading();
            this.toast.error('No hay suficiente existencia del artículo seleccionado.');
            return;
          }

          this.changeset.get('detalles').pushObject(this.detalleChangeset);
          this.loader.setNotLoading();
          this.transitionToRoute(this.parentRoute);
        });
      }

    }
  });

  _exports.default = _default;
});