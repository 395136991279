define("sirvo-web/controllers/admon/accesos/permisos/index", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "sirvo-web/models/permiso", "sirvo-web/models/permiso-grupo"], function (_exports, _abstractModuleIndex, _permiso, _permisoGrupo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    company: Ember.computed.readOnly('currentCompany.company'),
    editRoute: 'admon.accesos.permisos.record',
    groupEditRoute: 'admon.accesos.permisos.grupo',
    permisoModelClass: _permiso.default,
    permisoGrupoModelClass: _permisoGrupo.default,
    groupsColumns: Ember.computed(function () {
      return Ember.A([{
        label: 'Código',
        valuePath: 'codigo',
        filtrable: true,
        filterName: 'codigo',
        sortable: false,
        width: '150px'
      }, {
        label: 'Nombre',
        valuePath: 'nombre',
        filtrable: true,
        filterName: 'nombre',
        sortable: false,
        width: '250px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: {
          editGroup: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.editGroup.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }]);
    }),
    columns: Ember.computed(function () {
      return Ember.A([{
        label: 'Código',
        valuePath: 'codigo',
        filtrable: true,
        filterName: 'codigo',
        sortable: false,
        width: '150px'
      }, {
        label: 'Nombre',
        valuePath: 'nombre',
        filtrable: true,
        filterName: 'nombre',
        sortable: false,
        width: '250px'
      }, {
        label: 'Grupo',
        valuePath: 'grupo.nombre',
        filtrable: true,
        filterName: 'grupo.nombre',
        sortable: false,
        width: '250px'
      }, {
        label: 'Orden',
        valuePath: 'orden',
        sortable: false,
        width: '90px'
      }, {
        label: 'Activo',
        valuePath: 'activoFormatted',
        filtrable: true,
        filterName: 'activo',
        sortable: false,
        width: '100px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }]);
    }),
    actions: {
      addRecord() {
        this.transitionToRoute(this.editRoute, 'new');
      },

      addGroup() {
        this.transitionToRoute(this.groupEditRoute, 'new');
      },

      editGroup(item) {
        this.transitionToRoute(this.groupEditRoute, item.get('id'));
      }

    }
  });

  _exports.default = _default;
});