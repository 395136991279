define("sirvo-web/routes/inventario/movimientos/ingresos/existencia-inicial", ["exports", "servir-ember-utilities/routes/abstract-module-record", "servir-ember-utilities/mixins/route/has-tabs", "sirvo-web/mixins/permission-validator", "sirvo-web/validations/kar-mov"], function (_exports, _abstractModuleRecord, _hasTabs, _permissionValidator, _karMov) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_hasTabs.default, _permissionValidator.default, {
    routePermission: 'KarRegistrarExistenciaInicial',
    company: Ember.computed.readOnly('currentCompany.company'),
    fechaCierre: Ember.computed.readOnly('currentCompany.lastCierre.fecha'),
    modelName: 'kar-mov',
    indexRoute: 'inventario.movimientos.ingresos',
    defaultTabSubRoute: 'movimiento',
    validations: _karMov.default,
    relatedModelsToQuery: Ember.computed(function () {
      return [{
        name: 'tiposMovimientos',
        modelName: 'kar-tipomov',
        queryParams: {
          filter: {
            tipomov: 'EXISTENCIA_INICIAL',
            habilitar: 1
          }
        }
      }, {
        name: 'tiposDocumentos',
        modelName: 'kar-doc',
        queryParams: {
          filter: {
            empresa: this.company.get('id'),
            codsistema: 'KAR',
            deha: 'D',
            'tipoMovimiento.tipomov': 'EXISTENCIA_INICIAL'
          }
        }
      }];
    }),

    createRecordInstance() {
      return this.store.createRecord(this.modelName, {
        empresa: this.company,
        fecha: new Date()
      });
    },

    setupController(controller, model) {
      this._super(...arguments);

      let tipoMovimiento = model.tiposMovimientos.findBy('tipomov', 'EXISTENCIA_INICIAL');
      let tipoDocumento = model.tiposDocumentos.get('firstObject');

      if (!tipoMovimiento) {
        this.toast.error('No se encontró el tipo de movimiento de "EXISTENCIA_INICIAL".');
        return this.transitionTo(this.indexRoute);
      }

      if (!tipoDocumento) {
        this.toast.error('No se encontró un tipo de documento para el movimiento.');
        return this.transitionTo(this.indexRoute);
      }

      Ember.setProperties(model.record, {
        tipoMovimiento,
        tipoDocumento,
        deha: tipoDocumento.get('deha'),
        usacnt: tipoDocumento.get('usaCnt')
      });

      if (this.fechaCierre >= model.record.get('fecha')) {
        this.toast.warning('Registro solo de lectura por cierre de inventario.');
      }
    },

    actions: {
      willTransition() {
        return false;
      }

    }
  });

  _exports.default = _default;
});