define("sirvo-web/components/tables/cell/kar-movdet-post-existencia", ["exports", "sirvo-web/templates/components/tables/cell/promise-value"], function (_exports, _promiseValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Este componente se usa en la tabla de artículos y su objetivo es mostrar la
   * existencia del artículo en la primera bodega asignada.
   */
  var _default = Ember.Component.extend({
    layout: _promiseValue.default,
    table: null,
    row: null,
    column: null,
    request: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.set('value', this.getValuePromise());
    },

    getValuePromise() {
      let movDetalle = this.get('row.content');
      return this.request.get(`kar-movdets/${movDetalle.id}/saldo`).then(saldo => saldo.existencia).catch(() => 'Error');
    }

  });

  _exports.default = _default;
});