define("sirvo-web/components/pell-editor", ["exports", "ember-pell/components/pell-editor"], function (_exports, _pellEditor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _pellEditor.default;
    }
  });
});