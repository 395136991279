define("sirvo-web/routes/ods/tablas/tipos-buques/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "sirvo-web/mixins/permission-validator", "sirvo-web/validations/ods/tipo-buque"], function (_exports, _abstractModuleRecord, _permissionValidator, _tipoBuque) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_permissionValidator.default, {
    routePermission: 'OdsCrearTipoBuque',
    modelName: 'ods/tipo-buque',
    indexRoute: 'ods.tablas.tipos-buques',
    relatedModelsToQuery: Ember.computed(function () {
      return [];
    }),
    validations: Ember.computed(function () {
      return { ..._tipoBuque.default
      };
    })
  });

  _exports.default = _default;
});