define("sirvo-web/routes/inventario/procesos/inventario-fisico/articulos/carga-ajustes", ["exports", "sirvo-web/mixins/permission-validator"], function (_exports, _permissionValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_permissionValidator.default, {
    routePermission: 'KarCargarInventarioFisicoAjustes',

    setupController() {
      this._super(...arguments);

      this.controller.newFileChangeset();
      this.controller.set('data', []);
    }

  });

  _exports.default = _default;
});