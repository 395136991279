define("sirvo-web/routes/admon/grupos/directorios-clasificaciones", ["exports", "sirvo-web/mixins/access-validator"], function (_exports, _accessValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_accessValidator.default, {
    loader: Ember.inject.service(),
    routeAccess: 'ADMON-DIR-CLASIFICACIONES',

    model() {
      this.loader.setLoading(true);
      return Ember.RSVP.hash({
        clasificaciones: this.store.findAll('gru-clasificacion')
      }).finally(() => this.loader.setLoading(false));
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.setProperties({
        selectedClasificacion: null
      });
    }

  });

  _exports.default = _default;
});