define("sirvo-web/routes/inventario/movimientos/salidas/traslado/registro", ["exports", "servir-ember-utilities/routes/abstract-module-record", "sirvo-web/validations/kar-movdet"], function (_exports, _abstractModuleRecord, _karMovdet) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    kardex: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),
    fechaCierre: Ember.computed.readOnly('currentCompany.lastCierre.fecha'),
    modelName: 'kar-movdet',
    parentRoute: 'inventario.movimientos.salidas.traslado',
    indexRoute: 'inventario.movimientos.salidas.traslado.detalles',
    routeRecordIdentifierDynamicSegment: 'detalle_id',
    validations: _karMovdet.trasladoValidations,
    relatedModelsToQuery: Ember.computed('isNew', function () {
      let modelsToQuery = [{
        name: 'tiposUbicaciones',
        modelName: 'kar-articulo-tipo-ubicacion',
        queryParams: {
          filter: {
            empresa: this.company.id
          }
        }
      }];

      if (!this.isNew) {
        modelsToQuery.push({
          name: 'ubicaciones',
          modelName: 'kar-mov-det-ubicacion',
          queryParams: {
            filter: {
              movimientoDetalle: this.recordId
            },
            include: 'articuloUbicacion'
          }
        });
      }

      modelsToQuery.push({
        name: 'ubicacionesDestino',
        presetValue: Ember.A([])
      });
      return modelsToQuery;
    }),

    model() {
      let parentData = this.modelFor(this.parentRoute);

      if (!parentData.record.get('id')) {
        this.toast.error('Guarde el movimiento para poder registrar un detalle.');
        return this.transitionTo(`${this.parentRoute}.movimiento`);
      }

      return this._super(...arguments).then(modelData => {
        let movDet = modelData.record;

        if (movDet.get('isNew')) {
          if (this.fechaCierre >= parentData.record.get('fecha')) {
            this.toast.error('Registro solo de lectura por cierre de inventario.');
            return this.transitionTo(this.indexRoute);
          }

          movDet.set('movimiento', parentData.record);
        }

        let relationships = [movDet.get('articulo'), movDet.get('bodega'), movDet.get('unidadMedida'), movDet.get('cuenta'), movDet.get('centroCosto'), movDet.get('centroIntegracion')];
        return Ember.RSVP.all(relationships).then(() => modelData);
      }).then(modelData => {
        let movDet = modelData.record;

        if (!movDet.get('keymovdet')) {
          return modelData;
        }

        return Ember.RSVP.hash({
          movDetIngreso: this.store.query(this.modelName, {
            filter: {
              keyaux: movDet.get('keymovdet')
            },
            include: 'bodega'
          })
        }).then(({
          movDetIngreso
        }) => {
          if (!movDetIngreso.length) {
            this.toast.error(`El registro de ingreso del traslado no existe.`); // Destroy record if is new.

            movDet.rollbackAttributes();
            return this.transitionTo(this.indexRoute);
          }

          movDet.set('bodegaDestino', movDetIngreso.get('firstObject').get('bodega'));
          return Ember.merge(modelData, {
            movDetIngreso: movDetIngreso.get('firstObject')
          });
        });
      }).then(modelData => {
        if (!modelData.movDetIngreso) {
          return modelData;
        }

        return Ember.RSVP.hash({
          ubicacionesDestino: this.store.query('kar-mov-det-ubicacion', {
            filter: {
              movimientoDetalle: modelData.movDetIngreso.id
            },
            include: 'articuloUbicacion'
          })
        }).then(movDetIngresoData => Ember.merge(modelData, movDetIngresoData));
      });
    },

    createRecordInstance() {
      return this.store.createRecord(this.modelName, {
        empresa: this.company,
        bodegaDestino: null
      });
    },

    setupController(controller, model) {
      this._super(...arguments); // WARNING: No le paso el tercer parámetro porque no quiero que agregue la
      // validación de cuenta contable. Ahora mismo no se está solicitando.


      (0, _karMovdet.applyValidationsOnChangesetSalida)(controller.changeset, this.validations);
      let movDet = model.record;

      if (this.fechaCierre >= movDet.get('movimiento').get('fecha')) {
        this.toast.warning('Registro solo de lectura por cierre de inventario.');
      }

      let ubicacionesDestinoChangesets = null;
      let ubicacionesChangesets = this.kardex.createMovDetUbicacionesChangesets(model.tiposUbicaciones, movDet);

      if (model.movDetIngreso) {
        ubicacionesDestinoChangesets = this.kardex.createMovDetUbicacionesChangesets(model.tiposUbicaciones, model.movDetIngreso);
      } else {
        ubicacionesDestinoChangesets = model.tiposUbicaciones.map(tipoUbicacion => {
          let movDetUbicacion = this.store.createRecord('kar-mov-det-ubicacion', {
            tipoUbicacion
          });
          return this.kardex.createMovDetUbicacionDestinoChangeset(movDetUbicacion);
        });
      }

      controller.setProperties({
        ubicacionesChangesets,
        ubicacionesDestinoChangesets
      });
    },

    actions: {
      willTransition(transition) {
        if (transition.targetName.startsWith(this.routeName)) {
          return false;
        }

        if (this.controller.get('changeset').get('isDirty')) {
          transition.abort();
          this.send('openModal', 'popups/simple-confirm', {
            title: 'La forma se ha modificado. ¿Está seguro de descartar sus cambios?',
            message: '',
            onSubmit: () => {
              this.controller.discardChanges();
              transition.retry();
            }
          });
          return false;
        }

        this.controller.discardChanges();
      }

    }
  });

  _exports.default = _default;
});