define("sirvo-web/controllers/ods/servicios/ordenes-servicio/record/operaciones", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    parentCtrl: Ember.inject.controller('ods.servicios.ordenes-servicio.record'),
    actions: {
      createOperacionChangeset() {
        if (this.record.get('isNew')) {
          this.toast.warning('Haga click en Guardar antes de registrar operaciones.');
          return;
        }

        this.operacionesChangesets.pushObject(this.parentCtrl.createOperacionChangeset());
      },

      removeOperacion(operacionChangeset) {
        operacionChangeset.rollback();
        operacionChangeset.get('_content').destroyRecord();
        this.operacionesChangesets.removeObject(operacionChangeset);
        operacionChangeset.destroy();
      },

      saveOperacionChangeset(operacionChangeset) {
        return operacionChangeset.validate().then(() => {
          if (!operacionChangeset.get('isValid')) {
            this.toast.error('Hay un error con el formulario.');
            return;
          }

          this.loader.setLoading(true);
          operacionChangeset.set('isEditing', false);
          operacionChangeset.save().then(() => {
            this.toast.success('Configuración de operacion guardada correctamente.');
          }).catch(error => {
            this.toast.error('Ocurrió un error al guardar el registro.');
            throw error;
          }).finally(() => this.loader.setLoading(false));
        });
      },

      onProductoUpdated(operacionChangeset) {
        if (!operacionChangeset.get('producto.id')) {
          operacionChangeset.set('operacion', null);
          return;
        }

        let productoOperaciones = operacionChangeset.get('producto').get('operaciones').toArray();
        let firstOperacion = productoOperaciones.get('firstObject');
        operacionChangeset.set('operacion', firstOperacion || null);
      }

    }
  });

  _exports.default = _default;
});