define("sirvo-web/components/tables/cell/kar-movdet-bodega-destino", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    table: null,
    row: null,
    column: null,
    bodegaDestino: null,

    init() {
      this._super(...arguments);

      this.value = this.store.query('kar-movdet', {
        filter: {
          keyaux: this.row.get('keymovdet')
        },
        include: 'bodega'
      }).then(movIngreso => {
        this.set('bodegaDestino', movIngreso.get('firstObject'));
        return this.bodegaDestino ? this.bodegaDestino.get('bodega.codbodega') : null;
      });
    }

  });

  _exports.default = _default;
});