define("sirvo-web/routes/admon/accesos/permisos/grupo", ["exports", "servir-ember-utilities/routes/abstract-module-record", "sirvo-web/mixins/permission-validator", "ember-changeset-validations/validators", "servir-ember-utilities/validators/is-codigo"], function (_exports, _abstractModuleRecord, _permissionValidator, _validators, _isCodigo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_permissionValidator.default, {
    routePermission: 'AdmonCrearPermisosGrupos',
    modelName: 'permiso-grupo',
    indexRoute: 'admon.accesos.permisos',
    validations: Ember.computed(function () {
      return {
        codigo: [(0, _validators.validatePresence)({
          presence: true
        }), (0, _isCodigo.default)()],
        nombre: (0, _validators.validatePresence)(true)
      };
    }),
    relatedModelsToQuery: Ember.computed('company', function () {
      return [{
        name: 'grupos',
        modelName: 'permiso-grupo'
      }];
    })
  });

  _exports.default = _default;
});