define("sirvo-web/models/vf-perfil", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    codigo: (0, _model.attr)('string'),
    nombre: (0, _model.attr)('string'),
    activo: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    rutaInicio: (0, _model.attr)('string'),
    esSuperAdmin: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    menus: (0, _model.hasMany)('menu'),
    permisos: (0, _model.hasMany)('permiso'),
    activoFormatted: Ember.computed('activo', function () {
      return this.get('activo') ? 'Sí' : 'No';
    })
  });

  _exports.default = _default;
});