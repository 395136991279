define("sirvo-web/models/cartera-clientes", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    enviarCorreo: (0, _model.attr)('number'),
    codigoDirectorio: (0, _model.attr)('string'),
    nombreDirectorio: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    saldo: (0, _model.attr)('number'),
    envioCorreo: (0, _model.attr)('number')
  });

  _exports.default = _default;
});