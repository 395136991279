define("sirvo-web/routes/emision-contrasenas/detalle", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      let id = parseInt(params.id, 10);
      return this.store.findRecord('que/contrasena', id).then(contrasenaNew => {
        let keytra = contrasenaNew.get('keytra');
        return this.store.query('que/contrasena-linea', {
          filter: {
            keytra: keytra
          }
        }).then(contrasenaLineas => {
          contrasenaLineas.forEach(item => {
            item.set('formattedDate', (0, _moment.default)(item.get('fechaRecEncargado')).format('DD/MM/YYYY'));
          });
          return {
            contrasenaNew: contrasenaNew,
            contrasenaLineas: contrasenaLineas,
            keytra: keytra
          };
        });
      }).catch(error => {
        this.toast.error('Error al realizar la consulta: ' + error);
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('data', model.contrasenaLineas);
      controller.set('keytra', model.keytra);
      controller.set('isModalVisible', false);
      controller.set('isPrint', false);
    }

  });

  _exports.default = _default;
});