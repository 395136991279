define("sirvo-web/routes/inventario/movimientos/ingresos/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "servir-ember-utilities/mixins/route/has-tabs", "sirvo-web/mixins/permission-validator", "ember-changeset-validations/validators"], function (_exports, _abstractModuleRecord, _hasTabs, _permissionValidator, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_hasTabs.default, _permissionValidator.default, {
    request: Ember.inject.service(),
    routePermission: 'KarRegistrarIngreso',
    user: Ember.computed.readOnly('currentUser.user'),
    company: Ember.computed.readOnly('currentCompany.company'),
    fechaCierre: Ember.computed.readOnly('currentCompany.lastCierre.fecha'),
    modelName: 'kar-mov',
    indexRoute: 'inventario.movimientos.ingresos',
    defaultTabSubRoute: 'ingreso',
    validations: Ember.computed(function () {
      return {
        tipoMovimiento: (0, _validators.validatePresence)(true),
        tipoDocumento: (0, _validators.validatePresence)(true),
        serie: (0, _validators.validatePresence)(true),
        numero: (0, _validators.validatePresence)(true),
        proveedor: (0, _validators.validatePresence)(true),
        fecha: (0, _validators.validatePresence)(true)
      };
    }),
    relatedModelsToQuery: Ember.computed('isNew', function () {
      let relatedModels = [];

      if (this.isNew) {
        relatedModels.push({
          name: 'detalles',
          presetValue: Ember.A([])
        });
      } else {
        let userBodegasIds = this.user.get('bodegas').mapBy('id');
        relatedModels.push({
          name: 'detalles',
          modelName: 'kar-movdet',
          queryParams: {
            filter: {
              movimiento: this.recordId,
              bodega: userBodegasIds.length ? userBodegasIds : null
            },
            include: 'bodega,articulo,unidadMedida'
          }
        });
      }

      relatedModels.push({
        name: 'tipoMovIngreso',
        modelName: 'kar-tipomov',
        queryParams: {
          filter: {
            'tipomov': 'INGRESOS'
          }
        }
      }, {
        name: 'documentosIngreso',
        modelName: 'kar-doc',
        queryParams: {
          filter: {
            empresa: this.company.id,
            codsistema: 'KAR',
            deha: 'D',
            'tipoMovimiento.tipomov': 'INGRESOS'
          }
        }
      }, {
        name: 'tiposUbicaciones',
        modelName: 'kar-articulo-tipo-ubicacion',
        queryParams: {
          filter: {
            empresa: this.company.id
          }
        }
      }, {
        name: 'tipoIva',
        modelName: 'iva-tipo',
        queryParams: {
          filter: {
            pais: this.company.get('pais.id'),
            clasificacion: {
              exact: 'C'
            },
            tipo: {
              exact: 'LOCAL'
            }
          }
        }
      });
      return relatedModels;
    }),

    model() {
      return this._super(...arguments).then(modelData => {
        modelData.tipoIva = modelData.tipoIva.get('firstObject');
        return Ember.RSVP.all([modelData.record.get('proveedor')]).then(() => modelData);
      }).then(modelData => {
        if (modelData.record.get('isNew')) {
          return modelData;
        }

        return this.request.get(`kar-movs/${modelData.record.id}/poliza`).then(poliza => {
          return { ...modelData,
            poliza
          };
        });
      });
    },

    createRecordInstance() {
      return this.store.createRecord(this.modelName, {
        empresa: this.company,
        fecha: new Date()
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      let movIngreso = model.tipoMovIngreso.findBy('tipomov', 'INGRESOS');
      let docIngresoFA = model.documentosIngreso.findBy('codtipo', 'FA');

      if (!movIngreso) {
        this.toast.error('No se encontró el tipo de movimiento de "INGRESOS".');
        return this.transitionTo(this.indexRoute);
      }

      if (!docIngresoFA) {
        this.toast.error('No se encontró un tipo de documento para el ingreso.');
        return this.transitionTo(this.indexRoute);
      }

      Ember.setProperties(model.record, {
        tipoMovimiento: movIngreso,
        tipoDocumento: docIngresoFA,
        deha: docIngresoFA.get('deha'),
        usacnt: docIngresoFA.get('usaCnt'),
        tipoIva: model.tipoIva
      });

      if (this.fechaCierre >= model.record.get('fecha')) {
        this.toast.warning('Registro solo de lectura por cierre de inventario.');
      }

      controller.set('detalles', model.detalles.toArray());
    },

    actions: {
      willTransition() {
        return false;
      }

    }
  });

  _exports.default = _default;
});