define("sirvo-web/routes/cxp/contrasena/provision/registrar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController(controller, model) {
      this._super(controller, model);

      controller.set('reload', true);
    }

  });

  _exports.default = _default;
});