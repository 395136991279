define("sirvo-web/routes/ods/servicios/tipos-servicios/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "servir-ember-utilities/mixins/route/has-tabs", "sirvo-web/mixins/permission-validator", "sirvo-web/validations/ods/tipo-servicio"], function (_exports, _abstractModuleRecord, _hasTabs, _permissionValidator, _tipoServicio) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_hasTabs.default, _permissionValidator.default, {
    routePermission: 'OdsCrearTipoServicio',
    modelName: 'ods/tipo-servicio',
    indexRoute: 'ods.servicios.tipos-servicios',
    defaultTabSubRoute: 'generales',
    relatedModelsToQuery: Ember.computed(function () {
      let relatedModels = [{
        name: 'perfiles',
        modelName: 'vf-perfil'
      }, {
        name: 'documentos',
        modelName: 'ods/documento'
      }];
      return relatedModels;
    }),
    validations: Ember.computed(function () {
      return { ..._tipoServicio.default
      };
    })
  });

  _exports.default = _default;
});