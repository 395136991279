define("sirvo-web/routes/admon/accesos/menus/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "sirvo-web/mixins/permission-validator", "ember-changeset-validations/validators", "servir-ember-utilities/validators/custom-presence", "servir-ember-utilities/validators/is-codigo"], function (_exports, _abstractModuleRecord, _permissionValidator, _validators, _customPresence, _isCodigo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_permissionValidator.default, {
    routePermission: 'AdmonCrearMenu',
    modelName: 'menu',
    indexRoute: 'admon.accesos.menus',
    relatedModelsToQuery: Ember.computed(function () {
      let relatedModels = [{
        name: 'permisos',
        modelName: 'permiso'
      }, {
        name: 'menus',
        modelName: 'menu'
      }];
      return relatedModels;
    }),
    validations: Ember.computed(function () {
      return {
        codigo: [(0, _customPresence.default)({
          presence: true
        }), (0, _isCodigo.default)()],
        nombre: (0, _validators.validatePresence)({
          presence: true
        })
      };
    })
  });

  _exports.default = _default;
});