define("sirvo-web/controllers/inventario/reportes/conciliacion-compras", ["exports", "sirvo-web/mixins/reports-fechas-filters", "moment"], function (_exports, _reportsFechasFilters, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_reportsFechasFilters.default, {
    request: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),

    getSerializedFilters() {
      let fechaInicio = this.changeset.get('fechaInicio');
      let fechaFin = this.changeset.get('fechaFin');
      let filters = {
        fechaInicio: fechaInicio ? (0, _moment.default)(fechaInicio).format() : null,
        fechaFin: fechaFin ? (0, _moment.default)(fechaFin).format() : null
      };
      return filters;
    },

    actions: {
      generarReporte() {
        this.loader.setIsLoading();
        return this.changeset.validate().then(() => {
          if (!this.changeset.get('isValid')) {
            this.loader.setNotLoading();
            this.toast.error('Seleccione un valor para los campos obligatorios.');
            return;
          }

          const filters = this.getSerializedFilters();
          this.loader.setLoading(true);
          this.toast.info('Generando reporte');
          return this.request.get('kar/reporte-conciliacion-compras', filters).then(() => {
            this.toast.success('Reporte generado exitosamente');
          }).finally(() => this.loader.setLoading(false));
        });
      }

    }
  });

  _exports.default = _default;
});