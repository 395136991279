define("sirvo-web/models/ods/reporte-ordenes-servicio", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    tiposOrden: hasMany('ods/tipo-orden'),
    tiposServicios: hasMany('ods/tipo-servicio'),
    puerto: belongsTo('ods/puerto'),
    terminal: belongsTo('ods/terminal'),
    cliente: belongsTo('gru-directorio'),
    buques: hasMany('ods/buque'),
    fechaInicio: attr('date'),
    fechaFin: attr('date')
  });

  _exports.default = _default;
});