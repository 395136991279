define("sirvo-web/templates/admon/accesos/perfiles/record/accesos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RM7kuBnA",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n    \"],[1,[26,\"ember-jstree\",null,[[\"selectedNodes\",\"data\",\"themes\",\"plugins\",\"checkboxOptions\",\"eventDidChange\"],[[21,0,[\"jstreeSelectedNodes\"]],[21,0,[\"treeData\"]],[21,0,[\"themes\"]],[21,0,[\"plugins\"]],[21,0,[\"checkboxOptions\"]],[26,\"action\",[[21,0,[]],\"jstreeDidChange\"],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/admon/accesos/perfiles/record/accesos.hbs"
    }
  });

  _exports.default = _default;
});