define("sirvo-web/services/request", ["exports", "fetch", "ember-cli-file-saver/mixins/file-saver", "ember-fetch/utils/serialize-query-params", "ember-fetch/errors", "sirvo-web/config/environment", "sirvo-web/models/constants"], function (_exports, _fetch, _fileSaver, _serializeQueryParams, _errors, _environment, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_fileSaver.default, {
    session: Ember.inject.service(),
    toast: Ember.inject.service(),
    loader: Ember.inject.service(),
    errorHandler: Ember.inject.service(),

    _saveAttachmentFile(content, headers) {
      let disposition = headers['content-disposition'];
      let contentType = headers['content-type'];

      if (!disposition) {
        throw new Error('Got no Content-Disposition header.');
      }

      if (!contentType) {
        throw new Error('Got no Content-Type header.');
      }

      let fileName = null;

      if (disposition.indexOf('filename') !== -1) {
        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        let matches = filenameRegex.exec(disposition);

        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, '');
        }
      }

      if (!fileName) {
        throw new Error('No filename got from Content-Disposition header.');
      }

      if (disposition.indexOf('inline') !== -1) {
        this._openDocumentInNewTab(content, headers);

        return;
      }

      this.saveFileAs( // En el backend adoptamos la convención de que los nombres vengan URL encoded
      // para evitar problemas con caracteres especiales
      decodeURI(fileName), content, contentType);
    },

    _openDocumentInNewTab(content
    /*, headers*/
    ) {
      let url = window.URL.createObjectURL(content);
      window.open(url, '_blank');
    },

    _handleResponse(response) {
      if (response.ok) {
        let headers = response.headers && response.headers.map || response.headers;

        if (!headers || !headers['content-type']) {
          return response;
        }

        if (headers['content-type'].indexOf('application/json') === 0) {
          return response.json();
        }

        let isAttachment = _constants.default.UPLOADS_CONTENT_TYPES.find(contentType => {
          return headers['content-type'].indexOf(contentType) === 0;
        });

        if (isAttachment) {
          return response.blob().then(blobData => {
            this._saveAttachmentFile(blobData, headers);

            return response;
          });
        }

        return response;
      }

      if ((0, _errors.isBadRequestResponse)(response)) {
        this.toast.error('Solicitud incorrecta.');
      } else if ((0, _errors.isUnauthorizedResponse)(response)) {
        this.toast.error('Esta operación requiere de autenticación.');
      } else if ((0, _errors.isForbiddenResponse)(response)) {
        this.toast.error('No tiene permiso para realizar esta operación.');
      } else if ((0, _errors.isNotFoundResponse)(response)) {
        this.toast.error('Registro no encontrado.');
      } else if ((0, _errors.isInvalidResponse)(response)) {
        this.toast.error('Hay un error de validación.');
      } else if ((0, _errors.isServerErrorResponse)(response)) {
        this.toast.error('Ha ocurrido un error no esperado.');
      } else {
        this.toast.error('Ocurrió un error con su solicitud.');
      }

      throw response;
    },

    async fetch(uri, options = {}, handleErrors = true) {
      let {
        token
      } = this.session.get('data.authenticated');
      let {
        namespace
      } = _environment.default.API;
      let requestOptions = { ...options
      };

      if (!requestOptions.headers) {
        requestOptions.headers = {};
      }

      if (!requestOptions.anonymous) {
        requestOptions.headers = Ember.merge(requestOptions.headers, {
          Authorization: `Bearer ${token}`
        });
      }

      return (0, _fetch.default)(`/${namespace}/${uri}`, requestOptions).then(this._handleResponse.bind(this)).catch(error => {
        if (!handleErrors) {
          throw error;
        }

        this.errorHandler.handleBackendErrors(error);
      });
    },

    async get(uri, params = null, options = {}, handleErrors = true) {
      if (!params) {
        return this.fetch(uri, options);
      }

      const paramsString = (0, _serializeQueryParams.default)(params);
      return this.fetch(`${uri}?${paramsString}`, options, handleErrors);
    },

    async post(uri, data = {}, options = {}, handleErrors = true) {
      let requestOptions = Ember.merge({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }, options);
      return this.fetch(uri, requestOptions, handleErrors);
    }

  });

  _exports.default = _default;
});