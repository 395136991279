define("sirvo-web/routes/ods/tablas/terminales/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "sirvo-web/mixins/permission-validator", "sirvo-web/validations/ods/terminal"], function (_exports, _abstractModuleRecord, _permissionValidator, _terminal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_permissionValidator.default, {
    routePermission: 'OdsCrearTerminal',
    modelName: 'ods/terminal',
    indexRoute: 'ods.tablas.terminales',
    relatedModelsToQuery: Ember.computed(function () {
      let relatedModels = [{
        name: 'puertos',
        modelName: 'ods/puerto'
      }];
      return relatedModels;
    }),
    validations: Ember.computed(function () {
      return { ..._terminal.default
      };
    })
  });

  _exports.default = _default;
});