define("sirvo-web/models/ods/informe-buceo-linea", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    informeBuceo: belongsTo('ods/informe-buceo'),
    manguera: attr('number'),
    posicion: attr('string'),
    areaInspecionada: attr('string'),
    alineada: attr('boolean'),
    anomalias: attr('boolean'),
    orden: attr('number')
  });

  _exports.default = _default;
});