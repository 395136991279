define("sirvo-web/utils/format", ["exports", "sirvo-web/utils/common"], function (_exports, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatBytes = _exports.round = void 0;

  const round = (value, decimals = 2) => {
    if (isNaN(value)) return 0;
    return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
  };

  _exports.round = round;

  const formatBytes = (a, b) => {
    if (!(0, _common.isNumeric)(a)) {
      return null;
    }

    if (0 == a) return '0 Bytes';
    var c = 1e3,
        d = b || 2,
        e = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        f = Math.floor(Math.log(a) / Math.log(c));
    return parseFloat((a / Math.pow(c, f)).toFixed(d)) + ' ' + e[f];
  };

  _exports.formatBytes = formatBytes;
});