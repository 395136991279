define("sirvo-web/components/total-float-tabla", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * o 'div', dependiendo de cómo quieras que sea el contenedor del número formateado
     */
    tagName: 'span',
    classNameBindings: ['alignClass'],

    /**
     * Asumiendo que tienes una clase CSS que alinea el texto a la derecha
     */
    alignClass: 'text-right',
    formattedNumber: Ember.computed('value', 'row', 'column.valuePath', function () {
      let value = this.get('value'); //Para tablas componente SimpleTable

      if (value === undefined && this.get('row') && this.get('column.valuePath')) {
        //Para tablas componente EmberTable
        let valuePath = this.get('column.valuePath');
        value = this.get(`row.${valuePath}`);
      }

      let numericValue = parseFloat(String(value).replace(/,/g, ''));

      if (isNaN(numericValue)) {
        return '0.00';
      }

      return numericValue.toLocaleString('es-GT', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    })
  });

  _exports.default = _default;
});