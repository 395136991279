define("sirvo-web/routes/ods/tablas/agencias-navieras/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "sirvo-web/mixins/permission-validator", "sirvo-web/validations/ods/agencia-naviera"], function (_exports, _abstractModuleRecord, _permissionValidator, _agenciaNaviera) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_permissionValidator.default, {
    routePermission: 'OdsCrearAgenciaNaviera',
    modelName: 'ods/agencia-naviera',
    indexRoute: 'ods.tablas.agencias-navieras',
    relatedModelsToQuery: Ember.computed(function () {
      return [];
    }),
    validations: Ember.computed(function () {
      return { ..._agenciaNaviera.default
      };
    })
  });

  _exports.default = _default;
});