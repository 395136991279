define("sirvo-web/mixins/access-validator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    currentUser: Ember.inject.service(),
    toast: Ember.inject.service(),
    user: Ember.computed.readOnly('currentUser.user'),
    routeAccess: null,
    noAccessTransitionRoute: 'index',

    beforeModel(transition) {
      let userHasAccess = false;

      if (this.user && !Ember.isNone(this.routeAccess)) {
        let validAccesss = Array.isArray(this.routeAccess) ? this.routeAccess : [this.routeAccess];
        userHasAccess = validAccesss.reduce((prev, access) => prev || this.user.get(`access.${access}`), false);
      }

      if (userHasAccess) {
        return this._super(...arguments);
      }

      this.toast.error('No tiene permiso para acceder a esta ruta.'); // En lugar de transicionar a una ruta por defecto la propuesta es abortar
      // la transición. El hacer esto en el beforeModel se encuentra documentado en:
      // https://guides.emberjs.com/v2.9.0/routing/preventing-and-retrying-transitions/
      // Esto aplica cuando la transición proviene de la navegación dentro de la aplicación,
      // pero si se está entrando directamente por URL es mejor re-transicionar a
      // una ruta por defecto.
      // La idea de identificar esto mediante transition.sequence se tomó de aquí:
      // https://medium.com/trouva-product-blog/preventing-a-user-to-directly-access-a-route-in-ember-js-e53c7690850a

      if (transition.sequence === 0) {
        let transitionRoute = this.get('indexRoute') || this.get('noAccessTransitionRoute');
        this.replaceWith(transitionRoute);
        return;
      }

      return transition.abort();
    }

  });

  _exports.default = _default;
});