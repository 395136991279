define("sirvo-web/validations/com/word-upload", ["exports", "ember-changeset-validations/validators", "sirvo-web/models/constants"], function (_exports, _validators, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.blankWordUpload = _exports.default = void 0;
  const wordUpload = {
    file: (0, _validators.validatePresence)(true),
    type: (0, _validators.validateInclusion)({
      message: 'Archivo inválido',
      list: _constants.default.UPLOADS_WORD_TYPES
    }) // Se podría validar el tamaño máximo del archivo con el key "size".

  };
  const blankWordUpload = {
    type: (0, _validators.validateInclusion)({
      message: 'Archivo inválido',
      list: _constants.default.UPLOADS_WORD_TYPES
    })
  };
  _exports.blankWordUpload = blankWordUpload;
  var _default = wordUpload;
  _exports.default = _default;
});