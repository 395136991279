define("sirvo-web/controllers/inventario/procesos/cargas/index", ["exports", "sirvo-web/mixins/uploader"], function (_exports, _uploader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_uploader.default, {
    request: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),
    loadType: null,
    data: null,
    excelChangeset: null,
    actions: {
      filesUpdated(file) {
        // File input cleared?
        if (!file) {
          this.set('data', []);
        }
      },

      loadTypeUpdated() {
        // When loadType changes, file must be validted again.
        this.set('data', []);
      },

      descargarPlantilla() {
        if (!this.loadType) {
          this.toast.error('Seleccione un tipo de carga.');
          return;
        }

        this.loader.setLoading(true);
        return this.request.get(`empresas/${this.company.id}/carga-data`, {
          getTemplate: 1,
          loadType: this.loadType
        }).finally(() => this.loader.setLoading(false));
      },

      cargarArchivo(save = false) {
        if (!this.loadType) {
          this.toast.error('Seleccione un tipo de carga.');
          return;
        }

        this.set('uploadUrl', `empresas/${this.company.id}/carga-data`);
        return this.excelChangeset.validate().then(() => {
          if (!this.excelChangeset.get('isValid')) {
            this.toast.error('Debe adjuntar un archivo válido.');
            return;
          }

          let uploader = this._getNewUploader();

          this.loader.setLoading(true);
          return uploader.upload(this.excelChangeset.get('archivo'), {
            save: save ? 1 : 0,
            loadType: this.loadType
          }).then(data => {
            this.set('data', data);

            if (data.processed) {
              this.toast.success('Datos guardados correctamente');
              this.newExcelChangeset();
              this.set('data', []);
            } else {
              this.toast.success('Archivo validado correctamente');
            }
          }).catch(error => {
            this.toast.error('Ocurrió un error durante la carga. Revise los errores.');

            if (error.responseJSON && Array.isArray(error.responseJSON.errors)) {
              error.responseJSON.errors.forEach(e => this.toast.error(e.message));
            }

            if (error.responseJSON && error.responseJSON.data) {
              this.set('data', error.responseJSON);
            }

            throw error;
          }).finally(() => {
            // Because in each upload a new one is instantiated.
            uploader.destroy();
            this.loader.setLoading(false);
          });
        });
      }

    }
  });

  _exports.default = _default;
});