define("sirvo-web/controllers/cxc/procesos/estado-cartera-clientes", ["exports", "sirvo-web/mixins/reports-fechas-filters", "moment"], function (_exports, _reportsFechasFilters, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_reportsFechasFilters.default, {
    request: Ember.inject.service(),
    data: null,
    dataOriginal: null,
    sendEmails: null,
    filteredData: null,
    saveResult: null,
    openModal: null,
    showFilterMenu: false,
    filterCodigo: '',
    columns: Ember.computed(function () {
      return [{
        label: 'Enviar Correo',
        valuePath: 'enviarCorreo',
        sortable: false,
        cellComponent: 'checkbox-cell',
        width: '50px'
      }, {
        label: 'Código directorio',
        valuePath: 'codigo_directorio',
        sortable: false,
        sortingPropertyName: 'codigo_directorio',
        width: '75px',
        filtrable: true,
        filterName: 'codigo_directorio'
      }, {
        label: 'Nombre directorio',
        sortable: false,
        valuePath: 'nombre_directorio',
        width: '300px',
        filtrable: true,
        filterName: 'nombre_directorio'
      }, {
        label: 'Email',
        sortable: false,
        valuePath: 'email',
        width: '300px',
        filtrable: true,
        filterName: 'email'
      }, {
        label: 'Saldo',
        sortable: false,
        valuePath: 'saldo',
        width: '100px',
        cellClassNames: 'align-right',
        emberIntlCurrency: 'GTQ',
        cellComponent: 'tables/cell/accounting-amount'
      }];
    }),
    areAllChecked: Ember.computed('data.data.@each.enviarCorreo', function () {
      let allData = this.get('data.data');
      return allData && allData.every(item => item.enviarCorreo === '1');
    }),

    getSerializedFilters() {
      let fechaFin = this.changeset.get('fechaFin');
      let filters = {
        codigoEmpresa: this.changeset.get('codigoEmpresa'),
        fechaFin: fechaFin ? (0, _moment.default)(fechaFin).format() : null
      };
      return filters;
    },

    getFiltersPost() {
      let fechaFin = this.changeset.get('fechaFin');
      let filters = {
        codigoEmpresa: this.changeset.get('codigoEmpresa'),
        fechaFin: fechaFin ? (0, _moment.default)(fechaFin).format() : null,
        data: this.data
      };
      return filters;
    },

    actions: {
      toggleFilterMenu() {
        this.toggleProperty('showFilterMenu');
      },

      applyFilters() {
        let filterValue = this.filterCodigo.toLowerCase();
        this.filteredData = this.data.data.filter(item => {
          return item.codigo_directorio.toLowerCase().includes(filterValue) || item.nombre_directorio.toLowerCase().includes(filterValue) || item.email.toLowerCase().includes(filterValue) || item.saldo.toString().toLowerCase().includes(filterValue);
        });
        this.set('data.data', this.filteredData);

        if (this.filteredData.length === 0) {
          this.toast.info('No hay resultados con ese valor.');
        }
      },

      clearFilters() {
        this.set('filterCodigo', '');

        if (this.originalData) {
          this.originalData.forEach(originalItem => {
            let filteredItem = this.filteredData.find(item => item.idtransac === originalItem.idtransac);

            if (filteredItem && filteredItem.sendEmail === '1') {
              originalItem.sendEmail = '1';
            }
          });
          this.set('data.data', this.originalData);
        }
      },

      toggleAllCheckboxes() {
        let areAllChecked = this.get('areAllChecked');
        let newData = this.get('data.data').map(item => {
          Ember.set(item, 'enviarCorreo', areAllChecked ? '0' : '1');
          return item;
        });
        this.set('data.data', newData);
        this.set('areAllChecked', !areAllChecked);
        this.notifyPropertyChange('areAllChecked');
        Ember.run.next(() => {
          let checkboxElement = document.getElementById('selectAllCheckboxes');

          if (checkboxElement) {
            checkboxElement.checked = !areAllChecked;
          }
        });
      },

      sendEmails() {
        let filters = this.getFiltersPost();
        this.loader.setIsLoading();
        return this.request.post('cxc/proceso-estado-cartera-clientes', filters).then(() => {
          this.toast.success('Correos enviados correctamente.');
        }).catch(error => {
          this.toast.error('No se pudo enviar correctamente el estado de cuenta.');
          throw error;
        }).finally(() => this.loader.setNotLoading());
      },

      searchDirectorios() {
        return this.changeset.validate().then(() => {
          if (!this.changeset.get('isValid')) {
            this.toast.error('Corrija los errores en el formulario.');
            return;
          }

          let filters = this.getSerializedFilters();
          this.loader.setIsLoading();
          return this.request.get('cxc/proceso-estado-cartera-clientes', filters).then(data => {
            this.toast.success('Directorios encontrados exitosamente.');
            this.set('data', data);
            this.set('originalData', this.data.data);
            this.set('openModal', true);
          }).catch(error => {
            this.toast.error('No se pudo enviar correctamente el estado de cuenta.');
            throw error;
          }).finally(() => this.loader.setNotLoading());
        });
      }

    }
  });

  _exports.default = _default;
});