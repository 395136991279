define("sirvo-web/validations/notification", ["exports", "ember-changeset-validations/validators", "servir-ember-utilities/validators/is-codigo"], function (_exports, _validators, _isCodigo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    code: [(0, _validators.validatePresence)(true), (0, _isCodigo.default)()],
    name: (0, _validators.validatePresence)(true),
    subjectTemplate: (0, _validators.validatePresence)(true),
    bodyTemplate: (0, _validators.validatePresence)(true),
    event: (0, _validators.validatePresence)(true)
  };
  _exports.default = _default;
});