define("sirvo-web/components/emision-contrasenas/add-contrasena-linea", ["exports", "ember-changeset", "moment", "ember-changeset-validations/validators", "ember-changeset-validations"], function (_exports, _emberChangeset, _moment, _validators, _emberChangesetValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    request: Ember.inject.service(),
    validations: Ember.computed(function () {
      return {
        serie: (0, _validators.validatePresence)({
          presence: true
        }),
        numero: (0, _validators.validatePresence)({
          presence: true
        }),
        selectedTipoDocumento: (0, _validators.validatePresence)({
          presence: true
        }),
        fechaFactura: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),
    codSistema: 'CXP',
    changeset: null,
    concepto: '',
    valorMe: 0,
    valorMl: 0,
    isEnableValorMe: false,
    isEnableValorMl: false,
    categorias: Ember.computed(function () {
      return [{
        label: 'BIEN',
        value: 'BIEN'
      }, {
        label: 'SERVICIO',
        value: 'SERVICIO'
      }];
    }),
    existingData: null,
    contrasenaId: null,
    selectedCategoria: Ember.computed(function () {
      return this.categorias.findBy('value', 'BIEN');
    }),
    selectedMoneda: null,
    selectedSatDocumentoFel: null,
    selectedDocumentoBase: null,
    dropdownOptionDirectorio: null,
    dropdownOptionDocumentoBase: null,
    dropdownOptionSatDocumentoFel: null,
    dropdownOptionMonedas: null,
    isModalDocumentos: false,
    factura: null,
    dropdownOptionTipoDocumentos: null,
    selectedDirectorio: Ember.computed('dropdownOptionDirectorio.[]', function () {
      let options = this.get('dropdownOptionDirectorio');

      if (options && options.get('length') > 0) {
        return options.objectAt(0);
      }

      return null;
    }),

    async init() {
      this._super(...arguments);

      let data = new Ember.Object({
        serie: '',
        numero: '',
        fechaFactura: null,
        selectedTipoDocumento: null
      });
      let directorioResult = await this.store.query('gru-directorio', {
        filter: {
          coddirectorio: {
            exact: this.get('contrasenaNew.coddirectorio')
          },
          iddirectorio: {
            exact: this.get('contrasenaNew.directorio.id')
          }
        }
      });
      this.set('dropdownOptionDirectorio', directorioResult);
      let tipoDocumentosResponse = await this.request.get('documentos-empresa/sistema', {
        codSistema: this.codSistema
      });
      this.set('dropdownOptionTipoDocumentos', tipoDocumentosResponse.data);
      let changeset = new _emberChangeset.default(data, (0, _emberChangesetValidations.default)(this.validations), this.validations);
      this.set('changeset', changeset);

      if (this.existingData) {
        let changeset = this.get('changeset');
        changeset.set('serie', this.existingData.serie);
        changeset.set('numero', this.existingData.numero);
        changeset.set('fechaFactura', (0, _moment.default)(this.existingData.fechaFac).format('YYYY-MM-DD'));
        changeset.set('selectedTipoDocumento', this.dropdownOptionTipoDocumentos.findBy('tipo', this.existingData.codtipodoctos));
        this.setProperties({
          concepto: this.existingData.descripcion,
          valorMe: this.existingData.valorme,
          valorMl: this.existingData.valor,
          factura: this.existingData.factura,
          selectedCategoria: this.categorias.findBy('value', this.existingData.categoria)
        });
        this.actions.formatValueMl.call(this);
        this.actions.formatValueMe.call(this);
        this.actions.changeDocumentoBase.call(this);
        this.actions.changeMoneda.call(this);
        changeset.validate();
      }

      this.validateForm();
    },

    validateForm() {
      this.get('changeset').validate();
    },

    actions: {
      formatValueMl() {
        let valorMl = this.valorMl.toString();
        let nwValor = valorMl.replace(/,/g, '');
        let actualizado = parseFloat(nwValor);
        let formatted = isNaN(actualizado) ? '0' : actualizado.toLocaleString('es-GT', {
          maximumFractionDigits: 2
        });
        this.set('valorMl', formatted);
      },

      formatValueMe() {
        let valorMe = this.valorMe.toString();
        let nwValor = valorMe.replace(/,/g, '');
        let actualizado = parseFloat(nwValor);
        let formatted = isNaN(actualizado) ? '0' : actualizado.toLocaleString('es-GT', {
          maximumFractionDigits: 2
        });
        this.set('valorMe', formatted);
      },

      async saveContrasenaLinea() {
        this.loader.setLoading(true);
        let changeset = this.changeset;

        if (this.changeset.get('isValid')) {
          let contrasenaNew = this.get('contrasenaNew');
          let codempresa = this.get('company.codempresa');
          let codmoneda = this.selectedMoneda.codmoneda;
          let valordocml = parseFloat(this.valorMe.toString().replace(/,/g, '')) + parseFloat(this.valorMl.toString().replace(/,/g, ''));
          let valor = parseFloat(this.valorMl.toString().replace(/,/g, ''));
          let valorme = parseFloat(this.valorMe.toString().replace(/,/g, ''));
          let codsistema = this.codSistema;
          let codtipodoctos = changeset.get('selectedTipoDocumento.tipo');
          let keytra = contrasenaNew.keytra;
          let numerodoc = changeset.get('serie') + '-' + changeset.get('numero');
          let coddirectorio = contrasenaNew.coddirectorio;
          let codgrupo = contrasenaNew.codgrupo;
          let keytraProvision = '';
          let descripcion = this.concepto;
          let fechaChangeSet = changeset.get('fechaFactura');
          let fechaFac = (0, _moment.default)(fechaChangeSet, 'YYYY-MM-DD').toDate();
          let serie = changeset.get('serie');
          let numero = changeset.get('numero');
          let usatasac = 0;
          let codtipo = this.selectedDocumentoBase.codtipo;
          let fechaActualMoment = (0, _moment.default)().toDate();
          let fechaRecEncargado = fechaActualMoment;
          let categoria = this.selectedCategoria.label;
          let tasac = 0;

          try {
            if (this.existingData) {
              let contrasenaLinea = await this.store.findRecord('que/contrasena-linea', this.existingData.id);
              contrasenaLinea.setProperties({
                valordocml: valordocml,
                valorme: valorme,
                valor: valor,
                codtipodoctos: codtipodoctos,
                numerodoc: numerodoc,
                descripcion: descripcion,
                fechaFac: fechaFac,
                serie: serie,
                numero: numero,
                categoria: categoria,
                codtipo: codtipo
              });
              await contrasenaLinea.save();
              this.toast.success('Registro editado con éxito.');
              this.loader.setLoading(false);
              this.send('closeModal');
              return;
            }

            let existingRecord = await this.store.query('que/contrasena-linea', {
              filter: {
                keytra: {
                  exact: keytra
                },
                codtipodoctos: {
                  exact: codtipodoctos
                },
                codtipo: {
                  exact: codtipo
                },
                serie: {
                  exact: serie
                },
                numero: {
                  exact: numero
                },
                codempresa: {
                  exact: codempresa
                }
              }
            });

            if (existingRecord.length > 0) {
              this.toast.info('Ya existe un registro con el número de factura.');
              this.loader.setLoading(false);
              return;
            }

            let newContrasenaLinea = await this.store.createRecord('que/contrasena-linea', {
              codempresa,
              codmoneda,
              valordocml,
              valorme,
              valor,
              codsistema,
              codtipodoctos,
              keytra,
              numerodoc,
              coddirectorio,
              codgrupo,
              keytraProvision,
              descripcion,
              fechaFac,
              serie,
              numero,
              usatasac,
              fechaRecEncargado,
              categoria,
              tasac,
              codtipo,
              contrasena: contrasenaNew,
              devuelto: 0,
              esNotaCobro: 0
            });
            await newContrasenaLinea.save();
            this.refreshData();
            this.toast.success('Registro creado con éxito.');
            this.loader.setLoading(false);
            this.send('closeModal');
          } catch (error) {
            this.toast.error('Error: ' + error);
            this.loader.setLoading(false);
          }
        } else {
          this.toast.error('Los campos obligatorios están marcados con un asterisco *');
          this.loader.setLoading(false);
          return;
        }
      },

      updateSerie(value) {
        this.changeset.set('serie', value);
        this.validateForm();
      },

      updateNumero(value) {
        this.changeset.set('numero', value);
        this.validateForm();
      },

      updateFactura() {
        let serie = this.changeset.get('serie');
        let numero = this.changeset.get('numero');
        this.set('factura', serie + '-' + numero);
      },

      closeModal() {
        this.set('isVisible', false);
      },

      changeDocumentoBase() {
        let codtipo = this.changeset.get('selectedTipoDocumento.codigo');
        this.store.query('emp-docto', {
          filter: {
            codtipo: {
              exact: codtipo
            },
            codsistema: this.codSistema,
            codempresa: this.get('company.codempresa')
          }
        }).then(response => {
          this.set('dropdownOptionDocumentoBase', response);
          let options = this.get('dropdownOptionDocumentoBase');
          this.set('selectedDocumentoBase', options.objectAt(0));
        }).catch(error => {
          this.toast.error('Error al cargar la clasificacion. ' + error);
        });
      },

      changeMoneda() {
        let codmoneda = this.changeset.get('selectedTipoDocumento.codmoneda');
        this.store.query('emp-moneda', {
          filter: {
            codmoneda: codmoneda,
            codempresa: this.get('company.codempresa')
          }
        }).then(response => {
          this.set('dropdownMonedas', response);
          let options = this.get('dropdownMonedas');
          let codmoneda = options.objectAt(0).codmoneda;
          this.set('selectedMoneda', options.objectAt(0));

          if (!this.existingData) {
            if (codmoneda == 'USD') {
              this.set('valorMl', 0);
              this.set('valorMe', 0);
              this.set('isEnableValorMe', true);
              this.set('isEnableValorMl', false);
            } else {
              this.set('valorMl', 0);
              this.set('valorMe', 0);
              this.set('isEnableValorMe', false);
              this.set('isEnableValorMl', true);
            }
          }
        }).catch(error => {
          this.toast.error('Error al cargar moneda. ' + error);
        });
      },

      openModalDocumentos() {
        let nitEmisor = this.selectedDirectorio.nit;
        this.loader.setLoading(true);
        this.request.get('sat-documentos-fel/contrasenas', {
          nitEmisor
        }).then(response => {
          this.set('dropdownOptionSatDocumentoFel', response.data);
          this.loader.setNotLoading();
          this.set('isModalDocumentos', true);
        }).catch(error => {
          this.toast.error('Error al realizar la consulta: ' + error);
        }).finally(() => this.loader.setNotLoading());
      },

      closeModalDocumentos() {
        this.set('isModalDocumentos', false);
      },

      addDocumentoSat() {
        if (this.isEnableValorMl) {
          this.set('valorMl', this.selectedSatDocumentoFel.valorTotal);
        } else {
          this.set('valorMe', this.selectedSatDocumentoFel.valorTotal);
        }

        let fechaFactura = (0, _moment.default)(this.selectedSatDocumentoFel.fechaHoraEmision, 'D/M/YYYY').format('YYYY-MM-DD');
        let factura = this.selectedSatDocumentoFel.serie + '-' + this.selectedSatDocumentoFel.numero;
        this.changeset.set('serie', this.selectedSatDocumentoFel.serie);
        this.changeset.set('numero', this.selectedSatDocumentoFel.numero);
        this.set('factura', factura);
        this.changeset.set('fechaFactura', fechaFactura);
        this.set('isModalDocumentos', false);
      }

    }
  });

  _exports.default = _default;
});