define("sirvo-web/models/kar-articulo", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    codarticulo: (0, _model.attr)('string'),
    correlativo: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    codmedidaBase: (0, _model.attr)('string'),
    unidadMedidaBase: (0, _model.belongsTo)('kar-unidad-medida'),
    existenciaMin: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    existenciaMax: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    puntoReorden: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    descripcion: (0, _model.attr)('string'),
    ubicaciones: (0, _model.hasMany)('kar-articulo-localizacion'),
    debaja: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    fechaDebaja: (0, _model.attr)('date'),
    usaLote: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    usaFechaVencimiento: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    creusr: (0, _model.attr)('string'),
    fecusr: (0, _model.attr)('date'),
    codusr: (0, _model.attr)('string'),
    // Relationships
    empresa: (0, _model.belongsTo)('empresa'),
    tipoArticulo: (0, _model.belongsTo)('kar-tipo-articulo'),
    unidadesMedida: (0, _model.hasMany)('kar-tabla-medida'),
    bodegas: (0, _model.hasMany)('kar-bodega-det'),
    activo: Ember.computed('debaja', {
      set(key, value) {
        this.set('debaja', !value);
      },

      get() {
        return !this.debaja;
      }

    }),
    codigoDescripcion: Ember.computed('codarticulo', 'descripcion', function () {
      return `${this.codarticulo} - ${this.descripcion}`;
    }),

    save(options) {
      if (this.get('isDeleted')) {
        return this._super(options);
      } // WARNING: Actualmente, esto no funciona. No encontré por qué. Por ahora,
      // lo hice en el backend.


      if (!this.debaja) {
        this.set('fechaDebaja', null);
      }

      let removedUnidadesMedida = this.unidadesMedida.filterBy('isDeleted');
      let removedBodegas = this.bodegas.filterBy('isDeleted');
      let unsavedUnidadesMedida = this.unidadesMedida.filter(uMedida => uMedida.get('isNew') || uMedida.get('isDirty')).filterBy('isValidated');
      let unsavedBodegas = this.bodegas.rejectBy('isDeleted').filter(articuloBodega => articuloBodega.get('isNew') || articuloBodega.get('isDirty'));
      return Ember.RSVP.all(removedUnidadesMedida.map(unidadMedida => unidadMedida.save())).then(() => removedBodegas.map(articuloBodega => articuloBodega.save())).then(() => this._internalModel.save(options)).then(() => Ember.RSVP.all(unsavedUnidadesMedida.map(uMedida => uMedida.save()))).then(() => Ember.RSVP.all(unsavedBodegas.map(articuloBodega => articuloBodega.save()))).then(() => this);
    }

  });

  _exports.default = _default;
});