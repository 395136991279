define("sirvo-web/routes/inventario/movimientos/salidas/traslado", ["exports", "servir-ember-utilities/routes/abstract-module-record", "servir-ember-utilities/mixins/route/has-tabs", "sirvo-web/mixins/permission-validator", "ember-changeset-validations/validators"], function (_exports, _abstractModuleRecord, _hasTabs, _permissionValidator, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_hasTabs.default, _permissionValidator.default, {
    request: Ember.inject.service(),
    routePermission: 'KarRegistrarTraslado',
    company: Ember.computed.readOnly('currentCompany.company'),
    fechaCierre: Ember.computed.readOnly('currentCompany.lastCierre.fecha'),
    modelName: 'kar-mov',
    indexRoute: 'inventario.movimientos.salidas',
    defaultTabSubRoute: 'movimiento',
    validations: Ember.computed(function () {
      return {
        fecha: (0, _validators.validatePresence)(true),
        tipoMovimiento: (0, _validators.validatePresence)(true),
        tipoDocumento: (0, _validators.validatePresence)(true),
        numero: (0, _validators.validatePresence)(true)
      };
    }),
    relatedModelsToQuery: Ember.computed(function () {
      return [{
        name: 'tipoMovTraslado',
        modelName: 'kar-tipomov',
        queryParams: {
          filter: {
            tipomov: 'TRASLADOS',
            habilitar: 1
          }
        }
      }, {
        name: 'documentosTraslado',
        modelName: 'kar-doc',
        queryParams: {
          filter: {
            empresa: this.company.get('id'),
            codsistema: 'KAR',
            deha: 'H',
            'tipoMovimiento.tipomov': 'TRASLADOS'
          }
        }
      }];
    }),

    model() {
      return this._super(...arguments).then(modelData => {
        if (modelData.record.get('isNew')) {
          return modelData;
        }

        return this.request.get(`kar-movs/${modelData.record.id}/poliza`).then(poliza => {
          return { ...modelData,
            poliza
          };
        });
      });
    },

    createRecordInstance() {
      return this.store.createRecord(this.modelName, {
        empresa: this.company,
        fecha: new Date()
      });
    },

    setupController(controller, model) {
      this._super(...arguments);

      let movTraslado = model.tipoMovTraslado.findBy('tipomov', 'TRASLADOS');
      let docTraslado = model.documentosTraslado.get('firstObject');

      if (!movTraslado) {
        this.toast.error('No se encontró el tipo de movimiento de "TRASLADOS".');
        return this.transitionTo(this.indexRoute);
      }

      if (!docTraslado) {
        this.toast.error('No se encontró un tipo de documento para el traslado.');
        return this.transitionTo(this.indexRoute);
      }

      Ember.setProperties(model.record, {
        tipoMovimiento: movTraslado,
        tipoDocumento: docTraslado,
        deha: docTraslado.get('deha'),
        usacnt: docTraslado.get('usaCnt')
      });

      if (this.fechaCierre >= model.record.get('fecha')) {
        this.toast.warning('Registro solo de lectura por cierre de inventario.');
      }
    },

    actions: {
      willTransition() {
        return false;
      }

    }
  });

  _exports.default = _default;
});