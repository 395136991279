define("sirvo-web/models/permiso-grupo", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    codigo: (0, _model.attr)('string'),
    nombre: (0, _model.attr)('string'),
    orden: (0, _model.attr)('number'),
    superior: (0, _model.belongsTo)('permiso-grupo', {
      inverse: null
    })
  });

  _exports.default = _default;
});