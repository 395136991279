define("sirvo-web/routes/admon/grupos/directorios/index", ["exports", "servir-ember-utilities/routes/abstract-module-index", "sirvo-web/mixins/access-validator"], function (_exports, _abstractModuleIndex, _accessValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend(_accessValidator.default, {
    routeAccess: 'ADMON-DIRECTORIOS',

    setupController() {
      this._super(...arguments);

      this.controller.set('reload', true);
    }

  });

  _exports.default = _default;
});