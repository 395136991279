define("sirvo-web/models/ods/tipo-orden", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    codigo: attr('string'),
    nombre: attr('string'),
    reporterServiceId: attr('string'),
    plantilla: belongsTo('ods/upload', {
      inverse: null
    }),
    plantillaPdf: belongsTo('ods/upload', {
      inverse: null
    }),
    esAdicional: attr('boolean'),
    stringify: Ember.computed.readOnly('nombre')
  });

  _exports.default = _default;
});