define("sirvo-web/controllers/emision-contrasenas/detalle", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),
    data: null,
    keytra: null,
    isEditing: false,
    columns: Ember.computed(function () {
      return [{
        label: 'Tipo Documento',
        valuePath: 'codtipodoctos',
        width: '200px'
      }, {
        label: 'Factura',
        valuePath: 'numerodoc',
        width: '200px'
      }, {
        label: 'Moneda',
        valuePath: 'codmoneda',
        width: '200px'
      }, {
        label: 'Valor M.L.',
        valuePath: 'valor',
        width: '200px',
        cellClassNames: 'align-right',
        emberIntlCurrency: 'GTQ',
        cellComponent: 'tables/cell/accounting-amount'
      }, {
        label: 'Valor M.E.',
        valuePath: 'valorme',
        width: '200px',
        cellClassNames: 'align-right',
        emberIntlCurrency: 'USD',
        cellComponent: 'tables/cell/accounting-amount'
      }, {
        label: 'Fecha entrega al encargado',
        valuePath: 'formattedDate',
        width: '200px'
      }, {
        label: 'Provisionada',
        valuePath: 'keytraProvision',
        width: '85px',
        cellClassNames: 'align-center',
        cellComponent: 'tables/cell/provisionada-cell'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger    ',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }];
    }),
    contrasenaNew: Ember.computed('model.contrasenaNew', function () {
      return this.model.contrasenaNew;
    }),
    isModalVisible: false,
    isPrint: false,

    init() {
      this._super(...arguments);
    },

    actions: {
      refresModel() {
        this.refresh();
      },

      back() {
        let id = this.contrasenaNew.id;
        this.transitionToRoute('emision-contrasenas.new', id);
      },

      mostrarModal() {
        this.set('existingData', null);
        this.set('isModalVisible', true);
      },

      impresionDocumentoModal() {
        this.set('isPrint', true);
      },

      ocultarModal() {
        this.set('isModalVisible', false);
      },

      edit(item) {
        let existingData = {
          coddirectorio: item.get('coddirectorio'),
          codgrupo: item.get('codgrupo'),
          descripcion: item.get('descripcion'),
          usatasac: item.get('usatasac'),
          fechaRecEncargado: item.get('fechaRecEncargado'),
          tasac: item.get('tasac'),
          codtipo: item.get('codtipo'),
          keytra: item.get('keytra'),
          codsistema: item.get('codsistema'),
          codempresa: item.get('codempresa'),
          codmoneda: item.get('codmoneda'),
          serie: item.get('serie'),
          valordocml: item.get('valordocml'),
          numero: item.get('numero'),
          valorme: item.get('valorme'),
          valor: item.get('valor'),
          factura: item.get('numerodoc'),
          categoria: item.get('categoria'),
          codtipodoctos: item.get('codtipodoctos'),
          fechaFac: item.get('fechaFac'),
          id: item.get('id')
        };
        this.set('existingData', existingData);
        this.set('isModalVisible', true);
      },

      delete(item) {
        return this.store.query('que/contrasena-linea', {
          filter: {
            keytra: {
              exact: item.keytra
            },
            numerodoc: {
              exact: item.numerodoc
            },
            codempresa: item.codempresa,
            keytraProvisionCustomFilter: 1
          }
        }).then(contrasenaLineas => {
          let numberOfRecords = contrasenaLineas.get('length');

          if (numberOfRecords > 0) {
            this.toast.error('El registro ya esta provisionado, no se puede eliminar.');
          } else {
            return this.send('openModal', 'popups/simple-confirm', {
              title: '¿Estás seguro que deseas eliminar el registro?',
              message: 'No podrás revertir esta acción!',
              onSubmit: () => {
                this.store.findRecord('que/contrasena-linea', item.id, {
                  reload: true
                }).then(record => {
                  if (record) {
                    record.destroyRecord().then(() => {
                      this.toast.success('Registro eliminado.');
                    });
                  } else {
                    this.toast.error('El registro no se encontró.');
                  }
                });
              }
            });
          }
        });
      },

      async refreshData() {
        try {
          let contrasenaLineas = await this.store.query('que/contrasena-linea', {
            filter: {
              keytra: this.keytra
            }
          });
          contrasenaLineas.forEach(item => {
            item.set('formattedDate', (0, _moment.default)(item.get('fechaRecEncargado')).format('DD/MM/YYYY'));
          });
          let contrasena = this.model.contrasenaNew;
          contrasena.reload();
          this.set('data', contrasenaLineas);
        } catch (error) {
          this.toast.error('Error: ' + error);
        }
      }

    }
  });

  _exports.default = _default;
});