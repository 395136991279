define("sirvo-web/routes/ods/tablas/muelles/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "sirvo-web/mixins/permission-validator", "sirvo-web/validations/ods/muelle"], function (_exports, _abstractModuleRecord, _permissionValidator, _muelle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_permissionValidator.default, {
    routePermission: 'OdsCrearMuelle',
    modelName: 'ods/muelle',
    indexRoute: 'ods.tablas.muelles',
    relatedModelsToQuery: Ember.computed(function () {
      let relatedModels = [{
        name: 'terminales',
        modelName: 'ods/terminal'
      }];
      return relatedModels;
    }),
    validations: Ember.computed(function () {
      return { ..._muelle.default
      };
    })
  });

  _exports.default = _default;
});