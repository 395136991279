define("sirvo-web/routes/inventario/tablas/articulos", ["exports", "sirvo-web/mixins/access-validator"], function (_exports, _accessValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_accessValidator.default, {
    routeAccess: 'KAR-ARTICULOS',

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('reload', true);
    }

  });

  _exports.default = _default;
});