define("sirvo-web/controllers/admon/accesos/perfiles/record/accesos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    user: Ember.computed.readOnly('currentUser.user'),
    plugins: 'checkbox, wholerow',
    checkboxOptions: Ember.computed(function () {
      return {
        keep_selected_style: false
      };
    }),
    themes: Ember.computed(function () {
      return {
        icons: false
      };
    }),
    jstreeSelectedNodes: null,
    menus: Ember.computed('user.perfil', 'menusTodos', function () {
      if (this.get('user').get('perfil.esSuperAdmin')) {
        return this.menusTodos;
      }

      return this.menusTodos.rejectBy('soloSuperAdmin');
    }),
    treeData: Ember.computed('menus', 'changeset', function () {
      let currentMenuIds = this.changeset.get('menus').mapBy('id');
      let rootMenus = this.menus.rejectBy('superior.id').sortBy('orden');
      let childMenus = this.menus.filterBy('superior.id').sortBy('orden');
      let treeData = rootMenus.map(menu => this.buildMenuTree(menu, childMenus, currentMenuIds));
      return treeData;
    }),

    buildMenuTree(currentMenu, menus, currentMenuIds) {
      let childMenus = menus.filterBy('superior.id', currentMenu.id);
      let hasChildren = childMenus.length > 0;
      let menuData = {
        id: currentMenu.get('id'),
        text: currentMenu.get('nombre'),
        state: {
          opened: true,
          selected: !hasChildren && currentMenuIds.includes(currentMenu.get('id'))
        },
        children: Ember.A([])
      };
      childMenus.forEach(menu => menuData.children.pushObject(this.buildMenuTree(menu, menus, currentMenuIds)));
      return menuData;
    },

    actions: {
      jstreeDidChange() {
        // WARNING: On first load, selected nodes are empty.
        if (!this.jstreeSelectedNodes) {
          return;
        }

        let selectedNodeIds = this.jstreeSelectedNodes.mapBy('id');
        let menus = this.menus.filter(menu => selectedNodeIds.includes(menu.id));
        this.changeset.set('menus', menus);
      }

    }
  });

  _exports.default = _default;
});