define("sirvo-web/services/current-company", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    toast: Ember.inject.service(),
    loader: Ember.inject.service(),
    user: null,
    company: null,
    modulos: null,
    parameters: null,
    empParameters: null,
    karParameters: null,
    lastCierre: null,
    kardexModule: Ember.computed.filterBy('modulos', 'directorio', 'KARDEX'),
    karDecimalesConfig: Ember.computed('karParameters', function () {
      return {
        costos: this.karParameters ? this.karParameters.get('decimalesCostos') || 4 : 4,
        cantidades: this.karParameters ? this.karParameters.get('decimalesCantidades') || 2 : 2
      };
    }),

    load() {
      const token = this.session.data.authenticated.token;

      if (!token) {
        throw Error('User not authenticated.');
      }

      const authenticator = Ember.getOwner(this).lookup('authenticator:jwt');
      const userData = authenticator.getTokenData(token).username.split('@', 2);

      if (!userData || userData.length < 2) {
        throw Error('Empresa code not found from session data.');
      }

      const codempresa = userData[1];
      this.loader.setLoading(true);
      return this.store.query('empresa', {
        filter: {
          codempresa: {
            exact: codempresa
          }
        }
      }).then(result => {
        if (0 === result.get('length')) {
          throw Error(`Got no record for empresa '${codempresa}'.`);
        }

        if (1 !== result.get('length')) {
          throw Error(`Got more than one record for empresa '${codempresa}'.`);
        }

        this.set('company', result.get('firstObject'));
      }).then(() => Ember.RSVP.all([this.loadModulos(), this.loadParameters(), this.loadEmpParameters()])).then(() => this.loadKarParameters()).then(() => this.loadUserBodegas()).then(() => this.loadLastCierre()).then(() => this.company).catch(error => {
        this.toast.error('No es posible acceder en este momento.');
        throw error;
      }).finally(() => this.loader.setLoading(false));
    },

    loadModulos() {
      return this.store.query('modulo', {}).then(modulos => {
        this.set('modulos', modulos);
      });
    },

    loadParameters() {
      return Ember.RSVP.hash({
        empParameters: this.store.query('parametro', {
          filter: {
            empresa: this.company.id
          }
        }),
        globalParameters: this.store.query('parametro', {
          filter: {
            empresa: {
              exists: false
            }
          }
        })
      }).then(({
        empParameters,
        globalParameters
      }) => {
        let parameters = [...empParameters.toArray(), ...globalParameters.toArray()].reduce((parametersObj, parameterObj) => {
          let parameter = Ember.String.camelize(parameterObj.get('parametro').trim());
          let valor = parameterObj.get('valor').trim();

          if (!isNaN(parseFloat(valor))) {
            parametersObj.set(parameter, parseFloat(valor));
          } else if (['true', 'yes', '1'].includes(valor)) {
            parametersObj.set(parameter, true);
          } else if (['false', 'no', '0'].includes(valor)) {
            parametersObj.set(parameter, false);
          } else {
            parametersObj.set(parameter, valor);
          }

          return parametersObj;
        }, new Ember.Object());
        this.set('parameters', parameters);
      });
    },

    loadEmpParameters() {
      if (!this.company) {
        return Ember.RSVP.resolve(true);
      }

      return this.store.query('emp-parametros-generales', {
        filter: {
          empresa: this.company.id
        }
      }).then(empParameters => {
        this.set('empParameters', empParameters.get('firstObject'));
      });
    },

    loadKarParameters() {
      if (!this.company || !this.kardexModule.length) {
        return Ember.RSVP.resolve(true);
      }

      return this.store.query('kar-parametro', {
        filter: {
          empresa: this.company.id
        }
      }).then(karParameters => {
        this.set('karParameters', karParameters.get('firstObject'));
      });
    },

    loadUserBodegas() {
      if (!this.user || !this.kardexModule.length) {
        return Ember.RSVP.resolve(true);
      }

      return this.store.query('vf-user-kar-bodega', {
        filter: {
          usuario: this.user.id
        },
        include: 'bodega'
      }).then(userBodegas => {
        this.user.set('bodegas', userBodegas.mapBy('bodega'));
      });
    },

    loadLastCierre() {
      if (!this.company || !this.kardexModule.length) {
        return Ember.RSVP.resolve(true);
      }

      return this.store.query('kar-cierre', {
        filter: {
          empresa: this.company.id,
          page_number: 1,
          page_size: 1
        },
        sort: '-fecha'
      }).then(lastCierre => {
        this.set('lastCierre', lastCierre.get('firstObject'));
      });
    },

    setCompany(company) {
      this.set('company', company);
    },

    reload() {
      return this.company.reload().then(company => {
        this.set('company', company);
      });
    }

  });

  _exports.default = _default;
});