define("sirvo-web/controllers/inventario/procesos/inventario-fisico/index/record", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    indexCtrl: Ember.inject.controller('inventario.procesos.inventario-fisico.index'),
    kardex: Ember.inject.service(),
    request: Ember.inject.service(),
    indexRoute: null,
    postSaveTransitionRoute: Ember.computed.readOnly('routeName'),

    postSave()
    /*record*/
    {
      this.indexCtrl.reloadTable();
    },

    submit() {
      let superRef = this._super.bind(this);

      return Ember.RSVP.all([this.changeset.validate(), ...this.ubicacionesChangesets.map(changeset => changeset.validate())]).then(() => {
        if (this.changeset.get('isInvalid')) {
          this.toast.error('Hay un problema de validación con el formulario.');
          return;
        }

        let ubicacionesChangesetsValid = this.ubicacionesChangesets.reduce((prevIsValid, changeset) => prevIsValid && changeset.get('isValid'), true);

        if (!ubicacionesChangesetsValid) {
          this.toast.error('Hay un problema con las ubicaciones.');
          return;
        }

        this.ubicacionesChangesets.forEach(changeset => changeset.execute());
        return superRef();
      });
    },

    discardChanges() {
      this.changeset.rollback();
      this.ubicacionesChangesets.forEach(changeset => changeset.rollback()); // Eliminar los registros de ubicación.

      this.kardex.destroyUnsavedUbicaciones(this.record.get('ubicaciones')); // Es necesario que esto ocurra después de eliminar las ubicaciones.

      this.record.rollbackAttributes();
    },

    actions: {
      onClose() {
        this.discardChanges();
        this.transitionToRoute(this.indexRoute);
      },

      bodegaUpdated() {
        // Resetear el valor seleccionado de cada ubicación.
        this.ubicacionesChangesets.forEach(ubicacionChangeset => {
          if (ubicacionChangeset.get('articuloUbicacion.id')) {
            ubicacionChangeset.set('articuloUbicacion', null);
          }
        });
        this.kardex.updateBodegaUbicaciones(this.changeset.get('bodega'), this.ubicacionesMeta);
      },

      ubicacionUpdated(ubicacionChangeset, articuloUbicacion) {
        let tipoUbicacion = ubicacionChangeset.get('tipoUbicacion'); // Resetear el valor seleccionado de las ubicaciones dependientes.

        this.ubicacionesChangesets.forEach(ubicacionChangeset => {
          if (ubicacionChangeset.get('tipoUbicacion').get('tipoUbicacionSuperior.id') === tipoUbicacion.get('id')) {
            if (ubicacionChangeset.get('articuloUbicacion.id')) {
              ubicacionChangeset.set('articuloUbicacion', null);
            }
          }
        });
        this.kardex.updateUbicacionSubDivisiones(tipoUbicacion, articuloUbicacion, this.ubicacionesMeta);
      },

      cerrarInventario() {
        this.send('openModal', 'popups/simple-confirm', {
          title: 'Cerrar inventario',
          message: '¿Está seguro que quiere cerrar el inventario? Ya no se podrá modificar.',
          onSubmit: () => {
            this.changeset.set('cerrado', true);
            this.submit();
          }
        });
      },

      abrirInventario() {
        this.send('openModal', 'popups/simple-confirm', {
          title: 'Cerrar inventario',
          message: '¿Está seguro que quiere abrir el inventario?',
          onSubmit: () => {
            this.changeset.set('cerrado', false);
            this.submit();
          }
        });
      },

      generarInventario() {
        if (!this.record.get('id')) {
          return;
        }

        this.send('openModal', 'popups/simple-confirm', {
          title: '¿Está seguro de generar los registros de inventario?',
          message: `
          Este proceso puede demorar varios minutos. Se calculará el inventario
          teórico de todos los artículos en la bodega seleccionada y en todas
          sus ubicaciones.
        `,
          onSubmit: () => {
            this.loader.setLoading(true);
            this.request.post(`kar-inventario-fisicos/${this.record.get('id')}/generar`).then(() => {
              this.toast.success('Inventario generado correctamente.');
            }).finally(() => this.loader.setLoading(false));
          }
        });
      },

      generarNuevoConteo() {
        if (!this.record.get('id')) {
          return;
        }

        this.send('openModal', 'popups/simple-confirm', {
          title: 'Generar nuevo conteo',
          message: '¿Está seguro que quiere habilitar un nuevo conteo para todos los artículos?',
          onSubmit: () => {
            this.loader.setLoading(true);
            this.request.post(`kar-inventario-fisicos/${this.record.get('id')}/generar-conteo`).then(() => {
              this.toast.success('Conteo generado correctamente.');
            }).finally(() => this.loader.setLoading(false));
          }
        });
      }

    }
  });

  _exports.default = _default;
});