define("sirvo-web/validations/ods/informe-buceo", ["exports", "ember-changeset-validations/validators", "servir-ember-utilities/validators/is-hour"], function (_exports, _validators, _isHour) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    ordenDocumento: (0, _validators.validatePresence)(true),
    numero: (0, _validators.validatePresence)(true),
    fechaInicio: (0, _validators.validatePresence)(true),
    horaInicio: (0, _isHour.default)(),
    fechaFin: (0, _validators.validatePresence)(true),
    horaFin: (0, _isHour.default)(),
    buzos: (0, _validators.validatePresence)(true),
    librasInicio: (0, _validators.validatePresence)(true),
    librasFin: (0, _validators.validatePresence)(true),
    profundidad: (0, _validators.validatePresence)(true),
    narrativa: (0, _validators.validatePresence)(true)
  };
  _exports.default = _default;
});