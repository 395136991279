define("sirvo-web/routes/ods/servicios/ordenes-servicio/record/generales", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute"], function (_exports, _isTabSubroute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_isTabSubroute.default, {
    setupController()
    /*controller, model*/
    {
      this._super(...arguments);

      let changeset = this.controller.changeset;
      let serviciosAplicables = Ember.A([]);
      changeset.get('tiposServicios').forEach(tipoServicio => {
        serviciosAplicables.pushObjects(tipoServicio.get('servicios').toArray());
      }); // Quitar servicios que no aplican (inconsistencia), a la espera que el usuario
      // le de Guardar a la orden de servicio.
      // WARNING: Convertir en Array para no obtener elementos indefinidos.

      changeset.get('servicios').toArray().forEach(servicio => {
        if (!serviciosAplicables.includes(servicio)) {
          changeset.get('servicios').removeObject(servicio);
        }
      }); // Construir el check list de servicios por tipo, marcando los que están asignados.

      let serviciosPorTipoServicio = changeset.get('tiposServicios').map(tipoServicio => {
        let selected = changeset.get('servicios').filter(servicio => tipoServicio.get('servicios').includes(servicio));
        return {
          tipoServicio,
          selected
        };
      });
      this.controller.set('serviciosPorTipoServicio', serviciosPorTipoServicio);
    }

  });

  _exports.default = _default;
});