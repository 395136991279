define("sirvo-web/controllers/ods/servicios/tipos-orden/record", ["exports", "servir-ember-utilities/controllers/abstract-module-record", "sirvo-web/validations/com/excel-upload", "sirvo-web/models/ods/estado-hechos-plantilla"], function (_exports, _abstractModuleRecord, _excelUpload, _estadoHechosPlantilla) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    uploader: Ember.inject.service('ods/uploader'),
    errorHandler: Ember.inject.service(),
    user: Ember.computed.readOnly('currentUser.user'),
    postSaveTransitionRoute: Ember.computed.readOnly('routeName'),
    plantillaChangeset: null,
    plantillaPdfChangeset: null,
    reload: true,
    estadoHechosClass: _estadoHechosPlantilla.default,
    estadosHechosFilters: Ember.computed('record', function () {
      return [{
        selectedFilter: {
          filterName: 'tipoOrden'
        },
        value: this.record.get('id') || -1
      }];
    }),
    estadosHechosColumns: Ember.computed(function () {
      return Ember.A([{
        label: 'Descripción',
        valuePath: 'descripcion',
        sortable: false,
        width: '250px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.editRecord.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.deleteRecord.bind(this)
          }
        }
      }]);
    }),

    resetProperties() {
      this.setProperties({
        reload: true,
        plantillaChangeset: null,
        plantillaPdfChangeset: null
      });
    },

    createPlantillaChangeset() {
      this.set('plantillaChangeset', this.uploader.getNewUploadChangeset(_excelUpload.blankExcelUpload));
    },

    createPlantillaPdfChangeset() {
      this.set('plantillaPdfChangeset', this.uploader.getNewUploadChangeset(_excelUpload.blankExcelUpload));
    },

    saveFileChangeset(fileChangeset) {
      let file = fileChangeset ? fileChangeset.get('file') : null;

      if (!file) {
        return Ember.RSVP.resolve(null);
      }

      return fileChangeset.validate().then(() => {
        if (fileChangeset.get('isInvalid')) {
          this.toast.error('Hay errores con el archivo.');
          return Ember.RSVP.reject(null);
        }

        return this.uploader.uploadFile(file, {
          uploadRelatedModel: fileChangeset
        });
      });
    },

    processSave(changeset) {
      let superRef = this._super.bind(this, ...arguments);

      let saveFileChangesets = {
        plantilla: this.saveFileChangeset(this.plantillaChangeset),
        plantillaPdf: this.saveFileChangeset(this.plantillaPdfChangeset)
      };
      return Ember.RSVP.hash(saveFileChangesets).then(({
        plantilla,
        plantillaPdf
      }) => {
        // Registrar la plantilla en el tipo de orden.
        plantilla && changeset.set('plantilla', plantilla);
        plantillaPdf && changeset.set('plantillaPdf', plantillaPdf); // Limpiar changesets de plantillas.

        plantilla && this.set('plantillaChangeset', null);
        plantillaPdf && this.set('plantillaPdfChangeset', null);
        return superRef();
      });
    },

    actions: {
      discardChanges() {
        this.changeset.rollback();
        this.record.rollbackAttributes();
        this.transitionToRoute(this.get('indexRoute'));
      },

      downloadPlantilla(propertyName = 'plantilla') {
        if (!this.changeset.get(`${propertyName}.id`)) {
          return;
        }

        this.uploader.downloadFile(this.changeset.get(`${propertyName}.id`));
      },

      deletePlantilla(propertyName = 'plantilla') {
        return this.changeset.validate().then(() => {
          if (this.changeset.get('isInvalid')) {
            this.toast.error('Corrija los errores del formulario primero.');
            return;
          }

          this.send('openModal', 'popups/simple-confirm', {
            message: '¿Está seguro de eliminar el archivo?',
            onSubmit: () => {
              this.changeset.set(propertyName, null);
              this.submit().then(() => {
                if (propertyName === 'plantillaPdf') {
                  this.createPlantillaPdfChangeset();
                } else {
                  this.createPlantillaChangeset();
                }
              });
            }
          });
        });
      },

      addRecord() {
        if (this.record.get('isNew')) {
          return;
        }

        this.transitionToRoute(`${this.routeName}.estado-hechos-plantilla`, 'new');
      },

      editRecord(item) {
        this.transitionToRoute(`${this.routeName}.estado-hechos-plantilla`, item.get('id'));
      },

      deleteRecord(item
      /*, row, lightTable*/
      ) {
        this.send('openModal', 'popups/simple-confirm', {
          title: '¿Está seguro de eliminar el registro?',
          message: '',
          onSubmit: () => {
            item.destroyRecord().then(() => this.set('reload', true)).catch(error => this.errorHandler.handleBackendErrors(error));
          }
        });
      }

    }
  });

  _exports.default = _default;
});