define("sirvo-web/routes/inventario/movimientos/cortesias/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    company: Ember.computed.readOnly('currentCompany.company'),
    codTipoDocumento: 'NDC',
    indexRoute: 'index',

    model() {
      return Ember.RSVP.hash({
        tipoDocumento: this.store.query('kar-doc', {
          filter: {
            empresa: this.company.get('id'),
            deha: 'H',
            codtipo: this.codTipoDocumento
          },
          include: 'tipoMovimiento'
        })
      }).then(({
        tipoDocumento
      }) => {
        if (!tipoDocumento.length === 0) {
          this.toast.error(`No se encontró tipo de documento con código ${this.codTipoDocumento}.`);
          return this.transitionTo(this.indexRoute);
        }

        if (tipoDocumento.length > 1) {
          this.toast.error(`Más de un tipo de documento encontrado con código ${this.codTipoDocumento}.`);
          return this.transitionTo(this.indexRoute);
        }

        let tipoDocumentoObject = tipoDocumento.get('firstObject');
        let tipoMovimiento = tipoDocumentoObject.get('tipoMovimiento');
        return {
          tipoDocumento: tipoDocumentoObject,
          tipoMovimiento: tipoMovimiento
        };
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('tipoMovimiento', model.tipoMovimiento);
      controller.set('tipoDocumento', model.tipoDocumento);
      controller.set('reload', true);
    }

  });

  _exports.default = _default;
});