define("sirvo-web/routes/ods/tablas/buques/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "sirvo-web/mixins/permission-validator", "sirvo-web/validations/ods/buque"], function (_exports, _abstractModuleRecord, _permissionValidator, _buque) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_permissionValidator.default, {
    routePermission: 'OdsCrearBuque',
    modelName: 'ods/buque',
    indexRoute: 'ods.tablas.buques',
    relatedModelsToQuery: Ember.computed(function () {
      let relatedModels = [{
        name: 'tiposBuques',
        modelName: 'ods/tipo-buque'
      }, {
        name: 'productos',
        modelName: 'ods/producto'
      }];
      return relatedModels;
    }),
    validations: Ember.computed(function () {
      return { ..._buque.default
      };
    })
  });

  _exports.default = _default;
});