define("sirvo-web/templates/ods/servicios/tipos-orden/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Vj2E0kN0",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"w-100 bg-white border-bottom px-4\"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-12 py-4\"],[8],[0,\"\\n      \"],[6,\"h3\"],[8],[0,\"\\n        Tipos de orden\\n\\n        \"],[6,\"div\"],[10,\"class\",\"pull-right\"],[8],[0,\"\\n          \"],[4,\"bs-button\",null,[[\"type\",\"onClick\"],[\"success\",[26,\"action\",[[21,0,[]],\"addRecord\"],null]]],{\"statements\":[[0,\"\\n            Agregar\\n          \"]],\"parameters\":[]},null],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-12\"],[8],[0,\"\\n      \"],[1,[26,\"tables/paginated-table\",null,[[\"columns\",\"modelName\",\"modelClassObject\",\"pageSize\",\"tableClassNames\"],[[21,0,[\"columns\"]],[21,0,[\"modelName\"]],[21,0,[\"modelClass\"]],10,\"ember-table thead-gray\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[20,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/ods/servicios/tipos-orden/index.hbs"
    }
  });

  _exports.default = _default;
});