define("sirvo-web/controllers/facturas-cxp-dte/emitir", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "moment", "sirvo-web/mixins/reports-fechas-filters", "ember-changeset", "ember-changeset-validations"], function (_exports, _abstractModuleIndex, _moment, _reportsFechasFilters, _emberChangeset, _emberChangesetValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend(_reportsFechasFilters.default, {
    request: Ember.inject.service(),
    selectionService: Ember.inject.service(),
    lastRouteService: Ember.inject.service('last-route'),
    editRoute: 'contrasenas.movimientos.ordenes-de-compra.emitir.poliza-modal',
    company: Ember.computed.readOnly('currentCompany.company'),
    periodo: Ember.computed(function () {
      return (0, _moment.default)().format('YYYY-01');
    }),
    data: null,
    dataOriginal: null,
    dropdownOptionCentroDeCosto: null,
    usaServicios: false,
    tieneCentroDeCosto: false,
    searchDirectorio: null,
    selectedCentroDeCosto: null,
    dropdownCuentaProvision: null,
    cuentaProvisionSeleccionada: null,
    transaccionCreated: null,
    keytraContrasenaObtenido: null,
    sendOrdenes: null,
    isService: false,
    lastRoute: 'facturas-cxp-dte.emitir',
    proveedor: null,
    botonAgregarFactura: false,
    cuentaCargoSeleccionada: null,
    opcionesCuentasCargo: null,
    filteredData: null,
    dropdownOptionSatDocumentoFel: null,
    selectedSatDocumentoFel: null,
    saveResult: null,
    openModal: false,
    openModalPolizaPrincipal: false,
    isPolizaModalOpen: false,
    showFilterMenu: false,
    selectDirectorio: null,
    isModalDocumentos: false,
    isProcesarFacturas: false,
    dropdownDirectorios: null,
    diasCR: null,
    filterCodigo: '',
    fechaDocumento: null,
    fechaVencimiento: null,
    tipoDocumentosOpciones: null,
    tipoDocumentoSeleccionado: null,
    documentosEmpresa: null,
    tipoIVA: null,
    tipoIVASeleccionado: null,
    listaFacturas: null,
    documentosFELItems: null,
    fechaOrdenCompraSeleccionada: null,
    fechaActual: Ember.computed(function () {
      let fechaActual = (0, _moment.default)();
      let fechaToDate = fechaActual.toDate();
      return (0, _moment.default)(fechaToDate).format('YYYY-MM-DD');
    }),
    mesAnio: Ember.computed(function () {
      let fechaActual = (0, _moment.default)();
      let formattedDate = fechaActual.format('YYYY-MM');
      return formattedDate;
    }),
    nitSeleccionado: null,
    amountBienes: 0,
    amountServicios: 0,
    baseGravadaBienes: null,
    impuestosBienes: null,
    impuestosServicios: null,
    baseGravadaServicios: null,
    totalImpuestos: null,
    totalSinImpuestos: null,
    totalFinal: null,
    ordenesSeleccionadas: null,
    inputCombustible: null,
    valorInputCombustible: null,
    fechaInicio: null,
    ivaNitObject: null,
    fechaFin: null,
    habilitarTabla: false,
    mensajeMostrado: false,
    changeset: Ember.computed(function () {
      return new _emberChangeset.default(this, (0, _emberChangesetValidations.default)({}), this.validations);
    }),
    columns: Ember.computed(function () {
      return [{
        label: '',
        valuePath: 'status',
        sortable: false,
        cellComponent: 'checkbox-cell-status',
        width: '50px'
      }, {
        label: 'Serie',
        valuePath: 'serie',
        width: '60px'
      }, {
        label: 'Número',
        valuePath: 'numero',
        width: '60px'
      }, {
        label: 'Proveedor',
        valuePath: 'nombreEmisor',
        width: '60px'
      }, {
        label: 'NIT emisor',
        valuePath: 'nitEmisor',
        width: '60px'
      }, {
        label: 'Fecha de emisión',
        valuePath: 'fechaHoraEmision',
        width: '60px'
      }, {
        label: 'Valor total',
        valuePath: 'valorTotal',
        width: '100px',
        cellComponent: 'total-float-tabla',
        cellClassNames: 'align-right'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: {
          edit: {
            title: 'Registrar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.abrirModal.bind(this)
          }
        }
      }];
    }),

    init() {
      this._super(...arguments);

      this.dropdownOptionCentroDeCosto = [];
    },

    areAllChecked: Ember.computed('data.@each.status', function () {
      let allData = this.get('data');

      if (!allData || allData.length === 0) {
        return false;
      }

      return allData && allData.every(item => item.status === '1');
    }),

    async getDirectorios(nit) {
      return await this.store.query('gru-directorio', {
        filter: {
          nitSinGuiones: nit,
          fechabaja: 'NULL'
        }
      }).then(directorioList => {
        this.set('dropdownDirectorios', directorioList);
        let primerDirectorio = directorioList.get('firstObject');

        if (primerDirectorio) {
          this.send('selectDirectorio', primerDirectorio);
          this.send('loadClasificaciones');
        }

        return directorioList;
      }).catch(error => {
        this.toast.error('No  existen directorios asociados con el  NIT ' + nit);
        this.toast.error('Error ' + error);
        this.set('selectDirectorio', null);
        this.set('dropdownDirectorios', []);
      });
    },

    getCentrosDeCosto() {
      this.loader.setLoading(true);
      return this.store.query('emp-cc', {
        filter: {
          empresa: this.changeset.get('id'),
          // periodo: this.periodoPoliza.id,
          fechaInicioPeriodo: {
            before: this.fechaActual
          },
          fechaFinPeriodo: {
            after: this.fechaActual
          },
          ultimoNivel: 1
        }
      }).then(response => {
        return response;
      }).catch(error => {
        this.toast.error('Error al cargar los centros de costo. ' + error);
      }).finally(() => {
        this.loader.setLoading(false);
      });
    },

    async searchDirectorios(term) {
      try {
        let results = await this.request.get('directorios/razon-social-codigo', {
          term: term
        });
        this.set('dropdownDirectorios', results.data);
      } catch (error) {
        this.toast.error('Error al realizar la búsqueda: ', error);
        return [];
      }
    },

    reloadTable() {
      this.set('reload', true);
    },

    getSerializedFilters() {
      this.fechaFin = this.changeset.get('fechaFin');
      let filters = {
        codigoEmpresa: this.changeset.get('codigoEmpresa'),
        fechaFin: this.fechaFin ? (0, _moment.default)(this.fechaFin).format() : null
      };
      return filters;
    },

    getFiltersPost() {
      this.fechaFin = this.changeset.get('fechaFin');
      let filters = {
        codigoEmpresa: this.changeset.get('codigoEmpresa'),
        fechaFin: this.fechaFin ? (0, _moment.default)(this.fechaFin).format() : null,
        data: this.data
      };
      return filters;
    },

    getPeriodo() {
      this.loader.setIsLoading();
      return this.store.query('emp-periodo', {
        filter: {
          empresa: this.company.codempresa,
          fechaInicioPeriodo: {
            before: this.fechaActual
          },
          fechaFinPeriodo: {
            after: this.fechaActual
          }
        }
      }).finally(() => this.loader.setNotLoading());
    },

    formatNumberWithCommas(value) {
      let cleanedValue = value.toString().replace(/,/g, '');
      let numerico = parseFloat(cleanedValue);

      if (isNaN(numerico)) {
        return '0.00';
      }

      let parts = numerico.toFixed(2).split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return parts.join('.');
    },

    setUsaServiciosOrBienes(value) {
      this.loader.setIsLoading();

      try {
        if (this.usaServicios === true) {
          this.set('amountServicios', this.formatNumberWithCommas(value));
          this.set('amountBienes', 0.0);
          this.notifyPropertyChange('amountServicios');
          this.notifyPropertyChange('amountBienes');
          return;
        }

        this.set('amountBienes', this.formatNumberWithCommas(value));
        this.set('amountServicios', 0.0);
        this.notifyPropertyChange('amountServicios');
        this.notifyPropertyChange('amountBienes');
      } catch (error) {
        this.toast.error('Error al calcular el valor de los servicios o bienes: ' + error);
      } finally {
        this.loader.setNotLoading();
      }
    },

    async searchNit() {
      this.loader.setIsLoading();
      return await this.store.query('iva-nit', {
        filter: {
          nit: this.selectDirectorio.nit
        }
      }).then(ivaNit => {
        let ivaNitFirst = ivaNit.get('firstObject');
        let valorTotal = parseFloat(this.amountBienes.toString().replace(/,/g, '')) + parseFloat(this.amountServicios.toString().replace(/,/g, ''));

        if (ivaNitFirst) {
          this.set('usaServicios', ivaNitFirst.usaServicios);
          this.notifyPropertyChange('usaServicios');
          this.set('ivaNitObject', ivaNitFirst);
          this.setUsaServiciosOrBienes(valorTotal);
          return ivaNitFirst;
        }

        this.set('notFound', false);
        this.set('ivaNitObject', ivaNitFirst);
        return ivaNitFirst;
      }).catch(error => {
        this.toast.error('Error al buscar el NIT: ' + error);
        this.set('notFound', true);
      }).finally(() => this.loader.setNotLoading());
    },

    actions: {
      searchCentroDeCosto(term, dropdown) {
        return dropdown.options.filter(item => {
          return item.codcc.toLowerCase().includes(term.toLowerCase()) || item.descripcion.toLowerCase().includes(term.toLowerCase());
        });
      },

      cuentaCargoSeleccionada(cuentaCargoSeleccionada) {
        this.set('cuentaCargoSeleccionada', cuentaCargoSeleccionada);

        if (this.cuentaCargoSeleccionada.tieneCentroDeCosto === 1) {
          this.set('tieneCentroDeCosto', true);
          let centrosDeCostos = this.getCentrosDeCosto();
          this.set('dropdownOptionCentroDeCosto', centrosDeCostos);
          return;
        } else {
          this.set('dropdownOptionCentroDeCosto', []);
          this.set('selectedCentroDeCosto', false);
          this.set('tieneCentroDeCosto', false);
        }
      },

      selectedCentroDeCosto(centroDeCosto) {
        this.set('selectedCentroDeCosto', centroDeCosto);
      },

      async createIvaNit() {
        if (this.notFound) {
          let ivaNit = this.store.createRecord('iva-nit', {
            nit: this.selectDirectorio.nit,
            codpais: this.selectDirectorio.codpais,
            nombre: this.selectDirectorio.nombre
          });
          return await ivaNit.save().then(ivaNit => {
            this.set('ivaNit', ivaNit);
            this.set('notFound', false);
            return ivaNit;
          }).catch(error => {
            this.toast.error('Error al crear el NIT: ' + error);
          });
        }
      },

      setLastRoute() {
        this.set('lastRouteService.lastRoute', 'facturas-cxp-dte.emitir');
      },

      applyRightTextAlign(element) {
        element.style.textAlign = 'right';
      },

      formatearCombustible() {
        const valorFiltrado = parseFloat(this.valorInputCombustible).toFixed(2);
        this.set('valorInputCombustible', valorFiltrado);
        this.send('calculateTaxAndTotal');
      },

      seleccionarTipoDocumento(tipoDoc) {
        if (tipoDoc.tipoDocIva === 'FPC') {
          let tipoIVASeleccionado = this.tipoImpuestoOpciones.find(tipoIVA => tipoIVA.tipo === 'P.CONTRIB');

          if (tipoIVASeleccionado) {
            this.send('seleccionarTipoIVa', tipoIVASeleccionado);
          }
        }

        this.set('tipoDocumentoSeleccionado', tipoDoc);
      },

      seleccionarTipoIVa(tipoIVA) {
        this.set('tipoIVASeleccionado', tipoIVA);
        this.send('calculateTaxAndTotal');
      },

      calculateTaxAndTotal() {
        let porcentajeDeIVAProcesado = parseFloat(this.tipoIVASeleccionado.porcentaje) / 100 || 0.0;
        let combustibleTotal = this.valorInputCombustible ? parseFloat(this.valorInputCombustible.toString().replace(/,/g, '')) || 0.0 : null;
        let cantidadBienesProcesados = this.amountBienes ? parseFloat(this.amountBienes.toString().replace(/,/g, '')) || 0.0 : 0.0;
        let cantidadServiciosProcesados = this.amountServicios ? parseFloat(this.amountServicios.toString().replace(/,/g, '')) || 0.0 : 0.0;
        let montoSinIVABienes = Math.max(cantidadBienesProcesados - combustibleTotal, 0);
        let combustibleRestante = combustibleTotal > cantidadBienesProcesados ? combustibleTotal - cantidadBienesProcesados : 0;
        let montoSinIVAServicios = Math.max(cantidadServiciosProcesados - combustibleRestante, 0);
        let bienesBaseGravada = parseFloat((montoSinIVABienes / (1 + porcentajeDeIVAProcesado)).toFixed(2));
        let serviciosBaseGravada = parseFloat((montoSinIVAServicios / (1 + porcentajeDeIVAProcesado)).toFixed(2));
        let impuestoSobreBienes = parseFloat((montoSinIVABienes - bienesBaseGravada).toFixed(2));
        let impuestoSobreServicios = parseFloat((montoSinIVAServicios - serviciosBaseGravada).toFixed(2));
        let totalImpuestosProcesado = impuestoSobreBienes + impuestoSobreServicios;
        let totalFinal = bienesBaseGravada + serviciosBaseGravada;
        this.setProperties({
          amountBienes: this.formatNumberWithCommas(cantidadBienesProcesados),
          amountServicios: this.formatNumberWithCommas(cantidadServiciosProcesados),
          baseGravadaBienes: this.formatNumberWithCommas(bienesBaseGravada),
          baseGravadaServicios: this.formatNumberWithCommas(serviciosBaseGravada),
          impuestosBienes: this.formatNumberWithCommas(impuestoSobreBienes),
          impuestosServicios: this.formatNumberWithCommas(impuestoSobreServicios),
          totalImpuestos: this.formatNumberWithCommas(totalImpuestosProcesado),
          totalSinImpuestos: this.formatNumberWithCommas(totalFinal),
          totalFinal: this.formatNumberWithCommas(cantidadBienesProcesados + cantidadServiciosProcesados)
        });
      },

      async selectDirectorio(directorio) {
        this.set('selectDirectorio', directorio);

        if (this.selectDirectorio) {
          let nitDirectorio = directorio.nit;
          let diasCrDirectorio = directorio.diasCrCxp;
          let direccion = directorio.direccion;
          this.set('nitSeleccionado', nitDirectorio);
          this.set('diasCR', diasCrDirectorio);
          this.set('direccion', direccion);
          this.notifyPropertyChange('diasCR');
          this.notifyPropertyChange('fechaOrdenCompraSeleccionada');
          this.notifyPropertyChange('nitDirectorio');
          this.notifyPropertyChange('selectDirectorio');
          await this.send('updateFechaPago');
          await this.searchNit();
          this.set('clasificacionSeleccionada', null);
          await this.send('loadClasificaciones');
          return;
        }

        this.toast.info('No se ha seleccionado un directorio.');
      },

      cuentaProvisionSeleccionada(cuenta) {
        this.set('cuentaProvisionSeleccionada', cuenta);
      },

      procesar() {
        this.loader.setIsLoading();
        let dataSend = [{
          codempresa: this.get('company.codempresa'),
          codsistema: 'CXP',
          nit: this.selectDirectorio.nit,
          codtipo: this.tipoDocumentoSeleccionado.codtipo,
          codtipodoctos: this.tipoDocumentoSeleccionado.codtipodoctos,
          codtipoIva: this.tipoDocumentoSeleccionado.codtipo,
          serie: this.serie,
          numero: this.numero,
          deha: this.documentosEmpresa.deha,
          tipoIva: this.tipoIVASeleccionado.tipo,
          valorCombustible: this.valorInputCombustible ? parseFloat(this.valorInputCombustible.replace(/,/g, '')) : null,
          descripcionContable: this.get('descripcionContable'),
          fechaFactura: (0, _moment.default)(this.fechaActual, 'YYYY-MM-DD').toDate(),
          codClasifica: this.clasificacionSeleccionada ? this.clasificacionSeleccionada.codclasifica : null,
          numeroDocumento: this.serie + '-' + this.numero,
          ivaRetencion: 0,
          valordocml: parseFloat(this.totalFinal.replace(/,/g, '')),
          totalBienes: parseFloat(this.totalFinal.replace(/,/g, '')),
          valorme: 0,
          totalServicios: parseFloat(this.baseGravadaServicios.replace(/,/g, '')),
          baseExentaBienes: 0,
          baseExentaServicios: 0,
          tipoDocumentoImpuesto: '',
          numDocumentoImpuesto: '',
          valorOtrosNoGravados: 0,
          ordenCedula: '',
          registroCedula: '',
          iva: parseFloat(this.totalImpuestos.replace(/,/g, '')),
          totalDoc: parseFloat(this.totalFinal.replace(/,/g, '')),
          valor: parseFloat(this.totalFinal.replace(/,/g, '')),
          baseNoGravada: 0,
          baseGrabadaBienes: parseFloat(this.baseGravadaBienes.replace(/,/g, '')),
          baseGravadaServicios: parseFloat(this.baseGravadaServicios.replace(/,/g, '')),
          fechaVencimiento: this.fechaVencimiento,
          direccion: this.direccion,
          diasCredito: this.diasCR,
          yearMes: this.mesAnio,
          codigoDirectorioProveedor: this.selectDirectorio.coddirectorio,
          idIvaNit: this.ivaNitObject ? this.ivaNitObject.id : null,
          usaServicios: this.usaServicios,
          esContrasenaLinea: false,
          idContrasenaLinea: null
        }];
        let camposAVerificar = ['serie', 'numero', 'totalDoc', 'valor', 'iva', 'codempresa', 'codsistema', 'nit', 'codtipo', 'descripcionContable'];

        let encontrarCamposInvalidos = elemento => {
          let camposInvalidos = camposAVerificar.filter(campo => !elemento[campo] && elemento[campo] !== 0);
          return camposInvalidos;
        };

        let camposInvalidos = dataSend.flatMap(encontrarCamposInvalidos);

        if (camposInvalidos.length > 0) {
          this.toast.error(`Algunos campos requeridos están vacíos o son nulos: ${camposInvalidos.join(', ')}.`);
          this.loader.setNotLoading();
          return;
        }

        let json = {
          data: dataSend
        };
        this.request.post('cxp/registrar-factura', json).then(response => {
          this.toast.success(response.message);
          this.set('transaccionCreated', response.data.keytra);
          this.set('dataSend', null);
          this.set('listaFacturas', null);
          this.send('setLastRoute');
          this.send('onclose');
          this.set('openModalPolizaPrincipal', true);
          this.send('reloadPage');
        }).catch(error => {
          this.toast.error(error.status + ' ' + error.statusText);
        }).finally(() => this.loader.setNotLoading());
      },

      reloadPage() {
        this.set('selectDirectorio', null);
        this.set('dropdownDirectorios', []);
      },

      clasificacionSeleccionada(clasificacion) {
        this.set('clasificacionSeleccionada', clasificacion);
        this.send('getCuentaProvision');
      },

      resetImpuestos() {
        this.set('amountBienes', 0.0);
        this.set('amountServicios', 0.0);
        this.set('totalFinal', 0.0);
        this.set('baseGravadaBienes', 0.0);
        this.set('impuestosBienes', 0.0);
        this.set('totalImpuestos', 0.0);
        this.set('baseGravadaServicios', 0.0);
        this.set('impuestosServicios', 0.0);
        this.set('totalSinImpuestos', 0.0);
      },

      selectAndCloseModal(directorio) {
        this.set('selectDirectorio', directorio);
        this.set('openModalDirectorio', false);
      },

      toggleFilterMenu() {
        this.toggleProperty('showFilterMenu');
      },

      applyFilters(event) {
        let filterValue = event.target.value.toLowerCase();
        this.set('filterCodigo', filterValue);

        if (!this.originalData || this.originalData.length === 0) {
          this.set('originalData', [...this.data]);
        }

        let filteredData;

        if (!filterValue) {
          filteredData = [...this.originalData];
          this.set('mensajeMostrado', false);
        } else {
          filteredData = this.originalData.filter(item => {
            return item.nombreEmisor.toLowerCase().includes(filterValue) || item.numero.toLowerCase().includes(filterValue) || item.serie.toLowerCase().includes(filterValue) || item.nitEmisor.toLowerCase().includes(filterValue);
          });
        }

        this.set('data', filteredData.length > 0 ? filteredData : []);

        if (filteredData.length === 0 && !this.noResultsShown) {
          this.toast.info('No hay resultados con ese valor.');
          this.set('noResultsShown', true);
        }

        if (filteredData.length > 0 && this.noResultsShown) {
          this.set('noResultsShown', false);
        }
      },

      toggleCheckboxState(row) {
        this.get('selectionService').toggleRowSelection(row);
      },

      clearFilters() {
        this.set('filterCodigo', '');

        if (this.originalData) {
          this.originalData.forEach(originalItem => {
            let filteredItem = this.filteredData.find(item => item.idtransac === originalItem.idtransac);

            if (filteredItem && filteredItem.status === '1') {
              originalItem.status = '1';
            }
          });
          this.set('data', this.originalData);
          this.set('originalData', null);
        }
      },

      toggleAllCheckboxes(event) {
        if (event && event.type === 'keydown' && event.key !== 'Enter') {
          return;
        }

        let areAllChecked = this.get('areAllChecked');
        let newData = this.get('data').map(item => {
          Ember.set(item, 'status', areAllChecked ? '0' : '1');
          return item;
        });
        this.set('data', newData);
        this.set('areAllChecked', !areAllChecked);
        this.notifyPropertyChange('areAllChecked');
        Ember.run.next(() => {
          let checkboxElement = document.getElementById('selectAllCheckboxes');

          if (checkboxElement) {
            checkboxElement.checked = !areAllChecked;
          }
        });
      },

      uncheckAllCheckboxes() {
        let newData = this.get('data').map(item => {
          Ember.set(item, 'status', '0');
          return item;
        });
        this.set('data', newData);
        this.set('areAllChecked', false);
        this.notifyPropertyChange('areAllChecked');
        Ember.run.next(() => {
          let checkboxElement = document.getElementById('selectAllCheckboxes');

          if (checkboxElement) {
            checkboxElement.checked = false;
          }
        });
      },

      searchFacturasCXP() {
        let fechaInicio = this.changeset.get('fechaInicio');
        let fechaFin = this.changeset.get('fechaFin');

        if (!fechaInicio || !fechaFin) {
          this.toast.error('Debe seleccionar un rango de fechas para buscar.');
          return;
        }

        this.loader.setIsLoading();
        this.set('habilitarTabla', true);
        this.set('filterCodigo', '');
        this.request.get('sat-documentos-fel/contrasenas', {
          fechaInicio: (0, _moment.default)(fechaInicio).format(),
          fechaFin: (0, _moment.default)(fechaFin).format()
        }).then(response => {
          this.set('data', []);
          this.set('data', response.data);
          this.set('originalData', [...response.data]);
          this.toast.success('Documentos FEL obtenidos');
        }).catch(error => {
          this.toast.error('Error al obtener los documentos FEL.');
          this.toast.error(error);
        }).finally(() => this.loader.setNotLoading());
      },

      handleActualizar() {
        this.actions.searchFacturasCXP.call(this);
      },

      obtenerDirectorioPorNombre(term) {
        Ember.run.debounce(this, this.searchDirectorios, term, 500);
      },

      async updateFechaPago() {
        let diasCrCxp = this.selectDirectorio.dias_cr_cxp || this.diasCR;
        this.set('diasCR', diasCrCxp);
        let fechaRegistro = this.fechaActual;
        this.loader.setLoading(true);
        await this.request.get('contrasenas/fecha-pago', {
          diasCrCxp,
          fechaRegistro
        }).then(response => {
          let date = response.data[0].proximoPago;
          let dateMoment = (0, _moment.default)(date, 'DD-MM-YYYY').toDate();
          let fechaVencimiento = (0, _moment.default)(dateMoment).format('YYYY-MM-DD');
          this.set('fechaVencimiento', fechaVencimiento);
          this.notifyPropertyChange('fechaVencimiento');
          this.loader.setNotLoading();
        }).catch(error => {
          this.toast.error('Error al cargar la clasificacion. ' + error);
        });
        this.loader.setNotLoading();
      },

      closePolizaModal() {
        this.set('isPolizaModalOpen', false);
      },

      openPoliza() {
        let id = `${'poliza'}-${this.transaccionCreated}-${this.keytraContrasenaObtenido}`;
        this.transitionToRoute('contrasenas.movimientos.ordenes-de-compra.emitir.poliza-modal', id);
      },

      resetData() {
        this.listaFacturas = [];
        this.set('serie', '');
        this.set('numero', '');
        this.set('selectDirectorio', null);
        this.set('clasificacionSeleccionada', null);
        this.set('dropdownClasificaciones', null);
        this.set('cuentaProvisionSeleccionada', null);
        this.set('dropdownCuentaProvision', null);
        this.set('descripcionContable', null);
        this.set('fechaVencimiento', null);
        this.set('valorInputCombustible', null);
      },

      abrirModal(documentosFEL) {
        this.send('resetData');
        let fecha = null;
        let serie = null;
        let nit = null;
        let numeroFactura = null;
        let valorTotal = documentosFEL.valorTotal;
        valorTotal = valorTotal.replace(/,/g, '');
        fecha = documentosFEL.fechaHoraEmision;
        serie = documentosFEL.serie;
        nit = documentosFEL.nitEmisor;
        numeroFactura = documentosFEL.numero;
        this.set('fechaDocumento', fecha);
        this.getPeriodo();
        this.getDirectorios(nit);
        this.toggleProperty('openModal');
        this.set('fechaDocumento', (0, _moment.default)((0, _moment.default)(fecha, 'DD-MM-YYYY').toDate()).format('YYYY-MM-DD'));
        this.set('serie', serie);
        this.set('numero', numeroFactura);
        this.notifyPropertyChange('fechaDocumento');
        this.send('resetImpuestos');
        this.setUsaServiciosOrBienes(valorTotal);
        this.send('calculateTaxAndTotal');
      },

      onclose() {
        if (this.openModal == true) {
          this.toggleProperty('openModal');
        }
      },

      oncloseModalPolizaPrincipal() {
        if (this.openModalPolizaPrincipal == true) {
          this.set('openModalPolizaPrincipal', false);
        }
      },

      openModalDocumentos() {
        let nitEmisor = this.selectDirectorio.nit;
        this.loader.setLoading(true);
        this.request.get('sat-documentos-fel/contrasenas', {
          nitEmisor
        }).then(response => {
          this.set('dropdownOptionSatDocumentoFel', response.data);
          this.loader.setNotLoading();
          this.set('isModalDocumentos', true);
        }).catch(error => {
          this.toast.error('Error al realizar la consulta: ' + error);
        }).finally(() => this.loader.setNotLoading());
      },

      procesarFacturas() {
        this.send('resetData');
        this.set('isProcesarFacturas', true);
        this.set('selectDirectorio', true);
        this.set('cuentaCargoSeleccionada', true);
        this.listaFacturas = [];
        let tieneSeleccionado = this.data.some(facturasFel => facturasFel.status === '1');

        if (!tieneSeleccionado) {
          this.toast.error('No existen registros seleccionados.');
          return;
        }

        this.send('obtenerCuentasContables');
        this.data.forEach(facturasFel => {
          if (facturasFel.status === '1') {
            let value = facturasFel.valorTotal;
            let nuevoValor = value.replace(/,/g, '');
            Ember.set(facturasFel, 'valorTotal', nuevoValor);
            this.listaFacturas.push(facturasFel);
            this.set('filterCodigo', '');
          }
        });
      },

      getCuentaProvision() {
        this.loader.setIsLoading();
        this.store.query('clasificacion-cuenta', {
          filter: {
            grupo: this.company.get('grupo.id'),
            empresa: this.company.get('id'),
            codclasifica: {
              exact: this.clasificacionSeleccionada.codclasifica
            },
            codperiodo: this.periodo
          }
        }).then(cuentasProvisionResponse => {
          let selectedCuentaProvision = cuentasProvisionResponse.get('firstObject');
          this.set('dropdownCuentaProvision', cuentasProvisionResponse);
          this.set('cuentaProvisionSeleccionada', selectedCuentaProvision);
        }).catch(error => {
          this.toast.error('Error al cargar la cuenta provision. ' + error);
        }).finally(() => this.loader.setNotLoading());
      },

      generarFacturasCXP() {
        try {
          this.loader.setIsLoading();
          let dataSend = [{
            codempresa: this.get('company.codempresa'),
            codsistema: 'CXP',
            nit: this.selectDirectorio.nit,
            codtipo: this.tipoDocumentoSeleccionado.codtipo,
            codtipodoctos: this.tipoDocumentoSeleccionado.codtipodoctos,
            codtipoIva: this.tipoDocumentoSeleccionado.codtipo,
            deha: this.documentosEmpresa.deha,
            codClasifica: this.clasificacionSeleccionada.codclasifica,
            direccion: this.selectDirectorio.direccion,
            diasCredito: this.diasCR,
            yearMes: this.mesAnio,
            codigoDirectorioProveedor: this.selectDirectorio.coddirectorio,
            fechaRegistro: this.fechaActual,
            codigoCuentaContableCargo: this.cuentaCargoSeleccionada.codcuenta,
            idIvaNit: this.ivaNitObject ? this.ivaNitObject.id : null,
            usaServicios: this.usaServicios,
            codigoCuentaContableContraCuenta: this.selectedCentroDeCosto ? this.selectedCentroDeCosto.codcc : ''
          }];
          let json = {
            data: dataSend,
            listado_facturas: this.listaFacturas
          };
          this.request.post('cxp/registrar-facturas', json).then(response => {
            this.toast.success(response.message);
            this.get('selectionService').get('selectedRows').clear();
            this.actions.handleActualizar.call(this);
            this.set('dataSend', null);
            this.set('listaFacturas', null);
            this.send('closeModalProcesarFacturas');
            this.send('reloadPage');
          }).catch(error => {
            this.toast.error(error.status + ' ' + error.statusText);
          }).finally(() => this.loader.setNotLoading());
        } catch (error) {
          this.toast.error('Error al generar registrar facturas a CXP. ' + error);
          this.loader.setNotLoading();
        }
      },

      closeModalDocumentos() {
        this.set('isModalDocumentos', false);
      },

      closeModalProcesarFacturas() {
        this.set('isProcesarFacturas', false);
      },

      addDocumentoSat() {
        if (this.isService) {
          this.set('amountServicios', this.selectedSatDocumentoFel.valorTotal);
        } else {
          this.set('amountBienes', this.selectedSatDocumentoFel.valorTotal);
        }

        this.set('serie', this.selectedSatDocumentoFel.serie);
        this.set('numero', this.selectedSatDocumentoFel.numero);
        let fechaDocumento = (0, _moment.default)(this.selectedSatDocumentoFel.fechaHoraEmision, 'DD/MM/YYYY').format('YYYY-MM-DD');
        this.set('fechaDocumento', fechaDocumento);
        this.send('calculateTaxAndTotal');
        this.set('isModalDocumentos', false);
      },

      loadClasificaciones() {
        this.request.get('gru-directorio-clasifica-by-cod-grupo', {
          codDirectorio: this.selectDirectorio.coddirectorio
        }).then(clasificaciones => {
          this.set('dropdownClasificaciones', clasificaciones.data);
        }).catch(error => {
          this.toast.error('Error al cargar la clasificacion. ' + error);
        });
      },

      obtenerCuentasContables() {
        this.request.get('cxp/cuentas-contables', {
          codempresa: this.get('company.codempresa'),
          codperiodo: this.periodo
        }).then(cuentasContables => {
          this.set('opcionesCuentasCargo', cuentasContables.data);
        }).catch(error => {
          this.toast.error('Error al cargar las cuentas contables. ' + error);
        });
      },

      searchCuentaCargo(term, dropdown) {
        return dropdown.options.filter(item => {
          return item.codcuenta.toLowerCase().includes(term.toLowerCase()) || item.nomcta.toLowerCase().includes(term.toLowerCase()) || item.Naturaleza.toLowerCase().includes(term.toLowerCase()) || item.TIPO.toLowerCase().includes(term.toLowerCase()) || item.Auxiliar.toLowerCase().includes(term.toLowerCase()) || item.naturalezaCuenta.toLowerCase().includes(term.toLowerCase()) || item.Nivel.toLowerCase().includes(term.toLowerCase());
        });
      }

    }
  });

  _exports.default = _default;
});