define("sirvo-web/routes/admon/accesos/permisos/index", ["exports", "sirvo-web/mixins/access-validator"], function (_exports, _accessValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_accessValidator.default, {
    routeAccess: 'ADMON-PERMISOS',

    model() {
      return Ember.RSVP.hash({
        grupos: Ember.A([]),
        permisos: Ember.A([])
      });
    }

  });

  _exports.default = _default;
});