define("sirvo-web/controllers/ods/servicios/ordenes-servicio/record/documentos/estado-hechos", ["exports", "sirvo-web/mixins/com/arrange-items"], function (_exports, _arrangeItems) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_arrangeItems.default, {
    errorHandler: Ember.inject.service(),
    indexRoute: null,
    ordenDocumento: null,
    actividades: null,
    actionsEnabled: false,
    selectedTemplate: null,

    resetProperties() {
      this.setProperties({
        actionsEnabled: false,
        selectedTemplate: null
      });
    },

    createItem(list, values = {}) {
      return this.store.createRecord('ods/estado-hechos-actividad', {
        ordenDocumento: this.ordenDocumento,
        fecha: new Date(),
        ...values
      });
    },

    hasChanged(record) {
      return record.get('hasDirtyAttributes') || record.get('hasChanges');
    },

    validateActividad(actividad) {
      if (!actividad.get('fecha') || !actividad.get('descripcion') || !actividad.get('descripcion').trim() || !actividad.get('hasValidFecha')) {
        return false;
      }

      if (actividad.get('horas') && (actividad.get('horas') < 0 || actividad.get('horas') > 23)) {
        return false;
      }

      if (actividad.get('minutos') && (actividad.get('minutos') < 0 || actividad.get('minutos') > 59)) {
        return false;
      }

      return true;
    },

    saveActividad(actividad) {
      if (!this.validateActividad(actividad)) {
        actividad.set('hasErrors', true);
        return Ember.RSVP.resolve(false);
      }

      return actividad.save().then(record => {
        actividad.set('hasErrors', false);
        return record;
      }).catch(error => {
        actividad.set('hasErrors', true);
        this.errorHandler.handleSaveErrors(error, actividad);
      });
    },

    trySaveActividad(actividad) {
      if (this.hasChanged(actividad) && this.validateActividad(actividad)) {
        this.saveActividad(actividad);
      }
    },

    actions: {
      onClose() {
        this.actividades.forEach(actividad => actividad.rollbackAttributes());
        this.transitionToRoute(this.indexRoute);
      },

      onSubmit() {
        let promises = this.actividades.filter(this.hasChanged.bind(this)).map(this.saveActividad.bind(this));
        return Ember.RSVP.all(promises).then(() => {
          this.toast.success('Registros guardados correctamente.');
          this.transitionToRoute(this.indexRoute);
        }).catch(error => {
          this.toast.error('Error al guardar los registros.');
          throw error;
        });
      },

      onFechaUpdated(actividad) {
        if (this.fechaUpdatedTask) {
          Ember.run.cancel(this.fechaUpdatedTask);
        }

        let now = new Date();
        let current = actividad.get('fecha'); // Preservar la hora porque el componente lo cambia a cero.

        this.fechaUpdatedTask = Ember.run.later(() => {
          if (actividad.get('hasValidFecha')) {
            // TODO: Reflejar esto en la interfaz.
            actividad.get('fecha').setHours(current ? current.getHours() : now.getHours());
            actividad.get('fecha').setMinutes(current ? current.getMinutes() : now.getMinutes());
          }

          this.trySaveActividad(actividad);
        }, 500);
      },

      addItem(list) {
        let newItem = this._super(list);

        let prevItem = list.objectAt(list.length - 2);

        if (prevItem && prevItem.get('fecha')) {
          newItem.set('fecha', prevItem.get('fecha'));
        }
      },

      insertItem(list, item) {
        let newItem = this._super(list, item);

        if (item.get('fecha')) {
          newItem.set('fecha', item.get('fecha'));
        }
      },

      trySaveActividad(actividad) {
        this.trySaveActividad(actividad);
      },

      onSaveActividad(actividad) {
        this.saveActividad(actividad);
      },

      generateFromTemplate(template) {
        if (!template || !template.id) {
          this.toast.error('Seleccione una plantilla.');
          return;
        } // NOTE: En el template está restringido que se pueda llamar esta acción
        // solo cuando aún no hay actividades, pero por si acaso...


        if (this.actividades.length) {
          this.toast.error('Debe eliminar todas las actividades para generar a partir de una plantilla.');
          return;
        }

        this.send('openModal', 'popups/simple-confirm', {
          title: '¿Está seguro de generar a partir de una plantilla?',
          message: `Plantilla seleccionada: <strong>${template.get('descripcion')}</strong>`,
          onSubmit: () => {
            this.loader.setLoading(true);
            this.store.query('ods/estado-hechos-plantilla-actividad', {
              filter: {
                plantilla: template.id
              }
            }).then(actividades => {
              actividades = actividades.sortBy('orden');
              actividades.forEach(actividad => {
                this.actividades.pushObject(this.createItem(this.actividades, {
                  descripcion: actividad.get('descripcion'),
                  orden: actividad.get('orden') || 0
                }));
              });
            }).finally(() => this.loader.setLoading(false));
          }
        });
      }

    }
  });

  _exports.default = _default;
});