define("sirvo-web/models/menu", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    codigo: attr('string'),
    nombre: attr('string'),
    titulo: attr('string'),
    descripcion: attr('string'),
    orden: attr('number'),
    ruta: attr('string'),
    params: attr('string'),
    queryParams: attr('string'),
    activo: attr('boolean', {
      defaultValue: true
    }),
    soloSuperAdmin: attr('boolean'),
    superior: belongsTo('menu', {
      inverse: null
    }),
    activoFormatted: Ember.computed('activo', function () {
      return this.activo ? 'Sí' : 'No';
    })
  });

  _exports.default = _default;
});