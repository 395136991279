define("sirvo-web/controllers/cxp-conciliacion-sat", ["exports", "sirvo-web/utils/date"], function (_exports, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    request: Ember.inject.service(),
    selectedYear: Ember.computed(function () {
      return new Date().getFullYear();
    }),
    years: (0, _date.lastYears)(),
    selectedMonth: Ember.computed(function () {
      return String(new Date().getMonth() + 1).padStart(2, '0');
    }),
    months: _date.monthIndexes,
    actions: {
      generarReporte() {
        this.get('loader').setIsLoading();
        return this.request.get('generar-reporte-conciliacion-sat-cxp', {
          year: this.selectedYear,
          month: this.selectedMonth
        }).then(() => {
          this.toast.success('Reporte generado correctamente');
        }).finally(() => this.loader.setLoading(false));
      }

    }
  });

  _exports.default = _default;
});