define("sirvo-web/controllers/inventario/movimientos/cortesias/detalles", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "sirvo-web/models/kar-movdet"], function (_exports, _abstractModuleIndex, _karMovdet) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    fechaCierre: Ember.computed.readOnly('currentCompany.lastCierre.fecha'),
    modelClass: _karMovdet.default,
    registroCerrado: Ember.computed('changeset.fecha', 'fechaCierre', function () {
      if (!this.fechaCierre || !this.changeset.get('fecha')) {
        return false;
      }

      return this.fechaCierre >= this.changeset.get('fecha');
    }),
    reload: true,
    modelFilters: Ember.computed('record', function () {
      return [{
        selectedFilter: {
          filterName: 'movimiento'
        },
        value: this.record.get('id')
      }];
    }),
    includedRelationships: Ember.computed(function () {
      return ['articulo', 'unidadMedida', 'bodega'];
    }),
    columns: Ember.computed(function () {
      return [{
        label: 'No.',
        valuePath: 'linea',
        width: '80px'
      }, {
        label: 'Artículo',
        valuePath: 'articulo.codarticulo',
        width: '110px'
      }, {
        label: 'Nombre',
        valuePath: 'articulo.descripcion',
        width: '200px'
      }, {
        label: 'U. Medida',
        valuePath: 'unidadMedida.codigo',
        width: '100px',
        sortable: false
      }, {
        label: 'Bodega',
        valuePath: 'bodega.codbodega',
        width: '100px',
        sortable: false
      }, {
        label: 'Cantidad',
        valuePath: 'cantidad',
        width: '90px',
        sortable: false
      }, {
        label: 'Costo unitario',
        valuePath: 'precioUnitario',
        width: '130px',
        sortable: false
      }, {
        label: 'Total',
        valuePath: 'total',
        width: '100px',
        sortable: false
      }, {
        label: 'Existencia posterior',
        cellComponent: 'tables/cell/kar-movdet-post-existencia',
        width: '170px',
        sortable: false
      }];
    }),
    polizaColumns: Ember.computed(function () {
      return [{
        label: 'Cuenta',
        valuePath: 'cuenta',
        width: '110px',
        sortable: false
      }, {
        label: 'Nombre',
        valuePath: 'cuentaNombre',
        width: '200px',
        sortable: false
      }, {
        label: 'Concepto contable',
        valuePath: 'descripcionContable',
        width: '300px',
        sortable: false
      }, {
        label: 'Cargo',
        valuePath: 'cargo',
        width: '100px',
        sortable: false,
        totalValuePath: 'cargo',
        cellComponent: 'tables/cell/accounting-amount'
      }, {
        label: 'Abono',
        valuePath: 'abono',
        width: '100px',
        sortable: false,
        totalValuePath: 'cargo',
        cellComponent: 'tables/cell/accounting-amount'
      }, {
        label: 'Centro costo',
        valuePath: 'centroCosto',
        width: '110px',
        sortable: false
      }, {
        label: 'Centro integración',
        valuePath: 'centroIntegracion',
        width: '150px',
        sortable: false
      }];
    }),
    detalles: Ember.computed(function () {
      return Ember.A([]);
    }),

    refreshPoliza() {
      if (!this.record.id) {
        return [];
      }

      this.loader.setLoading(true);
      return this.request.get(`kar-movs/${this.record.id}/poliza`).then(poliza => {
        this.set('poliza', poliza);
      }).finally(() => this.loader.setLoading(false));
    },

    actions: {
      onClose() {
        this.transitionToRoute(this.indexRoute);
      },

      getReport() {
        if (!this.record.id) {
          this.toast.error('Guarde primero el registro para visualizar el reporte.');
          return;
        }

        this.loader.setLoading(true);
        this.toast.info('Generando reporte');
        return this.request.get(`kar-movs/${this.record.id}/reporte_lineas`).then(() => this.toast.success('Reporte generado exitosamente')).finally(() => this.loader.setLoading(false));
      },

      getPolizaReport() {
        if (!this.record.id) {
          this.toast.error('Guarde primero el registro para visualizar el reporte.');
          return;
        }

        this.loader.setLoading(true);
        this.toast.info('Generando reporte');
        return this.request.get(`kar-movs/${this.record.id}/reporte_poliza`).then(() => this.toast.success('Reporte generado exitosamente')).finally(() => this.loader.setLoading(false));
      },

      refreshPoliza() {
        return this.refreshPoliza();
      }

    }
  });

  _exports.default = _default;
});