define("sirvo-web/controllers/facturacion/envio-factura/emitir", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "moment", "sirvo-web/mixins/reports-fechas-filters", "ember-changeset", "ember-changeset-validations", "@fortawesome/fontawesome-svg-core", "@fortawesome/free-solid-svg-icons"], function (_exports, _abstractModuleIndex, _moment, _reportsFechasFilters, _emberChangeset, _emberChangesetValidations, _fontawesomeSvgCore, _freeSolidSvgIcons) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  _fontawesomeSvgCore.library.add(_freeSolidSvgIcons.faPencilAlt);

  var _default = _abstractModuleIndex.default.extend(_reportsFechasFilters.default, {
    request: Ember.inject.service(),
    editRoute: 'contrasenas.movimientos.ordenes-de-compra.emitir.poliza-modal',
    company: Ember.computed.readOnly('currentCompany.company'),
    periodo: Ember.computed(function () {
      return (0, _moment.default)().format('YYYY-01');
    }),
    data: null,
    lastRoute: 'facturacion.envio-factura.emitir',
    filteredData: null,
    isEnviarFacturas: false,
    htmlTemplate: null,
    selectDirectorio: null,
    dropdownDirectorios: null,
    filterCodigo: '',
    fechaInicio: null,
    fechaFin: null,
    mensajeMostrado: false,
    changeset: Ember.computed(function () {
      return new _emberChangeset.default(this, (0, _emberChangesetValidations.default)({}), this.validations);
    }),
    columns: Ember.computed(function () {
      return [{
        name: '',
        valuePath: 'status',
        cellComponent: 'checkbox-cell-status',
        isFixed: 'left',
        width: 80
      }, {
        name: 'Tipo',
        valuePath: 'tipo',
        isFixed: 'left'
      }, {
        name: 'Serie',
        valuePath: 'serie',
        isFixed: 'left'
      }, {
        name: 'Número',
        valuePath: 'numero',
        isFixed: 'left'
      }, {
        name: 'Fecha Registro',
        valuePath: 'fecha.date'
      }, {
        name: 'Directorio',
        valuePath: 'directorio'
      }, {
        name: 'Nombre',
        valuePath: 'nombre',
        width: 200
      }, {
        name: 'Proceso',
        valuePath: 'proceso',
        width: 80
      }, {
        name: 'Llave',
        valuePath: 'llave',
        width: 250
      }, {
        name: 'Clasificación',
        valuePath: 'clasificacion'
      }, {
        name: 'Descripción',
        valuePath: 'descripcion',
        width: 700
      }, {
        name: 'Doc.Ref.',
        valuePath: 'documentoDeReferencia'
      }, {
        name: 'DeHa',
        valuePath: 'deha'
      }, {
        name: 'CtaCte',
        valuePath: 'usaCuentaCorriente'
      }, {
        name: 'File Cta.Cte',
        valuePath: 'fileCuentaCorriente'
      }, {
        name: 'Moneda',
        valuePath: 'moneda'
      }, {
        name: 'Valor ML',
        valuePath: 'valorMonedaLocal',
        textAlign: 'right',
        cellComponent: 'total-float-tabla'
      }, {
        name: 'Valor ME',
        valuePath: 'valorMonedaExtranjera',
        textAlign: 'right',
        cellComponent: 'total-float-tabla'
      }, {
        name: 'Llave Ref.',
        valuePath: 'llaveReferencia'
      }, {
        name: 'Llave Aux.',
        valuePath: 'llaveAuxiliar'
      }, {
        name: 'Firmado E.',
        cellComponent: 'readonly-checkbox',
        valuePath: 'firmadoElectronicamente'
      }, {
        name: 'FACE Hora',
        valuePath: 'faceHora'
      }, {
        name: 'FACE Generado',
        valuePath: 'faceGenerado',
        width: 250
      }, {
        name: 'FACE Firma E.',
        valuePath: 'faceFirmaElectronica',
        width: 250
      }, {
        name: 'CNT.',
        cellComponent: 'readonly-checkbox',
        valuePath: 'cnt',
        width: 150
      }, {
        name: 'ACT.',
        cellComponent: 'readonly-checkbox',
        valuePath: 'act',
        width: 150
      }, {
        name: 'Descripción Adicional',
        valuePath: 'descripcionAdicional',
        width: 700
      }, {
        name: 'T/C',
        valuePath: 'tasaDeCambio'
      }, {
        name: 'Valor en M.L',
        valuePath: 'valorEnMonedaLocal'
      }, {
        name: 'Tipo IVA',
        valuePath: 'tipoIva'
      }, {
        name: '% IVA',
        valuePath: 'porcentaje'
      }, {
        name: 'Sucursal IVA',
        valuePath: 'sucursalIva'
      }, {
        name: 'Clasif. IVA',
        valuePath: 'clasificacionIva'
      }, {
        name: 'Tipo Doc. IVA',
        valuePath: 'tipoDocumentoIva'
      }, {
        name: 'Sistema',
        valuePath: 'sistema'
      }, {
        name: 'Fec.Anulado',
        valuePath: 'fechaAnulado'
      }, {
        name: 'Usr.Anulado',
        valuePath: 'usuarioAnulado'
      }, {
        name: 'Registro Creación',
        valuePath: 'registroCreacion',
        width: 500
      }, {
        name: 'Acciones',
        cellComponent: 'tables/object-actions-table',
        table: this,
        isFixed: 'right',
        objectActions: {
          edit: {
            title: 'Enviar',
            buttonClass: 'btn-primary',
            icon: 'pencil-alt',
            action: this.actions.abrirModal.bind(this)
          }
        }
      }];
    }),

    init() {
      this._super(...arguments);

      let destinatario = this.changeset.get('destinatario');

      if (!Array.isArray(destinatario)) {
        this.changeset.set('destinatario', Ember.A());
      }
    },

    actions: {
      updateEmail(selected) {
        this.changeset.set('email', selected);
      },

      async selectDirectorio(directorio) {
        this.set('selectDirectorio', directorio);

        if (this.selectDirectorio) {
          let direccion = directorio.direccion;
          this.set('direccion', direccion);
          this.notifyPropertyChange('selectDirectorio');
          this.send('updateFechaPago');
          this.searchNit();
          return;
        }

        this.toast.info('No se ha seleccionado un directorio.');
      },

      async procesar() {
        this.loader.setIsLoading();
        let changeset = this.changeset;
        await changeset.validate();

        if (!changeset.get('isValid')) {
          this.toast.error('Por favor, completa todos los campos obligatorios.');
          this.loader.setNotLoading();
          return;
        }

        let destinatarios = changeset.get('destinatario').join(',');
        let json = {
          destinatarios: destinatarios,
          htmlTemplate: changeset.get('mensaje'),
          subject: changeset.get('titulo'),
          facturaId: changeset.get('facturaId')
        };

        try {
          await this.request.post('fac/envio-factura-por-correo', json);
          this.toast.success('Correo enviado correctamente');
        } catch (error) {
          this.toast.error('Ocurrió un error al realizar el envío de la factura.');
          throw error;
        } finally {
          this.loader.setNotLoading();
        }
      },

      applyFilters(event) {
        let filterValue = event.target.value.toLowerCase();
        this.set('filterCodigo', filterValue);

        if (!this.originalData || this.originalData.length === 0) {
          this.set('originalData', [...this.data]);
        }

        let filteredData;

        if (!filterValue) {
          filteredData = [...this.originalData];
          this.set('mensajeMostrado', false);
        } else {
          filteredData = this.originalData.filter(item => {
            return item.nombre.toString().toLowerCase().includes(filterValue) || item.numero.toString().toLowerCase().includes(filterValue) || item.serie.toString().toLowerCase().includes(filterValue);
          });
        }

        this.set('data', filteredData.length > 0 ? filteredData : []);

        if (filteredData.length === 0 && !this.noResultsShown) {
          this.toast.info('No hay resultados con ese valor.');
          this.set('noResultsShown', true);
        }

        if (filteredData.length > 0 && this.noResultsShown) {
          this.set('noResultsShown', false);
        }
      },

      searchFacturasCXP() {
        let fechaInicio = this.changeset.get('fechaInicio');
        let fechaFin = this.changeset.get('fechaFin');

        if (!fechaInicio || !fechaFin) {
          this.toast.error('Debe seleccionar un rango de fechas para buscar.');
          return;
        }

        this.loader.setIsLoading();
        this.set('habilitarTabla', true);
        this.set('filterCodigo', '');
        this.request.get('fac/facturas-emitidas/fecha', {
          codigoEmpresa: this.get('company.codempresa'),
          fechaInicio: (0, _moment.default)(fechaInicio).format(),
          fechaFin: (0, _moment.default)(fechaFin).format()
        }).then(response => {
          this.set('data', response.data);
        }).catch(error => {
          this.toast.error('Error al obtener los documentos FEL.');
          this.toast.error(error);
        }).finally(() => this.loader.setNotLoading());
      },

      async getDirectorios(nit) {
        return await this.store.query('gru-directorio', {
          filter: {
            nit: {
              exact: nit
            },
            grupo: this.company.get('grupo.id'),
            fechabaja: 'NULL'
          }
        }).then(directorioList => {
          this.set('dropdownDirectorios', directorioList);
          let primerDirectorio = directorioList.get('firstObject');

          if (primerDirectorio) {
            this.send('selectDirectorio', primerDirectorio);
          }

          this.send('selectDirectorio', primerDirectorio);
          return directorioList;
        }).catch(error => {
          this.toast.error('No  existen directorios asociados con el  NIT ' + nit);
          this.toast.error('Error ' + error);
          this.set('selectDirectorio', null);
          this.set('dropdownDirectorios', []);
        });
      },

      abrirModal(row) {
        this.changeset.set('directorio', row.directorio);
        this.set('isEnviarFacturas', true);
        this.changeset.set('facturaId', row.id);
        this.send('getTemplateAndEmails', row.id);
        this.toggleProperty('openModal');
      },

      onclose() {
        if (this.openModal) {
          this.set('openModal', false);
        }
      },

      async getTemplateAndEmails(idTransaccion) {
        this.loader.setIsLoading();
        this.request.get('fac/facturas-emitidas/template', {
          idTransaccion
        }).then(response => {
          // TODO: Verificar por qué ponemos esto en el changeset y además en el
          // controller
          this.changeset.set('mensaje', response.data.template);
          this.set('htmlTemplate', response.data.template);
          this.changeset.set('destinatario', Ember.A(response.data.emails));
          this.changeset.set('titulo', Ember.A(response.data.titulo));
        }).catch(error => {
          this.toast.error('Error al obtener plantilla');
          this.toast.error(error);
        }).finally(() => this.loader.setNotLoading());
      },

      addEmail(email) {
        let destinatario = this.changeset.get('destinatario');

        if (!Array.isArray(destinatario)) {
          destinatario = Ember.A();
          this.changeset.set('destinatario', destinatario);
        }

        if (typeof email === 'string' && email.trim() !== '') {
          if (!destinatario.includes(email.trim())) {
            destinatario.pushObject(email.trim());
            this.changeset.set('destinatario', destinatario);
          }
        }
      },

      removeEmailAtIndex(index) {
        let destinatario = this.changeset.get('destinatario');
        destinatario.removeAt(index);
        this.changeset.set('destinatario', destinatario);
      }

    }
  });

  _exports.default = _default;
});