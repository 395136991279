define("sirvo-web/routes/inventario/reportes/conciliacion-compras", ["exports", "servir-ember-utilities/routes/abstract-module-index", "sirvo-web/mixins/access-validator", "ember-changeset", "ember-changeset-validations", "moment"], function (_exports, _abstractModuleIndex, _accessValidator, _emberChangeset, _emberChangesetValidations, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend(_accessValidator.default, {
    routeAccess: 'KAR-REPORTE-CONCIALIACION-COMPRAS',
    company: Ember.computed.readOnly('currentCompany.company'),
    validations: Ember.computed(function () {
      return {};
    }),

    model()
    /*params, transition*/
    {
      return this.store.createRecord('base-reporte', {
        fechaInicio: (0, _moment.default)().startOf('month').toDate(),
        fechaFin: (0, _moment.default)().endOf('month').toDate()
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      let changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(this.get('validations')), this.get('validations'));
      controller.set('changeset', changeset);
    }

  });

  _exports.default = _default;
});