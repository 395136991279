define("sirvo-web/components/cxp/mantenimiento-poliza", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    request: Ember.inject.service(),
    store: Ember.inject.service(),
    dropdownOptionCuentasContables: null,
    dropdownOptionCentroDeCosto: null,
    selectedCentroDeCosto: null,
    selectedCuentaContable: null,
    encabezadoLinea: null,
    descripcion: null,
    fechaActual: Ember.computed(function () {
      let fechaActual = (0, _moment.default)();
      let fechaToDate = fechaActual.toDate();
      return (0, _moment.default)(fechaToDate).format('YYYY-MM-DD');
    }),
    periodoPoliza: null,
    empresaPoliza: null,
    tieneCentroDeCosto: false,
    cargo: 0.00,
    abono: 0.00,
    fechaPoliza: Ember.computed(function () {
      let fechaActual = (0, _moment.default)();
      let fechaToDate = fechaActual.toDate();
      return (0, _moment.default)(fechaToDate).format('YYYY-MM-DD');
    }),

    async init() {
      this._super(...arguments);

      this.dropdownOptionCentroDeCosto = [];

      try {
        let empresa = await this.getEmpresa();
        this.set('empresaPoliza', empresa);
        let periodo = await this.getPeriodo();
        this.set('periodoPoliza', periodo);
        let descripcionContable = this.get('descripcionContable');
        let diferencia = this.get('diferencia');
        let cuentasContables = this.getCuentasContables();
        this.set('descripcion', descripcionContable);
        this.set('tieneCentroDeCosto', false);
        this.setCargoAbono(diferencia);
        this.set('dropdownOptionCuentasContables', cuentasContables);
      } catch (error) {
        this.toast.error('Error al cargar los datos. ' + error);
      }
    },

    getCuentasContables() {
      return this.request.get('cxp/cuentas-contables', {
        codempresa: this.encabezadoLinea.codempresa,
        codperiodo: this.get('periodo')
      }).then(response => {
        return response.data;
      }).catch(error => {
        this.toast.error('Error al cargar las cuentas contables. ' + error);
      });
    },

    async getEmpresa() {
      return await this.store.query('empresa', {
        filter: {
          codempresa: this.encabezadoLinea.codempresa
        }
      }).then(response => {
        let empresa = response.get('firstObject');
        return empresa;
      }).catch(error => {
        this.toast.error('Error al cargar la empresa. ' + error);
      });
    },

    async getPeriodo() {
      this.loader.setIsLoading();
      return await this.store.query('emp-periodo', {
        filter: {
          codempresa: this.empresaPoliza.codempresa,
          fechaInicioPeriodo: {
            before: this.fechaPoliza
          },
          fechaFinPeriodo: {
            after: this.fechaPoliza
          }
        }
      }).then(response => {
        let periodo = response.get('firstObject');
        return periodo;
      }).finally(() => this.loader.setNotLoading());
    },

    getCentrosDeCosto() {
      this.loader.setLoading(true);
      return this.store.query('emp-cc', {
        filter: {
          empresa: this.empresaPoliza.id,
          periodo: this.periodoPoliza.id,
          ultimoNivel: 1
        }
      }).then(response => {
        return response;
      }).catch(error => {
        this.toast.error('Error al cargar los centros de costo. ' + error);
      }).finally(() => {
        this.loader.setLoading(false);
      });
    },

    async getCuentaContable() {
      return await this.store.query('emp-nomenclatura', {
        filter: {
          codempresa: this.encabezadoLinea.codempresa,
          periodo: this.periodoPoliza.id,
          codcuenta: this.selectedCuentaContable.codcuenta
        }
      }).then(response => {
        let cuenta = response.get('firstObject');
        return cuenta;
      }).catch(error => {
        this.toast.error('Error al cargar la cuenta contable. ' + error);
      });
    },

    setCargoAbono(monto) {
      let montoString = String(monto).replace(/,/g, '');
      let montoFloat = parseFloat(montoString);

      if (montoFloat < 0) {
        this.set('cargo', Math.abs(montoFloat));
      } else if (monto > 0) {
        this.set('abono', Math.abs(montoFloat));
      }
    },

    actions: {
      searchCentroDeCosto(term, dropdown) {
        return dropdown.options.filter(item => {
          return item.codcc.toLowerCase().includes(term.toLowerCase()) || item.descripcion.toLowerCase().includes(term.toLowerCase());
        });
      },

      searchCuentaContable(term, dropdown) {
        return dropdown.options.filter(item => {
          return item.codcuenta.toLowerCase().includes(term.toLowerCase()) || item.nomcta.toLowerCase().includes(term.toLowerCase());
        });
      },

      async agregar() {
        this.loader.setLoading(true);

        try {
          let id = parseInt(this.encabezadoLinea.id);
          let codcuenta = this.selectedCuentaContable.codcuenta;
          let transaccion = await this.store.findRecord('transaccion', id);
          let cuenta = await this.getCuentaContable();
          let fechaTransaccion = (0, _moment.default)(this.fechaPoliza, 'YYYY-MM-DD').toDate();
          let newLineaTransaccion = await this.store.createRecord('det-transaccion', {
            descripcionCont: this.descripcion,
            codempresa: this.encabezadoLinea.codempresa,
            codgrupo: this.encabezadoLinea.codgrupo,
            codperiodo: this.get('periodo'),
            cargo: this.cargo,
            abono: this.abono,
            fechaTransaccion: fechaTransaccion,
            keytra: this.encabezadoLinea.keytra,
            codcuenta,
            codsistemaAux: 'CXP',
            codsistema: 'CXP',
            deha: this.selectedCuentaContable.naturalezaCuenta,
            transaccion: transaccion,
            periodo: this.periodoPoliza,
            espropio: 0,
            cuenta: cuenta,
            codcc: this.selectedCentroDeCosto ? this.selectedCentroDeCosto.codcc : ''
          });
          await newLineaTransaccion.save();
          this.toast.success('Registro creado con éxito.');
          this.refreshData();
          this.send('closeModal');
        } catch (error) {
          this.toast.error('Error: ' + error);
          this.loader.setLoading(false);
        }
      },

      selectedCuentaContable(cuentasContable) {
        this.set('selectedCuentaContable', cuentasContable);

        if (this.selectedCuentaContable.tieneCentroDeCosto === 1) {
          this.set('tieneCentroDeCosto', true);
          let centrosDeCostos = this.getCentrosDeCosto();
          this.set('dropdownOptionCentroDeCosto', centrosDeCostos);
          return;
        } else {
          this.set('dropdownOptionCentroDeCosto', []);
          this.set('selectedCentroDeCosto', null);
          this.set('tieneCentroDeCosto', false);
        }
      },

      selectedCentroDeCosto(centroDeCosto) {
        this.set('selectedCentroDeCosto', centroDeCosto);
      },

      closeModal() {
        this.set('isOpenAgregarLinea', false);
      }

    }
  });

  _exports.default = _default;
});