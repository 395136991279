define("sirvo-web/templates/components/tables/cell/promise-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1BYZdw/d",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[26,\"is-pending\",[[21,0,[\"value\"]]],null]],null,{\"statements\":[[0,\"  ...\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[26,\"await\",[[21,0,[\"value\"]]],null],true],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/components/tables/cell/promise-value.hbs"
    }
  });

  _exports.default = _default;
});