define("sirvo-web/controllers/inventario/movimientos/ingresos/ajuste/detalles", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "sirvo-web/models/kar-movdet"], function (_exports, _abstractModuleIndex, _karMovdet) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    user: Ember.computed.readOnly('currentUser.user'),
    fechaCierre: Ember.computed.readOnly('currentCompany.lastCierre.fecha'),
    reload: true,
    modelClass: _karMovdet.default,
    editRoute: Ember.computed('parentRoute', function () {
      return `${this.parentRoute}.registro`;
    }),
    modelFilters: Ember.computed('record', function () {
      let filters = [{
        selectedFilter: {
          filterName: 'movimiento'
        },
        value: this.record.get('id') || -1
      }];

      if (this.user.get('bodegas.length')) {
        filters.push({
          selectedFilter: {
            filterName: 'bodega'
          },
          value: this.user.get('bodegas').mapBy('id')
        });
      }

      return filters;
    }),
    includedRelationships: Ember.computed(function () {
      return ['articulo', 'unidadMedida', 'bodega'];
    }),
    columns: Ember.computed(function () {
      return [{
        label: 'Artículo',
        valuePath: 'articulo.codarticulo',
        width: '110px'
      }, {
        label: 'Descripción',
        valuePath: 'articulo.descripcion',
        width: '200px'
      }, {
        label: 'Cantidad',
        valuePath: 'cantidad',
        sortable: false,
        width: '100px'
      }, {
        label: 'U. Medida',
        valuePath: 'unidadMedida.codigo',
        sortable: false,
        width: '100px'
      }, {
        label: 'Precio U.',
        valuePath: 'precioUnitario',
        sortable: false,
        width: '100px'
      }, {
        label: 'Costo neto',
        valuePath: 'valorNeto',
        sortable: false,
        width: '100px',
        totalValuePath: 'valorNeto',
        cellComponent: 'tables/cell/accounting-amount'
      }, {
        label: 'Costo total',
        valuePath: 'total',
        sortable: false,
        width: '100px',
        totalValuePath: 'total',
        cellComponent: 'tables/cell/accounting-amount'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: {
          edit: {
            title: 'Consultar',
            buttonClass: 'btn-primary',
            icon: 'eye',
            action: this.actions.edit.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }];
    }),
    detalles: Ember.computed(function () {
      return Ember.A([]);
    }),
    actions: {
      edit(detalle) {
        if (!this.record.id || !detalle.id) {
          return;
        }

        this.transitionToRoute(this.editRoute, this.record.id, detalle.id);
      },

      delete(item, row, lightTable) {
        const superRef = this._super.bind(this);

        if (this.fechaCierre >= this.record.get('fecha')) {
          this.toast.error('Registro solo de lectura por cierre de inventario.');
          return;
        }

        return superRef(item, row, lightTable);
      },

      getReport() {
        if (!this.record.id) {
          this.toast.error('Guarde primero el registro para visualizar el reporte.');
          return;
        }

        this.loader.setLoading(true);
        this.toast.info('Generando reporte');
        return this.request.get(`kar-movs/${this.record.id}/reporte_lineas`).then(() => this.toast.success('Reporte generado exitosamente')).finally(() => this.loader.setLoading(false));
      }

    }
  });

  _exports.default = _default;
});