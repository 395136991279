define("sirvo-web/mixins/com/arrange-items", ["exports", "ember-changeset/utils/is-changeset", "sirvo-web/utils/common"], function (_exports, _isChangeset, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    sortBy: 'orden',

    createItem(list, values = {}) {
      return new Ember.Object(values);
    },

    fixOrders(list, fromIndex, nextOrder, sortBy) {
      sortBy = sortBy || this.sortBy;
      let idx = fromIndex; // Break when order is ok.

      while (idx < list.length && list.objectAt(idx).get(sortBy) < nextOrder) {
        list.objectAt(idx++).set(sortBy, nextOrder++);
      }
    },

    actions: {
      deleteItem(list, item) {
        (true && !(Array.isArray(list)) && Ember.assert('Argument \'list\' expected to be an array. Received: ' + typeof list, Array.isArray(list)));
        (true && !((0, _common.isObject)(item)) && Ember.assert('Argument \'item\' expected to be an object. Received: ' + typeof item, (0, _common.isObject)(item)));
        let destroyPromise = (0, _isChangeset.default)(item) ? item.get('_content').destroyRecord() : item.destroyRecord();
        this.loader.setLoading(true);
        return destroyPromise.then(() => {
          list.removeObject(item);
        }).catch(error => {
          if (Array.isArray(error.errors)) {
            error.errors.forEach(err => err.message && this.toast.error(err.message));
          }

          throw error;
        }).finally(() => this.loader.setLoading(false));
      },

      addItem(list, sortBy) {
        (true && !(Array.isArray(list)) && Ember.assert('Argument \'list\' expected to be an array. Received: ' + typeof list, Array.isArray(list)));
        (true && !(!sortBy || typeof sortBy === 'string') && Ember.assert('Argument \'sortBy\' expected to be a string. Received: ' + typeof sortBy, !sortBy || typeof sortBy === 'string'));
        sortBy = sortBy || this.sortBy;
        let ordersSorted = list.mapBy(sortBy).compact().sort();
        let lastOrder = ordersSorted.length ? ordersSorted.get('lastObject') : 0;
        let newItem = this.createItem(list, {
          orden: lastOrder + 1
        });
        list.pushObject(newItem);
        return newItem;
      },

      insertItem(list, afterItem, sortBy) {
        (true && !(Array.isArray(list)) && Ember.assert('Argument \'list\' expected to be an array. Received: ' + typeof list, Array.isArray(list)));
        (true && !((0, _common.isObject)(afterItem)) && Ember.assert('Argument \'afterItem\' expected to be an object. Received: ' + typeof afterItem, (0, _common.isObject)(afterItem)));
        (true && !(!sortBy || typeof sortBy === 'string') && Ember.assert('Argument \'sortBy\' expected to be a string. Received: ' + typeof sortBy, !sortBy || typeof sortBy === 'string'));
        sortBy = sortBy || this.sortBy;
        let index = list.indexOf(afterItem);

        if (index === -1) {
          return;
        }

        let newItem = this.createItem(list, {
          orden: (afterItem.get(sortBy) || 0) + 1
        }); // Fix orders after the clicked item.

        let fromIndex = index + 1;
        let nextOrder = newItem.get(sortBy) + 1;
        this.fixOrders(list, fromIndex, nextOrder); // Insert after the orders are fixed to avoid flashes in the UI.

        list.insertAt(index + 1, newItem);
        return newItem;
      },

      moveUpItem(list, item, sortBy) {
        (true && !(Array.isArray(list)) && Ember.assert('Argument \'list\' expected to be an array. Received: ' + typeof list, Array.isArray(list)));
        (true && !((0, _common.isObject)(item)) && Ember.assert('Argument \'item\' expected to be an object. Received: ' + typeof item, (0, _common.isObject)(item)));
        (true && !(!sortBy || typeof sortBy === 'string') && Ember.assert('Argument \'sortBy\' expected to be a string. Received: ' + typeof sortBy, !sortBy || typeof sortBy === 'string'));
        sortBy = sortBy || this.sortBy; // Ensure that order is the same as in the UI.

        list = list.sortBy(sortBy);
        let index = list.indexOf(item);

        if (index <= 0) {
          return;
        }

        let prevItem = list.objectAt(index - 1);
        let prevItemOrder = prevItem.get(sortBy) || 0; // Swap orders

        prevItem.set(sortBy, item.get(sortBy) || 0);
        item.set(sortBy, prevItemOrder); // If orders are diferent, there is no need to update next items.

        if (prevItem.get(sortBy) !== item.get(sortBy)) {
          return;
        } // Add one to the previous item.


        prevItem.set(sortBy, prevItem.get(sortBy) + 1); // Fix orders after the moved item.

        let fromIndex = index + 1;
        let nextOrder = prevItem.get(sortBy) + 1;
        this.fixOrders(list, fromIndex, nextOrder);
        return item;
      },

      moveDownItem(list, item, sortBy) {
        (true && !(Array.isArray(list)) && Ember.assert('Argument \'list\' expected to be an array. Received: ' + typeof list, Array.isArray(list)));
        (true && !((0, _common.isObject)(item)) && Ember.assert('Argument \'item\' expected to be an object. Received: ' + typeof item, (0, _common.isObject)(item)));
        (true && !(!sortBy || typeof sortBy === 'string') && Ember.assert('Argument \'sortBy\' expected to be a string. Received: ' + typeof sortBy, !sortBy || typeof sortBy === 'string'));
        sortBy = sortBy || this.sortBy; // Ensure that order is the same as in the UI.

        list = list.sortBy(sortBy);
        let index = list.indexOf(item);

        if (index === -1 || index >= list.length - 1) {
          return;
        }

        let nextItem = list.objectAt(index + 1);
        let nextItemOrder = nextItem.get(sortBy) || 0; // Swap orders

        nextItem.set(sortBy, item.get(sortBy) || 0);
        item.set(sortBy, nextItemOrder); // If orders are diferent, there is no need to update next items.

        if (nextItem.get(sortBy) !== item.get(sortBy)) {
          return;
        } // Add one to the clicked item.


        item.set(sortBy, item.get(sortBy) + 1); // Fix orders after the moved item.

        let fromIndex = index + 2;
        let nextOrder = item.get(sortBy) + 1;
        this.fixOrders(list, fromIndex, nextOrder);
        return item;
      }

    }
  });

  _exports.default = _default;
});