define("sirvo-web/controllers/contrasenas/movimientos/ordenes-de-compra/emitir/poliza-modal", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    indexCtrl: Ember.inject.controller('contrasenas.movimientos.ordenes-de-compra.emitir'),
    indexRoute: null,
    request: Ember.inject.service(),
    lastRouteService: Ember.inject.service('last-route'),
    isPolizaModalOpen: false,
    router: Ember.inject.service(),
    transaccionKeytra: null,
    showFilterMenu: false,
    filterCodigo: '',
    dataEncabezados: null,
    dataElementosTabla: null,
    fechaSis: null,
    tipoPoliza: null,
    conceptoPoliza: null,
    periodoPoliza: null,
    keytraPoliza: null,
    keytraContrasena: null,
    totalCargos: null,
    totalAbonos: null,
    isActualizarDisabled: false,
    isPrint: false,
    contrasenaEncontrada: null,
    isOpenAgregarLinea: false,
    columns: Ember.computed(function () {
      return [{
        label: 'Propio',
        cellComponent: 'readonly-checkbox',
        valuePath: 'espropio',
        width: '50px'
      }, {
        label: 'U. Niv.',
        cellComponent: 'readonly-checkbox',
        valuePath: 'univ',
        width: '50px'
      }, {
        label: 'Cuenta',
        valuePath: 'codcuenta',
        width: '80px'
      }, {
        label: 'Nombre',
        valuePath: 'nomcta',
        width: '200px'
      }, {
        label: 'Concepto contable',
        valuePath: 'orden_compra_codigo',
        width: '200px'
      }, {
        label: 'Cargo',
        valuePath: 'cargo',
        width: '80px',
        align: 'right',
        cellComponent: 'total-float-tabla'
      }, {
        label: 'Abono',
        valuePath: 'abono',
        width: '80px',
        align: 'right',
        cellComponent: 'total-float-tabla'
      }, {
        label: 'Cod. C.I.',
        valuePath: 'codci',
        width: '80px'
      }, {
        label: 'Cod. C.C.',
        valuePath: 'codcc',
        width: '150px'
      }, {
        label: '',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '40px',
        sortable: false,
        objectActions: {
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }];
    }),
    lastRoute: Ember.computed('lastRouteService.lastRoute', function () {
      return this.get('lastRouteService.lastRoute');
    }),

    init() {
      this._super(...arguments);

      this.set('lastRoute', this.get('lastRouteService.lastRoute'));
    },

    formatNumberWithCommas(value) {
      let numerico = Number(value);
      let parts = numerico.toFixed(2).split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join('.');
    },

    postSave()
    /*record*/
    {
      this.indexCtrl.reloadTable();
    },

    actions: {
      onClose() {
        this.router.transitionTo(this.lastRoute);
      },

      delete(item) {
        let id = item.id;
        return this.store.findRecord('det-transaccion', id).then(record => {
          if (parseInt(record.espropio) === 1) {
            this.toast.error('Registro generado por el sistema, no se puede eliminar.');
            return;
          }

          return this.send('openModal', 'popups/simple-confirm', {
            title: '¿Estás seguro que deseas eliminar el registro?',
            message: 'No podrás revertir esta acción!',
            onSubmit: () => {
              if (record) {
                record.destroyRecord().then(() => {
                  this.send('refreshData');
                  this.toast.success('Registro eliminado.');
                });
              } else {
                this.toast.error('El registro no se encontró.');
              }
            }
          });
        }).catch(error => {
          this.toast.error(error);
        });
      },

      toggleCloseModal() {
        this.router.transitionTo(this.lastRoute);
      },

      actualizarPoliza() {
        this.loader.setLoading(true);
        this.request.get('actualizar-poliza/transaccion', {
          keytra: this.keytraPoliza
        }).then(response => {
          this.toast.success(response.message);
          this.set('isActualizarDisabled', true);
        }).catch(error => {
          this.toast.success(error.errors[0].message);
        }).finally(() => this.loader.setNotLoading());
      },

      refreshData() {
        this.send('loadElementosPoliza', this.keytraPoliza);
      },

      verContrasena() {
        if (this.contrasenaEncontrada) {
          try {
            this.transitionToRoute('emision-contrasenas.new', this.contrasenaEncontrada.get('id'));
          } catch (error) {
            this.toast.error('Error en la transicion a ruta.');
          }
        } else {
          this.toast.error('Contraseña no encontrada.');
        }
      },

      impresionDocumentoModal() {
        this.set('isPrint', true);
      },

      mantenimientoPoliza() {
        this.set('isOpenAgregarLinea', true);
      },

      ocultarModal() {
        this.set('isModalVisible', false);
      },

      loadElementosPoliza(keytraPoliza) {
        let periodo = new Date().getFullYear() + '-01';
        return this.request.get('detalle-transaccion/empresas/periodo', {
          keytra: keytraPoliza,
          periodo: periodo
        }).then(response => {
          let totalCargos = 0,
              totalAbonos = 0;
          response.data.forEach(row => {
            totalCargos += parseFloat(row.cargo);
            totalAbonos += parseFloat(row.abono);
          });
          let diferencia = parseFloat(totalCargos) - parseFloat(totalAbonos);
          let totalCargosFormatted = this.formatNumberWithCommas(totalCargos);
          let diferenciaFormatted = this.formatNumberWithCommas(diferencia);
          let totalAbonosFormatted = this.formatNumberWithCommas(totalAbonos);
          this.set('dataElementosTabla', response.data);
          this.set('totalCargos', totalCargosFormatted);
          this.set('diferencia', diferenciaFormatted);
          this.set('totalAbonos', totalAbonosFormatted);
          this.notifyPropertyChange('dataElementosTabla');
          this.notifyPropertyChange('totalCargos');
          this.notifyPropertyChange('diferencia');
          this.notifyPropertyChange('totalAbonos');
        });
      }

    }
  });

  _exports.default = _default;
});