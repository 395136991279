define("sirvo-web/routes/admon/accesos/perfiles/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "servir-ember-utilities/mixins/route/has-tabs", "ember-changeset-validations/validators"], function (_exports, _abstractModuleRecord, _hasTabs, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // WARNING: Permiso comentado a fin de configurar instalaciones desde cero.
  var _default = _abstractModuleRecord.default.extend(_hasTabs.default,
  /*PermissionValidator, */
  {
    routePermission: 'AdmonCrearPerfiles',
    modelName: 'vf-perfil',
    indexRoute: 'admon.accesos.perfiles',
    defaultTabSubRoute: 'generales',
    relatedModelsToQuery: Ember.computed(function () {
      let relatedModels = [{
        name: 'permisosGrupos',
        modelName: 'permiso-grupo'
      }, {
        name: 'permisosTodos',
        modelName: 'permiso'
      }, {
        name: 'menusTodos',
        modelName: 'menu'
      }];
      return relatedModels;
    }),
    validations: Ember.computed(function () {
      return {
        codigo: (0, _validators.validatePresence)({
          presence: true
        }),
        nombre: (0, _validators.validatePresence)({
          presence: true
        })
      };
    })
  });

  _exports.default = _default;
});