define("sirvo-web/services/selection-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    selectedRows: Ember.A(),
    selectedCount: Ember.computed('selectedRows.[]', function () {
      return this.get('selectedRows.length');
    }),
    showButton: Ember.computed('selectedCount', function () {
      return this.get('selectedCount') >= 2;
    }),

    toggleRowSelection(row) {
      if (this.get('selectedRows').includes(row)) {
        this.get('selectedRows').removeObject(row);
      } else {
        this.get('selectedRows').addObject(row);
      }
    }

  });

  _exports.default = _default;
});