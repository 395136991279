define("sirvo-web/components/tables/cell/anulado-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['anulado-cell'],

    didReceiveAttrs() {
      this._super(...arguments);

      const anulado = this.get('row.anulado');

      if (anulado) {
        this.set('isAnulado', true);
      }
    },

    isProvisioned: false,
    cellContent: Ember.computed('row.anulado', function () {
      return this.get('isAnulado') ? 'X' : '';
    }),
    cellStyle: Ember.computed('isAnulado', function () {
      return this.get('isAnulado') ? 'background-color: #DB4444; padding: 8px; border-radius: 20px; color: white; font-weight: bold;' : '';
    })
  });

  _exports.default = _default;
});