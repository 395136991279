define("sirvo-web/controllers/inventario/movimientos/ingresos/ajuste", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    request: Ember.inject.service(),
    indexCtrl: Ember.inject.controller('inventario.movimientos.ingresos'),
    detallesCtrl: Ember.inject.controller('inventario.movimientos.ingresos.ajuste.detalles'),
    indexRoute: null,
    fechaCierre: Ember.computed.readOnly('currentCompany.lastCierre.fecha'),
    registroCerrado: Ember.computed('changeset.fecha', 'fechaCierre', function () {
      if (!this.fechaCierre || !this.changeset.get('fecha')) {
        return false;
      }

      return this.fechaCierre >= this.changeset.get('fecha');
    }),

    postSave()
    /*record*/
    {
      if (this.indexCtrl) {
        this.indexCtrl.set('reload', true);
      }
    },

    actions: {
      onClose() {
        this.transitionToRoute(this.indexRoute);
      },

      eliminarLineas() {
        if (this.record.get('isNew')) {
          return;
        }

        if (this.registroCerrado) {
          this.toast.error('Registro solo de lectura por cierre de inventario.');
          return;
        }

        this.send('openModal', 'popups/simple-confirm', {
          title: '¿Está seguro de eliminar todas las líneas del movimiento?',
          message: 'Se eliminarán todas las líneas de detalle junto con las pólizas asociadas.',
          onSubmit: () => {
            this.loader.setLoading(true);
            return this.request.post(`kar-movs/${this.record.id}/eliminar-detalles`).then(() => {
              this.toast.success('Líneas de detalle eliminadas correctamente.');
              this.detallesCtrl.set('reload', true);
            }).finally(() => this.loader.setLoading(false));
          }
        });
      }

    }
  });

  _exports.default = _default;
});