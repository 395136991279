define("sirvo-web/templates/components/readonly-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XC+tsm7t",
    "block": "{\"symbols\":[\"@value\"],\"statements\":[[6,\"input\"],[11,\"checked\",[26,\"eq\",[[21,1,[]],1],null],null],[10,\"disabled\",\"\"],[10,\"type\",\"checkbox\"],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/components/readonly-checkbox.hbs"
    }
  });

  _exports.default = _default;
});