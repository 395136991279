define("sirvo-web/routes/contrasenas/movimientos/ordenes-de-compra/emitir/poliza-modal", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "moment"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  let PolizaModalRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class PolizaModalRoute extends Ember.Route {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "request", _descriptor2, this);
    }

    model(params) {
      let [id, keytraPoliza, keytraContrasena] = params.id.split('-');
      return Ember.RSVP.hash({
        id: id,
        keytraPoliza: keytraPoliza,
        keytraContrasena: keytraContrasena,
        encabezados: this.loadEncabezados(keytraPoliza),
        elementosPoliza: this.loadElementosPoliza(keytraPoliza),
        contrasena: this.findContrasena(keytraContrasena)
      });
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      controller.setProperties({
        periodoPoliza: model.elementosPoliza.data[0].codperiodo,
        keytraPoliza: model.keytraPoliza,
        keytraContrasena: model.keytraContrasena,
        dataEncabezados: model.encabezados.data,
        dataElementosTabla: model.elementosPoliza.data,
        fechaSis: model.encabezados.fechaSis,
        tipoPoliza: model.encabezados.tipoPoliza,
        conceptoPoliza: model.encabezados.conceptoPoliza,
        totalCargos: model.elementosPoliza.totalCargos,
        diferencia: model.elementosPoliza.diferencia,
        totalAbonos: model.elementosPoliza.totalAbonos,
        contrasenaEncontrada: model.contrasena,
        isActualizarDisabled: model.encabezados.data.actualizaDocCnt
      });
    }

    loadEncabezados(keytraPoliza) {
      return this.request.get('transacciones/empresas/sistema/obtener', {
        keytra: keytraPoliza
      }).then(response => {
        let encabezado = response.data[0];
        return {
          data: encabezado,
          fechaSis: (0, _moment.default)(encabezado.fecha.date).format('YYYY-MM-DD'),
          tipoPoliza: encabezado.nomsistema,
          conceptoPoliza: encabezado.descripcionContable
        };
      });
    }

    loadElementosPoliza(keytraPoliza) {
      let periodo = new Date().getFullYear() + '-01';
      return this.request.get('detalle-transaccion/empresas/periodo', {
        keytra: keytraPoliza,
        periodo: periodo
      }).then(response => {
        let totalCargos = 0,
            totalAbonos = 0;
        response.data.forEach(row => {
          totalCargos += parseFloat(row.cargo);
          totalAbonos += parseFloat(row.abono);
        });
        let diferencia = parseFloat(totalCargos) - parseFloat(totalAbonos);
        return {
          data: response.data,
          totalCargos: this.formatNumberWithCommas(totalCargos),
          diferencia: this.formatNumberWithCommas(diferencia),
          totalAbonos: this.formatNumberWithCommas(totalAbonos)
        };
      });
    }

    findContrasena(keytraContrasena) {
      return this.store.query('que/contrasena', {
        filter: {
          keytra: keytraContrasena
        }
      }).then(result => result.get('firstObject'));
    }

    formatNumberWithCommas(value) {
      let numerico = Number(value);
      let parts = numerico.toFixed(2).split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "request", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = PolizaModalRoute;
});