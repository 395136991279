define("sirvo-web/controllers/admon/accesos/usuarios/index", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "sirvo-web/models/vf-user"], function (_exports, _abstractModuleIndex, _vfUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    company: Ember.computed.readOnly('currentCompany.company'),
    editRoute: 'admon.accesos.usuarios.record',
    modelClass: _vfUser.default,
    includedRelationships: Ember.computed(function () {
      return ['perfil'];
    }),
    columns: Ember.computed(function () {
      return Ember.A([{
        label: 'Usuario',
        valuePath: 'coduser',
        filtrable: true,
        filterName: 'coduser',
        sortable: false,
        width: '150px'
      }, {
        label: 'Nombre',
        valuePath: 'nombre',
        filtrable: true,
        filterName: 'nombre',
        sortable: false,
        width: '250px'
      }, {
        label: 'Perfil',
        valuePath: 'perfil.nombre',
        filtrable: true,
        filterName: 'perfil.nombre',
        sortable: false,
        width: '250px'
      }, {
        label: 'Correo',
        valuePath: 'email',
        filtrable: true,
        filterName: 'email',
        sortable: false,
        width: '200px'
      }, {
        label: 'Activo',
        valuePath: 'activoFormatted',
        filtrable: true,
        filterName: 'activo',
        sortable: false,
        width: '100px'
      }]);
    })
  });

  _exports.default = _default;
});