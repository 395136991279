define("sirvo-web/controllers/ods/servicios/tipos-orden/record/estado-hechos-plantilla", ["exports", "sirvo-web/mixins/com/arrange-items"], function (_exports, _arrangeItems) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_arrangeItems.default, {
    errorHandler: Ember.inject.service(),
    parentCtrl: Ember.inject.controller('ods.servicios.tipos-orden.record'),
    indexRoute: null,
    plantilla: null,
    actividades: null,

    createItem(list, values = {}) {
      return this.store.createRecord('ods/estado-hechos-plantilla-actividad', {
        plantilla: this.plantilla,
        ...values
      });
    },

    hasChanged(record) {
      return record.get('hasDirtyAttributes') || record.get('hasChanges');
    },

    validateActividad(actividad) {
      if (!actividad.get('descripcion') || !actividad.get('descripcion').trim()) {
        return false;
      } // Debe guardar primero la plantilla.


      if (!actividad.get('plantilla.id')) {
        return false;
      }

      return true;
    },

    saveActividad(actividad) {
      if (!this.validateActividad(actividad)) {
        actividad.set('hasErrors', true);
        return Ember.RSVP.resolve(null);
      }

      return actividad.save().then(record => {
        actividad.set('hasErrors', false);
        return record;
      }).catch(error => {
        actividad.set('hasErrors', true);
        this.errorHandler.handleSaveErrors(error, actividad);
      });
    },

    trySaveActividad(actividad) {
      if (this.hasChanged(actividad) && this.validateActividad(actividad)) {
        this.saveActividad(actividad);
      }
    },

    validatePlantilla(plantilla) {
      if (!plantilla.get('descripcion') || !plantilla.get('descripcion').trim()) {
        return false;
      }

      return true;
    },

    savePlantilla(plantilla) {
      if (!this.validatePlantilla(plantilla)) {
        plantilla.set('hasErrors', true);
        this.toast.error('Debe definir un nombre para la plantilla.');
        return Ember.RSVP.resolve(null);
      }

      return plantilla.save().then(record => {
        plantilla.set('hasErrors', false);
        this.parentCtrl.set('reload', true);
        return record;
      }).catch(error => {
        plantilla.set('hasErrors', true);
        this.errorHandler.handleSaveErrors(error, plantilla);
      });
    },

    trySavePlantilla(plantilla) {
      if (this.hasChanged(plantilla) && this.validatePlantilla(plantilla)) {
        this.savePlantilla(plantilla);
      }
    },

    actions: {
      onClose() {
        this.actividades.forEach(actividad => actividad.rollbackAttributes());
        this.plantilla.rollbackAttributes();
        this.transitionToRoute(this.indexRoute);
      },

      onSubmit() {
        return this.savePlantilla(this.plantilla).then(plantilla => {
          if (!plantilla) {
            return null;
          }

          let promises = this.actividades.filter(this.hasChanged.bind(this)).map(this.saveActividad.bind(this));
          return Ember.RSVP.all(promises).then(() => plantilla);
        }).then(plantilla => {
          if (!plantilla) {
            this.toast.info('Ningún registro guardado.');
            return;
          }

          this.toast.success('Plantilla guardada correctamente.');
          this.transitionToRoute(this.indexRoute);
        }).catch(error => {
          this.toast.error('Error al guardar los registros.');
          throw error;
        });
      },

      trySavePlantilla() {
        this.trySavePlantilla(this.plantilla);
      },

      trySaveActividad(actividad) {
        this.trySaveActividad(actividad);
      },

      onSaveActividad(actividad) {
        this.saveActividad(actividad);
      }

    }
  });

  _exports.default = _default;
});