define("sirvo-web/models/que/contrasena", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    // codempresa: attr('string'),
    codsistema: attr('string'),
    codgrupo: attr('string'),
    codempQuedan: attr('string'),
    codtipo: attr('string'),
    fecha: attr('custom-date'),
    yearmes: attr('string'),
    coddirectorio: attr('string'),
    fechaPago: attr('custom-date'),
    diascredito: attr('number'),
    codempresa: attr('string'),
    observaciones: attr('string'),
    numerodoc: attr('string'),
    codtipodoctos: attr('string'),
    codclasifica: attr('string'),
    serie: attr('string'),
    numero: attr('number'),
    anulado: attr('number'),
    userAnulado: attr('string'),
    fechaAnulado: attr('custom-date'),
    motivoAnulado: attr('string'),
    keytra: attr('string'),
    facturaSinOrdenCompra: attr('number'),
    directorio: belongsTo('gru-directorio'),
    empresa: belongsTo('empresa'),
    fechaFormateada: Ember.computed('fecha', function () {
      let fecha = (0, _moment.default)(this.fecha).toDate();
      return (0, _moment.default)(fecha).format('DD/MM/YYYY');
    }),
    fechaPagoFormateada: Ember.computed('fechaPago', function () {
      let fechaPago = (0, _moment.default)(this.fechaPago).toDate();
      return (0, _moment.default)(fechaPago).format('DD/MM/YYYY');
    })
  });

  _exports.default = _default;
});