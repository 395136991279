define("sirvo-web/templates/inventario/movimientos/ingresos/registro/detalles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fH+i9H2d",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"row pb-2\"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"col-sm-12\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"pull-right\"],[8],[0,\"\\n      \"],[4,\"bs-button\",null,[[\"type\",\"size\",\"onClick\"],[\"info\",\"sm\",[26,\"action\",[[21,0,[]],\"getReport\"],null]]],{\"statements\":[[0,\"\\n        Descargar\\n      \"]],\"parameters\":[]},null],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[26,\"tables/simple-table\",null,[[\"model\",\"columns\",\"displayTotalRow\",\"tableClassNames\",\"totalRowClass\"],[[21,0,[\"model\",\"detalles\"]],[21,0,[\"columns\"]],true,\"ember-table thead-gray\",\"table-total-row\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/inventario/movimientos/ingresos/registro/detalles.hbs"
    }
  });

  _exports.default = _default;
});