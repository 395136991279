define("sirvo-web/controllers/inventario/reportes/existencia-bodega", ["exports", "sirvo-web/mixins/reports-fechas-filters", "moment"], function (_exports, _reportsFechasFilters, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_reportsFechasFilters.default, {
    request: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),

    getSerializedFilters() {
      let fechaFin = this.changeset.get('fechaFin');
      let filters = {
        bodega: this.changeset.get('bodega.id'),
        articulo: this.changeset.get('articulo.id'),
        fechaFin: fechaFin ? (0, _moment.default)(fechaFin).format() : null,
        incluirInactivos: this.changeset.get('incluirInactivos') ? 1 : 0
      };
      return filters;
    },

    actions: {
      searchArticulo(text) {
        if (!text || text.length < 3) {
          return;
        }

        if (this.articleQuery) {
          Ember.run.cancel(this.articleQuery);
        }

        this.articleQuery = Ember.run.later(() => {
          this.set('availableArticles', this.store.query('kar-articulo', {
            filter: {
              descripcion: text,
              empresa: this.company.id
            }
          }));
        }, 500);
      },

      generarReporte() {
        this.loader.setIsLoading();
        return this.changeset.validate().then(() => {
          if (!this.changeset.get('isValid')) {
            this.loader.setNotLoading();
            this.toast.error('Debe seleccionar fecha inicio y fecha fin.');
            return;
          }

          const filters = this.getSerializedFilters();
          this.loader.setLoading(true);
          this.toast.info('Generando reporte');
          return this.request.get('kar/reporte-existencia-bodega', filters).then(() => {
            this.toast.success('Reporte generado exitosamente');
          }).finally(() => this.loader.setLoading(false));
        });
      }

    }
  });

  _exports.default = _default;
});