define("sirvo-web/models/ods/orden-servicio-operacion", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    ordenServicio: belongsTo('ods/orden-servicio'),
    producto: belongsTo('ods/producto'),
    operacion: belongsTo('ods/operacion'),
    cantidad: attr('number'),
    observaciones: attr('string')
  });

  _exports.default = _default;
});