define("sirvo-web/models/kar-mov", ["exports", "@ember-data/model", "moment"], function (_exports, _model, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    empresa: (0, _model.belongsTo)('empresa'),
    keymov: (0, _model.attr)('string'),
    fecha: (0, _model.attr)('custom-date'),
    // yearmes: attr('string'),
    correlativo: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    tipoMovimiento: (0, _model.belongsTo)('kar-tipomov'),
    deha: (0, _model.attr)('string'),
    serie: (0, _model.attr)('string'),
    numero: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    tipoDocumento: (0, _model.belongsTo)('kar-doc'),
    usacnt: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    valordocml: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    valorNeto: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    grupo: (0, _model.belongsTo)('grupo'),
    proveedor: (0, _model.belongsTo)('gru-directorio'),
    referencia: (0, _model.attr)('string'),
    observaciones: (0, _model.attr)('string'),
    // espropio: attr('number', { defaultValue: 0 }),
    // anulado: attr('number', { defaultValue: 0 }),
    autorizadoPor: (0, _model.belongsTo)('gru-directorio'),
    recibidoPor: (0, _model.belongsTo)('gru-directorio'),
    fechaFormatted: Ember.computed('fecha', function () {
      const momentDate = (0, _moment.default)(this.fecha);

      if (Ember.isNone(this.fecha) || !momentDate.isValid()) {
        return '';
      }

      return momentDate.format('DD/MM/YYYY');
    }),
    fechaPhpFormatted: Ember.computed('fecha', function () {
      const momentDate = (0, _moment.default)(this.fecha);

      if (Ember.isNone(this.fecha) || !momentDate.isValid()) {
        return null;
      }

      return momentDate.format('YYYY-MM-DD HH:mm:ss');
    }),

    didLoad() {
      // Hack to avoid the current time set by Ember that affect comparisons.
      if (this.fecha instanceof Date) {
        this.fecha.setHours(0, 0, 0, 0);
      }
    }

  });

  _exports.default = _default;
});