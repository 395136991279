define("sirvo-web/controllers/admon/procesos/notificaciones/record/variables", ["exports", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators", "servir-ember-utilities/validators/is-codigo"], function (_exports, _emberChangeset, _emberChangesetValidations, _validators, _isCodigo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    variablesChangesets: Ember.computed(function () {
      return Ember.A([]);
    }),
    notificationVariableValidations: Ember.computed(function () {
      return {
        name: [(0, _validators.validatePresence)({
          presence: true
        }), (0, _isCodigo.default)({
          regex: /^[a-zA-Z0-9]+$/,
          message: 'Nombre de variable inválida'
        })],
        expression: (0, _validators.validatePresence)({
          presence: true
        }),
        notification: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),

    createVariableChangeset(notificationVariable) {
      if (!notificationVariable) {
        notificationVariable = this.store.createRecord('notification-variable', {
          notification: this.record
        });
      }

      return new _emberChangeset.default(notificationVariable, (0, _emberChangesetValidations.default)(this.notificationVariableValidations), this.notificationVariableValidations, {
        skipValidate: false
      });
    },

    actions: {
      addNewVariableChangeset() {
        this.variablesChangesets.pushObject(this.createVariableChangeset());
      },

      removeVariable(variableChangeset) {
        variableChangeset.rollback();
        variableChangeset.get('_content').destroyRecord();
        this.variablesChangesets.removeObject(variableChangeset);
        variableChangeset.destroy();
      },

      saveVariableChangeset(variableChangeset) {
        return variableChangeset.validate().then(() => {
          if (!variableChangeset.get('isValid')) {
            this.toast.error('Hay un error con el formulario.');
            return;
          }

          this.loader.setLoading(true);
          variableChangeset.save().then(() => {
            this.toast.success('Expressión guardada correctamente.');
          }).catch(error => {
            this.toast.error('Ocurrió un error al guardar la expresión.');
            throw error;
          }).finally(() => this.loader.setLoading(false));
        });
      }

    }
  });

  _exports.default = _default;
});