define("sirvo-web/mixins/uploader", ["exports", "ember-uploader/uploaders/uploader", "sirvo-web/config/environment", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators"], function (_exports, _uploader, _environment, _emberChangeset, _emberChangesetValidations, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    session: Ember.inject.service(),
    uploadUrl: null,
    excelChangeset: null,
    excelValidations: Ember.computed(function () {
      return {
        archivo: (0, _validators.validatePresence)({
          presence: true,
          message: 'Ningún archivo seleccionado'
        }),
        type: (0, _validators.validateInclusion)({
          list: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
          message: 'Archivo inválido'
        })
      };
    }),

    newExcelChangeset() {
      let changeset = new _emberChangeset.default(new Ember.Object({
        archivo: null
      }), (0, _emberChangesetValidations.default)(this.get('excelValidations')), this.get('excelValidations'));
      this.set('excelChangeset', changeset);
    },

    _getNewUploader() {
      if (!this.uploadUrl) {
        throw new Error('Set uploadUrl for file upload.');
      }

      let {
        namespace
      } = _environment.default.API;
      let {
        token
      } = this.get('session.data.authenticated');

      let uploader = _uploader.default.create({
        url: `/${namespace}/${this.uploadUrl}`,
        paramName: 'archivo',
        ajaxSettings: {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      });

      return uploader;
    }

  });

  _exports.default = _default;
});