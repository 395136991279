define("sirvo-web/controllers/alquileres-renta/maestros/propiedades/record/documentos/agregar-documento", ["exports", "sirvo-web/config/environment", "ember-uploader/uploaders/uploader"], function (_exports, _environment, _uploader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    request: Ember.inject.service(),
    session: Ember.inject.service(),
    toast: Ember.inject.service(),
    ajaxService: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),
    indexCtrl: Ember.inject.controller('alquileres-renta.maestros.propiedades.record.documentos'),
    uploadUrl: null,
    indexRoute: 'alquileres-renta.maestros.propiedades.record.documentos',

    getSerializedData() {
      let data = {
        codigoPropiedad: this.documentoChangeset.get('codigoPropiedad'),
        codigoEmpresa: this.documentoChangeset.get('codigoEmpresa'),
        descripcion: this.documentoChangeset.get('descripcion')
      };
      return data;
    },

    _getNewUploader() {
      let {
        token
      } = this.get('session.data.authenticated');

      let uploader = _uploader.default.create({
        url: this.uploadUrl,
        paramName: 'archivo',
        ajaxSettings: {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      });

      return uploader;
    },

    actions: {
      onClose() {
        this.transitionToRoute(this.indexRoute);
      },

      cargarDocumento() {
        let {
          namespace
        } = _environment.default.API;
        let url = `/${namespace}/documento-propiedad`;
        this.set('uploadUrl', url);
        this.loader.setIsLoading();
        return this.documentoChangeset.validate().then(() => {
          if (!this.documentoChangeset.get('isValid')) {
            this.loader.setNotLoading();
            this.toast.error('Hubo un problema al cargar el documento.');
            return;
          }

          const data = this.getSerializedData();

          let uploader = this._getNewUploader();

          this.loader.setLoading(true);
          this.toast.info('Cargando documento...');
          return uploader.upload(this.documentoChangeset.get('archivo'), data).then(() => {
            this.toast.success('Imagen cargada correctamente');
            this.indexCtrl.reloadModel();
          }).catch(error => {
            this.toast.error('No se pudo cargar correctamente el documento.');
            throw error;
          }).finally(() => {
            this.loader.setLoading(false);
            this.transitionToRoute(this.indexRoute);
          });
        });
      }

    }
  });

  _exports.default = _default;
});