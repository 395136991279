define("sirvo-web/routes/inventario/tablas/bodegas-ubicaciones/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "sirvo-web/mixins/permission-validator", "ember-changeset-validations/validators"], function (_exports, _abstractModuleRecord, _permissionValidator, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_permissionValidator.default, {
    routePermission: 'KarAgregarBodegaUbicacion',
    company: Ember.computed.readOnly('currentCompany.company'),
    modelName: 'kar-bodega-ubicacion',
    indexRoute: 'inventario.tablas.bodegas-ubicaciones',
    validations: Ember.computed(function () {
      return {
        empresa: (0, _validators.validatePresence)({
          presence: true
        }),
        codigo: (0, _validators.validatePresence)({
          presence: true
        }),
        descripcion: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),

    createRecordInstance() {
      return this.store.createRecord(this.modelName, {
        empresa: this.company
      });
    },

    actions: {
      willTransition() {
        return false;
      }

    }
  });

  _exports.default = _default;
});