define("sirvo-web/routes/oca/ordenes-envio", ["exports", "servir-ember-utilities/routes/abstract-module-index", "sirvo-web/mixins/access-validator"], function (_exports, _abstractModuleIndex, _accessValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend(_accessValidator.default, {
    ocaHelper: Ember.inject.service(),
    routeAccess: 'OCA-ORDENES-ENVIO',
    company: Ember.computed.readOnly('currentCompany.company'),

    model() {
      return Ember.RSVP.hash({
        ordenesEnvio: this.ocaHelper.getOrdenesEnvio(this.company)
      });
    }

  });

  _exports.default = _default;
});