define("sirvo-web/controllers/admon/accesos/perfiles/record/permisos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    user: Ember.computed.readOnly('currentUser.user'),
    plugins: 'checkbox, wholerow',
    checkboxOptions: Ember.computed(function () {
      return {
        keep_selected_style: false
      };
    }),
    themes: Ember.computed(function () {
      return {
        icons: false
      };
    }),
    jstreeSelectedNodes: null,
    permisos: Ember.computed('user.perfil', 'permisosTodos', function () {
      if (this.get('user').get('perfil.esSuperAdmin')) {
        return this.permisosTodos;
      }

      return this.permisosTodos.rejectBy('soloSuperAdmin');
    }),
    treeData: Ember.computed('permisos', 'permisosGrupos', 'changeset', function () {
      let currentPermissionIds = this.changeset.get('permisos').mapBy('id');
      let rootGroups = this.permisosGrupos.rejectBy('superior.id').sortBy('orden');
      let childGroups = this.permisosGrupos.filterBy('superior.id').sortBy('orden');
      let childPermissions = this.permisos.filterBy('grupo.id').sortBy('orden');
      let orphanPermissions = this.permisos.rejectBy('grupo.id').sortBy('orden');
      let treeData = orphanPermissions.map(permission => this.mapPermissionToJsTreeNode(permission, currentPermissionIds));
      rootGroups.forEach(group => treeData.pushObject(this.buildGroupPermissions(group, childGroups, childPermissions, currentPermissionIds)));
      return treeData;
    }),

    buildGroupPermissions(currentGroup, childGroups, permisos, currentPermissionIds) {
      let groupPermissionsData = permisos.filterBy('grupo.id', currentGroup.id).map(permission => this.mapPermissionToJsTreeNode(permission, currentPermissionIds)); // WARNING: Prefix id with 'group_' to avoid duplicated IDs in the tree.

      let groupData = {
        id: `group_${currentGroup.id}`,
        text: currentGroup.get('nombre'),
        state: {
          opened: true,
          selected: false
        },
        data: {
          isGroup: true
        },
        children: groupPermissionsData
      };
      childGroups.filterBy('superior.id', currentGroup.id).forEach(group => groupData.children.pushObject(this.buildGroupPermissions(group, childGroups, permisos, currentPermissionIds)));
      return groupData;
    },

    mapPermissionToJsTreeNode(permission, currentPermissionIds) {
      return {
        id: permission.get('id'),
        text: permission.get('nombre'),
        state: {
          opened: true,
          selected: currentPermissionIds.includes(permission.get('id'))
        },
        children: Ember.A([])
      };
    },

    actions: {
      jstreeDidChange() {
        // WARNING: On first load, selected nodes are empty.
        if (!this.jstreeSelectedNodes) {
          return;
        }

        let selectedNodeIds = this.jstreeSelectedNodes.mapBy('id');
        let permisos = this.permisos.filter(p => selectedNodeIds.includes(p.id));
        this.changeset.set('permisos', permisos);
      }

    }
  });

  _exports.default = _default;
});