define("sirvo-web/templates/components/tables/object-actions-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r8PNJhhQ",
    "block": "{\"symbols\":[\"objectActionName\",\"objectAction\"],\"statements\":[[4,\"each\",[[26,\"-each-in\",[[22,[\"objectActions\"]]],null]],null,{\"statements\":[[0,\"  \"],[6,\"button\"],[11,\"class\",[27,[\"btn btn-minier \",[26,\"if\",[[21,2,[\"buttonClass\"]],[21,2,[\"buttonClass\"]],\"btn-default\"],null]]]],[11,\"title\",[27,[[26,\"if\",[[21,2,[\"title\"]],[21,2,[\"title\"]]],null]]]],[3,\"action\",[[21,0,[]],[26,\"if\",[[21,2,[\"action\"]],[21,2,[\"action\"]],\"onClick\"],null],[22,[\"row\"]]]],[8],[0,\"\\n    \"],[1,[26,\"fa-icon\",null,[[\"icon\"],[[21,2,[\"icon\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/components/tables/object-actions-table.hbs"
    }
  });

  _exports.default = _default;
});