define("sirvo-web/routes/emision-contrasenas/new", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    company: Ember.computed.readOnly('currentCompany.company'),
    request: Ember.inject.service(),

    model(params) {
      let codsistema = 'QUE';
      let codtipo = 'QUE';
      let codtipodoctos = 'QUE';
      let tipoClasificacion = 'PROVEEDORES';
      let habilitado = 1;
      let nousadoctos = 0;
      let contrasena = null;

      if (!isNaN(params.id)) {
        contrasena = this.store.findRecord('que/contrasena', params.id);
      }

      return Ember.RSVP.hash({
        ownSistemas: this.store.query('own-sistema', {
          filter: {
            codsistema,
            habilitado,
            nousadoctos
          }
        }),
        documentoBase: this.store.query('com-tipo-docto', {
          filter: {
            codsistema,
            codtipodoctos
          }
        }),
        tipoDocumentos: this.store.query('emp-docto', {
          filter: {
            codsistema,
            codempresa: this.company.get('codempresa')
          }
        }),
        gruDirectorios: this.request.get('gru-directorio-by-tipo', {
          tipoClasificacion
        }).then(response => response.data),
        // TODO: Cambiar filtro de codempresa por empresa.id
        documentoSerie: this.store.query('empresa-documento-serie', {
          filter: {
            codsistema,
            codtipodoctos,
            codempresa: this.company.get('codempresa'),
            codtipo
          }
        }),
        contrasenaEdit: contrasena
      });
    },

    init() {
      this._super(...arguments);
    },

    setupController(controller, model) {
      this._super(controller, model);

      let fechaActual = (0, _moment.default)();
      let fechaToDate = fechaActual.toDate();
      let fecha = (0, _moment.default)(fechaToDate).format('YYYY-MM-DD');

      if (model.contrasenaEdit !== null) {
        let fechaPago = (0, _moment.default)(model.contrasenaEdit.fechaPago).format('YYYY-MM-DD');
        let fecha = (0, _moment.default)(model.contrasenaEdit.fecha).format('YYYY-MM-DD');
        let observaciones = model.contrasenaEdit.observaciones;
        let numero = model.contrasenaEdit.numero;
        let keytra = model.contrasenaEdit.keytra;
        let options = model.gruDirectorios;
        let contrasena = model.contrasenaEdit;

        if (options && options.get('length') > 0) {
          let codDirectorio = contrasena.coddirectorio;
          let selectedOption = options.findBy('codigo', codDirectorio);
          controller.set('selectedDirectorio', selectedOption);
          controller.send('updateClasificacion', selectedOption);
        }

        controller.set('proximoPago', fechaPago);
        controller.set('observaciones', observaciones);
        controller.set('numero', numero);
        controller.set('fecha', fecha);
        controller.set('llave', keytra);
      } else {
        controller.set('fecha', fecha);
        controller.set('selectedDirectorio', null);
        controller.set('selectedOptionClasificacion', null);
        controller.set('observaciones', null);
        controller.set('numero', 0);
        controller.set('proximoPago', null);
      }

      controller.set('contrasena', model.contrasenaEdit);
      controller.set('dropdownOptionsDirectorios', model.gruDirectorios);
      controller.set('dropdownOptionsOwnSistemas', model.ownSistemas);
      controller.set('dropdownOptionsTipoDocumentos', model.tipoDocumentos);
      controller.set('dropdownOptionsDocumentoBase', model.documentoBase);
      controller.set('dropdownOptionsDocumentoSerie', model.documentoSerie);
      controller.set('reload', true);
    }

  });

  _exports.default = _default;
});