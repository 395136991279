define("sirvo-web/routes/iva/reportes/libro-ventas", ["exports", "servir-ember-utilities/routes/abstract-module-index", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators"], function (_exports, _abstractModuleIndex, _emberChangeset, _emberChangesetValidations, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    company: Ember.computed.readOnly('currentCompany.company'),
    validations: Ember.computed(function () {
      return {
        fechaFin: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),

    model()
    /*params, transition*/
    {
      let filterModel = this.store.createRecord('reporte-cxc-integracion-saldo-cliente');
      return filterModel;
    },

    setupController(controller, model) {
      this._super(controller, model);

      let changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(this.get('validations')), this.get('validations'));
      controller.set('changeset', changeset);
    }

  });

  _exports.default = _default;
});