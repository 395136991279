define("sirvo-web/models/ods/buceo-inmersion", ["exports", "ember-data", "moment", "sirvo-web/utils/common"], function (_exports, _emberData, _moment, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    ordenDocumento: belongsTo('ods/orden-servicio-documento'),
    numero: attr('number'),
    fechaInicio: attr('custom-date'),
    fechaFin: attr('custom-date'),
    narrativa: attr('string'),
    observaciones: attr('string'),
    lineas: hasMany('ods/buceo-inmersion-linea'),
    huboInspeccion: attr('boolean'),
    horaInicio: Ember.computed('fechaInicio', {
      get() {
        let momentDate = (0, _moment.default)(this.fechaInicio || '');
        return momentDate.isValid() ? momentDate.format('HH:mm') : null;
      },

      set(propertyName, value) {
        if (!this.fechaInicio) {
          return value;
        }

        if (!(0, _common.isHour)(value)) {
          this.fechaInicio.setHours(0, 0, 0);
          return value;
        }

        let pieces = value.split(':');
        this.fechaInicio.setHours(pieces[0], pieces[1], 0);
        return value;
      }

    }),
    horaFin: Ember.computed('fechaFin', {
      get() {
        let momentDate = (0, _moment.default)(this.fechaFin || '');
        return momentDate.isValid() ? momentDate.format('HH:mm') : null;
      },

      set(propertyName, value) {
        if (!this.fechaFin) {
          return value;
        }

        if (!(0, _common.isHour)(value)) {
          this.fechaFin.setHours(0, 0, 0);
          return value;
        }

        let pieces = value.split(':');
        this.fechaFin.setHours(pieces[0], pieces[1], 0);
        return value;
      }

    })
  });

  _exports.default = _default;
});