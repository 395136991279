define("sirvo-web/routes/inventario/procesos/inventario-fisico/index/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "sirvo-web/mixins/permission-validator", "ember-changeset-validations/validators", "moment"], function (_exports, _abstractModuleRecord, _permissionValidator, _validators, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_permissionValidator.default, {
    kardex: Ember.inject.service(),
    routePermission: 'KarAgregarInventarioFisico',
    company: Ember.computed.readOnly('currentCompany.company'),
    modelName: 'kar-inventario-fisico',
    indexRoute: 'inventario.procesos.inventario-fisico.index',
    validations: Ember.computed(function () {
      return {
        empresa: (0, _validators.validatePresence)({
          presence: true
        }),
        bodega: (0, _validators.validatePresence)({
          presence: true
        }),
        fecha: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),
    relatedModelsToQuery: Ember.computed('isNew', function () {
      let queryMeta = [{
        name: 'bodegas',
        modelName: 'kar-bodega',
        queryParams: {
          filter: {
            empresa: this.company.id
          }
        }
      }, {
        name: 'tiposUbicaciones',
        modelName: 'kar-articulo-tipo-ubicacion',
        queryParams: {
          filter: {
            empresa: this.company.id
          }
        }
      }];

      if (!this.isNew) {
        queryMeta.pushObjects([{
          name: 'ubicaciones',
          modelName: 'kar-inventario-fisico-ubicacion',
          queryParams: {
            filter: {
              inventarioFisico: this.recordId
            },
            include: 'articuloUbicacion'
          }
        }]);
      }

      return queryMeta;
    }),

    createRecordInstance() {
      let fechaInicio = (0, _moment.default)().subtract(3, 'years');
      return this.store.createRecord(this.modelName, {
        empresa: this.company,
        fecha: new Date(),
        fechaInicioMovimientos: fechaInicio.toDate()
      });
    },

    setupController(controller, model) {
      this._super(...arguments);

      controller.set('ubicacionesChangesets', this.kardex.createInventarioFisicoUbicacionesChangesets(model.tiposUbicaciones, model.record));
      controller.set('ubicacionesMeta', {});
      model.tiposUbicaciones.forEach(tipoUbicacion => {
        controller.ubicacionesMeta[tipoUbicacion.id] = {
          tipoUbicacion,
          ubicaciones: Ember.A([])
        };
      });

      if (controller.changeset.get('bodega.id')) {
        this.kardex.updateBodegaUbicaciones(controller.changeset.get('bodega'), controller.ubicacionesMeta);
        controller.ubicacionesChangesets.forEach(ubicacionChangeset => {
          this.kardex.updateUbicacionSubDivisiones(ubicacionChangeset.get('tipoUbicacion'), ubicacionChangeset.get('articuloUbicacion'), controller.ubicacionesMeta);
        });
      }
    },

    actions: {
      willTransition() {
        return false;
      }

    }
  });

  _exports.default = _default;
});