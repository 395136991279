define("sirvo-web/controllers/admon/procesos/notificaciones/record", ["exports", "servir-ember-utilities/controllers/abstract-module-record", "ember-changeset", "ember-changeset-validations", "sirvo-web/validations/notification-profile"], function (_exports, _abstractModuleRecord, _emberChangeset, _emberChangesetValidations, _notificationProfile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    postSaveTransitionRouteIfNotNew: null,
    profileConfigChangesets: null,

    createProfileConfigChangeset(notificationProfile) {
      return new _emberChangeset.default(notificationProfile, (0, _emberChangesetValidations.default)(_notificationProfile.default), _notificationProfile.default, {
        skipValidate: false
      });
    },

    validate() {
      return Ember.RSVP.all([this.changeset.validate(), ...this.profileConfigChangesets.map(changeset => changeset.validate())]).then(() => {
        let changesetsAreValid = this.profileConfigChangesets.reduce((prevIsValid, profileChangeset) => prevIsValid && profileChangeset.get('isValid'), true);

        if (!this.changeset.get('isValid') || !changesetsAreValid) {
          this.toast.error('Hay errores en el formulario.');
          return Ember.RSVP.resolve(false);
        }

        let countByProfile = this.profileConfigChangesets.reduce((map, profileChangeset) => {
          profileChangeset.get('profiles').forEach(profile => {
            map[profile.get('code')] = map[profile.get('code')] || {
              name: profile.get('name'),
              count: 0
            };
            map[profile.get('code')].count += 1;
          });
          return map;
        }, {});
        let profileRepeated = Object.values(countByProfile).find(obj => obj.count > 1);

        if (profileRepeated) {
          this.toast.error(`El perfil "${profileRepeated.name}" está asignado más de una vez.`);
          return Ember.RSVP.resolve(false);
        }

        return Ember.RSVP.resolve(true);
      });
    },

    processSave() {
      let superRef = this._super.bind(this, ...arguments);

      return this.validate().then(isValid => {
        if (!isValid) {
          this.loader.setLoading(false);
          return Ember.RSVP.resolve();
        }

        this.profileConfigChangesets.forEach(changeset => changeset.execute());
        return superRef();
      });
    },

    actions: {
      discardChanges() {
        this.changeset.rollback();
        this.record.rollbackAttributes();
        this.transitionToRoute(this.get('indexRoute'));
      }

    }
  });

  _exports.default = _default;
});