define("sirvo-web/models/empresa-documento-serie", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    codempresa: (0, _model.attr)('string'),
    codsistema: (0, _model.attr)('string'),
    codtipodoctos: (0, _model.attr)('string'),
    codtipo: (0, _model.attr)('string'),
    serie: (0, _model.attr)('string'),
    numero: (0, _model.attr)('number'),
    // Relationships
    empresa: (0, _model.belongsTo)('empresa'),
    sistema: (0, _model.belongsTo)('own-sistema'),
    tipoDocumento: (0, _model.belongsTo)('emp-docto')
  });

  _exports.default = _default;
});