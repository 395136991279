define("sirvo-web/routes/ods/servicios/ordenes-servicio/record/documentos", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute"], function (_exports, _isTabSubroute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { A as emberArray } from '@ember/array';
  var _default = Ember.Route.extend(_isTabSubroute.default, {
    setupController()
    /*controller, model*/
    {
      this._super(...arguments);

      let parentCtrl = this.controllerFor(this.parentRoute);
      this.controller.resetProperties();
      this.controller.set('ordenDocumentosList', parentCtrl.get('ordenDocumentosList')); // Configuración del changeset para el documento consolidado.

      this.controller.resetDocumentoConsolidadoChangeset();
    }

  });

  _exports.default = _default;
});