define("sirvo-web/controllers/inventario/procesos/inventario-fisico/index/reporte", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    request: Ember.inject.service(),
    indexRoute: 'inventario.procesos.inventario-fisico.index',
    parameters: null,

    /**
     * NOTE: Asumimos que no debería haber más de 10 conteos.
     */
    conteos: Ember.computed(function () {
      return [...Array(10)].map((x, i) => i + 1);
    }),
    actions: {
      onClose() {
        this.transitionToRoute(this.indexRoute);
      },

      submit() {
        let data = {
          articulosFilter: this.parameters.get('articulosFilter'),
          conteo: this.parameters.get('conteo'),
          imprimirValores: this.parameters.get('imprimirValores') ? 1 : 0,
          imprimirUltimoConteo: this.parameters.get('imprimirUltimoConteo') ? 1 : 0
        };
        this.loader.setLoading(true);
        return this.request.get(`kar-inventario-fisicos/${this.model.inventarioFisico.get('id')}/reporte`, data).then(() => {
          this.toast.success('Reporte generado exitosamente');
        }).finally(() => this.loader.setLoading(false));
      }

    }
  });

  _exports.default = _default;
});