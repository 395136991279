define("sirvo-web/controllers/ods/servicios/ordenes-servicio/record/generales/anular", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    parentCtrl: Ember.inject.controller('ods.servicios.ordenes-servicio.record'),
    actions: {
      discardChanges() {
        this.changeset.rollback();
        this.record.rollbackAttributes();
        this.transitionToRoute(this.get('indexRoute'));
      },

      anular() {
        this.changeset.set('anulada', true);
        this.changeset.set('fechaAnulacion', new Date());
        this.parentCtrl.submit();
      }

    }
  });

  _exports.default = _default;
});