define("sirvo-web/routes/inventario/procesos/cargas/index", ["exports", "sirvo-web/mixins/access-validator"], function (_exports, _accessValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_accessValidator.default, {
    routeAccess: 'KAR-CARGAS',

    setupController() {
      this._super(...arguments);

      this.controller.newExcelChangeset();
      this.controller.set('data', []);
      this.controller.set('loadType', 'BODEGAS');
    }

  });

  _exports.default = _default;
});