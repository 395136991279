define("sirvo-web/services/current-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    currentCompany: Ember.inject.service(),
    toast: Ember.inject.service(),
    loader: Ember.inject.service(),
    user: null,
    permissions: null,
    menus: null,
    sidebarMenus: null,

    load() {
      const token = this.session.data.authenticated.token;

      if (!token) {
        throw Error('User not authenticated.');
      }

      const authenticator = Ember.getOwner(this).lookup('authenticator:jwt');
      const [username, codempresa] = authenticator.getTokenData(token).username.split('@', 2);

      if (!username) {
        throw Error('Username not found from session data.');
      }

      this.loader.setLoading(true);
      return this.store.query('vf-user', {
        filter: {
          coduser: {
            exact: username
          },
          codempresa: {
            exact: codempresa
          }
        },
        include: 'perfil'
      }).then(result => {
        if (0 === result.get('length')) {
          throw new Error(`Got no record for 'usuario' with username '${username}'`);
        }

        if (1 !== result.get('length')) {
          throw new Error(`Got more than one record for 'usuario' with username '${username}'`);
        }

        this.set('user', result.get('firstObject'));
        this.currentCompany.set('user', this.user);
      }).then(() => this.currentCompany.load()).then(() => this.fetchPerfilData()).then(() => {
        this.setupMenuTree();
        this.setupUserAccess();
        this.setupUserPermissions();
        return this.user;
      }).catch(error => {
        this.toast.error('No es posible acceder en este momento.');
        throw error;
      }).finally(() => this.loader.setLoading(false));
    },

    fetchUserEmpresas() {
      let emptyResolver = new Promise(resolve => resolve([]));

      if (!this.user) {
        return emptyResolver;
      }

      return this.store.query('vf-user', {
        filter: {
          coduser: this.user.get('coduser')
        }
      }).then(users => {
        let empresasCodes = users.mapBy('codempresa');

        if (!empresasCodes.length) {
          return emptyResolver;
        }

        return this.store.query('empresa', {
          filter: {
            codempresa: empresasCodes
          }
        });
      });
    },

    /**
     * Obtiene los menús y permisos asociados al perfil del usuario actual. También
     * carga los menús superiores a los que el perfil no tiene acceso, pero son
     * requeridos para mostrar los menús hijos a los que sí tiene acceso.
     *
     * @return Promise
     */
    fetchPerfilData() {
      if (!this.user) {
        throw Error('User not loaded yet.');
      }

      if (!this.user.belongsTo('perfil').id()) {
        this.set('permissions', []);
        this.toast.error('No tiene un perfil asignado.');
        return Ember.RSVP.resolve(null);
      }

      let menuIds = this.user.get('perfil.content').hasMany('menus').ids();
      let permissionIds = this.user.get('perfil.content').hasMany('permisos').ids();
      let menusPromise = Ember.RSVP.resolve([]);
      let permissionsPromise = Ember.RSVP.resolve([]);

      if (menuIds.length) {
        menusPromise = this.store.query('menu', {
          filter: {
            id: menuIds,
            activo: 1
          }
        });
      }

      if (permissionIds.length) {
        permissionsPromise = this.store.query('permiso', {
          filter: {
            id: permissionIds,
            activo: 1
          }
        });
      }

      return Ember.RSVP.hash({
        menus: menusPromise,
        permissions: permissionsPromise
      }).then(({
        menus,
        permissions
      }) => {
        this.set('permissions', permissions);
        let finalMenus = menus.toArray(); // Listado de promesas que cargan todos los menús superiores.

        let getParentsPromise = menus.map(menu => this._getParentMenus(menu));
        return Ember.RSVP.all(getParentsPromise).then(() => {
          // Una vez cargados todos los menús, localizar los menús superiores
          // que no se encuentran en el listado de menús del perfil y agregarlos.
          menus.forEach(currentMenu => {
            while (currentMenu.get('superior.id')) {
              let superior = currentMenu.get('superior.content');

              if (!finalMenus.includes(superior)) {
                finalMenus.pushObject(superior);
              }

              currentMenu = superior;
            }
          });
          this.set('menus', finalMenus);

          if (!this.menus.length) {
            this.toast.warning('Su perfil no tiene asignado ningún acceso.');
          }
        });
      });
    },

    /**
     * Carga todos los menús superiores de un menú, recursivamente en una sola promesa.
     *
     * @param  Menu menu
     * @return Promise
     */
    _getParentMenus(menu) {
      if (!menu.belongsTo('superior').id()) {
        return Ember.RSVP.resolve(null);
      }

      return menu.get('superior').then(superior => this._getParentMenus(superior));
    },

    _routeExists(routeName) {
      if (!routeName || typeof routeName !== 'string' || !routeName.trim()) {
        return false;
      }

      try {
        return Boolean(this.router.urlFor(routeName.trim()));
      } catch (error) {
        return false;
      }
    },

    _menuRouteExists(menu) {
      // If the menu doesn't have a route, ignore.
      if (!menu.get('ruta')) {
        return true;
      }

      return this._routeExists(menu.get('ruta'));
    },

    /**
     * Construye el árbol de menús para el sidebar.
     *
     * @return void
     */
    setupMenuTree() {
      if (!this.menus) {
        throw Error('Menus not loaded yet.');
      }

      let sidebarMenus = this.menus.rejectBy('superior.id').filter(this._menuRouteExists.bind(this)).sortBy('orden');
      let childMenus = this.menus.filterBy('superior.id').filter(this._menuRouteExists.bind(this)).sortBy('orden');
      sidebarMenus.forEach(menu => this._setupMenuChildren(menu, childMenus));
      this.set('sidebarMenus', sidebarMenus); // WARNING: For debuggin only
      // sidebarMenus.forEach((menu) => this._printMenuTree(menu));
    },

    /**
     * Localiza los menús hijos de un menú y se lo setea en la propiedad "children"
     * como un array. Ejecuta el proceso recursivamente sobre cada menú hijo.
     *
     * @param  Menu menu
     * @param  Menu[] childMenus
     * @return void
     */
    _setupMenuChildren(menu, childMenus) {
      menu.children = Ember.A([]);
      let menuChildren = childMenus.filterBy('superior.id', menu.id).sortBy('orden');
      menuChildren.forEach(childMenu => {
        menu.children.push(childMenu);

        this._setupMenuChildren(childMenu, childMenus);
      });
    },

    /**
     * Imprime en la consola el árbol de menús del menú recibido, recursivamente.
     * Los logs están comentados para evitar errores del linter. Hay que descomentarlos.
     *
     * @param  Menu menu
     * @return void
     */
    _printMenuTree(menu) {
      // console.group(menu.codigo);
      menu.children.forEach(childMenu => {
        this._printMenuTree(childMenu);
      }); // console.groupEnd();
    },

    setupUserAccess() {
      if (!this.menus) {
        throw Error('Menus not loaded yet.');
      }

      let userAccess = this.menus.mapBy('codigo').reduce((obj, menu) => Object.assign(obj, {
        [menu]: true
      }), {});
      this.user.set('access', userAccess);
    },

    setupUserPermissions() {
      if (!this.permissions) {
        throw Error('Permissions not loaded yet.');
      }

      let userPermissions = this.permissions.mapBy('codigo').reduce((obj, permission) => Object.assign(obj, {
        [permission]: true
      }), {});
      this.user.set('permissions', userPermissions);
    }

  });

  _exports.default = _default;
});