define("sirvo-web/routes/inventario/movimientos/cortesias/detalles", ["exports", "servir-ember-utilities/routes/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    request: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),
    modelName: 'kar-mov',
    indexRoute: 'inventario.movimientos.cortesias',
    validations: Ember.computed(function () {
      return {};
    }),

    model() {
      return this._super(...arguments).then(modelData => {
        return this.request.get(`kar-movs/${modelData.record.id}/poliza`).then(poliza => {
          return { ...modelData,
            poliza
          };
        });
      });
    },

    setupController() {
      this._super(...arguments);

      this.controller.set('reload', true);
    }

  });

  _exports.default = _default;
});