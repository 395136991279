define("sirvo-web/components/tables/cell/kar-articulo-existencia-bodega-principal", ["exports", "sirvo-web/templates/components/tables/cell/promise-value"], function (_exports, _promiseValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Este componente se usa en la tabla de artículos y su objetivo es mostrar la
   * existencia del artículo en la primera bodega asignada.
   */
  var _default = Ember.Component.extend({
    layout: _promiseValue.default,
    table: null,
    row: null,
    column: null,
    request: Ember.inject.service(),

    init() {
      this._super(...arguments); // WARNING: Por conveniencia, el API devuelve el código de la bodega que se consulta.


      this.set('value', this.getValuePromise().then(saldosBodegas => {
        if (!Array.isArray(saldosBodegas)) {
          return 'N/A';
        }

        return saldosBodegas.map(saldo => String.format('{0} => {1} ({2})', saldo.bodega_codigo || 'N/A', saldo.existencia, saldo.valor_promedio)).join('<br/>');
      }).catch(() => 'Error'));
    },

    getValuePromise() {
      let articulo = this.get('row.content');

      if (!articulo.get('ubicaciones.length')) {
        return Ember.RSVP.resolve([]);
      }

      return this.request.get(`kar-articulos/${articulo.id}/saldo?bodegasPrincipales=1`);
    }

  });

  _exports.default = _default;
});