define("sirvo-web/controllers/inventario/movimientos/ingresos/registro/ingreso", ["exports", "servir-ember-utilities/controllers/abstract-module-record", "sirvo-web/utils/format"], function (_exports, _abstractModuleRecord, _format) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    kardex: Ember.inject.service(),
    fechaCierre: Ember.computed.readOnly('currentCompany.lastCierre.fecha'),
    decimalesConfig: Ember.computed.readOnly('currentCompany.karDecimalesConfig'),
    movimiento: null,
    bodegas: null,
    unidadesMedida: null,
    postSaveTransitionRoute: Ember.computed.readOnly('indexRoute'),
    registroCerrado: Ember.computed('movimiento.fecha', 'fechaCierre', function () {
      if (!this.fechaCierre || !this.movimiento.get('fecha')) {
        return false;
      }

      return this.fechaCierre >= this.movimiento.get('fecha');
    }),

    updateDependentValues() {
      let tasaCambio = parseFloat(this.changeset.get('tasac')) || 0;
      Ember.setProperties(this.changeset, {
        subtotal: this.changeset.get('total'),
        valorNeto: this.changeset.get('total'),
        valorNetoMe: tasaCambio ? (0, _format.round)(this.changeset.get('total') / tasaCambio) : 0
      });
    },

    discardChanges() {
      this.changeset.rollback();
      this.ubicacionesChangesets.forEach(changeset => changeset.rollback()); // Eliminar los registros de ubicación.

      this.kardex.destroyUnsavedUbicaciones(this.record.get('ubicaciones')); // Es necesario que esto ocurra después de eliminar las ubicaciones.

      this.record.rollbackAttributes();
    },

    actions: {
      updateCantidad(changeset, properyName, value) {
        changeset.set(properyName, value || 0);
        this.kardex.onChangesetCantidadUpdated(changeset);
      },

      updateCosto(changeset, properyName, value) {
        changeset.set(properyName, value || 0);
        let cantidad = parseFloat(changeset.get('cantidad')) || 0;

        if (properyName === 'precioUnitario') {
          changeset.set('total', (0, _format.round)(cantidad * changeset.get('precioUnitario')));
        } else {
          changeset.set('precioUnitario', cantidad ? (0, _format.round)(changeset.get('total') / cantidad, this.decimalesConfig.costos) : 0);
        }

        this.updateDependentValues();
      },

      updateTasaCambio(changeset, properyName, value) {
        changeset.set(properyName, value || 0);
        this.updateDependentValues();
      },

      serializeArticulo(articulo) {
        return articulo ? articulo.get('codarticulo') : null;
      },

      serializeBodega(bodega) {
        return bodega ? bodega.get('codbodega') : null;
      },

      serializeUnidadMedida(unidadMedida) {
        return unidadMedida ? unidadMedida.get('codigo') : null;
      },

      cancelEdit() {
        // The route will destroy the record if it's new.
        this.transitionToRoute(this.indexRoute);
      }

    }
  });

  _exports.default = _default;
});